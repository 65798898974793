<template>
  <router-link
    :name="name"
    class="link"
    :class="theme"
    :to="link"
    @click="emitClick"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "BaseLink",
  props: {
    link: {
      type: String,
      default: "/",
    },
    name: String,
    theme: String,
  },
  methods: {
    emitClick(event) {
      this.$emit("click", event.target.value);
    },
  },
};
</script>

<style scoped>
.link {
  color: var(--current-accent-color);
  display: inline-block;
}
.base {
  color: var(--base-color);
}
.button {
  background-color: var(--current-accent-color);
  border-radius: 100px;
  color: white;
  cursor: pointer;
  display: block;
  padding: 0.875em 1.875em;
  text-align: center;
  width: 100%;
}
.disabled {
  color: var(--disabled-accent-color);
}
.button.disabled {
  background-color: var(--disabled-accent-color);
  color: var(--disabled-subaccent-color);
  pointer-events: none;
}
.alternate {
  color: var(--alternate-accent-color);
}
.button.alternate {
  background-color: var(--alternate-accent-color);
  border: 1px solid var(--border-color);
  color: var(--company-accent-color);
}
.primary {
  color: var(--default-accent-color);
}
.button.primary {
  background-color: var(--default-accent-color);
}
.facebook {
  color: var(--facebook-accent-color);
}
.button.facebook {
  background-color: var(--facebook-accent-color);
}
.worker {
  color: var(--worker-accent-color);
}
.button.worker {
  background-color: var(--worker-accent-color);
}
.company {
  color: var(--company-accent-color);
}
.button.company {
  background-color: var(--company-accent-color);
}
.button.auto {
  width: auto;
}
.button.round {
  padding: 0.875em 0.75em;
  width: auto;
}
.button.narrow {
  padding: 0.875em;
}
.button.with-space {
  margin-bottom: 0.5em;
}
</style>
