<template>
  <div class="about">
    <h1>About</h1>
    <p>{{ msg }}</p>
    <p>{{ msg }}</p>
    <p>{{ msg }}</p>
    <p>{{ msg }}</p>
    <p>{{ msg }}</p>
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  name: "PageAbout",
  title: "About",
  components: {},
  data() {
    return {
      msg: "This is the about page Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ut libero lobortis, fermentum urna a, sodales neque. Nulla sem elit, sodales eget sem lobortis, laoreet luctus tortor. Donec tempor at odio id consectetur. Sed nec massa et erat cursus convallis. Sed sit amet sem malesuada, dignissim felis id, bibendum nibh. Donec fringilla nunc purus, vitae rhoncus augue convallis ac. Sed elementum, quam ac finibus consequat, metus dui rhoncus arcu, vel sollicitudin justo est facilisis magna. Sed lobortis massa sed efficitur vulputate.",
    };
  },
};
</script>

<style>
</style>
