<template>
  <form id="horse" enctype="multipart/form-data" @submit.prevent="saveHorse()">
    <AvatarUpload
      name="avatar"
      :avatar="horse.avatar"
      avatar-type="horse"
    ></AvatarUpload>

    <BaseInput
      name="usef_name"
      label-text="Registered/Show Name"
      v-model="horse.usef_name"
    />
    <BaseInput name="nickname" label-text="Nickname" v-model="horse.nickname" />
    <BaseInput
      name="usef_number"
      label-text="USEF#"
      v-model="horse.usef_number"
    />
    <BaseInput
      type="date"
      name="date_of_birth"
      label-text="D.O.B."
      v-model="horse.date_of_birth"
    />
    <BaseSelect name="sex" label-text="Sex" v-model="horse.sex">
      <option v-for="sex in horseSexes" :key="sex.id" :value="sex.value">
        {{ sex.text }}
      </option>
    </BaseSelect>
    <div class="stats-height">
      <base-input
        name="height"
        label-text="Height"
        v-model="horse.height"
        style="width: 60%"
        class="horse-profile-input-inline"
      />
      <base-select
        id="horse-height-size-type"
        :label="true"
        name="height_type"
        v-model="horse.height_type"
        style="width: 39%"
        class="horse-profile-input-inline"
      >
        <option value="hands" selected>Hands</option>
        <option value="centimeters">Cm</option>
      </base-select>
    </div>
    <base-input
      name="color"
      label-text="Color"
      v-model="horse.color"
      class="horse-profile-input"
    />
    <base-input
      name="breed"
      label-text="Breed"
      v-model="horse.breed"
      class="horse-profile-input"
    />
    <BaseSelect
      name="location_id"
      label-text="Location"
      v-model="currentLocation"
      v-if="userLocations.length > 0"
    >
      <option
        v-for="userLocation in userLocations"
        :key="userLocation.location.id"
        :value="userLocation.location.id"
      >
        {{ userLocation.location.name }}
      </option>
    </BaseSelect>
    <div
      class="worker-editables"
      v-if="
        currentUser.account_type === 'worker' ||
        currentUser.account_type === 'trainer' ||
        currentUser.account_type === 'company'
      "
    >
      <div class="lesson-horse">
        <label class="container"
          >Lesson horse
          <input type="checkbox" v-model="horse.lesson_horse" />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="skill-level-container" v-if="this.horse.lesson_horse">
        <section class="skill-level">
          <div class="skill-info">
            <div class="skill-info-label">Adjust skill level</div>
            <div class="skill-info-tooltip">
              <font-awesome-icon :icon="['fa', 'info-circle']" fixed-width />
            </div>
          </div>
          <div class="skill-adjust">
            <div>Beginner</div>
            <div
              class="skill-button active-button"
              ref="button1"
              @click="setSkill(1)"
            ></div>
            <div class="skill-button" ref="button2" @click="setSkill(2)"></div>
            <div class="skill-button" ref="button3" @click="setSkill(3)"></div>
            <div class="skill-button" ref="button4" @click="setSkill(4)"></div>
            <div class="skill-button" ref="button5" @click="setSkill(5)"></div>
            <div>Expert</div>
          </div>
        </section>
      </div>
    </div>
    <base-text-area
      name="about"
      :label="true"
      label-text="About"
      rows="6"
      v-model="horse.about"
    />
    <Flex justify="between">
      <BaseButton type="submit">Save Horse</BaseButton>
      <BaseButton
        v-if="horse.id"
        type="button"
        theme="alternate"
        @click="promptDeleteHorse()"
        >Delete Horse</BaseButton
      >
    </Flex>
    <loading-modal v-if="showLoading"></loading-modal>
  </form>
</template>

<script>
import AvatarUpload from "../utils/AvatarUpload.vue";
import Flex from "../style/Flex.vue";
import BaseTextArea from "../base/BaseTextArea";
import { mapState } from "vuex";
import LoadingModal from "../utils/LoadingModal";

export default {
  name: "HorseProfileForm",
  components: { AvatarUpload, Flex, LoadingModal },
  props: {
    horse: {
      type: Object,
      default: {},
    },
    currentUserId: Number,
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      horseSexes: [
        { id: 1, value: "mare", text: "Mare" },
        { id: 2, value: "gelding", text: "Gelding" },
        { id: 3, value: "stallion", text: "Stallion" },
      ],
      userLocations: [],
      currentLocation: this.horse.location_id,
      showLoading: false,
    };
  },

  watch: {
    "horse.height": function (val) {
      if (val < 0) {
        this.horse.height = 0;
      }
      if (this.horse.height_type == "hands") {
        if (val % 1 > 0.3) {
          this.horse.height = (val | 0) + 0.3;
        }
      }
    },
  },

  mounted() {
    if (!this.horse.height_type) {
      this.horse.height_type = "hands";
    }
    this.getUserLocations(this.currentUserId);
    if (this.horse.skill_level) {
      this.setSkill(this.horse.skill_level);
    }
  },

  methods: {
    saveHorse() {
      const data = new FormData(document.getElementById("horse"));
      this.showLoading = true;
      data.append("owner_id", this.currentUserId);
      if (this.horse.lesson_horse) {
        data.append("skill_level", this.horse.skill_level);
        data.append("lesson_horse", this.horse.lesson_horse);
      }
      // Determine which method to call based on whether or not horse record previously exists
      const functionName = this.horse.id
        ? "horse/UPDATE_HORSE"
        : "horse/ADD_HORSE";
      const params = { id: this.horse.id, horse: data };

      this.$store
        .dispatch(functionName, params)
        .then((response) => {
          document.location.reload(true);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    getUserLocations(userId) {
      this.$store
        .dispatch("location/GET_USER_LOCATIONS", userId)
        .then((response) => {
          this.userLocations = response.data;
        });
    },

    promptDeleteHorse() {
      const deleteHorse = confirm(
        "Would you like to delete this horse profile?"
      );
      if (deleteHorse) this.deleteHorse();
    },

    deleteHorse() {
      this.$store
        .dispatch("horse/DELETE_HORSE", this.horse.id)
        .then((response) => {
          document.location.reload(true);
        });
    },
    setSkill(level) {
      this.horse.skill_level = level;
      const buttons = [
        this.$refs.button1,
        this.$refs.button2,
        this.$refs.button3,
        this.$refs.button4,
        this.$refs.button5,
      ];
      for (let i = 0; i < 5; i++) {
        if (this.horse.skill_level - 1 >= i) {
          buttons[i].classList.add("active-button");
        } else {
          buttons[i].classList.remove("active-button");
        }
      }
    },
  },
};
</script>

<style scoped>
.horse-profile-input-inline {
  display: inline-block;
}

#horse-height-size-type {
  right: 0px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #f1f1f1;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #073354;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.skill-level {
  margin-bottom: 15px;
}
</style>
