import axios from "axios";
import {
  AUTH_SUCCESS,
  REMOVE_USER_DATA,
  SET_USER,
  LOGIN,
  LOGOUT,
  LOGIN_ATTEMPT,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_FROM_TOKEN,
  FETCH_USER,
  GET_USER_RATING,
  CREATE_USER_RATING,
  UPDATE_USER_RATING,
  CHANGE_PASSWORD,
} from "../actions/user";

const clearTokenFromLocaleStorage = () => localStorage.removeItem("user-token");

// Initial State
const state = {
  currentUser: {},
  token: localStorage.getItem("user-token") || "",
};

// Getters
const getters = {
  isAuthenticated: (state) => !!state.token,
};

// Actions
const actions = {
  LOGIN_ATTEMPT(_, user) {
    axios.post("/authenticate", {
      user: { email: user.email, password: user.password },
    });
  },

  GET_USER_RATING({ commit }, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("skill_ratings", { params: queryParams })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  CREATE_USER_RATING({ commit }, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "skill_ratings?user_id=" +
            queryParams.user_id +
            "&company_id=" +
            queryParams.company_id +
            "&skill_rating=" +
            queryParams.skill_rating
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_USER_RATING({ commit }, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "skill_ratings/" +
            queryParams.rate_id +
            "?user_id=" +
            queryParams.user_id +
            "&company_id=" +
            queryParams.company_id +
            "&skill_rating=" +
            queryParams.skill_rating
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  async LOGIN({ commit, dispatch }, user) {
    commit("global/START_LOADING", "", { root: true });

    try {
      // dispatch(LOGIN_ATTEMPT, user)
      const loginAttempt = await axios.post("/authenticate", {
        user: { email: user.email, password: user.password },
      });

      localStorage.setItem("user-token", loginAttempt.data.token);
      axios.defaults.headers.common["Authorization"] = loginAttempt.data.token;
      commit(AUTH_SUCCESS, loginAttempt.data.token);
      commit(SET_USER, loginAttempt.data.user);
      dispatch("global/CHANGE_THEME", loginAttempt.data.user.account_type, {
        root: true,
      });
      commit("global/GLOBAL_SUCCESS", "", { root: true });
    } catch (error) {
      commit("global/GLOBAL_ERROR", error.message, { root: true });
      commit(REMOVE_USER_DATA);
      clearTokenFromLocaleStorage();
    }

    commit("global/STOP_LOADING", "", { root: true });
  },

  LOGOUT({ commit, dispatch }) {
    return new Promise((resolve) => {
      commit(REMOVE_USER_DATA);
      clearTokenFromLocaleStorage();
      delete axios.defaults.headers.common["Authorization"];
      dispatch("global/CHANGE_THEME", "default", { root: true });
      commit("global/GLOBAL_SUCCESS", "", { root: true });
      resolve();
    });
  },

  GET_USER_FROM_TOKEN({ commit, dispatch, state, getters }) {
    if (!getters.isAuthenticated) return;

    return new Promise((resolve, reject) => {
      axios
        .get("/user_from_token", { token: state.token })
        .then((response) => {
          commit(SET_USER, response.data);
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          dispatch("global/CHANGE_THEME", response.data.account_type, {
            root: true,
          });
          resolve(response);
        })
        .catch((error) => {
          commit(REMOVE_USER_DATA);
          clearTokenFromLocaleStorage();
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  async CREATE_USER({ commit, dispatch }, user) {
    try {
      await axios.post("/users", user);
      dispatch(LOGIN, user);
    } catch (error) {
      commit(REMOVE_USER_DATA);
      clearTokenFromLocaleStorage();
      commit("global/GLOBAL_ERROR", error.message, { root: true });
      if (error.response.status == 409) {
        window.location.href = "#/login";
      }
    }
  },

  UPDATE_USER({ commit }, userParams) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/users/${userParams.id}`, userParams.user, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          if (userParams.finished) {
            commit("FINISH_USER_SETUP");
          }
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  DELETE_USER({ commit, dispatch }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/users/${userId}`)
        .then((response) => {
          dispatch(LOGOUT);
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  FETCH_USER({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/users/${userId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  SEARCH_USERS({ commit }, searchQuery) {
    return new Promise((resolve, reject) => {
      axios
        .get("/users", { params: { search_query: searchQuery } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  CHANGE_PASSWORD({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put("/users/" + params.userId, {
          password: params.newPassword,
          password_confirmation: params.confirmPassword,
          old_password: params.oldPassword,
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
};

// Mutations
const mutations = {
  [AUTH_SUCCESS]: (state, token) => {
    state.hasError = false;
    state.token = token;
  },

  [REMOVE_USER_DATA]: (state) => {
    state.token = "";
    state.currentUser = {};
  },

  [SET_USER]: (state, user) => {
    state.currentUser = user;
  },

  FINISH_USER_SETUP: (state) => {
    if (state && state.currentUser) {
      state.currentUser.registration_finished = true;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
