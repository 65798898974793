import axios from "axios";
import {
  GET_ACTIVITIES,
  GET_COMPANY_ACTIVITIES,
  GET_TRAINER_ACTIVITIES,
  CREATE_TRAINER_ACTIVITY,
  GET_USER_ACTIVITY,
  DESTROY_TRAINER_ACTIVITY,
  CREATE_USER_ACTIVITY,
  UPDATE_USER_ACTIVITY,
  GET_USER_ACTIVITIES,
  GET_TRAINER_ACTIVITY,
  TRAINER_DECLINE_USER_ACTIVITY,
  CREATE_COMPANY_ACTIVITY,
  UPDATE_COMPANY_ACTIVITY,
  DELETE_COMPANY_ACTIVITY,
  GET_COMPANY_USER_ACTIVITIES,
  GET_RECENT_ACTIVITIES,
  EDIT_CUSTOM_ACTIVITY,
  DELETE_CUSTOM_ACTIVITY,
  GET_UNAPPROVED_ACTIVITIES,
  CREATE_TRAINER_USER_ACTIVITY,
} from "../actions/activity";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  GET_RECENT_ACTIVITIES({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user_activities?recent=true&user_id=" + userId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  GET_UNAPPROVED_ACTIVITIES({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user_activities?unapproved=true&user_id=" + userId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  GET_ACTIVITIES({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/activities")
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_COMPANY_ACTIVITIES({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company_activities", {
          params: { company_id: params.companyId, type: params.type },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_TRAINER_ACTIVITIES({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/trainer_activities", {
          params: { user_id: params.userId, type: params.type },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_TRAINER_ACTIVITY({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/trainer_activities/${id}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  CREATE_TRAINER_ACTIVITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/trainer_activities", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  DESTROY_TRAINER_ACTIVITY({ commit }, employee_activity_id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/trainer_activities/${employee_activity_id}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  CREATE_USER_ACTIVITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user_activities", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  ACCEPT_USER_ACTIVITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/user_activities/${params.id}`, params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  UPDATE_USER_ACTIVITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/trainer_user_activities/${params.id}`, params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_USER_ACTIVITIES({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user_activities", {
          params: { user_id: params.userId, limit: params.limit },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_TRAINER_USER_ACTIVITIES({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/trainer_user_activities", {
          params: {
            trainer_id: params.trainerId,
            limit: params.limit,
            for_approval: params.forApproval,
          },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  CREATE_TRAINER_USER_ACTIVITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/trainer_user_activities", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_COMPANY_USER_ACTIVITIES({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company_user_activities", {
          params: { company_id: params.companyId, limit: params.limit },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_USER_ACTIVITY({ commit }, userActivityId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user_activities/${userActivityId}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  TRAINER_DECLINE_USER_ACTIVITY({ commit }, userActivityId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/trainer_user_activities/${userActivityId}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  RIDER_DECLINE_USER_ACTIVITY({ commit }, userActivityId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user_activities/${userActivityId}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  CREATE_COMPANY_ACTIVITY({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/company_activities", data)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  UPDATE_COMPANY_ACTIVITY({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company_activities/${data.companyActivityId}`, data)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  DELETE_COMPANY_ACTIVITY({ commit }, companyActivityId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/company_activities/${companyActivityId}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  CREATE_CUSTOM_ACTIVITY({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/custom_company_activities", data)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  EDIT_CUSTOM_ACTIVITY({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/custom_company_activities/" + param.companyActivityId,
          param.data
        )
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  DELETE_CUSTOM_ACTIVITY({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/custom_company_activities/" + param.companyActivityId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
