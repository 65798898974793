<template>
  <div>
    <h2 class="section-title">Search Barns</h2>

    <BaseInput
      name="location_name"
      label-text="Search for Barns:"
      v-model="searchQuery"
      v-debounce:500ms="searchLocations"
    />

    <BasePanel v-if="this.noResults">
      <Flex align="center" justify="center">
        <font-awesome-icon
          :icon="['fas', 'info-circle']"
          class="noResults"
        ></font-awesome-icon>
        <p>No Results Found</p>
      </Flex>
    </BasePanel>

    <LocationSearchResult
      v-if="foundBarns"
      v-for="foundBarn in foundBarns"
      :key="foundBarn.id"
      :location="foundBarn"
    >
    </LocationSearchResult>

    <div v-if="errorMessage">
      <p class="error">{{ errorMessage }}</p>
    </div>

    <div v-if="barns.length">
      <h2 class="section-title">My Barns</h2>
      <LocationUserPanel
        v-for="barn in barns"
        :location="barn"
        :key="barn.id"
      />

      <h2 class="section-title">Home Barn</h2>
      <p>
        Your home barn is where you usually take your lessons. We'll
        automatically choose it when booking activities.
      </p>
      <div class="space--large" />
      <BaseSelect
        id="home_barn"
        :label="false"
        placeholder="Select Home Barn"
        v-model="homeBarnId"
        v-if="hasBarns"
      >
        <option v-for="barn in barns" :key="barn.id" :value="barn.id">
          {{ barn.location.name }}
        </option>
      </BaseSelect>

      <BaseButton @click="setHomeBarn()">Save & Continue</BaseButton>
    </div>
    <loading-modal v-if="showLoading" />
  </div>
</template>

<script>
import ButtonAddRemove from "../../buttons/ButtonAddRemove";
import Flex from "../../style/Flex";
import LocationUserPanel from "../../locations/LocationUserPanel";
import LocationSearchResult from "../../locations/LocationSearchResult";
import LoadingModal from "../../utils/LoadingModal";

export default {
  name: "Locations",
  title: null,
  components: {
    Flex,
    ButtonAddRemove,
    LoadingModal,
    LocationSearchResult,
    LocationUserPanel,
  },
  props: {
    currentIndex: Number,
    steps: Array,
  },

  data() {
    return {
      searchQuery: "",
      barns: [],
      foundBarns: [],
      //currentBarn: {},
      homeBarnId: null,
      currentUser: {},
      errorMessage: "",
      showLoading: false,
      noResults: false,
    };
  },

  computed: {
    hasBarns: function () {
      return this.barns.length > 0;
    },
  },

  async mounted() {
    this.showLoading = true;
    await this.getUser();
  },

  methods: {
    async getUser() {
      const user = await this.$store.dispatch("user/GET_USER_FROM_TOKEN");
      this.currentUser = user.data;
      this.getUserBarns(this.currentUser.id);
    },

    searchLocations() {
      if (this.searchQuery) {
        this.noResults = false;
        this.$store
          .dispatch("location/SEARCH_LOCATIONS", this.searchQuery)
          .then((response) => {
            this.foundBarns = response.data.filter((r) =>
              this.barns.every((brn) => brn.location.id != r.id)
            );
            if (this.foundBarns.length == 0) {
              this.noResults = true;
            }
          });
      }
    },

    getUserBarns(userId) {
      this.$store
        .dispatch("location/GET_USER_LOCATIONS", userId)
        .then((response) => {
          this.barns = response.data;
          const filteredBards = this.barns.filter((b) => b.home_location);
          this.homeBarnId = filteredBards.length ? filteredBards[0].id : "";
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    setHomeBarn() {
      const newPath = this.steps[this.currentIndex + 1].value;
      if (!this.homeBarnId) {
        this.errorMessage = "Please choose a Home Barn before continuing.";
        //what if they don't is this necessary?
        return;
      }

      this.$store
        .dispatch("location/SET_HOME_LOCATION", this.homeBarnId)
        .then((response) => {
          this.errorMessage = "";
          this.$router.push(newPath);
        });
    },
  },
};
</script>

<style scoped>
p,
a {
  font-size: 1.125em;
}
.close {
  font-size: 2.5em;
}
.add-a-barn {
  color: var(--border-color);
  font-size: 1.3125em;
  font-weight: var(--semibold);
  text-transform: uppercase;
}
.error {
  font-size: 1.25em;
  color: red;
  padding: 1em;
}
</style>
