<template>
  <BasePanel type="active">
    <Flex justify="between">
      <Flex justify="start">
        <Avatar size="small" :image-source="user.avatar" />
        <div>
          <p class="name">{{ user.name }}</p>
        </div>
      </Flex>
      <slot />
    </Flex>
  </BasePanel>
</template>

<script>
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";

export default {
  name: "UserPanel",
  props: { user: Object },
  components: { Flex, Avatar },
  computed: {
    addRemove() {
      if (this.following) return "remove";
      return "add";
    },
  },

  methods: {
    emitEdit() {
      this.$emit("edit-user", this.user);
    },
  },
};
</script>

<style scoped>
.name {
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 0.1em;
}
.trophy {
  color: var(--gold);
  font-size: 0.75em;
}
.lessons {
  color: var(--dark-grey);
  font-size: 0.875em;
}
</style>
