// Initial State
const state = {
  expanded: false,
};

// Getters
const getters = {};

// Actions
const actions = {
  toggleTheSidebar({ commit }) {
    commit("toggleTheSidebar");
  },
  openTheSidebar({ commit }) {
    commit("openTheSidebar");
  },
  closeTheSidebar({ commit }) {
    commit("closeTheSidebar");
  },
};

// Mutations
const mutations = {
  toggleTheSidebar(state) {
    state.expanded = !state.expanded;
  },
  openTheSidebar(state) {
    state.expanded = true;
  },
  closeTheSidebar(state) {
    state.expanded = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
