<template>
  <form id="activity" @submit.prevent="submitPropsToNextRoute">
    <h1 class="page-title">Schedule Activity</h1>
    <h2 class="section-title">Select Location</h2>
    <div v-show="!loadedBarns || barns.length > 0">
      <Flex justify="center" direction="column">
        <div class="home-barn-warning" v-if="homeBarnId === undefined">
          Pick a home barn under settings to have it be chosen by default
        </div>
        <Cover :image-source="selectedBarnAvatar" />
        <BaseSelect
          class="location-select"
          v-model="selectedBarnId"
          @change.native="getBarnTrainers()"
        >
          <option
            v-for="barn in barns"
            :key="barn.location.id"
            :value="barn.location.id"
          >
            {{ barn.location.name }}
          </option>
        </BaseSelect>
      </Flex>
      <div class="space--large">
        <h2 class="section-title">Select Trainer</h2>

        <div v-show="trainers.length > 0">
          <TrainerPanel
            :trainer="selectedTrainer"
            @click.native="openTrainerModal()"
          >
            <ButtonEdit />
          </TrainerPanel>

          <Modal ref="trainerModal">
            <Flex justify="stretch" direction="column">
              <h2>Select a Trainer for this activity</h2>
              <Flex class="trainer-modal" direction="column">
                <TrainerPanel
                  v-for="trainer in trainers"
                  :key="trainer.id"
                  :trainer="trainer"
                  @click.native="setSelectedTrainer(trainer.id)"
                >
                  <buttonAddRemove />
                </TrainerPanel>
              </Flex>
            </Flex>
          </Modal>
        </div>

        <div v-show="!(trainers.length > 0)">
          There are currently no trainers at this barn.
        </div>
      </div>

      <div class="space--large" v-if="trainers.length > 0">
        <h2 class="section-title">Select Activity</h2>

        <div v-if="activities.length > 0">
          <ActivityPanel
            :activity="selectedActivity.activity"
            @click.native="openActivityModal()"
          >
            <ButtonEdit />
          </ActivityPanel>

          <Modal v-if="activities.length > 0" ref="activityModal">
            <Flex justify="stretch" direction="column">
              <h2>Select an Activity</h2>
              <Flex class="activity-modal" direction="column">
                <ActivityPanel
                  v-for="activity in activities"
                  :key="activity.id"
                  :activity="activity.activity"
                  @click.native="setSelectedActivity(activity.id)"
                >
                  <ButtonAddRemove />
                </ActivityPanel>
              </Flex>
            </Flex>
          </Modal>
        </div>
      </div>

      <div class="space--large" v-if="trainers.length > 0 && showHorse">
        <h2 class="section-title">Select Horse</h2>
        <div>
          <HorsePanel :horse="selectedHorse" @click.native="selectHorse">
            <BaseButton theme="round alternate">
              <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
            </BaseButton>
          </HorsePanel>
          <activity-horse-select-modal
            id="horse-modal"
            :horses="horses"
            v-if="showHorseModal"
            @sethorsetonull="setHorseToNull"
            @selecthorsefrommodal="selectHorseFromModal"
          />
        </div>
      </div>

      <div>
        <form @submit.prevent="goToConfirmationPage()">
          <h1>Select a Date for Activity:</h1>
          <div
            v-if="
              this.activities.length &&
              this.selectedActivity &&
              this.selectedBarnId &&
              this.selectedTrainer.id
            "
          >
            <TrainerScheduleTimeSlots
              :employee-activity="selectedActivity"
              :location-id="this.selectedBarnId"
              :trainer-id="this.selectedTrainer.id"
              @set-time-slot="selectTimeSlot"
              v-if="this.showLoading == false"
            />
          </div>

          <div v-if="error">
            {{ error }}
          </div>

          <BaseButton type="submit">Save & Continue</BaseButton>
        </form>
      </div>
    </div>
    <div v-show="!(!loadedBarns || barns.length > 0)">
      <router-link to="/register/locations">
        <base-button>Please add a barn</base-button>
      </router-link>
    </div>
    <loading-modal v-if="showLoading" />
  </form>
</template>

<script>
import { mapState } from "vuex";
import Cover from "../utils/Cover";
import Flex from "../style/Flex";
import Modal from "../base/BaseModal.vue";

import ButtonAddRemove from "../buttons/ButtonAddRemove";
import BaseButton from "../base/BaseButton";
import ButtonEdit from "../buttons/ButtonEdit";
import TrainerPanel from "../trainers/TrainerPanel";
import ActivityPanel from "../activities/ActivityPanel";
import HorsePanel from "../horses/HorsePanel";
import ActivityHorseSelectModal from "../horses/ActivityHorseSelectModal";
import TrainerScheduleTimeSlots from "../trainers/TrainerScheduleTimeSlots";

import CalendarPanel from "../calendars/CalendarPanel";
import LoadingModal from "../utils/LoadingModal";

export default {
  name: "PageActivitiesSignup",
  components: {
    Flex,
    Cover,
    ActivityHorseSelectModal,
    Modal,
    HorsePanel,
    ButtonAddRemove,
    LoadingModal,
    ButtonEdit,
    TrainerPanel,
    ActivityPanel,
    CalendarPanel,
    BaseButton,
    TrainerScheduleTimeSlots,
  },

  data() {
    return {
      activities: [],
      selectedActivity: {},
      barns: [],
      loadedBarns: false,
      trainers: [],
      selectedTrainer: {},
      selectedBarnId: undefined,
      homeBarnId: undefined,
      currentSchedule: {},
      horses: [],
      selectedHorseId: undefined,
      showHorseModal: false,
      showLoading: false,
      activityDate: null,
      selectedTimeSlot: null,
      trainerSchedule: {},
      error: "",
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    selectedBarnAvatar() {
      const currentBarn = this.barns.filter(
        (r) => r.location.id == this.selectedBarnId
      )[0];
      if (currentBarn) {
        return currentBarn.location.company.avatar;
      } else {
        return "";
      }
    },
    showHorse() {
      if (this.selectedTrainer.company_settings) {
        return (
          this.selectedTrainer.company_settings.filter((s) => {
            return s.name == "no_horse_selection";
          }).length == 0
        );
      }
    },
    selectedHorse() {
      if (this.selectedHorseId) {
        const horses = this.horses.filter((r) => r.id == this.selectedHorseId);
        if (horses) {
          return horses[0];
        }
      } else if (this.$route.query.horse_id) {
        const horses = this.horses.filter(
          (r) => r.id == this.$route.query.horse_id
        );
        if (horses) {
          return horses[0];
        }
      }
      return null;
    },
    selectedCompanyId() {
      return this.selectedTrainer.company_id;
    },
  },

  async mounted() {
    this.showLoading = true;
    await this.getBarns();
    if (this.$route.query.locationId) {
      this.preselectData();
    }
  },

  methods: {
    async preselectData() {
      let selectedLocation =
        this.barns.filter((barn) => {
          return barn.location.id == this.$route.query.locationId;
        })[0] || null;
      if (selectedLocation != null) {
        this.selectedBarnId = selectedLocation.location.id;
      } else {
        await this.$store.dispatch("location/CREATE_USER_LOCATION", {
          user_id: this.currentUser.id,
          location_id: this.$route.query.locationId,
        });
        await this.getBarns();
        await this.preselectData();
        return;
      }
      this.activities = [];
      await this.getBarnTrainers();
    },
    selectHorseFromModal(e) {
      this.selectedHorseId = e.id;
      this.showHorseModal = false;
    },

    setHorseToNull() {
      this.selectedHorseId = null;
      this.showHorseModal = false;
    },

    selectHorse() {
      this.showHorseModal = true;
    },
    async getBarns() {
      await this.$store
        .dispatch("location/GET_USER_LOCATIONS", this.currentUser.id)
        .then((response) => {
          this.barns = response.data;
          if (this.barns.length > 0 && !this.$route.query.locationId) {
            // MARK this is where to set home barn default for home barn

            const homeBarnsArray = this.barns.filter((r) => r.home_location);
            if (homeBarnsArray && homeBarnsArray.length > 0) {
              this.selectedBarnId = homeBarnsArray[0].location.id;
              this.homeBarnId = this.selectedBarnId;
            } else {
              this.selectedBarnId = this.barns[0].location.id;
            }
            this.getBarnTrainers();
          }
        })
        .finally(() => {
          this.loadedBarns = true;
          if (this.selectedBarnId === undefined) {
            this.showLoading = false;
          }
        });
    },

    async getBarnTrainers() {
      await this.$store
        .dispatch("trainer/LOCATION_TRAINERS", this.selectedBarnId)
        .then((response) => {
          this.trainers = response.data;
          if (this.trainers[0]) {
            this.setSelectedTrainer(this.trainers[0].id);
          } else {
            this.showLoading = false;
          }
        })
        .finally(async () => {
          if (this.$route.query.tarinerId) {
            await this.setSelectedTrainer(this.$route.query.tarinerId);
          }
        });
    },

    openTrainerModal() {
      this.$refs.trainerModal.openModal();
    },

    closeTrainerModal() {
      if (this.$refs.trainerModal) {
        this.$refs.trainerModal.closeModal();
      }
    },

    openActivityModal() {
      this.$refs.activityModal.openModal();
    },

    closeActivityModal() {
      this.$refs.activityModal.closeModal();
    },

    setSelectedTrainer(id) {
      let auxTrainer = this.trainers.find((t) => t.id == id);
      if (auxTrainer) {
        this.selectedTrainer = auxTrainer;
      }
      if (this.selectedTrainer) {
        this.fetchTrainerServices(this.selectedTrainer.id);
        this.fetchBarnHorses();
      }
      this.closeTrainerModal();
    },

    fetchTrainerServices(trainerId) {
      if (trainerId) {
        this.$store
          .dispatch("activity/GET_TRAINER_ACTIVITIES", {
            userId: trainerId,
            type: "lessons",
          })
          .then((response) => {
            this.activities = response.data;
            if (this.activities[0]) this.selectedActivity = this.activities[0];
          })
          .finally(() => {
            if (this.$route.query.employeeActivityId) {
              let selectedActivity =
                this.activities.filter((act) => {
                  return (
                    act.company_activity_id ==
                    this.$route.query.employeeActivityId
                  );
                })[0] || null;
              if (selectedActivity) {
                this.selectedActivity = selectedActivity;
              }
            }
            this.showLoading = false;
          });
      }
    },

    fetchBarnHorses() {
      const skill_ratings = this.currentUser.skill_ratings.filter(
        (rating) => rating.company_id == this.selectedCompanyId
      );
      let skill_level = skill_ratings.skill_level;
      const location_id = this.selectedBarnId;
      const lesson_horse = true;
      const owner_id = this.currentUser.id;
      if (skill_ratings && skill_ratings.length > 0) {
        let skill_rating = skill_ratings[0];
        skill_level = skill_rating.skill_level;
      } else {
        skill_level = 0;
      }
      this.$store
        .dispatch("horse/GET_HORSES_FOR_ACTIVITY", {
          skill_level,
          location_id,
          lesson_horse,
          owner_id,
        })
        .then((response) => {
          this.horses = response.data;
          this.setMyHorse();
        });
    },

    setMyHorse() {
      let myHorses = this.horses.filter(
        (x) => x.owner_id == this.currentUser.id
      );

      if (this.currentUser.account_type == "personal" && myHorses.count != 0) {
        this.selectedHorseId = myHorses[0].id;
      }
    },

    setSelectedActivity(id) {
      this.selectedActivity = this.activities.find((a) => a.id === id);
      this.closeActivityModal();
    },

    submitPropsToNextRoute() {
      const data = {
        employeeActivity: this.selectedActivity,
        schedulerId: this.selectedTrainer.id,
        locationId: this.selectedBarnId,
        horseId: this.selectedHorseId,
      };
      this.$router.push({ name: "activity-date", params: data });
    },

    selectTimeSlot(data) {
      this.selectedTimeSlot = data.timeSlot;
      this.activityDate = data.activityDate;
    },

    goToConfirmationPage() {
      if (!this.selectedTimeSlot) {
        this.errorMessage("You need to select a time slot before continuing.");
      } else {
        const userActivity = {
          employee_activity_id: this.selectedActivity.id,
          participant_id: this.currentUser.id,
          location_id: this.selectedBarnId,
          horse_id: this.selectedHorseId,
        };
        const activityBlock = {
          schedule_chunk_id: this.selectedTimeSlot.schedule_chunk_id,
          start_time: this.composeDatetime(this.selectedTimeSlot.start_time),
          end_time: this.composeDatetime(this.selectedTimeSlot.end_time),
        };

        this.$router.push({
          name: "activity-payment",
          params: { userActivity, activityBlock },
        });
      }
    },

    errorMessage(message) {
      this.error = message;
    },

    composeDatetime(time) {
      let parsedTime = this.moment.utc(time);
      let date = this.moment.utc(
        this.activityDate.substring(0, 10),
        "YYYY-MM-DD"
      );
      date.hour(parsedTime.hour());
      date.minutes(parsedTime.minutes());

      return date.format();
    },
  },
};
</script>

<style scoped>
.location-select {
  position: relative;
  transform: translateY(-50%);
  width: 90%;
}

.activity-modal {
  width: 90%;
}

.trainer-modal {
  width: 90%;
}

.home-barn-warning {
  width: 90%;
  margin-bottom: 10px;
  text-align: center;
}

#horse-modal {
  z-index: 3;
}
</style>
