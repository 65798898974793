export const CREATE_LOCATION = "CREATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATION = "GET_LOCATION";
export const GET_USER_LOCATIONS = "GET_USER_LOCATIONS";
export const GET_EMPLOYER_LOCATIONS = "GET_EMPLOYER_LOCATIONS";
export const UPDATE_USER_LOCATIONS = "UPDATE_USER_LOCATIONS";
export const SEARCH_LOCATIONS = "SEARCH_LOCATIONS";
export const CREATE_USER_LOCATION = "CREATE_USER_LOCATION";
export const DELETE_USER_LOCATION = "DELETE_USER_LOCATION";
