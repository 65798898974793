<template>
  <div>
    <div class="horizontal-line"></div>
    <p class="explication">
      Service Packages are a way to bundle credits for activities together for a
      fixed cost. For example, you might create a 10 lesson pack for a reduced
      price.
    </p>
    <add-activity-package-card />
    <edit-package-card
      v-for="activityPackage in activityPackages"
      :key="activityPackage.id"
      :activitycreditname="activityPackage.name"
      :usedfor="activityPackage.usedFor"
      :price="activityPackage.price"
      :package-id="activityPackage.id"
      :company-activities="activityPackage.activities"
    />
    <button class="btn-save" @click="savePackages">save & continue</button>
  </div>
</template>

<script>
import AddActivityPackageCard from "../../activity-package/AddActivityPackageCard";
import EditPackageCard from "../../activity-package/EditActivityPackageCard";
import { mapState } from "vuex";

export default {
  components: { AddActivityPackageCard, EditPackageCard },
  data: function () {
    return {
      activityPackagess: [],
      companyPackages: [],
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    activityPackages: function () {
      if (this.companyPackages) {
        return this.companyPackages.map((companyPackage) => {
          return {
            name: companyPackage.name,
            price: companyPackage.price,
            id: companyPackage.id,
            usedFor: companyPackage.package_memberships.map((package_mem) => {
              return {
                quantity: package_mem.quantity,
                name: package_mem.activity_credit.name,
              };
            }),
            activities: companyPackage.activity_credits.map((d) => {
              return d.company_activities.map((e) => {
                return e.full_name;
              });
            }),
          };
        });
      }
      return [];
    },
  },
  methods: {
    savePackages() {},
  },
  mounted: function () {
    this.$store
      .dispatch(
        "activityPackage/RETRIEVE_COMPANY_PACKAGES",
        this.currentUser.company_id
      )
      .then((r) => {
        this.companyPackages = r.data;
      });
  },
};
</script>

<style scoped>
.horizontal-line {
  width: 100%;
  height: 30px;
  border-bottom: 2px solid rgba(155, 155, 155, 0.5);
  margin-bottom: 20px;
}

.explication {
  margin-bottom: 40px;
}

.btn-save {
  background-color: #9b9b9b;
  color: #fcfcfc;
  border: 2px solid #9b9b9b;
}

button {
  width: 100%;
  padding: 10px 0;
  border-radius: 50px;
  border: 2px solid #ebebeb;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0 0 0;
  outline: none;
}
</style>