<template>
  <div>
    <div class="input input--without-label">
      <datetime
        id="due_date"
        class="theme"
        type="date"
        zone="UTC"
        :min-datetime="nextAllowedDay"
        @input="getTrainerSchedule()"
        name="activity_date"
        v-model="activityDate"
      >
      </datetime>
    </div>
    <Flex
      direction="row"
      id="timeslotList"
      class="slider"
      v-if="this.timeSlots.length"
    >
      <BasePanel
        theme="calendar"
        class="slide"
        v-for="(timeSlot, index) in timeSlots"
        :key="'timeslot-' + index"
      >
        <Flex justify="between" direction="column" class="time">
          <div class="range">
            {{
              moment
                .utc(
                  timeSlot.start_time.replace(
                    "2000-01-01",
                    moment.utc().format("YYYY-MM-DD")
                  )
                )
                .format("hh:mm a")
            }}
          </div>
          <div class="range">
            {{
              moment
                .utc(
                  timeSlot.end_time.replace(
                    "2000-01-01",
                    moment.utc().format("YYYY-MM-DD")
                  )
                )
                .format("hh:mm a")
            }}
          </div>
          <div>
            <span class="radio">
              <input
                :id="index"
                name="selected_time_slot"
                type="radio"
                :value="timeSlot"
                @change="selectTimeSlot(timeSlot)"
              />
              <label :for="index"></label>
            </span>
          </div>
        </Flex>
      </BasePanel>
    </Flex>
  </div>
</template>

<script>
import Flickity from "flickity";
import Flex from "../style/Flex";

export default {
  name: "TrainerScheduleTimeSlots",
  props: {
    employeeActivity: Object,
    locationId: [String, Number],
    trainerId: [String, Number],
  },

  components: { Flex, Flickity },

  computed: {
    currentDate() {
      return this.moment.utc().toISOString();
    },
    nextAllowedDay() {
      // TODO FIX TZ
      let today = this.moment();
      console.log(today.date());
      if (today.hour() >= 20) {
        return this.moment()
          .utc()
          .set("date", today.add(2, "day").date())
          .toISOString();
      } else {
        return this.moment()
          .utc()
          .set("date", today.add(1, "day").date())
          .toISOString();
      }
    },
  },

  data() {
    return {
      timeSlots: [],
      activityDate: null,
      flkty: null,
    };
  },
  watch: {
    timeSlots: async function () {
      if (this.flkty) {
        this.flkty.destroy();
      }
      await this.$nextTick();
      this.flkty = this.initializeSlider("timeslotList");
    },
    employeeActivity: function () {
      this.getTrainerSchedule();
    },
    locationId: function () {
      this.getTrainerSchedule();
    },
    trainerId: function () {
      this.getTrainerSchedule();
    },
  },

  mounted() {},
  methods: {
    getTrainerSchedule() {
      if (this.activityDate == "") {
        this.activityDate = this.nextAllowedDay;
      }
      const selectedDay = this.moment.utc(this.activityDate).format();
      const data = {
        trainer_id: this.trainerId,
        location_id: this.locationId,
        day: selectedDay,
        duration: this.employeeActivity.activity.length,
      };

      this.$store
        .dispatch("schedule/GET_TRAINER_AVAILABILITY", data)
        .then((response) => {
          this.timeSlots = response.data;
        });
    },
    initializeSlider(id) {
      const slider = document.getElementById(id);
      if (!slider) return;

      return new Flickity(slider, {
        cellAlign: "left",
        contain: true,
        prevNextButtons: false,
        dragThreshold: 10,
        freeScroll: true,
        freeScrollFriction: 0.025,
      });
    },

    selectTimeSlot(timeSlot) {
      this.$emit("set-time-slot", {
        timeSlot,
        activityDate: this.activityDate,
      });
    },
  },
};
</script>

<style scoped>
.range {
  font-size: 1em;
}

.radio {
  margin-top: 2em;
}

.time {
  padding: 0.8em;
}

.slider {
  display: block;
  max-width: 100%;
  overflow: hidden;
}

.slider:focus {
  outline: none;
}

.slide {
  font-weight: var(--semibold);
  margin-right: 1em;
  text-align: center;
  width: 150px;
}
</style>
