<template>
  <div>
    <h1 class="title">To begin, select how you'll be using Riding Post.</h1>

    <BasePanel theme="company" not-full-width="true">
      <button @click="confirmTypeSelection('company')">
        <Flex justify="between" align="start">
          <h2 class="panel-title">Barn/Company Owner:</h2>
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
        </Flex>
        <p>
          I am a Barn and/or Company owner. In addition to the features of the
          Professional account, I also manage my company's services and
          information.
        </p>
        <br />
        <p>Riders pay me or my company directly for my company's services.</p>
      </button>
    </BasePanel>

    <BasePanel theme="worker" not-full-width="true">
      <button @click="confirmTypeSelection('worker')">
        <Flex justify="between" align="start">
          <h2 class="panel-title">Professional:</h2>
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
        </Flex>
        <p>
          I am a trainer, groom, and/or company employee. I manage just my
          services and availability so riders can book activities with me.
        </p>
        <br />
        <p>I am paid by a company.</p>
      </button>
    </BasePanel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";

export default {
  name: "PageRegistrationProfessionalTypeSelect",
  title: null,
  components: { Flex },
  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  methods: {
    confirmTypeSelection(accountType) {
      // const accountSelection = window.confirm(`Do you want to make your account a ${accountType} account`)
      // if (accountSelection) this.updateUser(accountType)
      this.updateUser(accountType);
    },

    updateUser(accountType) {
      const formData = new FormData();
      formData.append("account_type", accountType);

      this.$store
        .dispatch("user/UPDATE_USER", {
          id: this.currentUser.id,
          user: formData,
        })
        .then((response) => {
          this.$router.push(`/register/${response.data.account_type}/profile`);
        });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
.panel-title {
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
p {
  line-height: 1.25;
}
</style>
