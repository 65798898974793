<template>
  <form id="event-form" @submit.prevent="submit">
    <h1 class="page-title">Create Event</h1>
    <p class="space">Schedule a new event</p>

    <div class="space--large">
      <BaseInput name="title" label-text="Event Title:" v-model="event.title" />
    </div>

    <label for="event_start_date">Event Start Date</label>
    <div class="input">
      <datetime
        id="event_start_date"
        zone="UTC"
        type="date"
        name="event_start_date"
        v-model="eventStartDate"
      ></datetime>
    </div>

    <label for="event_end_date">Event End Date</label>
    <div class="input">
      <datetime
        id="event_end_date"
        zone="UTC"
        type="date"
        name="event_end_date"
        v-model="eventEndDate"
      ></datetime>
    </div>

    <CoverUpload
      name="avatar"
      :cover-photo="event.avatar"
      avatar-type="barn"
      @delete-cover-photo-request="confirmDeletePhoto"
    />

    <!-- <div class="space--large">
      <h2 class="section-title">Location:</h2>

      <div v-if="!atNewAddress">
        <p>Select Existing Location</p>

        <BaseSelect
          class="location-select"
          :label="false"
          name="location_id"
          v-model="event.location_id"
        >
          <option v-for="barn in barns" :key="barn.id" :value="barn.id">
            {{ barn.name }}
          </option>
        </BaseSelect>
      </div>

      <div v-else>
        <p>Enter Address for Event</p>
        <BaseInput
          name="address_attributes[street_address_line1]"
          label-text="Address Line 1"
          v-model="address.street_address_line1"
        />
        <BaseInput
          name="address_attributes[street_address_line2]"
          label-text="Address Line 2"
          v-model="address.street_address_line2"
        />

        <BaseInput
          name="address_attributes[state]"
          label-text="State"
          v-model="address.state"
        />
        <BaseInput
          name="address_attributes[city]"
          label-text="City"
          v-model="address.city"
        />
        <BaseInput
          name="address_attributes[postal_code]"
          label-text="Zip Code"
          v-model="address.postal_code"
        />
      </div>

      <BaseCheckbox
        label-text="Manually Enter Address for Event"
        name="new_address"
        v-model="atNewAddress"
      />
    </div> -->

    <div class="space--large">
      <BaseTextArea
        name="description"
        label-text="Event Description"
        rows="6"
        v-model="event.description"
      />
    </div>

    <!-- <div>
      <BaseSelect
        name="visibility"
        label-text="Event Visibility"
        v-model="event.visibility"
      >
        <option v-for="vis in visibility" :key="vis" :value="vis">
          {{ vis }}
        </option>
      </BaseSelect>
    </div> -->

    <!-- <label for="registration_deadline">Registration Deadline</label>
    <div class="input">
      <datetime
        id="registration_deadline"
        zone="UTC"
        type="date"
        name="registration_deadline"
        v-model="registrationDeadline"
      ></datetime>
    </div> -->
    <!-- 
    <label for="start_time">Start Time</label>
    <div class="input">
      <datetime
        id="start_time"
        type="time"
        zone="UTC"
        name="start_time"
        :use12-hour="true"
        :format="formattedTime"
        v-model="event.start_time"
      ></datetime>
    </div>

    <label for="end_time">End Time</label>
    <div class="input">
      <datetime
        id="end_time"
        type="time"
        zone="UTC"
        name="end_time"
        :use12-hour="true"
        :format="formattedTime"
        v-model="event.end_time"
      ></datetime>
    </div> -->

    <!-- <div class="space--large">
      <BaseInput
        name="max_attendance"
        type="number"
        label-text="Max Number of Atendees:"
        v-model="event.max_attendance"
      />
    </div> -->
    <!-- 
    <div class="space--large">
      <div class="radio">
        <input
          type="radio"
          id="same_fee"
          value="false"
          name="billed_after_registration"
          v-model="event.billed_after_registration"
        />
        <label for="same_fee"> Attendees will pay same fee: </label>
      </div>

      <BaseInput
        v-if="event.billed_after_registration == 'false'"
        name="price"
        label-text="Price:"
        v-model="event.price"
        type="number"
        step="0.01"
      />

      <div class="radio">
        <input
          type="radio"
          id="billed_after"
          value="true"
          name="billed_after_registration"
          v-model="event.billed_after_registration"
        />
        <label id="before-label" for="billed_after">
          Attendees will be billed after event due to variable costs associated
          with the nature of this event.
        </label>
      </div>
    </div> -->
    <Flex>
      <BaseButton type="submit" theme="with-space">
        {{ eventId ? "Edit Event" : "Create Event" }}
      </BaseButton>
      <BaseButton
        v-if="eventId"
        type="button"
        theme="worker with-space"
        @click="promptDeleteEvent(eventId)"
      >
        Delete Event
      </BaseButton>
    </Flex>
    <Flex>
      <BaseButton theme="alternate" @click="goBack()"> Cancel </BaseButton>
    </Flex>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import CoverUpload from "../utils/CoverUpload";
import { DateTime } from "luxon";

export default {
  name: "EventPage",
  components: { Flex, CoverUpload },
  props: {
    eventId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      event: {
        billed_after_registration: "false",
        start_time: this.now(),
        end_time: this.now(),
      },
      address: {},
      barns: [],
      atNewAddress: false,
      eventStartDate: this.today(),
      eventEndDate: this.today(),
      registrationDeadline: this.today(),
      formattedTime: DateTime.TIME_SIMPLE,
      visibility: ["secret", "shared", "accessible"],
    };
  },

  mounted() {
    this.getBarns();
    setTimeout(() => this.getEvent(), 2000);
  },

  methods: {
    submit() {
      const eventForm = document.getElementById("event-form");
      const formData = new FormData(eventForm);
      formData.append("company_id", this.currentUser.company_id);
      formData.append("start_time", this.eventStartDate);
      formData.append("end_time", this.eventEndDate);

      this.event.id ? this.updateEvent(formData) : this.createEvent(formData);
    },

    createEvent(formData) {
      this.$store.dispatch("event/CREATE_EVENT", formData).then((response) => {
        this.$router.push("/events");
      });
    },
    promptDeleteEvent(eventId) {
      const eventDelete = confirm("Do You wan to delete this event?");
      if (eventDelete) this.deleteEvent(eventId);
    },
    deleteEvent(id) {
      this.$store.dispatch("event/DELETE_EVENT", id).then((response) => {
        this.$router.push("/events");
      });
    },

    updateEvent(formData) {
      this.$store
        .dispatch("event/UPDATE_EVENT", { id: this.event.id, form: formData })
        .then((response) => {
          this.$router.push("/events");
        });
    },
    goBack() {
      this.$router.push("/events");
    },

    confirmDeletePhoto() {
      const deletePhoto = window.confirm(
        "Are you sure you want to delete your event photo?"
      );
      const data = new FormData();
      data.append("destroy_avatar", true);
      const params = { id: this.event.id, form: data };

      if (deletePhoto) {
        this.$store.dispatch("event/UPDATE_EVENT", params).then((response) => {
          this.$router.push(`/events/${this.event.id}`);
        });
      }
    },

    getEvent() {
      if (this.eventId) {
        this.$store
          .dispatch("event/GET_EVENT", this.eventId)
          .then((response) => {
            this.event = response.data;
            this.startTime = this.parseDateTime(this.event.start_time);
            this.endTime = this.parseDateTime(this.event.end_time);
            this.registrationDeadline = this.parseDate(
              this.event.registration_deadline
            );
            this.eventStartDate = this.parseDate(this.event.start_time);
            this.eventEndDate = this.parseDate(this.event.end_time);

            if (this.event.location) {
              this.address = this.event.location;
            } else {
              this.address = this.event.address;
              this.atNewAddress = true;
            }
          });
      }
    },

    parseDateTime(datetime) {
      let date = this.moment.utc(datetime);
      return date.format("HH:mm");
    },

    parseDate(datetime) {
      let date = this.moment.utc(datetime);
      return date.format("YYYY-MM-DD");
    },

    composeDate(time) {
      const parsedTime = this.moment
        .utc(time)
        .format(this.moment.HTML5_FMT.TIME);
      let date = this.moment.utc(time);
      date.hour(0);
      date.minute(0);
      // date.hour(parsedTime.split(":")[0]);
      // date.minute(parsedTime.split(":")[1].slice(0, 2));

      return date.format();
    },

    getBarns() {
      this.$store
        .dispatch("company/GET_COMPANY", this.currentUser.company_id)
        .then((response) => {
          this.barns = response.data.locations;
        });
    },

    today() {
      return this.moment.utc().format(this.moment.HTML5_FMT.DATE);
    },

    now() {
      return this.moment.utc().format();
    },
  },
};
</script>

<style scoped>
#before-label {
  position: relative;
  padding-left: 24px;
}
#before-label::before {
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  top: 0;
  left: 0px;
}
</style>