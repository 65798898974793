<template>
  <article class="location-panel">
    <BasePanel theme="active image" :style="fallbackImageSource">
      <Flex justify="between">
        <Flex justify="start">
          <div>
            <h1>{{ location.location.name }}</h1>
            <p>Member</p>
          </div>
        </Flex>
        <BaseButton theme="round alternate" @click="emitEdit()" v-if="owner">
          <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
        </BaseButton>
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import BarnDefaultImage from "../../assets/images/covers/company.jpg";
import Flex from "../style/Flex";

export default {
  name: "LocationPanel",
  props: {
    location: Object,
    owner: {
      type: Boolean,
      default: false,
    },
  },
  components: { Flex },
  computed: {
    fallbackImageSource() {
      var image = this.location.location.avatar
        ? this.location.location.avatar
        : BarnDefaultImage;
      return "background-image: url(" + image + ");";
    },
  },
  methods: {
    emitEdit() {
      this.$emit("edit-location", this.location);
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--white);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  color: var(--white);
  font-size: 0.875em;
  font-weight: var(--semibold);
}
</style>
