<template>
  <div class="active-package-card">
    <div class="active-credit-card-header">
      <span class="package-name">{{ activityCredit.name }}</span>
      <div class="btn-cta" @click="removeCredit">
        <span class="sign"> - </span>
      </div>
      <div class="btn-cta" @click="editPackage">
        <font-awesome-icon class="edit-icon" :icon="['fas', 'edit']" />
      </div>
    </div>
    <div class="quantity-form">
      <div class="quantity-input-div">
        <BaseInput
          class="active-credit-quantity"
          type="number"
          name="packagePrice"
          label-text="Quantity"
          v-model="quantity"
        />
      </div>
      <button
        v-if="quantity != initialQuantity"
        class="btn-save-quantity"
        @click="saveQuantity"
      >
        Save <br />
        quantity
      </button>
    </div>
    <div class="usability-box">
      <span class="usability-box-title">Can be used for:</span>
      <div
        class="usability-item"
        v-for="item in activityCredit.usedFor"
        :key="item"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveCreditCard",
  props: ["activityCredit"],
  computed: {
    activities() {
      return this.usedfor;
    },
  },
  data: function () {
    return {
      quantity: undefined,
      initialQuantity: undefined,
    };
  },
  mounted() {
    this.quantity = this.activityCredit.quantity;
    this.initialQuantity = this.activityCredit.quantity;
  },
  methods: {
    editPackage() {
      this.$router.push("/activity-credits/" + this.activityCredit.id);
    },
    removeCredit() {
      this.$emit("remove-credit", this.activityCredit.id);
    },
    saveQuantity() {
      this.$emit("save-quantity", {
        quantity: this.quantity,
        id: this.activityCredit.id,
        membership_id: this.activityCredit.membership_id,
      });
      this.initialQuantity = this.quantity;
    },
  },
};
</script>

<style scoped>
.active-package-card {
  width: 100%;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  padding: 10px;
}
.active-package-card:not(:last-child) {
  margin-bottom: 15px;
}
.quantity-form {
  display: flex;
}
.quantity-input-div {
  width: 75%;
}
.package-name {
  max-width: 80%;
  display: inline-block;
  color: rgb(119, 118, 118);
  font-size: 20px;
  font-weight: 600;
}

.usability-box-title {
  display: inline-block;
  width: 100%;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
}
.usability-item {
  color: #9b9b9b;
  font-size: 15px;
  padding: 2px;
  font-weight: 400;
}

.sign {
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-55%);
  position: relative;
}

.btn-cta {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  color: #64c8c1;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  float: right;
  margin-right: 3px;
  position: relative;
}

.edit-icon {
  font-size: 20px;
  color: #9b9b9b;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.active-credit-card-header {
  margin-bottom: 30px;
}
.usability-box {
  display: flex;
  flex-direction: column;
}

button {
  width: 25%;
  padding: 2px;
  border-radius: 50px;
  border: 2px solid #ebebeb;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  outline: none;
  height: 42px;
  margin-left: 2px;
}
</style>