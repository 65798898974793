<template>
  <div class="slider" id="locationSlider">
    <BasePanel class="slide" v-for="barn in company.locations" :key="barn.id">
      <Flex direction="column" justify="center" align="center">
        <div @click="linkToLocation(barn.id)">
          <Avatar size="large" class="space" :image-source="barn.avatar" />
          <br />
          {{ barn.name }}
        </div>
      </Flex>
    </BasePanel>
  </div>
</template>
<script>
import Flickity from "flickity";
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";

export default {
  name: "LocationSlider",
  components: { Avatar, Flex },
  props: {
    company: {},
  },
  mounted() {
    this.initializeSlider("locationSlider");
  },
  methods: {
    linkToLocation(id) {
      this.$router.push("/companies/locations/" + id);
    },
    initializeSlider(id) {
      const slider = document.getElementById(id);
      if (!slider) return;

      const flkty = new Flickity(slider, {
        cellAlign: "left",
        contain: true,
        prevNextButtons: false,
        setGallerySize: true,
      });
    },
  },
};
</script>
<style scoped>
.slider {
  /* display: block; */
  max-width: 100%;
  overflow: hidden;
}

.slider:focus {
  outline: none;
}

.slide {
  font-weight: var(--semibold);
  margin-right: 1em;
  text-align: center;
}
.continue {
  font-size: 1.125em;
  font-weight: var(--semibold);
}
</style>
