<template>
  <div>
    <h1 class="registration-title">Log In</h1>
    <br />

    <form @submit.prevent="login">
      <BaseInput
        type="email"
        label-text="Email Address"
        name="email"
        v-model="user.email"
      />
      <BaseInput
        type="password"
        label-text="Password"
        name="password"
        v-model="user.password"
      />
      <BaseButton type="submit">Log In</BaseButton>
    </form>
  </div>
</template>

<script>
export default {
  name: "PageRegistrationLogin",
  title: null,
  data() {
    return {
      user: {},
    };
  },

  methods: {
    login() {
      this.$store.dispatch("user/LOGIN", this.user).then((response) => {
        this.$router.push("/dashboard");
      });
    },
  },
};
</script>

<style scoped>
div {
  text-align: center;
}
p,
a {
  font-size: 1.125em;
}
</style>
