<template>
<div>
  <BasePanel
    v-if="employeeActivity && employeeActivity.activity && location.company"
  >
    <Flex direction="column" justify="start">
      <Flex class="location" v-if="location" direction="column">
        <p class="location_header">{{ location.name }}</p>
        <Cover
          class="location-cover"
          cover-type="barn"
          :image-source="location.avatar"
        >
          <Flex direction="row" justify="between">
            <Flex direction="column" v-if="this.horse">
              <Avatar avatar-type="horse" :image-source="horse.avatar"></Avatar>
              {{ this.horse.nickname }}
            </Flex>
            <Flex direction="column">
              <Avatar :image-source="currentUser.avatar"></Avatar>
              {{ this.currentUser.first_name }}
            </Flex>
          </Flex>
        </Cover>
      </Flex>
      <Flex
        direction="column"
        justify="start"
        class="lesson-time space"
        v-if="activityBlock"
      >
        <Flex justify="start">
          {{ moment.utc(activityBlock.start_time).format("MMMM DD, YYYY") }}
        </Flex>
        <Flex>
          {{ moment.utc(activityBlock.start_time).format("hh:mm a") }}
          -
          {{ moment.utc(activityBlock.end_time).format("hh:mm a") }}
        </Flex>
      </Flex>
      <Flex class="lesson-name" direction="column" justify="start">
        <Flex justify="start">{{ employeeActivity.activity.service }}</Flex>
        <Flex justify="start"
          >({{ employeeActivity.activity.duration_in_minutes }})</Flex
        >
        <p v-if="showPayment">${{ employeeActivity.price }}</p>
      </Flex>
      <Flex direction="column" justify="start">
        <Flex class="trainer-label">Trainer:</Flex>
        <Flex
          direction="row"
          justify="start"
          v-if="employeeActivity && employeeActivity.employee"
        >
          <Avatar
            size="small"
            :image-source="employeeActivity.employee.avatar"
          />
          <div class="trainer-name">{{ employeeActivity.employee.name }}</div>
        </Flex>
      </Flex>

      <div
        class="description"
        v-if="
          employeeActivity &&
          employeeActivity.activity &&
          employeeActivity.activity.description
        "
      >
        <span class="description-label">Descripton :</span>
        {{ employeeActivity.activity.description }}
      </div>

      <!-- <hr v-if="companyHasPackagesForActivity" />

    <div class="did-you-know" v-if="companyHasPackagesForActivity">
      <span class="did-you-know-label">Did you know?</span> <br />
      <div class="barn-offer">
        {{ location.name }} has packages for this activity that you might be
        interested in.
      </div>
      <BaseButton id="view-packages-button" @click="openPackagesModal"
        >View Packages</BaseButton
      >
    </div> -->

      <!-- <hr v-if="canUseCredits" />

    <div class="credits" v-if="canUseCredits">
      <span class="credits-label"
        >You have {{ validCreditForCompanyActivity.length }} credit(s) available
        for this activity.</span
      >
      <div class="credits-check-box">
        <BaseCheckbox
          label-text="Use a credit for this activity"
          name="subscription"
          v-model="useCredit"
        />
      </div>
    </div> -->

      <!-- 
    <form @submit.prevent="createUserActivity()">
      <div v-if="employeeActivity.activity && location.name">
        <UserActivityInformation :employee-activity="employeeActivity" :time-block="activityBlock" :location="location">
        </UserActivityInformation>

        
      </div>
    </form> -->
      <!-- <buy-activity-packages-modal
      :activity-id="employeeActivity.activity.id"
      :company-id="location.company_id"
      v-if="showActivityPackageModal"
      @goback="closeActivityPackageModal"
    /> -->
    </Flex>
  </BasePanel>
  <Flex justify="start" direction="column" id="button-submit">
    <!-- <BaseButton type="button" theme="alternate">Edit</BaseButton> -->
    <BaseButton @click="createUserActivity()" :disabled="submitted">{{ submitMessage }}</BaseButton>
  </Flex>
  <div>
</template>

<script>
import Flex from "../style/Flex";
import UserActivityInformation from "./UserActivityInformation";
import Avatar from "../utils/Avatar";
import BaseButton from "../base/BaseButton";
import BaseCheckbox from "../base/BaseCheckbox";
import BuyActivityPackagesModal from "../activity-package/BuyActivityPackagesModal";
import { mapState } from "vuex";
import moment from "moment";
import Cover from "../utils/Cover.vue";

export default {
  name: "UserActivityPayment",
  props: { userActivity: Object, activityBlock: Object },
  components: {
    Flex,
    UserActivityInformation,
    Avatar,
    BaseButton,
    BaseCheckbox,
    BuyActivityPackagesModal,
    Cover,
  },
  data() {
    return {
      location: {},
      employeeActivity: {},
      useCredit: false,
      showActivityPackageModal: false,
      validCredits: [],
      companyPackages: [],
      horse: undefined,
      submitted: false,
    };
  },

  async mounted() {
    this.$store.commit("global/START_LOADING");
    setTimeout(async () => {
      await this.findLocation();
      await this.findEmployeeActivity();
      if (this.userActivity.horse_id) {
        await this.findHorse();
      }
      await this.$store
        .dispatch("activityCredit/GET_USER_CREDITS", this.currentUser.id)
        .then((r) => {
          this.validCredits =
            this.$store.getters["activityCredit/validUserCredits"];
        });
      await this.$store
        .dispatch(
          "activityPackage/RETRIEVE_COMPANY_PACKAGES",
          this.employeeActivity.company_activity_company_id
        )
        .then((r) => {
          this.companyPackages = r.data;
        });
      this.$store.commit("global/STOP_LOADING");
    }, 2000);
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    showPayment() {
      let paymentSetting = this.location.company.settings.filter((e) => {
        return e.name == "no_payment";
      }).length;
      return paymentSetting == 0;
    },
    submitMessage() {
      if (
        this.location.company &&
        this.location.company.settings.filter((s) => {
          return s.name == "skip_request_approval";
        }).length
      ) {
        return "Book Activity";
      } else {
        return "Send Request";
      }
    },
    canUseCredits() {
      return this.validCreditForCompanyActivity.some((r) => {
        return r.valid_company_activities.some((g) => {
          return g == this.employeeActivity.company_activity_id;
        });
      });
    },
    companyHasPackagesForActivity() {
      return this.companyPackages.some((companyPackage) => {
        return companyPackage.activity_credits.some((credit) => {
          return credit.company_activities.some((activity) => {
            return activity.activity_id == this.employeeActivity.activity.id;
          });
        });
      });
    },
    validCreditForCompanyActivity() {
      return this.validCredits.filter((credit) => {
        return credit.valid_company_activities.some((g) => {
          return g == this.employeeActivity.company_activity_id;
        });
      });
    },
    creditOwnershipToUse() {
      if (!this.canUseCredits) {
        return null;
      }
      if (!this.validCredits) {
        return null;
      }

      let ordered = this.validCredits.sort((a, b) => {
        if (!a.expiration_date) {
          return 1;
        }
        if (!b.expiration_date) {
          return 0;
        }
        let aDate = moment.utc(a.expiration_date, "YYYY-MM-DD");
        let bDate = moment.utc(b.expiration_date, "YYYY-MM-DD");
        return aDate > bDate ? 1 : 0;
      });
      if (!ordered || ordered.length == 0) {
        return null;
      }
      return ordered[0].id;
    },
  },

  methods: {
    openPackagesModal() {
      this.showActivityPackageModal = true;
    },
    async findLocation() {
      await this.$store
        .dispatch("location/GET_LOCATION", this.userActivity.location_id)
        .then((response) => {
          this.location = response.data;
        });
    },

    async findEmployeeActivity() {
      await this.$store
        .dispatch(
          "activity/GET_TRAINER_ACTIVITY",
          this.userActivity.employee_activity_id
        )
        .then((response) => {
          this.employeeActivity = response.data;
        });
    },

    async findHorse() {
      await this.$store
        .dispatch("horse/GET_HORSE", this.userActivity.horse_id)
        .then((response) => {
          this.horse = response.data;
        })
        .catch(() => {
          this.horse = null;
        });
    },

    async closeActivityPackageModal() {
      this.showActivityPackageModal = false;
      this.$store.commit("global/START_LOADING");
      await this.$store
        .dispatch("activityCredit/GET_USER_CREDITS", this.currentUser.id)
        .then((r) => {
          this.validCredits =
            this.$store.getters["activityCredit/validUserCredits"];
        });
      this.$store.commit("global/STOP_LOADING");
    },

    createUserActivity() {
      this.submitted=true
      const data = {
        user_activity: this.userActivity,
        activity_block: this.activityBlock,
        credit_ownership_id: this.creditOwnershipToUse,
      };

      this.$store
        .dispatch("activity/CREATE_USER_ACTIVITY", data)
        .then((response) => {
          this.$router.push({
            name: "dashboard",
            params: { userActivity: response.data },
          });
        });
    },
  },
};
</script>

<style style scoped>
h1,
h2 {
  font-size: 1.25em;
  font-weight: var(--semibold);
  text-align: left;
}
h1 {
  text-align: left;
  display: inline-block;
  color: var(--navy);
  margin-bottom: 15px;
}
.description-label,
.did-you-know-label,
.credits {
  font-weight: var(--semibold);
}
.description,
.did-you-know,
.credits-lable {
  line-height: 1.3;
  color: var(--charcoal);
  margin-bottom: 15px;
}
h2 {
  font-size: 1.25em;
  font-weight: var(--semibold);
  text-align: left;
}
.trainer-label {
  margin-top: 1em;
  font-size: 1.25em;
  font-weight: var(--semibold);
  color: var(--navy);
  margin-bottom: 15px;
}
.trainer-container {
  display: flex;
  direction: row;
  margin-bottom: 20px;
}
.trainer-name {
  color: var(--charcoal);
  font-size: 1.2em;
  font-weight: var(--semibold);
  width: 100%;
}
.trainer-info-container {
  width: 100%;
  margin-top: 4px;
}
.avatar {
  display: inline-block;
  margin: 0px 7px;
}
.name {
  color: var(--charcoal);
  font-size: 1.3125em;
  font-weight: var(--semibold);
}
.location {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 15px;
}
.location_header {
  color: var(--navy);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
.lesson-name {
  color: var(--charcoal);
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}

.lesson-time {
  font-size: 1.25em;
  font-style: italic;
  font-weight: var(--semibold);
}
#view-packages-button {
  background-color: transparent;
  color: gray;
  border: 1px solid gray;
  margin-top: 15px;
}
#button-submit {
  margin-top: 15px;
}
.credits-check-box {
  margin-top: 5px;
}

#button-submit :disabled{
  opacity: 40%;
}

