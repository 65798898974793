<template>
  <div>
    <Flex justify="between">
      <h1 class="title space">My Events</h1>

      <BaseLink theme="button auto" link="/events/edit">
        New Event <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
      </BaseLink>
    </Flex>

    <div v-for="event in events" :key="event.id" class="event-list">
      <BasePanel type="clickable" @click.native="goToEventPage(event.id)">
        <Flex justify="between" direction="row">
          <Avatar
            size="small"
            :image-source="event.avatar"
            avatar-type="event"
          />
          <Flex direction="column" align="start">
            <p class="name">{{ event.title }}</p>
            <Flex direction="row">
              <p class="location">
                {{ moment.utc(event.start_time).format("MMM D") }} -
                {{ moment.utc(event.end_time).format("MMM D") }}
              </p>
            </Flex>
          </Flex>
          <Flex justify="end" direction="row">
            <ButtonFaButton
              ref="eventInterested"
              class="fa-thumbs-up"
              :icon="['fas', 'thumbs-up']"
            >
              {{
                filter_registrations_by_interest_level(
                  event.event_registrations,
                  "yes"
                ).length
              }}</ButtonFaButton
            >
            <ButtonFaButton
              ref="eventNotInterested"
              class="fa-thumbs-down"
              :icon="['fas', 'thumbs-down']"
              >{{
                filter_registrations_by_interest_level(
                  event.event_registrations,
                  "no"
                ).length
              }}</ButtonFaButton
            >
          </Flex>
        </Flex>
        <Flex
          justify="evenly"
          align="start"
          direction="row"
          class="event_registrations"
          v-if="event.event_registrations.length"
        >
          <div class="interested-riders">
            <h3 class="interested">Interested:</h3>
            <ul
              v-for="event_registration in filter_registrations_by_interest_level(
                event.event_registrations,
                'yes'
              )"
              :key="event_registration.id"
            >
              <li class="rider">{{ event_registration.user_name }}</li>
            </ul>
          </div>
          <div class="interested-riders">
            <h3 class="not_interested">Not Interested:</h3>
            <ul
              v-for="event_registration in filter_registrations_by_interest_level(
                event.event_registrations,
                'no'
              )"
              :key="event_registration.id"
            >
              <li class="rider">{{ event_registration.user_name }}</li>
            </ul>
          </div>
          <div class="interested-riders">
            <h3 class="not_replied">Not Replied</h3>
            <ul
              v-for="event_registration in find_not_replied(
                event.event_registrations
              )"
              :key="event_registration.id"
            >
              <li class="rider">{{ event_registration.user_name }}</li>
            </ul>
          </div></Flex
        >
      </BasePanel>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";
import ButtonFaButton from "../buttons/ButtonFaButton.vue";

export default {
  name: "EventPage",
  components: { Flex, Avatar, ButtonFaButton },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      events: [],
    };
  },
  watch: {},
  created() {
    setTimeout(() => this.getEvents(), 2000);
  },

  methods: {
    filter_registrations_by_interest_level(
      event_registrations,
      interest_level
    ) {
      return event_registrations.filter((x) => {
        return x.interest_level == interest_level;
      });
    },

    find_not_replied(event_registrations) {
      return [];
    },

    getEvents() {
      this.$store
        .dispatch("event/GET_EVENTS", {
          company_id: this.currentUser.company_id,
        })
        .then((response) => {
          this.events = response.data;
        });
    },

    goToEventPage(id) {
      this.$router.push({ name: "edit-event", params: { eventId: id } });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 1.35em;
  font-weight: var(--semibold);
}
p.interested-riders {
  margin-top: 1.1em;
}
.event-list {
  margin-top: 1em;
}

h3.interested {
  font-size: 1.1em;
  color: var(--dark-grey);
}
h3.not_interested {
  font-size: 1.1em;
  color: var(--dark-grey);
}
h3.not_replied {
  font-size: 1.1em;
  color: var(--dark-grey);
}
.event_registrations {
  margin-top: 1em;
}
li.rider {
  font-size: 0.9em;
  margin-left: 0.5em;
  color: var(--default-accent-color);
}
</style>