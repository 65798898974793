<script>
import { mapState } from "vuex";

export default {
  name: "BaseProfile",
  title: null,

  props: {
    currentIndex: Number,
    steps: Array,
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  methods: {
    updateUser() {
      const formData = new FormData(document.getElementById("profile"));
      const userParams = { id: this.currentUser.id, user: formData };
      this.showLoading = true;
      this.$store
        .dispatch("user/UPDATE_USER", userParams)
        .then((response) => {
          this.$parent.nextPage();
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    confirmDelete() {
      const deleteAction = window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      );
      if (deleteAction) this.deleteUser();
    },

    deleteUser() {
      this.$store
        .dispatch("user/DELETE_USER", this.currentUser.id)
        .then((response) => {
          this.$router.push("/");
        });
    },

    confirmDeletePhoto() {
      const deleteAction = window.confirm(
        "Are you sure you want to delete your photo?"
      );
      const data = new FormData();
      data.append("destroy_avatar", true);
      const params = { id: this.currentUser.id, user: data };

      if (deleteAction) {
        this.$store.dispatch("user/UPDATE_USER", params).then((response) => {
          window.location.reload(true);
        });
      }
    },
  },
};
</script>

<style scoped>
p,
a {
  font-size: 1.125em;
}
</style>
