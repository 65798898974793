<template>
  <div v-if="schedule">
    <WorkerAvailabilityPanel
      v-for="day in days"
      :key="day"
      :schedule="schedule"
      :day="day"
    />
  </div>
</template>

<script>
import WorkerAvailabilityPanel from "../registration/worker/utils/WorkerAvailabilityPanel";

export default {
  name: "TrainerPanel",
  props: { schedule: Object },
  components: { WorkerAvailabilityPanel },
  data() {
    return {
      days: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
    };
  },
};
</script>
