<template>
  <div
    class="cover"
    :class="{ small: size == 'small' }"
    :style="fallbackImageSource"
  >
    <slot />
  </div>
</template>

<script>
import CompanyCover from "../../assets/images/covers/company.jpg";
import BarnCover from "../../assets/images/covers/company.jpg";

export default {
  name: "Cover",
  props: {
    size: String,
    coverType: {
      type: String,
      default: "company",
    },
    imageSource: String,
  },
  computed: {
    fallbackImageSource() {
      const image = this.imageSource
        ? this.imageSource
        : this.imageFallback(this.coverType);
      return `background-image: url('${image}');`;
    },
  },
  methods: {
    imageFallback(type) {
      switch (type) {
        case "barn":
          return BarnCover;
        /* case 'trainer':*/
        /* return TrainerCover;*/
        /* case 'worker':*/
        /* return WorkerCover;*/
        default:
          return CompanyCover;
      }
    },
  },
};
</script>

<style scoped>
.cover {
  align-items: center;
  background-color: var(--company-accent-color);
  background-position: center;
  background-size: cover;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  color: var(--white);
  display: inline-flex;
  font-size: 1.5em;
  font-weight: var(--semibold);
  height: 50vw;
  justify-content: center;
  width: 100%;
}
.small {
  height: 35vw;
}
</style>
