<template>
  <button id="menuButton" @click="toggleTheSidebar()">
    <span v-if="!expanded">
      <img src="../../assets/images/menu.svg" alt="Menu" />
    </span>
    <span v-else>
      <img src="../../assets/images/menu-close.svg" alt="Menu" />
    </span>
  </button>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TheMenuButton",
  computed: mapState({
    expanded: (state) => state.theSidebar.expanded,
  }),
  methods: mapActions("theSidebar", ["toggleTheSidebar"]),
};
</script>

<style scoped>
button {
  cursor: pointer;
  padding: 0.4em 0;
  position: absolute;
  z-index: 2;
}
</style>
