<template>
  <div v-if="company.id">
    <BackButton theme="round" style-class="top-left" />
    <Cover class="space--large">
      <h1>{{ company.name }}</h1>
    </Cover>

    <h1 class="company-name">{{ company.name }}</h1>

    <p class="company-address space" v-if="company.address">
      {{ company.address.street_address_line1 }}
      <br />
      <span v-if="company.address.street_address_line2">
        {{ company.address.street_address_line2 }}
        <br />
      </span>
      {{
        `${company.address.city}, ${company.address.state} ${company.address.postal_code}`
      }}
    </p>

    <ul class="contact space">
      <li class="contact__item" v-if="company.phone && company.phone !== ''">
        <font-awesome-icon
          :icon="['fas', 'phone']"
          fixed-width
          class="planet"
        />
        <a :href="`tel:${company.phone}`">
          {{ company.phone }}
        </a>
      </li>
      <li
        class="contact__item"
        v-if="company.website && company.website !== ''"
      >
        <font-awesome-icon
          :icon="['fas', 'globe-americas']"
          fixed-width
          class="planet"
        />
        <a class="link" :href="company.website">
          {{ company.website }}
        </a>
      </li>
      <li class="contact__item" v-if="company.email && company.email !== ''">
        <font-awesome-icon
          :icon="['fas', 'envelope']"
          fixed-width
          class="planet"
        />
        <a class="link" :href="'mailto:' + company.email">
          {{ company.email }}
        </a>
      </li>
    </ul>

    <p class="description space">
      {{ company.bio }}
    </p>

    <h2 v-if="company.employees.length > 0" class="section-title">Trainers</h2>
    <EmployeeSlider :company="company" />

    <h2 class="section-title" v-if="company.locations.length > 0">Barns</h2>
    <LocationSlider :company="company" />

    <!-- <h2 class="section-title">Horses</h2>
    <div class="slider" id="slider-2">
      <BasePanel class="slide">
        <Flex direction="column" justify="center" align="center">
          <Avatar size="large" class="space"/>
          Harry Trotter
        </Flex>
      </BasePanel>
    </div> -->

    <!--    <BaseButton id="view-packages-button" @click="openPackagesModal"-->
    <!--      >View Packages</BaseButton-->
    <!--    >-->

    <!--    <buy-activity-packages-modal-->
    <!--      :company-id="company.id"-->
    <!--      v-if="showActivityPackageModal"-->
    <!--      @goback="closeActivityPackageModal"-->
    <!--    />-->
  </div>
</template>

<script>
import Cover from "../utils/Cover";
import EmployeeSlider from "./EmployeeSlider";
import LocationSlider from "./LocationSlider";
import BuyActivityPackagesModal from "../activity-package/BuyActivityPackagesModal";
import Avatar from "../utils/Avatar.vue";
import BackButton from "../common/BackButton.vue";

export default {
  name: "PageCompanyProfile",
  components: {
    LocationSlider,
    EmployeeSlider,
    Cover,
    BuyActivityPackagesModal,
    Avatar,
    BackButton,
  },

  data() {
    return {
      company: {},
      showActivityPackageModal: false,
    };
  },

  mounted() {
    this.getCompany();
  },

  methods: {
    getCompany() {
      this.$store
        .dispatch("company/GET_COMPANY", this.$route.params.id)
        .then((response) => {
          this.company = response.data;
        });
    },
    openPackagesModal() {
      this.showActivityPackageModal = true;
    },
    async closeActivityPackageModal() {
      this.showActivityPackageModal = false;
    },
  },
};
</script>

<style scoped>
.company-name {
  color: var(--navy);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.company-address {
  line-height: 1.25em;
}
.contact {
  font-size: 1.25em;
  font-weight: var(--semibold);
}
.contact__item {
  margin-bottom: 0.5em;
}
.planet {
  color: var(--current-accent-color);
}
.description {
  font-size: 1.125em;
  line-height: 1.25em;
}
</style>
