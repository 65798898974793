import axios from "axios";
import {
  GLOBAL_ERROR,
  GLOBAL_SUCCESS,
  SET_THEME,
  CHANGE_THEME,
  START_LOADING,
  STOP_LOADING,
  GLOBAL_SEARCH,
} from "../actions/global";
const THEMES = ["default", "worker", "company", "facebook"];

// Initial State
const state = {
  hasError: false,
  loading: false,
  errorMessage: "",
  successMessage: "",
  theme: "default",
};

// Getters
const getters = {
  isLoading: (state) => state.loading === true,
};

// Actions
const actions = {
  CHANGE_THEME({ commit }, theme) {
    if (!THEMES.includes(theme)) return;

    const color = getComputedStyle(document.documentElement).getPropertyValue(
      `--${theme}-accent-color`
    );
    localStorage.setItem("theme-color", color);
    document.documentElement.style.setProperty("--current-accent-color", color);
    commit(SET_THEME, theme);
  },

  GLOBAL_SEARCH({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/searches", { params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {
  [GLOBAL_ERROR]: (state, error) => {
    state.hasError = true;
    state.errorMessage = error;
    state.successMessage = "";
  },

  [GLOBAL_SUCCESS]: (state, message = "") => {
    state.hasError = false;
    state.errorMessage = "";
    state.successMessage = message;
  },

  [SET_THEME]: (state, newTheme) => {
    state.theme = newTheme;
  },

  [START_LOADING]: (state) => {
    state.loading = true;
  },

  [STOP_LOADING]: (state) => {
    state.loading = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
