<template>
  <div>
    <form id="employers">
      <div v-if="!showAddEmployer">
        <h2 class="section-title">Employers</h2>
        <p class="space">
          Please tell us who you work for, if you work for more than one company
          simply add another below
        </p>
        <BasePanel
          v-if="employers.length"
          v-for="relationship in employers"
          :key="`employers${relationship.id}`"
          theme="active"
        >
          <Flex justify="between" direction="row">
            <span class="employer">{{ relationship.employer.name }}</span>
            <ButtonAddRemove
              @click.native="promptDeleteEmployer(relationship)"
              type="remove"
            />
          </Flex>
        </BasePanel>
        <div v-else>
          <p class="current-employers">You have no current employers.</p>
        </div>

        <!-- <BasePanel type="clickable" @click.native="toggleAddEmployer()">
          <Flex justify="between" direction="row">
            <p class="add-an-employer">Add Employer</p>
            <ButtonAddRemove type="add" />
          </Flex>
        </BasePanel> -->

        <div v-if="employers.length">
          <h2 class="section-title">Primary Employer</h2>
          <p class="space">
            Which company is your primary employer? (This makes it easier for us
            to give priority when managing your account)
          </p>

          <BaseSelect
            label-text="Primary Employer"
            placeholder="No primary employer is set"
            id="primary-employer"
            v-model="primaryEmployerId"
          >
            <option
              v-for="relationship in employers"
              :key="relationship.id"
              :value="relationship.id"
            >
              {{ relationship.employer.name }}
            </option>
          </BaseSelect>
        </div>

        <BaseButton @click="setPrimaryEmployer()" type="button"
          >Save & Continue</BaseButton
        >
      </div>
      <div v-else>
        <h2 class="section-title">Add an additional Employer</h2>

        <BaseInput
          label-text="Select an employer"
          v-model="searchQueryEmployer"
        />

        <div class="user-results" v-if="showCards">
          <div
            v-for="(result, index) in filterOtherCompanies"
            :key="result.id + index * 100"
          >
            <BasePanel type="clickable" @click.native="selectEmplyer(result)">
              <Flex justify="between">
                <Flex justify="start">
                  <Avatar :image-source="result.avatar" type="company" />
                  <!-- if result is user or barn -->
                  <div>
                    {{ result.name }}
                  </div>
                </Flex>
              </Flex>
            </BasePanel>
          </div>
        </div>

        <!-- <BaseSelect
          :label="false"
          placeholder="Select an employer"
          label-text="Add Employers"
          v-if="showAddEmployer"
          v-model="newEmployerId"
        >
          <option
            v-for="company in otherCompanies"
            :key="company.name"
            :value="company.id"
          >Add {{ company.name }} as Employer</option>
        </BaseSelect>-->
        <BaseButton @click="addEmployer(newEmployerId)" class="space"
          >Add Employer</BaseButton
        >
        <BaseButton @click="toggleAddEmployer()" theme="alternate"
          >Cancel</BaseButton
        >
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../../style/Flex";
import ButtonAddRemove from "../../buttons/ButtonAddRemove";
import Avatar from "../../utils/Avatar";

export default {
  name: "Employer",
  title: null,
  components: { Flex, ButtonAddRemove, Avatar },

  props: {
    currentIndex: Number,
    steps: Array,
  },

  data() {
    return {
      employers: [],
      otherEmployers: [],
      primaryEmployerId: null,
      newEmployerId: null,
      currentUser: {},
      showAddEmployer: false,
      searchQueryEmployer: "",
      serachFromCard: false,
      showCards: true,
    };
  },

  async mounted() {
    this.$store.commit("global/START_LOADING");
    await Promise.all([this.getCompanies(), this.getUserEmployers()]);
    this.$store.commit("global/STOP_LOADING");
  },

  watch: {
    searchQueryEmployer(newValue) {
      if (this.serachFromCard) {
        this.showCards = false;
        this.serachFromCard = false;
      } else {
        this.showCards = true;
        this.newEmployerId = null;
      }
    },
  },

  computed: {
    primaryEmployer() {
      return this.employers.find((e) => e.id == this.primaryEmployerId);
    },

    filterOtherCompanies() {
      if (this.searchQueryEmployer.length > 2) {
        return this.otherCompanies.filter((r) => {
          return r.name
            .toLowerCase()
            .includes(this.searchQueryEmployer.toLowerCase());
        });
      }

      return [];
    },

    otherCompanies() {
      if (this.primaryEmployer) {
        const employers = this.employers.map((e) => e.employer.id);
        const otherEmployers = this.otherEmployers.map((e) => e.employer.id);
        return this.companies.filter(
          (c) => !employers.includes(c.id) && !otherEmployers.includes(c.id)
        );
      } else {
        return this.companies;
      }
    },
  },

  methods: {
    selectEmplyer(emplyer) {
      this.serachFromCard = true;
      this.newEmployerId = emplyer.id;
      this.searchQueryEmployer = emplyer.name;
    },
    async getCompanies() {
      await this.$store
        .dispatch("company/GET_COMPANIES")
        .then((response) => {
          this.companies = response.data;
        })
        .catch((r) => {
          this.$store.commit("global/STOP_LOADING");
        });
    },

    async getUserEmployers() {
      const user = await this.$store
        .dispatch("user/GET_USER_FROM_TOKEN")
        .catch((r) => {
          this.$store.commit("global/STOP_LOADING");
        });
      this.currentUser = user.data;
      await this.fetchEmployers(this.currentUser.id);
    },

    async fetchEmployers(userId) {
      await this.$store
        .dispatch("company/GET_EMPLOYERS", userId)
        .then((response) => {
          this.employers = response.data;
          this.primaryEmployerId = this.employers.filter(
            (e) => e.primary_employer === true
          )[0].id;
          this.otherEmployers = this.employers.filter(
            (r) => r.id !== this.primaryEmployer.id
          );
        })
        .catch((r) => {
          this.$store.commit("global/STOP_LOADING");
        });
    },

    addEmployer(companyId) {
      const data = { employee_id: this.currentUser.id, employer_id: companyId };
      this.$store.dispatch("company/ADD_EMPLOYER", data).then((response) => {
        this.employers.push(response.data);
        this.otherEmployers.push(response.data);
        if (this.employers.length == 1) {
          this.primaryEmployerId = this.employers[0].id;
          const setPrimaryData = {
            id: this.employers[0].id,
            employee_id: this.currentUser.id,
            employer_id: this.employers[0].employer.id,
            primary_employer: true,
          };

          this.$store
            .dispatch("company/UPDATE_EMPLOYER", setPrimaryData)
            .then((response) => {
              this.$parent.nextPage();
            });
        }
        this.searchQueryEmployer = "";
      });
      this.showAddEmployer = false;
    },

    toggleAddEmployer() {
      this.showAddEmployer = !this.showAddEmployer;
    },

    promptDeleteEmployer(relationship) {
      const removeEmployer = window.confirm(
        `Do you want to remove ${relationship.employer.name} as your employer?`
      );
      if (removeEmployer) this.deleteEmployer(relationship.id);
    },

    deleteEmployer(id) {
      this.$store.dispatch("company/DELETE_EMPLOYER", id).then((response) => {
        window.location.reload(true);
      });
    },

    setPrimaryEmployer() {
      if (!this.employers.length) return this.$parent.nextPage();

      const data = {
        id: this.primaryEmployerId,
        employee_id: this.currentUser.id,
        employer_id: this.primaryEmployer.employer.id,
        primary_employer: true,
      };

      this.$store.dispatch("company/UPDATE_EMPLOYER", data).then((response) => {
        this.$parent.nextPage();
      });
    },
  },
};
</script>

<style scoped>
h2 {
  padding-top: 1em;
}
.employer {
  font-weight: var(--semibold);
}
.add-an-employer {
  color: var(--border-color);
  font-size: 1.3125em;
  font-weight: var(--semibold);
  text-transform: uppercase;
}
.current-employers {
  padding: 0 0 2em 0;
  font-size: 1.1em;
  font-weight: var(--semibold);
}
</style>
