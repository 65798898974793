<template>
  <div class="add-card">
    <div class="add-active-credit-card-header" @click="addNewActivityPackage">
      <span class="add-activity-name">ADD SERVICE PACKAGE</span>
      <div class="btn-cta">
        <span class="sign"> + </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    addNewActivityPackage() {
      this.$router.push("/activity-package");
    },
  },
};
</script>

<style scoped>
.add-card {
  width: 100%;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.add-activity-name {
  max-width: 80%;
  display: inline-block;
  color: #9b9b9b;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 0;
}

.sign {
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-55%);
  position: relative;
}

.btn-cta {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  color: #64c8c1;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  float: right;
}
</style>