<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-select-horse">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <div class="header">Select horse for this activity</div>
        </header>
        <section
          class="modal-body space--large"
          id="modalDescription"
          v-if="!forTrainerReview"
        >
          <HorsePanel
            :horse="null"
            class="horse-card"
            @click.native="selectTainer"
          >
            <BaseButton theme="round alternate">
              <font-awesome-icon
                :icon="['fas', 'plus']"
                fixed-width
                style="color: #4fc2ba"
              />
            </BaseButton>
          </HorsePanel>
          <div v-for="horse in horses" :key="horse.id">
            <HorsePanel
              :horse="horse"
              class="horse-card"
              @click.native="selectHorse($event, horse)"
            >
              <BaseButton theme="round alternate">
                <font-awesome-icon
                  :icon="['fas', 'plus']"
                  fixed-width
                  style="color: #4fc2ba"
                />
              </BaseButton>
            </HorsePanel>
          </div>
        </section>
        <section class="modal-body space--large" id="modalDescription" v-else>
          <HorsePanel
            :horse="null"
            class="horse-card"
            @click.native="selectTainer"
          >
            <BaseButton theme="round alternate">
              <font-awesome-icon
                :icon="['fas', 'plus']"
                fixed-width
                style="color: #4fc2ba"
              />
            </BaseButton>
          </HorsePanel>
          <div v-for="horse in horses" :key="horse.id">
            <TrainerReviewHorsePanel
              :horse="horse"
              :number-of-activities="getNumberOfActivities(horse.id)"
              class="horse-card"
              @click.native="selectHorse($event, horse)"
            >
              <BaseButton theme="round alternate">
                <font-awesome-icon
                  :icon="['fas', 'plus']"
                  fixed-width
                  style="color: #4fc2ba"
                />
              </BaseButton>
            </TrainerReviewHorsePanel>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import HorsePanel from "./HorsePanel";
import TrainerReviewHorsePanel from "./TrainerReviewHorsePanel";

export default {
  name: "activityHorseSelectModal",
  components: { HorsePanel, TrainerReviewHorsePanel },
  props: {
    horses: Array,
    forTrainerReview: Boolean,
    horsesWithActivities: Array,
  },
  data() {
    return {
      riderAvatar: require("../../assets/images/avatars/user.jpg"),
      skill: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getNumberOfActivities(idOfHorse) {
      let horseArray = this.horsesWithActivities.filter(
        (r) => r.id == idOfHorse
      );
      if (horseArray && horseArray.length > 0) {
        return horseArray[0].user_activities_in_time_period.length;
      }
    },
    selectTainer() {
      this.$emit("sethorsetonull");
    },
    selectHorse(e, horse) {
      this.$emit("selecthorsefrommodal", horse);
    },
  },
};
</script>

<style>
.modal-backdrop-select-horse {
  width: 100%;
  height: 90%;
  position: fixed;
  top: 55px;
  overflow-y: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  z-index: 100;
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.modal-header {
  padding: 15px;
  display: flex;
}

.modal-header {
  color: #013053;
  justify-content: space-between;
  font-size: 21px;
  font-weight: 600;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.horse-card {
  width: 100%;
  padding-right: 10px;
}
</style>