<template>
  <nav v-if="enabled">
    <ul>
      <li>
        <router-link to="/Dashboard">
          <font-awesome-icon :icon="['fas', 'home']" />
          <span>Home</span>
        </router-link>
      </li>
      <li>
        <router-link :to="bookActivitiyRoute">
          <font-awesome-icon :icon="['fas', 'book']" />
          <span>Book Activity</span>
        </router-link>
      </li>
      <!--<li>
        <router-link to="/">
          <font-awesome-icon :icon="['fas', 'map-signs']" />
          <span>Stables</span>
        </router-link>
      </li>-->
      <li>
        <router-link to="/calendar">
          <font-awesome-icon :icon="['far', 'calendar-alt']" />
          <span>My Calendar</span>
        </router-link>
      </li>
      <!--      <li v-if="accountType == 'personal'">-->
      <!--        <router-link to="/user/invoices">-->
      <!--          <font-awesome-icon :icon="['far', 'money-bill-alt']" />-->
      <!--          <span>Invoices</span>-->
      <!--        </router-link>-->
      <!--      </li>-->
      <!--      <li v-else-if="accountType == 'company'">-->
      <!--        <router-link to="/companies/invoices">-->
      <!--          <font-awesome-icon :icon="['far', 'money-bill-alt']" />-->
      <!--          <span>Invoices</span>-->
      <!--        </router-link>-->
      <!--      </li>-->
      <li v-if="accountType != 'personal'" @click="goToBarnBoard">
        <a>
          <font-awesome-icon
            :icon="['fa', 'warehouse']"
            v-bind:class="{
              greyedOut: accountType == 'worker' && idForBarnBoard == null,
            }"
          />
          <span>Barn Board</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TheActionMenu",
  components: {},
  data() {
    return {
      idForBarnBoard: null,
    };
  },
  computed: {
    ...mapState({
      enabled: (state) => state.theActionMenu.enabled,
      accountType: (state) => state.user.currentUser.account_type,
      currentUser: (state) => state.user.currentUser,
    }),
    bookActivitiyRoute: function () {
      if (this.currentUser.account_type === "personal") {
        return "/activities";
      }
      return "/companies/create-user-activity";
    },
  },
  methods: {
    goToBarnBoard() {
      if (this.accountType == "company") {
        this.$router.push(`/barn-board/${this.currentUser.company_id}`);
      } else {
        if (this.idForBarnBoard != null) {
          this.$router.push(`/barn-board/${this.idForBarnBoard}`);
        }
      }
    },
  },
  async mounted() {
    if (this.accountType == "worker") {
      await this.$store
        .dispatch("company/GET_EMPLOYERS", this.currentUser.id)
        .then((response) => {
          let employers = response.data;
          this.idForBarnBoard = employers.filter(
            (e) => e.primary_employer === true
          )[0].employer.id;
        })
        .catch((r) => {
          this.$store.commit("global/STOP_LOADING");
        });
    }
  },
};
</script>

<style scoped>
nav {
  background-color: var(--action-menu-background-color);
  padding-bottom: env(safe-area-inset-bottom); /* iPhone X */
  position: fixed;
  text-align: center;
  transition: right 0.25s;
  width: 100%;
  z-index: 1003;

  bottom: 0;
  left: 0;
  right: 0;
}
nav ul {
  display: flex;
  justify-content: space-around;
}
nav ul li {
  width: 100%;
}
nav ul li a {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.4em;
}
@media (min-width: 321px) {
  nav ul li a {
    padding: 0.875em;
  }
}
nav ul li a svg {
  font-size: 1.5em;
}
nav ul li a span {
  font-size: 0.75em;
  margin-top: 0.5em;
}
.greyedOut {
  color: grey;
}
</style>
