<template>
  <div v-if="event.id">
    <h1 class="page-title">
      Event Details for: {{ event.title }} {{ `(${event.visibility})` }}
    </h1>

    <div class="space--large">
      <Cover :image-source="event.avatar" />
    </div>

    <div class="space--large">
      <h2 class="section-title">
        Location:
        <span v-if="event.location">{{ event.location.name }}</span>
      </h2>

      <div v-if="address">
        <div class="location-address space">
          {{ address.street_address_line1 }}
          <br />

          <div v-if="address.street_address_line1">
            {{ address.street_address_line2 }}
            <br />
          </div>

          {{ `${address.city}, ${address.state} ${address.postal_code}` }}
        </div>
      </div>
    </div>

    <BasePanel>
      <Flex class="space--large" justify="start">
        <div class="description">
          <h3 class="section-title">Details</h3>
          <p>{{ event.description }}</p>
        </div>
      </Flex>

      <Flex class="space--large" justify="start">
        <div>
          <h3 class="section-title">Date & Time:</h3>
          <p class="time space">
            {{ moment.utc(event.start_time).format("MMMM DD, YYYY") }}
            <br />
            {{
              `${moment.utc(event.start_time).format("hh:mm a")} - ${moment
                .utc(event.end_time)
                .format("hh:mm a")}`
            }}
          </p>
        </div>
      </Flex>

      <Flex class="space--large" justify="start">
        <div>
          <h3 class="section-title">Registration Deadline:</h3>
          <p class="time space">
            {{
              moment.utc(event.registration_deadline).format("MMMM DD, YYYY")
            }}
          </p>
        </div>
      </Flex>

      <Flex class="space--large" justify="start">
        <div>
          <h3 class="section-title">Max Number of Atendees:</h3>
          {{ event.max_attendance }}
        </div>
      </Flex>
    </BasePanel>

    <div v-if="isEventCreator">
      <BaseButton type="button" @click="editEvent()">Edit Event</BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Cover from "../utils/Cover";

export default {
  name: "EventPage",
  components: { Flex, Cover },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),

    isEventCreator() {
      return this.currentUser.company_id == this.event.company_id
        ? true
        : false;
    },
  },

  data() {
    return {
      event: {},
      address: {},
    };
  },

  created() {
    setTimeout(() => this.getEvent(), 2000);
  },

  methods: {
    getEvent() {
      this.$store
        .dispatch("event/GET_EVENT", this.$route.params.id)
        .then((response) => {
          this.event = response.data;
          this.event.location
            ? (this.address = this.event.location.address)
            : (this.address = this.event.address);
        });
    },

    editEvent() {
      this.$router.push({
        name: "edit-event",
        params: { eventId: this.event.id },
      });
    },
  },
};
</script>

<style scoped>
.location-name {
  color: var(--navy);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.location-address {
  line-height: 1.25em;
}
.description {
  font-size: 1.125em;
  line-height: 1.25em;
}
.time {
  font-size: 1.125em;
  font-style: italic;
  font-weight: var(--semibold);
}
</style>