import axios from "axios";
import moment from "moment";
import {
  GET_ACTIVITY_CREDIT,
  GET_COMPANY_ACTIVITY_CREDITS,
  GET_USER_CREDITS,
  DELETE_ACTIVITY_FROM_CREDIT,
  ADD_ACTIVITY_TO_CREDIT,
  SAVE_ACTIVITY_CREDIT,
  UPDATE_ACTIVITY_CREDIT,
  DELETE_ACTIVITY_CREDIT,
} from "../actions/activityCredit";

// Initial State
const state = {
  userCredits: [],
};

// Getters
const getters = {
  validUserCredits: (state) => {
    return state.userCredits.filter((credit) => {
      let expiration = credit.expiration_date;
      if (expiration != null) {
        let today = moment.utc();
        let expirationDate = moment.utc(expiration, "YYYY-MM-DD");
        return !credit.used && expirationDate > today;
      }
      return !credit.used;
    });
  },
};

// actions
const actions = {
  GET_COMPANY_ACTIVITY_CREDITS({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/activity_credits?company_id=" + companyId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  GET_ACTIVITY_CREDIT({ commit }, creditActivityId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/activity_credits/" + creditActivityId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  DELETE_ACTIVITY_CREDIT({ commit }, creditActivityId) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/activity_credits/" + creditActivityId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  ADD_ACTIVITY_TO_CREDIT({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/credit_mappings", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  DELETE_ACTIVITY_FROM_CREDIT({ commit }, mappingId) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/credit_mappings/" + mappingId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  SAVE_ACTIVITY_CREDIT({ commit }, createActivityCreditParams) {
    return new Promise((resolve, reject) => {
      axios
        .post("/activity_credits", createActivityCreditParams)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  GET_USER_CREDITS({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("credit_ownerships?user_id=" + userId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          commit("setUsersCredits", response.data);
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
};

// Mutations
const mutations = {
  setUsersCredits(state, credits) {
    state.userCredits = credits;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
