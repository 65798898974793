<template>
  <Flex v-if="horse.id" direction="column" justify="start">
    <BackButton theme="round" style-class="top-left" />
    <Flex justify="between" direction="column">
      <Cover :image-source="locationImage">
        <Flex direction="column">
          <Avatar :image-source="horseImage" />
          <p>{{ horse.usef_name }}</p>
          <p>"{{ horse.nickname }}"</p>
        </Flex>
      </Cover>
    </Flex>

    <!-- <div class="logo-box">
        <img :src="logoImage" alt="logo" class="logo-image" />
      </div>
      <div class="horse-avatar">
        <img :src="horseImage" alt="horse image" class="horse-avatar-image" />
      </div>
      <h3 class="horse-header-details text-center title">
        {{ horse.usef_name }}
      </h3>
      <h4 class="horse-header-details text-center subtitle">
        {{ locationName }}
      </h4> -->
    <!-- <button id="btn-follow">FOLLOWING <span class="hearth">♡ ♥</span></button> -->

    <Flex direction="column">
      <br />
      <!-- <h4 class="stats-title">Stats</h4> -->
      <base-input
        name="usef_name"
        label-text="Registered Name"
        :value="horse.usef_name"
        :disabled="true"
        class="horse-profile-input"
      />

      <base-input
        name="nickname"
        label-text="Nickname"
        :value="horse.nickname"
        :disabled="true"
        class="horse-profile-input"
      />
      <base-input
        name="usefNumber"
        label-text="USEF#"
        :value="horse.usef_number"
        :disabled="true"
        class="horse-profile-input"
      />
      <base-input
        name="dob"
        label-text="D.O.B."
        :value="horse.date_of_birth"
        :disabled="true"
        class="horse-profile-input"
      />
      <base-input
        name="sex"
        label-text="Sex"
        :value="horse.sex"
        :disabled="true"
        class="horse-profile-input"
      />
      <Flex direction="row">
        <base-input
          name="height-value"
          label-text="Height"
          v-model="horse.height"
          :disabled="true"
          class="horse-profile-input-inline"
        />
        <base-select
          :label="true"
          name="height-type"
          v-model="horse.height_type"
          :disabled="true"
          class="horse-profile-input-inline horse-profile-input-inline-ml"
        >
          <option value="hands">Hands</option>
          <option value="centimeters">Centimeters</option>
        </base-select>
      </Flex>
      <base-input
        name="color"
        label-text="Color"
        v-model="horse.color"
        :disabled="true"
        class="horse-profile-input"
      />
      <base-input
        name="breed"
        label-text="Breed"
        v-model="horse.breed"
        :disabled="true"
        class="horse-profile-input"
      />
    </Flex>

    <hr />

    <div class="about">
      <h4 class="about-title">About</h4>
      <p class="about-content">{{ aboutParagraph }}</p>
    </div>

    <div
      class="continue-reading"
      v-if="showContinueReading && !continueReadingPressed"
      @click="continueReadingPressed = true"
    >
      Continue Reading
    </div>
  </Flex>
</template>

<script>
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";
import CompanyPanel from "../companies/CompanyPanel";
import BaseInput from "../../components/base/BaseInput";
import BaseSelect from "../../components/base/BaseSelect";
import BackButton from "../../components/common/BackButton.vue";
import Cover from "../../components/utils/Cover.vue";

export default {
  name: "PageHorseProfile",
  components: {
    Flex,
    Avatar,
    CompanyPanel,
    BaseInput,
    BaseSelect,
    BackButton,
    Cover,
  },

  data() {
    return {
      horse: {},
      continueReadingPressed: false,
    };
  },

  computed: {
    horseImage() {
      if (this.horse && this.horse.avatar) {
        return this.horse.avatar;
      }
      return require("../../assets/images/avatars/horse.jpg");
    },
    locationName() {
      if (this.horse && this.horse.location) {
        return this.horse.location.name;
      }
      return "";
    },
    locationImage() {
      if (this.horse && this.horse.location) {
        return this.horse.location.avatar;
      }
      return "";
    },
    logoImage() {
      if (this.horse && this.horse.location && this.horse.location.avatar) {
        return this.horse.location.avatar;
      }
      return require("../../assets/images/logo.svg");
    },
    aboutParagraph() {
      if (!this.horse) {
        return "";
      }
      if (!this.horse.about) {
        return "";
      }
      var allWords = this.horse.about.split(" ");
      var result = "";
      for (var i = 0; i < allWords.length; i++) {
        if (i < 30) {
          result += allWords[i] + " ";
        } else if (i >= 30 && this.continueReadingPressed) {
          result += allWords[i] + " ";
        }
      }
      if (allWords.length >= 30 && !this.continueReadingPressed0) {
        result += "...";
      }
      return result;
    },
    showContinueReading() {
      if (!this.horse || !this.horse.about) {
        return false;
      }
      var allWords = this.horse.about.split(" ");
      if (allWords.length > 29) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.getHorse();
  },

  methods: {
    getHorse() {
      this.$store
        .dispatch("horse/GET_HORSE", this.$route.params.id)
        .then((response) => {
          this.horse = response.data;
        });
    },
  },
};
</script>

<style scoped>
.hearth {
  color: red;
}
.text-center {
  text-align: center;
}
#btn-follow {
  background-color: #ffffff;
  padding: 1em 2em;
  border-radius: 50px;
  top: 100%;
  position: relative;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  color: #bcbcbc;
  border: 1px solid #bcbcbc;
  display: none;
}
#btn-follow > span {
  color: #ff9cb0;
  margin-left: 5px;
}

.stats-title,
.about-title {
  color: #2c526e;
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.horse-name {
  color: var(--navy);
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.title {
  font-size: 1.4em;
  font-weight: var(--semibold);
  line-height: 1.6;
  color: #ffffff;
}
.subtitle {
  font-size: 1.1em;
  color: #ffffff;
}
.horse-heading {
  background: url(../../assets/images/covers/company.jpg);
  background-position: center;
  background-size: cover;
  height: 45vh;
  margin-bottom: 30px;
}
.heading-content {
  /* position: absolute; */
  height: 15%;
  width: 40%;
  left: 50%;
  top: 12%;
  transform: translateX(-50%);
  text-align: center;
}

.horse-avatar {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}
.horse-avatar-image {
  border-radius: 50%;
  margin-bottom: 30%;
}
.logo-image {
  height: 100px;
  width: 100%;
}
.heading {
  color: var(--navy);
  font-size: 1.15em;
  font-weight: var(--semibold);
}
.horse-profile-input {
}
.horse-profile-input-inline {
  display: inline-block;
}

.horse-profile-input-inline-ml {
  margin-left: 5px;
}

.about-content {
  padding: 0px 8px;
  /* font-weight: var(--semibold); */
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
}
.continue-reading {
  color: #4cc1ba;
  margin-top: 10px;
  font-size: 18px;
}

.horse-header-details {
  text-shadow: 2px 2px 4px #000000;
}
</style>
