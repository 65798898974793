<template>
  <div class="card">
    <img :src="barnImage" alt="barnImage" class="avatar-image" />
    <div class="barn-details">
      <div class="barn-name">
        {{ barnname }}
      </div>
      <div class="barn-numbers" v-if="trainerType">
        <span>
          <div class="bullet"></div>
          {{ trainerType }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatarimage: {
      type: String,
      default: null,
    },
    barnname: {
      default: null,
    },
    trainerType: {
      default: null,
    },
  },
  computed: {
    barnImage() {
      if (this.avatarimage) {
        return this.avatarimage;
      }
      return require("../../assets/images/covers/location.jpg");
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 10px;
  /* padding: 15px; */
  -webkit-box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  -moz-box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  display: flex;
  align-items: center;
}

.bullet {
  display: inline-block;
  height: 9px;
  width: 9px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-left: 8px;
}

.barn-details {
  height: 100%;
  margin-left: 20px;
}

.barn-name {
  color: gray;
  font-weight: var(--semibold);
  font-size: 1em;
  margin: 18px 0px 10px 10px;
}

.barn-numbers {
  margin: 5px 0px 10px 10px;
  font-size: 14px;
  color: gray;
  font-weight: var(--semibold);
}
</style>