<template>
  <div>
    <div v-if="editing">
      <h2 class="section-title">Add a barn</h2>
      <!-- <BaseInput label-text="Search by barn name" /> -->

      <LocationForm
        v-if="currentUser"
        :current-user-id="currentUser.id"
        :company-id="currentUser.company_id"
        :barn="currentBarn"
      />
      <BaseButton theme="alternate" @click="toggleEditMode(false)">
        Cancel
      </BaseButton>
    </div>

    <div v-else>
      <BaseSelect
        id="home_barn"
        :label="false"
        placeholder="Select Home Barn"
        v-model="homeBarnId"
        v-show="false"
      >
        <option v-for="barn in barns" :key="barn.id" :value="barn.id">
          {{ barn.location.name }}
        </option>
      </BaseSelect>

      <h2 class="section-title">My Barns</h2>
      <LocationPanel
        v-if="barns.length"
        v-for="barn in barns"
        :location="barn"
        :key="barn.id"
        @edit-location="selectAndEditBarn"
        :owner="barn.location.company_id == currentUser.company_id"
      />

      <BasePanel type="clickable" @click.native="selectAndEditBarn(newBarn)">
        <Flex justify="between" direction="row">
          <p class="add-a-barn">Add A Barn</p>
          <ButtonAddRemove type="add" />
        </Flex>
      </BasePanel>

      <div v-if="errorMessage">
        <p class="error">{{ errorMessage }}</p>
      </div>

      <BaseButton @click="setHomeBarn()">Save & Continue</BaseButton>
    </div>
  </div>
</template>

<script>
import ButtonAddRemove from "../../buttons/ButtonAddRemove";
import Flex from "../../style/Flex";
import LocationPanel from "../../locations/LocationPanel";
import LocationForm from "../../locations/LocationForm";

export default {
  name: "Locations",
  title: null,
  components: { Flex, ButtonAddRemove, LocationPanel, LocationForm },
  props: {
    currentIndex: Number,
    steps: Array,
    currentLocationId: Number,
  },
  data() {
    return {
      barns: [],
      currentBarn: {},
      newBarn: { address: {}, location: {} },
      homeBarnId: null,
      editing: false,
      currentUser: {},
      errorMessage: "",
      loaded: false,
    };
  },

  watch: {
    loaded: function (newValue) {
      if (newValue == true && this.currentLocationId) {
        const filtered = this.barns.filter(
          (r) => r.location.id == this.currentLocationId
        );
        if (filtered && filtered.length > 0) {
          this.selectAndEditBarn(filtered[0]);
        }
      }
    },
  },

  mounted() {
    this.getUser();
  },

  methods: {
    async getUser() {
      this.$store.commit("global/START_LOADING");
      const user = await this.$store.dispatch("user/GET_USER_FROM_TOKEN");
      this.currentUser = user.data;
      await this.getUserBarns(this.currentUser.id);
      this.$store.commit("global/STOP_LOADING");
    },

    async getUserBarns(userId) {
      await this.$store
        .dispatch("location/GET_USER_LOCATIONS", userId)
        .then((response) => {
          this.barns = response.data;
          this.homeBarnId = this.barns.filter((b) => b.home_location)[0].id;
          this.loaded = true;
        })
        .catch(() => {
          this.$store.commit("global/STOP_LOADING");
        });
    },

    toggleEditMode(state) {
      this.editing = state;
    },

    locationSaved(newLocation) {
      this.barns.push(newLocation);
      this.toggleEditMode(false);
    },

    selectAndEditBarn(barn) {
      this.currentBarn = barn;
      this.toggleEditMode(true);
    },

    setHomeBarn() {
      const newPath = this.steps[this.currentIndex + 1].value;
      if (!this.homeBarnId) {
        this.errorMessage = "You must choose a Home Barn before continuing.";
        return;
      }

      this.$store
        .dispatch("location/SET_HOME_LOCATION", this.homeBarnId)
        .then((response) => {
          this.errorMessage = "";
          this.$router.push(newPath);
        });
    },
  },
};
</script>

<style scoped>
p,
a {
  font-size: 1.125em;
}
.close {
  font-size: 2.5em;
}
.add-a-barn {
  color: var(--border-color);
  font-size: 1.3125em;
  font-weight: var(--semibold);
  text-transform: uppercase;
}
.error {
  font-size: 1.25em;
  color: red;
  padding: 1em;
}
</style>
