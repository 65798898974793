<template>
  <form
    id="profile"
    @submit.prevent="updateUser()"
    enctype="multipart/form-data"
  >
    <AvatarUpload
      name="avatar"
      :avatar="currentUser.avatar"
      @delete-photo-request="confirmDeletePhoto"
    >
    </AvatarUpload>

    <BaseInput
      type="text"
      name="first_name"
      label-text="First Name"
      v-model="currentUser.first_name"
    />
    <BaseInput
      type="text"
      name="last_name"
      label-text="Last Name"
      v-model="currentUser.last_name"
    />
    <BaseInput
      type="email"
      name="email"
      label-text="Email Address"
      v-model="currentUser.email"
    />
    <BaseButton
      id="change-passowrd-button"
      type="button"
      @click="changePassword"
      theme="alternate auto"
      >Change password</BaseButton
    >
    <Flex>
      <BaseButton type="submit" theme="auto">Save & Continue</BaseButton>
      <BaseButton type="button" @click="confirmDelete()" theme="alternate auto"
        >Delete Account</BaseButton
      >
    </Flex>
    <change-password-modal
      v-if="showPasswordModal"
      @goback="showPasswordModal = false"
    />
    <loading-modal v-if="showLoading" />
  </form>
</template>

<script>
import Flex from "../../style/Flex";
import AvatarUpload from "../../utils/AvatarUpload";
import BaseProfile from "../BaseProfile";
import ChangePasswordModal from "../../common/ChangePasswordModal";
import LoadingModal from "../../utils/LoadingModal";

export default {
  name: "Profile",
  title: null,
  components: { Flex, AvatarUpload, ChangePasswordModal, LoadingModal },
  extends: BaseProfile,
  data() {
    return {
      showPasswordModal: false,
      showLoading: false,
    };
  },
  methods: {
    changePassword() {
      this.showPasswordModal = true;
    },
  },
};
</script>

<style scoped>
#change-passowrd-button {
  width: 100%;
  margin-bottom: 10px;
}
</style>
