<template>
  <div class="card">
    <div class="top"></div>
    <div class="center">**** **** **** {{ creditCard.last4 }}</div>
    <div class="bottom">
      <div class="name">
        {{ name }}
        <br />
        {{ `${creditCard.exp_month}/${creditCard.exp_year}` }}
      </div>
      <div class="brand">{{ creditCard.brand }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCreditCard",
  props: {
    name: String,
    creditCard: Object,
  },
};
</script>

<style scoped>
.card {
  align-items: flex-start;
  background: var(--card-background-color);
  border: 1px solid var(--card-border-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 54vw;
  justify-content: space-between;
  max-height: 200px;
  max-width: 340px;
  padding: 0.5em;
  width: 100%;
}
.top {
  align-items: center;
  background: white;
  border-radius: 4px;
  display: flex;
  height: 2.5em;
  justify-content: center;
  position: relative;
  width: 3.4375em;
}
.top:before,
.top:after {
  content: "";
  border-color: var(--card-background-color);
  border-style: solid;
  display: block;
  position: absolute;
}
.top:before {
  border-width: 0 2px 0 2px;
  bottom: 0;
  top: 0;
  width: 33%;
}
.top:after {
  border-width: 2px 0 2px 0;
  height: 33%;
  left: 0;
  right: 0;
}
.center {
  display: flex;
  font-size: 1.75em;
  font-weight: 600;
  justify-content: center;
  width: 100%;
}
.bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bottom .name {
  text-align: left;
  width: 100%;
}
.bottom img {
  max-height: 1.25em;
  width: auto;
}
.brand {
  font-size: 1.75em;
  font-weight: 600;
}
</style>
