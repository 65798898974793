<template>
  <span class="radio">
    <input
      :id="name"
      :name="name"
      type="radio"
      :checked="value"
      :value="value"
      @input="emitChange"
    />
    <label :for="name" v-visible="label">
      {{ labelText }}
    </label>
  </span>
</template>

<script>
export default {
  name: "BaseRadio",
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    labelText: String,
    name: String,
    value: String,
  },
  data() {
    return {};
  },
  methods: {
    emitChange(event) {
      // This won't work with named values
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style scoped>
</style>
