<template>
  <div class="recent-activity-card">
    <div class="avatar-container">
      <img :src="trainerAvatar" alt="Trainer avatar" />
    </div>
    <div class="activity-info">
      <div class="trainer">
        {{ activity.trainer }}
      </div>
      <div class="activity-name">
        {{ activity.employee_activity.activity.full_title }}
      </div>
    </div>
    <div class="rebook" @click="rebookActivity">
      <BaseButton
        class="button-add-remove"
        theme="round alternate"
        style="background-color: var(--seafoam)"
      >
        <font-awesome-icon
          :icon="['fas', 'redo']"
          fixed-width
          style="color: white"
        />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import ButtonAddRemove from "../buttons/ButtonAddRemove";
export default {
  props: {
    activity: {
      type: Object,
      default: {},
    },
  },
  components: { ButtonAddRemove },
  data: function () {
    return {
      defaultUserImage: require("../../assets/images/avatars/trainer.png"),
    };
  },
  computed: {
    trainerAvatar() {
      if (this.activity.employee_activity.employee.avatar) {
        return this.activity.employee_activity.employee.avatar;
      }
      return this.defaultUserImage;
    },
  },
  methods: {
    rebookActivity() {
      this.$router.push({
        path: "/activities",
        query: {
          locationId: this.activity.location_id,
          employeeActivityId: this.activity.employee_activity.id,
          trainerId: this.activity.employee_activity.employee.id,
          horse_id: this.activity.horse_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.recent-activity-card {
  width: 100%;
  height: 70px;
  padding: 10px;
  position: relative;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  margin: 7px 0px;
  border: 1px solid #d8d8d8;
}

.avatar-container {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.activity-info {
  display: inline-block;
  height: 100%;
  top: 8px;
  position: absolute;
  margin-left: 10px;
  max-width: 60%;
}
.trainer {
  color: var(--dark-grey);
  font-weight: 600;
  font-size: 18px;
}
.activity-name {
  font-size: 14px;
}
.rebook {
  float: right;
  height: 50px;
  width: 50px;
}
</style>