<template>
  <div class="name">
    {{ location.name }}
  </div>
</template>

<script>
export default {
  props: ["location"],
};
</script>

<style scoped>
.name {
  color: var(--black);
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
</style>