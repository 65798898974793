<template>
  <Flex v-if="location.id" direction="column" justify="start">
    <BackButton theme="round" style-class="top-left" />
    <Flex direction="column">
      <Cover :image-source="location.avatar">
        <p>{{ location.name }}</p>
      </Cover>
    </Flex>
    <!-- <button id="btn-follow"> FOLLOW 
         <span class="hearth">♡  ♥</span>
      </button> -->
    <Flex justify="end" direction="row">
      <button id="btn-phone" class="circle-top" v-if="location.primary_phone">
        <font-awesome-icon :icon="['fas', 'phone']" fixed-width />
      </button>
      <button id="btn-mail" class="circle-top" v-if="location.primary_email">
        <font-awesome-icon :icon="['fas', 'envelope']" fixed-width />
      </button>
      <button
        id="btn-website"
        class="circle-top"
        v-if="location.company.website"
      >
        <font-awesome-icon :icon="['fas', 'globe-americas']" fixed-width />
      </button>
    </Flex>

    <!-- THIS BUTTON WILL BE SHOWN WHEN WE DO SOME VALIDATION ON WHO CAN SEE IT -->
    <!-- <button id="btn-settings" v-if="currentUser.account_type !== 'personal'" @click="goToSettings">
        <font-awesome-icon :icon="['fas','cog']" fixed-width />
      </button> -->

    <Flex direction="column" justify="start">
      <h3 class="heading">{{ location.name }}</h3>
      <div class="location-address">
        {{ location.address.formatted_address }}
      </div>
      <!-- <button id="btn-view" @click="goToBarnBoard">VIEW BARN BOARD</button> -->

      <div v-if="location.company.bio">
        <p class="barn-description">
          {{ aboutParagraph }}
        </p>

        <span
          class="expand-reading"
          v-if="showContinueReading && !continueReadingPressed"
          @click="continueReadingPressed = true"
          >Continue Reading...</span
        >
      </div>

      <h3 class="sub-heading">Trainers</h3>

      <Flex direction="row" justify="start">
        <router-link
          :to="'/users/' + trainer.id"
          v-for="trainer in trainers"
          :key="trainer.id"
          ><Flex direction="column" justify="between" class="mb1">
            <Avatar :image-source="trainer.avatar" size="large" />
            <p class="trainer_name">{{ trainer.name }}</p>
          </Flex>
        </router-link>
      </Flex>
      <span class="sub-heading"> Horses</span>
      <Flex direction="row" justify="start" v-if="horses && horses.length > 0">
        <router-link
          v-for="horse in horses"
          :key="horse.id"
          :to="'/horses/' + horse.id"
        >
          <BasePanel>
            <Flex direction="column" justify="between">
              <Avatar
                :image-source="horse.avatar"
                size="small"
                avatar-type="horse"
              />
              <p class="horse_name">{{ horse.nickname }}</p>
            </Flex>
          </BasePanel>
        </router-link>
      </Flex>

      <span class="sub-heading">Companies</span>

      <company-card
        @click="goToCompany(location.company_id)"
        :avatarImage="
          location.company.avatar
            ? location.company.avatar
            : defaultCompanyImage
        "
        :companyName="location.company.name"
        :trainersNr="location.company.employees.length"
        :servicesNr="companyActivitiesNumber"
      />
    </Flex>
  </Flex>
</template>

<script>
import Flickity from "flickity";
import Avatar from "../utils/Avatar";
import Cover from "../utils/Cover";
import Flex from "../style/Flex";
import TrainerPanel from "../trainers/TrainerPanel";
import CompanyPanel from "../companies/CompanyPanel";
import PresentationCard from "../common/PresentationCard";
import CompanyCard from "../common/CompanyCard";
import { mapState } from "vuex";
import BackButton from "../common/BackButton.vue";
export default {
  name: "PageLocationProfile",
  components: {
    Flex,
    Cover,
    Avatar,
    TrainerPanel,
    CompanyPanel,
    PresentationCard,
    CompanyCard,
    BackButton,
  },

  data() {
    return {
      logoImage: require("../../assets/images/logo.svg"),
      defaultLocationImage: require("../../assets/images/covers/location.jpg"),
      location: {},
      horses: [],
      trainers: [],
      companies: [],
      continueReadingPressed: false,
      defaultHorseImage: require("../../assets/images/avatars/horse.jpg"),
      defaultCompanyImage: require("../../assets/images/covers/company.jpg"),
      companyActivitiesNumber: 0,
    };
  },

  computed: {
    locationImage() {
      if (this.location.avatar) {
        return {
          "background-image": "url(" + this.location.avatar + ")",
        };
      }
      return {
        "background-image": "url(" + this.defaultLocationImage + ")",
      };
    },
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    aboutParagraph() {
      if (this.location && this.location.introduction) {
        var allWords = this.location.introduction.split(" ");
        var result = "";
        for (var i = 0; i < allWords.length; i++) {
          if (i < 30) {
            result += allWords[i] + " ";
          } else if (i >= 30 && this.continueReadingPressed) {
            result += allWords[i] + " ";
          }
        }
        if (allWords.length >= 30 && !this.continueReadingPressed) {
          result += "...";
        }
        return result;
      }
      return "";
    },
    showContinueReading() {
      if (!this.location || !this.location.introduction) {
        return false;
      }
      var allWords = this.location.introduction.split(" ");
      if (allWords.length > 29) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    setTimeout(() => {
      this.getLocation();
      this.getLocationTrainers();
      this.getHorses();
    }, 1000);

    this.initializeSlider("slider");
    this.initializeSlider("slider-2");
  },

  methods: {
    goToCompany(id) {
      this.$router.push("/companies/profile/" + id);
    },
    goToBarnBoard() {
      if (this.location && this.location.company_id) {
        this.$router.push(`/barn-board/${this.location.company_id}`);
      }
    },
    initializeSlider(id) {
      const slider = document.getElementById(id);
      if (!slider) return;

      const flkty = new Flickity(slider, {
        cellAlign: "left",
        contain: true,
        prevNextButtons: false,
        setGallerySize: true,
      });
    },

    goToSettings() {
      this.$router.push({
        path: "/register/company/locations",
        query: {
          goToLocationId: this.location.id,
        },
      });
    },

    getLocation() {
      this.$store
        .dispatch("location/GET_LOCATION", this.$route.params.id)
        .then((response) => {
          this.location = response.data;
          this.getCompanyActivities();
        });
    },

    getCompanyActivities() {
      this.$store
        .dispatch("activity/GET_COMPANY_ACTIVITIES", {
          companyId: this.location.company.id,
        })
        .then((response) => {
          this.companyActivitiesNumber = response.data.length;
        });
    },

    getLocationTrainers() {
      this.$store
        .dispatch("trainer/LOCATION_TRAINERS", this.$route.params.id)
        .then((response) => {
          this.trainers = response.data;
        });
    },

    getHorses() {
      this.$store
        .dispatch("horse/GET_HORSES_BY_BARN_ID", this.$route.params.id)
        .then((response) => {
          this.horses = response.data;
        });
    },
  },
};
</script>

<style scoped>
button {
  outline: none;
}

.location-heading {
  /* background: url(../../assets/images/covers/company.jpg); */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 45vh;
  margin-bottom: 30px;
}
.heading-content {
  position: absolute;
  height: 15%;
  width: 60%;
  left: 50%;
  top: 12%;
  transform: translateX(-50%);
  text-align: center;
}

.logo-image {
  height: 100px;
  width: 75%;
}

.text-center {
  text-align: center;
}

.title {
  margin-top: 30px;
  font-size: 1.4em;
  font-weight: var(--semibold);
  line-height: 1.6;
  color: #ffffff;
}

.contact {
  color: #4a4a4a;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}

.mb1 {
  margin-bottom: 1em;
}

.globe {
  color: #4ac1ba;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  font-size: 18px;
}

.expand-reading {
  color: #4ac1ba;
  font-size: 20px;
  margin: 10px 0 30px 0;
  display: block;
  font-style: italic;
}

.barn-description {
  font-size: 18px;
  line-height: 1.3;
  margin: 20px 0;
  font-family: "Graphik";
}

#btn-follow {
  background-color: #ffffff;
  padding: 1em 3em;
  border-radius: 50px;
  top: 100%;
  position: relative;
  transform: translateY(-50%);
  left: 5%;
  color: #bcbcbc;
  border: 1px solid #bcbcbc;
  font-size: 85%;
  font-weight: var(--semibold);
}

#btn-view {
  text-align: center;
  color: #ffffff;
  background-color: #4ac1ba;
  padding: 15px 0;
  width: 100%;
  border-radius: 50px;
  margin: 20px 0;
  font-size: 18px;
}

#btn-settings {
  background-color: #ffffff;
  border-radius: 700px;
  padding: 1em 1em;
  top: 100%;
  position: relative;
  transform: translateY(-50%);
  float: right;
  right: 8%;
  color: #bcbcbc;
  border: 1px solid #bcbcbc;
  font-size: 100%;
}

/* #btn-mail {
  background-color: #4ac1ba;
  border-radius: 700px;
  padding: 1em 1em;
  top: 100%;
  position: relative;
  transform: translateY(-50%);
  float: right;
  right: 8%;
  color: #ffffff;
  border: 1px solid #bcbcbc;
  font-size: 100%;
  margin-left: 10px;
} */

.hearth {
  color: red;
}

.location-details {
  margin-top: 50px;
}

.location-address {
  align-self: flex-start;
  width: 70%;
  color: #626262;
  font-size: 1.05em;
  line-height: 1.4;
}

.cards-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.cards-wrapper::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* -------------------previous---------------------- */

.heading {
  align-self: flex-start;
  color: var(--navy);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
  display: flex;
}

.sub-heading {
  align-self: flex-start;
  color: var(--navy);
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin-bottom: 1em;
  display: flex;
}

/* .location-address {
    line-height: 1.25em;
  } */
.contact {
  font-size: 1.25em;
  font-weight: var(--semibold);
}
.contact__item {
  margin-bottom: 0.5em;
}
.planet {
  color: var(--current-accent-color);
}
.description {
  font-size: 1.125em;
  line-height: 1.25em;
}
.continue {
  font-size: 1.125em;
  font-weight: var(--semibold);
}
.slider {
  /* display: block; */
  max-width: 100%;
  overflow: hidden;
}
.slider:focus {
  outline: none;
}
.slide {
  font-weight: var(--semibold);
  margin-right: 1em;
  text-align: center;
}

.horse_name {
  margin-top: 0.5em;
  color: var(--navy);
  font-weight: var(--semibold);
  font-size: 1.1em;
}

.trainer_name {
  margin-top: 0.5em;
  color: var(--navy);
  font-weight: var(--semibold);
  font-size: 1.25em;
}

.circle-top {
  background-color: #4ac1ba;
  border-radius: 700px;
  padding: 1em 1em;
  top: 100%;
  position: relative;
  transform: translateY(-50%);
  /* float: right; */
  /* right: 8%; */
  color: #ffffff;
  border: 1px solid #bcbcbc;
  font-size: 100%;
  margin-left: 5px;
}
</style>
