<template>
  <div>
    <p class="space">
      Please select all of the services you would like to offer. These should be
      selected based on location.
    </p>

    <form id="activities" @submit.prevent="$parent.nextPage()">
      <BaseSelect
        label-text="Employer"
        v-if="employers.length && currentEmployer"
        id="employer-select"
        class="employer-name"
        v-model="currentEmployerId"
      >
        <option
          v-for="relationship in employers"
          :key="relationship.id"
          :value="relationship.id"
          @click="switchCurrentEmployer(relationship)"
        >
          {{ relationship.employer.name }}
        </option>
      </BaseSelect>
      <Cover
        size="small"
        class="space"
        v-if="currentEmployer"
        :image-source="currentEmployer.employer.avatar"
      >
        <!-- if statement required for first run while async completes -->
        <h2 class="employer-name" v-if="currentEmployer">
          {{ currentEmployer.employer.name }}
        </h2>
      </Cover>

      <div v-if="AddedEmployeeServices.length">
        <h2 class="section-title">My Activities</h2>

        <BaseLesson
          v-for="service in AddedEmployeeServices"
          :key="service.id"
          :name="service.full_name"
        >
          <ButtonAddRemove
            :type="service.isClaimed ? 'remove' : 'add'"
            @click.native="promptAddOrRemoveService(service, service.isClaimed)"
          />
        </BaseLesson>
      </div>

      <div v-if="AvailableEmployeeServices.length">
        <h2 class="section-title">Company Activities</h2>

        <BaseLesson
          v-for="service in AvailableEmployeeServices"
          :key="service.id"
          :name="service.full_name"
        >
          <ButtonAddRemove
            :type="service.isClaimed ? 'remove' : 'add'"
            @click.native="promptAddOrRemoveService(service, service.isClaimed)"
          />
        </BaseLesson>
      </div>

      <p class="space" v-if="!currentEmployerServices.length">
        This employer does not offer any services.
      </p>

      <BaseButton type="submit">Save & Continue</BaseButton>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cover from "../../utils/Cover";
import Flex from "../../style/Flex";
import ButtonAddRemove from "../../buttons/ButtonAddRemove";

export default {
  name: "Services",
  title: null,
  components: { Cover, Flex, ButtonAddRemove },
  props: {
    currentIndex: Number,
    steps: Array,
  },
  data() {
    return {
      currentUser: {},
      employeeServices: [],
      employers: [],
      primaryEmployerId: null,
      currentEmployerId: null,
      currentEmployerServices: [],
    };
  },

  async mounted() {
    this.$store.commit("global/START_LOADING");
    await this.getUserEmployers();
    this.$store.commit("global/STOP_LOADING");
  },

  computed: {
    currentEmployer() {
      return this.employers.find((e) => e.id == this.currentEmployerId);
    },

    primaryEmployer() {
      return this.employers.find((e) => e.id == this.primaryEmployerId);
    },
    AddedEmployeeServices() {
      return this.currentEmployerServices.filter((r) => {
        return r.isClaimed;
      });
    },
    AvailableEmployeeServices() {
      return this.currentEmployerServices.filter((r) => {
        return !r.isClaimed;
      });
    },
  },

  methods: {
    async getUserEmployers() {
      const user = await this.$store.dispatch("user/GET_USER_FROM_TOKEN");
      this.currentUser = user.data;
      await Promise.all([
        this.fetchEmployeeServices(this.currentUser.id),
        this.fetchEmployers(this.currentUser.id),
      ]);
    },

    async fetchEmployers(userId) {
      const employers = await this.$store.dispatch(
        "company/GET_EMPLOYERS",
        userId
      );
      this.employers = employers.data;

      const userPrimaryEmployer = this.employers.filter(
        (e) => e.primary_employer === true
      )[0];
      if (userPrimaryEmployer) this.primaryEmployerId = userPrimaryEmployer.id;
      this.currentEmployerId = this.primaryEmployerId;

      if (this.primaryEmployer) {
        await this.fetchEmployerServices(this.primaryEmployer.employer.id);
      }
    },

    async fetchEmployerServices(companyId) {
      await this.$store
        .dispatch("activity/GET_COMPANY_ACTIVITIES", {
          companyId,
          type: "lessons",
        })
        .then((response) => {
          this.currentEmployerServices = response.data;
          this.markClaimedServices();
        });
    },

    async fetchEmployeeServices(userId) {
      await this.$store
        .dispatch("activity/GET_TRAINER_ACTIVITIES", {
          userId,
          type: "lessons",
        })
        .then((response) => {
          this.employeeServices = response.data;
        });
    },

    markClaimedServices() {
      const companyServicesIds = this.currentEmployerServices.map((s) => s.id);
      const claimedServices = this.employeeServices
        .filter((service) => {
          return companyServicesIds.includes(service.company_activity_id);
        })
        .map((s) => s.company_activity_id);

      this.currentEmployerServices.map((cs) => {
        claimedServices.includes(cs.id)
          ? (cs.isClaimed = true)
          : (cs.isClaimed = false);
        return cs;
      });
    },

    switchCurrentEmployer(relationship) {
      this.currentEmployerId = relationship.id;
      this.fetchEmployerServices(relationship.employer.id);
    },

    promptAddOrRemoveService(companyService, isClaimed) {
      const action = isClaimed ? "remove" : "add";
      if (action == "add") {
        this.addOrRemoveService(companyService, isClaimed);
        return;
      }
      const prompt = window.confirm(`Do you want to ${action} this service.`);
      if (prompt) this.addOrRemoveService(companyService, isClaimed);
    },

    addOrRemoveService(companyService, isClaimed) {
      let functionName = "";
      if (isClaimed) {
        functionName = "DESTROY_TRAINER_ACTIVITY";
        let aux = this.employeeServices.filter(
          (r) => r.company_activity_id == companyService.id
        );
        if (aux && aux.length > 0) {
          let id = aux[0].id;
          this.$store
            .dispatch(`activity/${functionName}`, id)
            .then((response) => {
              window.location.reload(true);
            });
        }
      } else {
        functionName = "CREATE_TRAINER_ACTIVITY";
        const data = {
          employee_id: this.currentUser.id,
          company_activity_id: companyService.id,
        };

        this.$store
          .dispatch(`activity/${functionName}`, data)
          .then((response) => {
            window.location.reload(true);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>
