<template>
  <div>
    <h1 class="registration-title space">
      Welcome, {{ currentUser.first_name || "friend" }}. <br />
      Would you like to finish setting up your profile at this time?
    </h1>
    <BaseLink theme="button alternate with-space" :link="registrationLink">
      Yes, let's finish up!
    </BaseLink>
    <BaseLink theme="button with-space" link="/search">
      No thanks, I'd like to start browsing
    </BaseLink>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageRegistrationUserContinue",
  title: null,
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),

    registrationLink() {
      if (this.currentUser) {
        if (this.currentUser.account_type === "personal") {
          return "/register/profile";
        } else {
          return `/register/${this.currentUser.account_type}/profile`;
        }
      }
    },
  },
};
</script>

<style scoped>
div {
  text-align: center;
}
p,
a {
  font-size: 1.125em;
}
</style>
