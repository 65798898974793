<template>
  <form id="activity-form" @submit.prevent="submit">
    <h1 class="page-title">Schedule Activity for a Rider</h1>

    <div class="space--large users-sections">
      <BaseInput
        label-text="Search for a rider"
        v-model="searchQuery"
        v-debounce:500ms="search"
      />

      <BasePanel class="user-results" v-if="results.length">
        <div v-for="(result, index) in results" :key="result.id + index * 100">
          <UserPanel
            :user="result"
            type="clickable"
            @click.native="selectUser(result)"
          >
          </UserPanel>
        </div>
      </BasePanel>
    </div>

    <h2 class="section-title" v-if="selectedUser">Rider:</h2>
    <UserPanel v-if="selectedUser" :user="selectedUser"> </UserPanel>

    <div class="space--large">
      <h2 class="section-title">Location:</h2>

      <BaseSelect
        :label="false"
        v-model="locationId"
        @change.native="getBarnTrainers()"
      >
        <option v-for="barn in barns" :key="`barn-${barn.id}`" :value="barn.id">
          {{ barn.name }}
        </option>
      </BaseSelect>
    </div>

    <div class="space--large">
      <h2 class="section-title">Select Trainer</h2>

      <div v-if="trainers.length > 0">
        <TrainerPanel
          :trainer="selectedTrainer"
          @click.native="openTrainerModal()"
        >
          <ButtonEdit />
        </TrainerPanel>

        <Modal ref="activityTrainerModal">
          <Flex
            justify="stretch"
            direction="column"
            id="trainer-modal"
            v-for="trainer in trainers"
            :key="trainer.id"
          >
            <TrainerPanel
              :trainer="trainer"
              @click.native="setSelectedTrainer(trainer.id)"
            >
              <buttonAddRemove />
            </TrainerPanel>
          </Flex>
        </Modal>
      </div>

      <div v-else>There are currently no trainers at this barn.</div>
    </div>

    <div class="space--large">
      <h2 class="section-title">Select Activity</h2>

      <div v-if="employeeActivities.length > 0">
        <ActivityPanel
          v-if="employeeActivity.id"
          :activity="employeeActivity.activity"
          @click.native="openActivityModal()"
        >
          <ButtonEdit />
        </ActivityPanel>

        <Modal ref="activitySelectionModal">
          <Flex
            justify="stretch"
            direction="column"
            v-for="employeeActivity in employeeActivities"
            :key="employeeActivity.id"
          >
            <ActivityPanel
              :activity="employeeActivity.activity"
              @click.native="setSelectedActivity(employeeActivity.id)"
            >
              <ButtonAddRemove />
            </ActivityPanel>
          </Flex>
        </Modal>

        <div v-if="!employeeActivities.length > 0">
          This trainer has not set up any activities.
        </div>
      </div>
    </div>

    <div class="space--large" v-if="showHorse">
      <h2 class="section-title">Select Horse</h2>
      <div>
        <HorsePanel :horse="selectedHorse" @click.native="selectHorse">
          <BaseButton theme="round alternate">
            <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
          </BaseButton>
        </HorsePanel>
        <activity-horse-select-modal
          id="horse-modal"
          :horses="horses"
          v-if="showHorseModal"
          @sethorsetonull="setHorseToNull"
          @selecthorsefrommodal="selectHorseFromModal"
        />
      </div>
    </div>

    <TrainerScheduleTimeSlots
      v-if="employeeActivity.id && locationId && selectedTrainer.id"
      :employee-activity="employeeActivity"
      :location-id="locationId"
      :trainer-id="selectedTrainer.id"
      @set-time-slot="selectTimeSlot"
    />

    <BaseButton type="submit">Create Activity</BaseButton>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Modal from "../base/BaseModal.vue";
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import ButtonEdit from "../buttons/ButtonEdit";
import TrainerPanel from "../trainers/TrainerPanel";
import ActivityPanel from "../activities/ActivityPanel";
import TrainerScheduleTimeSlots from "../trainers/TrainerScheduleTimeSlots";
import HorsePanel from "../horses/HorsePanel";
import ActivityHorseSelectModal from "../horses/ActivityHorseSelectModal";
import Avatar from "../utils/Avatar";
import UserPanel from "../users/UserPanel.vue";

export default {
  name: "CreateUserActivity",
  components: {
    Flex,
    Modal,
    ButtonAddRemove,
    ButtonEdit,
    TrainerPanel,
    ActivityPanel,
    TrainerScheduleTimeSlots,
    HorsePanel,
    ActivityHorseSelectModal,
    Avatar,
    UserPanel,
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    showHorse() {
      if (this.selectedTrainer.company_settings) {
        return (
          this.selectedTrainer.company_settings.filter((s) => {
            return s.name == "no_horse_selection";
          }).length == 0
        );
      }
    },
    selectedHorse() {
      if (this.selectedHorseId) {
        const horses = this.horses.filter((r) => r.id == this.selectedHorseId);
        if (horses) {
          return horses[0];
        }
      }
      return null;
    },
  },

  data() {
    return {
      barns: [],
      trainers: [],
      employeeActivities: [],
      users: [],
      selectedTimeSlot: {},
      employeeActivity: {},
      selectedTrainer: {},
      activityDate: null,
      locationId: undefined,
      userId: undefined,
      horses: [],
      selectedHorseId: undefined,
      showHorseModal: false,
      searchQuery: "",
      results: [],
      selectedUser: null,
    };
  },

  created() {
    setTimeout(() => this.getBarns(), 1000);
    setTimeout(() => this.getUsers(), 1000);
  },

  methods: {
    selectUser(userObj) {
      this.selectedUser = userObj;
      this.userId = userObj.id;
      this.results = null;
      this.searchQuery = "";
      this.results = [];
    },
    search() {
      if (this.searchQuery && this.searchQuery.length > 2) {
        this.$store
          .dispatch("global/GLOBAL_SEARCH", {
            query: this.searchQuery,
            limit: 15,
            type: "user",
          })
          .then((response) => {
            this.results = response.data.filter((r) => r.account_type);
          });
      } else {
        this.results = null;
        this.results = [];
      }
    },
    fetchBarnHorses() {
      const skill_ratings = 5;
      let skill_level = skill_ratings.skill_level;
      const location_id = this.locationId;
      const lesson_horse = true;
      const owner_id = this.userId;
      if (skill_ratings && skill_ratings.length > 0) {
        let skill_rating = skill_ratings[0];
        skill_level = skill_rating.skill_level;
      } else {
        skill_level = 0;
      }
      this.$store
        .dispatch("horse/GET_HORSES_FOR_ACTIVITY", {
          location_id,
        })
        .then((response) => {
          this.horses = response.data.filter((a, b) => {
            if (a.owner_id == this.userId) {
              return -1;
            } else if (b.owner_id == this.userId) {
              return 1;
            } else if (a.nickname.toUpperCase() < b.nickname.toUpperCase()) {
              return -1;
            } else if (b.nickname.toUpperCase() < a.nickname.toUpperCase()) {
              return 1;
            } else {
              return 0;
            }
          });
        });
    },
    selectHorseFromModal(e) {
      this.selectedHorseId = e.id;
      this.showHorseModal = false;
    },
    setHorseToNull() {
      this.selectedHorseId = null;
      this.showHorseModal = false;
    },
    submit() {
      const userActivity = {
        employee_activity_id: this.employeeActivity.id,
        participant_id: this.userId,
        location_id: this.locationId,
        horse_id: this.selectedHorseId,
        status: 1, // sent_to_rider
      };
      const activityBlock = {
        schedule_chunk_id: this.selectedTimeSlot.schedule_chunk_id,
        start_time: this.composeDatetime(this.selectedTimeSlot.start_time),
        end_time: this.composeDatetime(this.selectedTimeSlot.end_time),
      };

      const data = {
        activity_block: activityBlock,
        user_activity: userActivity,
      };
      this.$store
        .dispatch("activity/CREATE_TRAINER_USER_ACTIVITY", data)
        .then((response) => {
          this.$router.push("/dashboard");
        });
    },

    composeDatetime(time) {
      let parsedTime = this.moment.utc(time);
      let date = this.moment.utc(
        this.activityDate.substring(0, 10),
        "YYYY-MM-DD"
      );
      date.hour(parsedTime.hour());
      date.minutes(parsedTime.minutes());

      return date.format();
    },

    selectTimeSlot(data) {
      this.selectedTimeSlot = data.timeSlot;
      this.activityDate = data.activityDate;
    },

    getUsers() {
      this.$store.dispatch("user/SEARCH_USERS", "").then((response) => {
        this.users = response.data;
      });
    },

    getBarns() {
      this.$store
        .dispatch("location/GET_EMPLOYER_LOCATIONS", this.currentUser.id)
        .then((response) => {
          let companies = response.data;
          let auxBarns = [];
          if (companies && companies.length > 0) {
            for (let i = 0; i < companies.length; i++) {
              auxBarns = auxBarns.concat(companies[i].locations);
            }
          }
          this.barns = auxBarns;
          if (this.barns && this.barns.length > 0) {
            this.locationId = this.barns[0].id;
            this.getBarnTrainers();
          }
        });
    },

    getBarnTrainers() {
      this.$store
        .dispatch("trainer/LOCATION_TRAINERS", this.locationId)
        .then((response) => {
          this.trainers = response.data;
          if (this.trainers[0]) this.setSelectedTrainer(this.trainers[0].id);
        });
      this.fetchBarnHorses();
    },
    selectHorse() {
      this.showHorseModal = true;
    },
    openTrainerModal() {
      this.$refs.activityTrainerModal.openModal();
    },

    closeTrainerModal() {
      if (this.$refs.activityTrainerModal) {
        this.$refs.activityTrainerModal.closeModal();
      }
    },

    openActivityModal() {
      this.$refs.activitySelectionModal.openModal();
    },

    closeActivityModal() {
      this.$refs.activitySelectionModal.closeModal();
    },

    getTrainerActivities(trainerId) {
      this.$store
        .dispatch("activity/GET_TRAINER_ACTIVITIES", { userId: trainerId })
        .then((response) => {
          this.employeeActivities = response.data;
          if (this.employeeActivities.length > 0)
            this.employeeActivity = this.employeeActivities[0];
        });
    },

    setSelectedTrainer(id) {
      this.selectedTrainer = this.trainers.find((t) => t.id == id);
      this.getTrainerActivities(this.selectedTrainer.id);
      this.closeTrainerModal();
    },

    setSelectedActivity(id) {
      this.employeeActivity = this.employeeActivities.find((a) => a.id == id);
      this.closeActivityModal();
    },
  },
};
</script>

<style scoped>
.users-sections {
  position: relative;
}

.user-results {
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 100;
  background-color: white;
}

#trainer-modal {
  width: 95%;
}
</style>