<template>
  <span class="input input--select" :class="{ 'input--without-label': !label }">
    <select :id="id || name" :name="name" :value="value" @input="emitChange">
      <option v-if="placeholder != 'false'" disabled="true" value="">
        {{ placeholder }}
      </option>
      <slot />
    </select>
    <label :for="id || name" v-visible="label">
      {{ labelText }}
    </label>
  </span>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    labelText: String,
    id: String,
    name: String,
    options: Array,
    placeholder: {
      type: String,
      default: "false",
    },
    placeholderText: String,
    value: [String, Number, Object],
  },
  methods: {
    emitChange(event) {
      this.$emit("input", event.target.value);
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
