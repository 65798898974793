<template>
  <div class="slider" id="employeeSlider">
    <BasePanel
      class="slide"
      v-for="employee in company.employees"
      :key="employee.id"
    >
      <Flex direction="column" justify="center" align="center">
        <div @click="linkToTrainer(employee.id)">
          <Avatar size="large" class="space" :image-source="employee.avatar" />
          <br />
          {{ employee.name }}
        </div>
      </Flex>
    </BasePanel>
  </div>
</template>
<script>
import Flickity from "flickity";
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";

export default {
  name: "EmployeeSlider",
  components: { Avatar, Flex },
  props: {
    company: {},
  },
  mounted() {
    this.initializeSlider("employeeSlider");
  },
  methods: {
    initializeSlider(id) {
      const slider = document.getElementById(id);
      if (!slider) return;

      const flkty = new Flickity(slider, {
        cellAlign: "left",
        contain: true,
        prevNextButtons: false,
        setGallerySize: true,
      });
    },
    linkToTrainer(id) {
      this.$router.push("/users/" + id);
    },
  },
};
</script>
<style scoped>
.slider {
  /* display: block; */
  max-width: 100%;
  overflow: hidden;
}

.slider:focus {
  outline: none;
}

.slide {
  font-weight: var(--semibold);
  margin-right: 1em;
  text-align: center;
}
.continue {
  font-size: 1.125em;
  font-weight: var(--semibold);
}
</style>
