<template>
  <BasePanel type="active">
    <Flex justify="between" align="stretch">
      <Flex justify="start">
        <div class="symbol">
          <font-awesome-icon
            :icon="['fas', 'dollar-sign']"
            fixed-width
            class="icon dollar"
          />
        </div>
        <div>
          <p class="details">
            <span class="price">
              {{ convertPrice(invoice.amount) }}
            </span>
            <!-- <span class="due">
              Due: 8.31.2019
            </span> -->
            <span class="due">
              Created: {{ convertTime(invoice.created) }}
            </span>
          </p>
          <p class="notice">
            {{ invoice.description }}
            <!-- <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon" />
            Please Note: Past Due! -->
          </p>
        </div>
      </Flex>
      <Flex align="center" class="eye">
        <button @click="viewDetails(invoice)">
          <font-awesome-icon :icon="['far', 'eye']" />
        </button>
      </Flex>
    </Flex>
  </BasePanel>
</template>

<script>
import Flex from "../style/Flex";

export default {
  name: "PaymentPanel",
  props: { invoice: Object },
  components: { Flex },

  methods: {
    convertPrice(price) {
      return `$${parseInt(price) / 100}`;
    },

    convertTime(timestamp) {
      return this.moment.unix(timestamp).format("MMMM DD, YYYY");
    },

    viewDetails(invoice) {
      this.$router.push({
        name: "payment-details",
        params: { id: invoice.id },
      });
    },
  },
};
</script>

<style scoped>
.symbol {
  border: 1px solid var(--border-color);
  border-radius: 100%;
  font-size: 1.5em;
  padding: 0.45em 0.35em;
  line-height: 1em;
}
.icon {
  color: var(--default-accent-color);
}
.details {
  margin-bottom: 0.25em;
}
.price {
  color: var(--base);
  font-weight: var(--semibold);
  margin-right: 0.25em;
}
.due,
.notice {
  font-size: 0.875em;
}
.notice {
  color: var(--base);
  font-weight: var(--semibold);
}
.eye {
  border-left: 1px solid var(--border-color);
  font-size: 1.875em;
  padding-left: 0.5em;
  width: auto;
}
</style>
