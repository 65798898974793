<template>
  <form
    id="company-profile"
    @submit.prevent="updateCompany()"
    enctype="multipart/form-data"
  >
    <h3>Owner Profile</h3>
    <AvatarUpload
      name="avatar"
      :avatar="currentUser.avatar"
      @delete-photo-request="confirmDeletePhoto"
    >
    </AvatarUpload>

    <BaseInput
      type="text"
      name="user[first_name]"
      label-text="First Name"
      v-model="currentUser.first_name"
    />
    <BaseInput
      type="text"
      name="user[last_name]"
      label-text="Last Name"
      v-model="currentUser.last_name"
    />
    <BaseInput
      type="email"
      name="user[email]"
      label-text="Email Address"
      v-model="currentUser.email"
    />

    <BaseTextArea
      name="user[bio]"
      :label="false"
      rows="6"
      v-model="currentUser.bio"
      placeholder="Add your bio, career highlights, and/or certifications..."
    />

    <h3>Company Profile Information</h3>
    <CoverUpload
      name="company_avatar"
      :cover-photo="company.avatar"
      @delete-cover-photo-request="confirmDeleteCompanyPhoto"
    >
    </CoverUpload>

    <BaseInput
      type="text"
      name="[company]name"
      label-text="Company Name"
      v-model="company.name"
    />
    <BaseInput
      type="text"
      name="address_attributes[street_address_line1]"
      label-text="Address"
      v-model="companyAddress.street_address_line1"
    />
    <BaseInput
      type="text"
      name="address_attributes[street_address_line2]"
      label-text="Address Line 2"
      v-model="companyAddress.street_address_line2"
    />
    <BaseInput
      type="text"
      name="address_attributes[city]"
      label-text="City"
      v-model="companyAddress.city"
    />
    <BaseInput
      type="text"
      name="address_attributes[state]"
      label-text="State"
      v-model="companyAddress.state"
    />
    <BaseInput
      type="text"
      name="address_attributes[postal_code]"
      label-text="Zip Code"
      v-model="companyAddress.postal_code"
    />

    <BaseInput
      type="email"
      name="company[email]"
      label-text="Company Email Address"
      v-model="company.email"
    />
    <BaseInput
      type="text"
      name="company[phone]"
      label-text="Company Phone Number"
      v-model="company.phone"
    />
    <BaseInput
      type="text"
      name="company[website]"
      label-text="Company Website"
      v-model="company.website"
    />

    <h2>Company Introduction</h2>

    <BaseTextArea
      name="company[bio]"
      :label="false"
      rows="6"
      v-model="company.bio"
      placeholder="Add your company's bio, career highlights, services provided, and certification.  This will appear on your company's homepage."
    />
    <BaseButton
      id="change-passowrd-button"
      type="button"
      @click="changePassword"
      theme="alternate auto"
      >Change password</BaseButton
    >
    <Flex>
      <BaseButton type="submit" theme="auto">Save & Continue</BaseButton>
      <BaseButton type="button" @click="confirmDelete()" theme="alternate auto"
        >Delete Account</BaseButton
      >
    </Flex>
    <change-password-modal
      v-if="showPasswordModal"
      @goback="showPasswordModal = false"
    />
  </form>
</template>

<script>
import Flex from "../../style/Flex";
import AvatarUpload from "../../utils/AvatarUpload";
import CoverUpload from "../../utils/CoverUpload";
import BaseProfile from "../BaseProfile";
import ChangePasswordModal from "../../common/ChangePasswordModal";
import LoadingModal from "../../utils/LoadingModal";

export default {
  name: "Profile",
  title: null,
  components: {
    Flex,
    AvatarUpload,
    CoverUpload,
    ChangePasswordModal,
    LoadingModal,
  },
  extends: BaseProfile,
  data() {
    return {
      company: {},
      companyAddress: {},
      showPasswordModal: false,
      showLoading: false,
    };
  },

  async mounted() {
    await this.fetchCompany();
  },

  methods: {
    async fetchCompany() {
      // fetch user from the serve again to get updated company_id
      const user = await this.$store.dispatch("user/GET_USER_FROM_TOKEN");

      this.$store
        .dispatch("company/GET_COMPANY", user.data.company_id)
        .then((response) => {
          this.company = response.data;
          if (this.company.address) this.companyAddress = this.company.address;
        });
    },

    confirmDeleteCompanyPhoto() {
      const deleteCompanyPhoto = window.confirm(
        "Are you sure you want to delete your company photo?"
      );
      const data = new FormData();
      data.append("destroy_company_avatar", true);
      const params = { id: this.currentUser.company_id, user: data };

      if (deleteCompanyPhoto) {
        this.$store
          .dispatch("company/UPDATE_COMPANY", params)
          .then((response) => {
            window.location.reload(true);
          });
      }
    },

    updateCompany() {
      const formData = new FormData(document.getElementById("company-profile"));
      const params = { id: this.currentUser.company_id, companyData: formData };
      this.showLoading = true;
      this.$store
        .dispatch("company/UPDATE_COMPANY", params)
        .then((response) => {
          this.$parent.nextPage();
          window.location.reload();
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    changePassword() {
      this.showPasswordModal = true;
    },
  },
};
</script>

<style scoped>
h3 {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
p,
a {
  font-size: 1.125em;
}
#change-passowrd-button {
  width: 100%;
  margin-bottom: 10px;
}
</style>
