<template>
  <div class="modal-backdrop">
    <div
      class="modal"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
    >
      <header class="modal-header" id="modalTitle">
        <div class="header">Edit rider ability</div>
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
        >
          <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
        </button>
      </header>
      <section class="modal-body-skill" id="modalDescription">
        <div class="rider-avatar">
          <img :src="riderAvatar" alt="Rider avatar" id="rider-avatar-image" />
        </div>
        <div class="rider-info">
          <div class="rider-name">
            {{ rider.name }}
          </div>
          <div class="rider-type">New client</div>
        </div>
      </section>
      <div class="skill-level-container">
        <section class="skill-level">
          <div class="skill-info">
            <div class="skill-info-label">Adjust skill level</div>
        
          </div>
          <div class="skill-adjust">
            <div>Beginner</div>
            <div
              class="skill-button active-button"
              ref="button1"
              @click="setSkill(1)"
            ></div>
            <div class="skill-button" ref="button2" @click="setSkill(2)"></div>
            <div class="skill-button" ref="button3" @click="setSkill(3)"></div>
            <div class="skill-button" ref="button4" @click="setSkill(4)"></div>
            <div class="skill-button" ref="button5" @click="setSkill(5)"></div>
            <div>Expert</div>
          </div>
        </section>
      </div>
      <footer class="modal-footer">
        <div name="footer">
          <button
            type="button"
            class="btn-blue"
            @click="close"
            aria-label="Close modal"
          >
            BACK
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "editRiderSkillModal",
  components: {
  },
  data() {
    return {
      riderAvatar: require("../../assets/images/avatars/user.jpg"),
      skill: 0,
      rating: null,
      hasRating: false,
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  props: {
    rider: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {
    this.$store
      .dispatch("user/GET_USER_RATING", {
        user_id: this.rider.id,
        company_id: this.currentUser.company_id,
      })
      .then((response) => {
        const ratings = response.data;
        if (ratings && ratings.length > 0) {
          this.rating = ratings[0];
          this.hasRating = true;
          this.setSkill(this.rating.skill_rating);
        } else {
          this.rating = null;
          this.hasRating = false;
        }
      });
  },

  methods: {
    close() {
      if (this.hasRating) {
        this.$store
          .dispatch("user/UPDATE_USER_RATING", {
            rate_id: this.rating.id,
            company_id: this.currentUser.company_id,
            user_id: this.rider.id,
            skill_rating: this.skill,
          })
          .then((response) => {})
          .catch((err) => {});
      } else {
        this.$store
          .dispatch("user/CREATE_USER_RATING", {
            user_id: this.rider.id,
            company_id: this.currentUser.company_id,
            skill_rating: this.skill,
          })
          .then((response) => {})
          .catch((err) => {});
      }
      this.$emit("close");
    },
    setSkill(level) {
      this.skill = level;
      const buttons = [
        this.$refs.button1,
        this.$refs.button2,
        this.$refs.button3,
        this.$refs.button4,
        this.$refs.button5,
      ];
      for (let i = 0; i < 5; i++) {
        if (this.skill - 1 >= i) {
          buttons[i].classList.add("active-button");
        } else {
          buttons[i].classList.remove("active-button");
        }
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  z-index: 1;
}

#rider-avatar-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.rider-avatar {
  display: inline-block;
}

.rider-info {
  height: 100%;
  margin-left: 15px;
  padding-top: 10px;
}

.skill-level-container {
  display: flex;
  justify-content: center;
}

.skill-level {
  border: 3px solid rgb(218, 217, 217);
  border-radius: 15px;
  width: 95%;
  padding: 15px;
}

.skill-adjust {
  margin-top: 25px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}

.skill-button {
  width: 20px;
  height: 20px;
  background-color: rgb(218, 217, 217);
  border-radius: 50%;
  border: 1px rgb(218, 217, 217) solid;
}

.active-button {
  background-color: var(--navy);
}

.skill-info {
  display: flex;
  justify-content: space-between;
}

.skill-info-label {
  color: #626262;
  font-size: 20px;
  font-weight: var(--semibold);
}

.rider-name {
  color: #626262;
  font-size: 22px;
  font-weight: var(--semibold);
  margin-bottom: 10px;
}

.rider-type {
  font-size: 15px;
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  color: #013053;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 600;
}

.modal-footer {
  justify-content: center;
  padding: 10px 30px;
}

.modal-body-skill {
  position: relative;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
}

.btn-close {
  border: none;
  font-size: 14px;
  padding: 1px 2px;
  cursor: pointer;
  font-weight: bold;
  color: #013053;
  background: transparent;
  border: 1px solid #013053;
  border-radius: 5px;
}

.btn-blue {
  color: #ffffff;
  background: #013053;
  border: 1px solid #013053;
  border-radius: 50px;
  padding: 10px 100px;
}
</style>