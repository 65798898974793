import axios from "axios";
import {
  CREATE_EVENT,
  UPDATE_EVENT,
  GET_EVENT,
  GET_EVENTS,
  DELETE_EVENT,
} from "../actions/event";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  CREATE_EVENT({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/events", params)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  DELETE_EVENT({ commit }, eventId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/events/${eventId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_EVENT({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/events/${params.id}`, params.form)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_EVENTS({ commit }, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/events", { params: queryParams })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_EVENT({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/events/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
