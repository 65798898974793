<template>
  <div class="flex" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Flex",
  props: ["align", "justify", "direction"],
  computed: {
    classes() {
      var classes = "";
      if (this.align) {
        classes += " align-" + this.align;
      }
      if (this.justify) {
        classes += " justify justify-" + this.justify;
      }
      if (this.direction) {
        classes += " direction-" + this.direction;
      }
      return classes;
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.justify > * {
  margin-left: 0.25em;
  margin-right: 0.25em;
}
.justify > *:first-child {
  margin-left: 0;
}
.justify > *:last-child {
  margin-right: 0;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-stretch {
  justify-content: stretch;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.align-stretch {
  align-items: stretch;
}
.direction-row {
  flex-direction: row;
}
.direction-column {
  flex-direction: column;
}
</style>
