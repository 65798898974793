<template>
  <div>
    <div v-if="!editingHorse">
      <h3>
        Horses I Own ({{ currentUser.horses ? currentUser.horses.length : 0 }})
      </h3>

      <HorsePanel
        v-if="currentUser"
        v-for="horse in currentUser.horses"
        :key="horse.id"
        :horse="horse"
      >
        <BaseButton
          theme="round alternate"
          @click="setCurrentHorseAndEdit(horse)"
        >
          <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
        </BaseButton>
      </HorsePanel>

      <BasePanel type="clickable">
        <Flex
          justify="between"
          direction="row"
          @click.native="setCurrentHorseAndEdit(defaultHorseObject)"
        >
          <p class="add-a-horse">Add A Horse</p>
          <ButtonAddRemove type="add" />
        </Flex>
      </BasePanel>

      <!-- TODO: format this success message -->
      <p v-if="successMessage">{{ successMessage }}</p>

      <BaseButton @click="$parent.nextPage()">Save & Continue</BaseButton>
    </div>

    <div v-if="editingHorse">
      <Flex justify="between" directimnnmmmon="row">
        <h3>{{ title }}</h3>
        <BaseButton theme="alternate auto" @click="toggleHorseEdit(false)"
          >Cancel</BaseButton
        >
      </Flex>
      <HorseForm
        :horse="currentHorse || defaultHorseObject"
        :current-user-id="currentUser.id"
      >
      </HorseForm>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonAddRemove from "../../buttons/ButtonAddRemove";
import Flex from "../../style/Flex";
import HorsePanel from "../../horses/HorsePanel.vue";
import HorseForm from "../../horses/HorseProfileForm.vue";

export default {
  name: "CompanyHorses",
  title: null,
  components: { Flex, ButtonAddRemove, HorsePanel, HorseForm },
  props: {
    currentIndex: Number,
    steps: Array,
  },

  data() {
    return {
      editingHorse: false,
      defaultHorseObject: { date_of_birth: Date.now() },
      currentHorse: {},
      successMessage: "",
    };
  },

  computed: {
    title() {
      return this.currentHorse.id ? "Edit Horse" : "Add New Horse";
    },

    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  methods: {
    toggleHorseEdit(state) {
      this.editingHorse = state;
    },

    setCurrentHorseAndEdit(horse) {
      this.currentHorse = horse;
      this.toggleHorseEdit(true);
    },
  },
};
</script>

<style scoped>
h3 {
  color: var(--navy);
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
h3,
p,
a {
  font-size: 1.125em;
}
.add-a-horse {
  color: var(--border-color);
  font-size: 1.3125em;
  font-weight: var(--semibold);
  text-transform: uppercase;
}
</style>
