import axios from "axios";
import {
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_LOCATION,
  GET_LOCATIONS,
  GET_USER_LOCATIONS,
  UPDATE_USER_LOCATIONS,
  SEARCH_LOCATIONS,
  CREATE_USER_LOCATION,
  DELETE_USER_LOCATION,
} from "../actions/location";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  GET_LOCATIONS({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/locations")
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_LOCATION({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/locations/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_USER_LOCATIONS({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user_locations", { params: { user_id: userId } })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_EMPLOYER_LOCATIONS({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/employer_locations", { params: { user_id: userId } })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  SET_HOME_LOCATION({ commit }, userLocationId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/user_locations/${userLocationId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  CREATE_LOCATION({ commit }, location) {
    return new Promise((resolve, reject) => {
      axios
        .post("/locations", location)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_LOCATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/locations/${params.id}`, params.location)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  DELETE_LOCATION({ commit }, locationId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/locations/${locationId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  SEARCH_LOCATIONS({ commit }, searchQuery) {
    return new Promise((resolve, reject) => {
      axios
        .get("/locations", { params: { location_query: searchQuery } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  CREATE_USER_LOCATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user_locations/", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  DELETE_USER_LOCATION({ commit }, userLocationId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user_locations/${userLocationId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
