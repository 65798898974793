import axios from "axios";
import {
  GET_HORSE,
  ADD_HORSE,
  UPDATE_HORSE,
  DELETE_HORSE,
  GET_HORSES_BY_BARN_ID,
  GET_HORSES_FOR_ACTIVITY,
  GET_HORSE_ACTIVITIES,
} from "../actions/horse";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  GET_HORSE({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/horses/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_HORSES_FOR_ACTIVITY({ commit }, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(`horses`, { params: queryParams })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  ADD_HORSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/horses", params.horse)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_HORSE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/horses/${params.id}`, params.horse, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  DELETE_HORSE({ commit }, horseId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/horses/${horseId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_HORSES_BY_BARN_ID({ commit }, locationId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/horses", { params: { location_id: locationId } })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_HORSE_ACTIVITIES({ commit }, reqParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/horse_activities", { params: reqParams })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
