<template>
  <BasePanel type="active">
    <h1>Invoice</h1>
    <Flex justify="between" align="stretch">
      <Flex justify="start">
        <div class="symbol">
          <font-awesome-icon
            :icon="['fas', 'dollar-sign']"
            fixed-width
            class="icon dollar"
          />
        </div>
        <div>
          <div class="details">
            <span class="price"> ${{ invoice.amount }} </span>

            <div class="due">
              <div v-if="invoice.subscription">
                <p class="payment-date">
                  Payment Date:
                  {{ moment.utc(invoice.due_date).format("MMMM DD, YYYY") }}
                </p>

                <p>Note: This payment is recurring. No action is needed.</p>
              </div>
              <div v-else>
                <p class="payment-date">
                  Due:
                  {{ moment.utc(invoice.due_date).format("MMMM DD, YYYY") }}
                </p>
              </div>
            </div>
          </div>
          <p class="notice" v-if="pastDue(invoice) && !invoice.subscription">
            <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"
              class="icon"
            />
            Please Note: Past Due!
          </p>
        </div>
      </Flex>
      <Flex align="center" class="eye">
        <button @click="viewDetails(invoice)">
          <font-awesome-icon :icon="['far', 'eye']" />
        </button>
      </Flex>
    </Flex>
  </BasePanel>
</template>

<script>
import Flex from "../style/Flex";

export default {
  name: "InvoicePanel",
  props: { invoice: Object },
  components: { Flex },

  methods: {
    pastDue(invoice) {
      return this.moment.utc() > this.moment.utc(invoice.due_date);
    },

    viewDetails(invoice) {
      this.$router.push({ name: "user-invoice", params: { id: invoice.id } });
    },
  },
};
</script>

<style scoped>
.symbol {
  border: 1px solid var(--border-color);
  border-radius: 100%;
  font-size: 1.5em;
  padding: 0.45em 0.35em;
  line-height: 1em;
}
.icon {
  color: var(--default-accent-color);
}
.details {
  margin-bottom: 0.25em;
}
.price {
  color: var(--base);
  font-weight: var(--semibold);
  margin-right: 0.25em;
}
.due,
.notice {
  font-size: 0.875em;
}
.notice {
  color: var(--base);
  font-weight: var(--semibold);
}
.eye {
  border-left: 1px solid var(--border-color);
  font-size: 1.875em;
  padding-left: 0.5em;
  width: auto;
}
.payment-date {
  color: var(--base);
  font-weight: var(--semibold);
  margin: 0.25em;
}
</style>
