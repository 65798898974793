import axios from "axios";
import {
  CREATE_EVENT_REGISTRATION,
  UPDATE_EVENT_REGISTRATION,
  GET_EVENT_REGISTRATION,
  GET_EVENT_REGISTRATIONS,
} from "../actions/eventRegistration";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  CREATE_EVENT_REGISTRATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/event_registrations", params)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_EVENT_REGISTRATION({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/event_registrations/${params.id}`, params.form)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_EVENT_REGISTRATIONS({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/event_registrations", { params: { company_id: companyId } })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_EVENT_REGISTRATION({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/event_registrations/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
