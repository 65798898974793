<template>
  <div class="avatar"
       :class="size"
       :style="fallbackImageSource">
</template>

<script>
import UserAvatar from "../../assets/images/avatars/user.jpg";
import WorkerAvatar from "../../assets/images/avatars/worker.jpg";
import HorseAvatar from "../../assets/images/avatars/horse.jpg";
import CompanyAvatar from "../../assets/images/avatars/company.jpg";
import EventAvatar from "../../assets/images/avatars/event.png";

export default {
  name: "Avatar",
  props: {
    size: String,
    imageSource: String,
    avatarType: {
      type: String,
      default: "user",
    },
  },
  computed: {
    fallbackImageSource() {
      const image = this.imageSource
        ? this.imageSource
        : this.imageFallback(this.avatarType);
      return `background-image: url('${image}');`;
    },
  },
  methods: {
    imageFallback(type) {
      switch (type) {
        case "horse":
          return HorseAvatar;
        case "worker":
          return WorkerAvatar;
        case "company":
          return CompanyAvatar;
        case "event":
          return EventAvatar;
        default:
          return UserAvatar;
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  background-color: var(--company-accent-color);
  background-position: center;
  border-radius: 100%;
  background-size: cover;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  height: 68px;
  width: 68px;
  min-width: 68px;
  min-height: 68px;
}
.small {
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;
}
.large {
  height: 120px;
  width: 120px;
  min-width: 120px;
  min-height: 120px;
}
</style>
