<template>
  <div>
    <div class="flex-table" v-if="isDoneLoading">
      <Flex class="filler-cell"><span></span></Flex>
      <Flex v-for="i in 7" :key="'day' + i" class="date-header">
        {{
          startDate
            .clone()
            .add(i - 1, "days")
            .format("ddd MMM Do")
        }}
      </Flex>
      <template v-for="horse in horses">
        <Flex direction="column" justify="center" class="horse-cell">
          <router-link :to="'/horses/' + horse.id" :key="horse.id">
            <Avatar
              size="small"
              :image-source="horse.avatar"
              avatar-type="horse"
            />
          </router-link>
          <div class="horse-name" v-if="horse.name">{{ horse.name }}</div>
          <div class="horse-name" v-else>Any lesson horse</div>
        </Flex>
        <Flex
          v-for="(day, index) in horse.days"
          :key="'horse-day' + '_' + horse.id + index"
          dirction="row"
          class="activity-cell"
        >
          <Flex direction="column" v-if="day.length > 0">
            <Flex justify="center" class="activity-time">
              {{ moment.utc(day[0].start_time).format("hh:mma").substr(0, 6) }}
              -
              {{
                moment.utc(day[0].end_time).format("hh:mma").substr(0, 6)
              }}</Flex
            >
            <Flex direction="row" class="activity">
              <Flex
                direction="column"
                class="rider-information"
                align="center"
                justify="start"
              >
                <Avatar
                  :image-source="getRiderAvatar(day[0].rider.avatar)"
                  avatar-type="user"
                  class="rider-avatar"
                  size="small"
                />
                <span class="rider-name">{{ day[0].rider.name }}</span>
              </Flex>
              <Flex direction="column" class="activity-info">
                <span class="activity-name">
                  {{ day[0].company_activity_name }}
                </span>
                <span justify="end" class="trainer-name">{{
                  day[0].trainer.name
                }}</span>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </template>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import ListActivitiesModal from "./ListActivitiesModal";
import Avatar from "../utils/Avatar";
import UserAvatar from "../../assets/images/avatars/user.jpg";
import Flex from "../style/Flex.vue";

export default {
  components: { ButtonAddRemove, ListActivitiesModal, Avatar, Flex },
  data: function () {
    return {
      showModal: false,
      currentActivities: [],
      currentCompanyId: undefined,
      boardDataForCurrentWeek: undefined,
      isDoneLoading: true,
    };
  },
  props: ["startDate", "dateChanges"],
  watch: {
    dateChanges: async function (newVal) {
      this.isDoneLoading = false;
      await this.intiailizePage();
      this.isDoneLoading = true;
    },
  },
  computed: {
    horses: function () {
      if (this.boardDataForCurrentWeek) {
        let keys = Object.keys(this.boardDataForCurrentWeek.horses);
        let horses = keys.map((v) => {
          return this.boardDataForCurrentWeek.horses[v];
        });
        let compH = horses.map((r) => {
          return {
            id: r.id,
            name: r.name,
            avatar: r.avatar,
            daysRaw: r.days,
          };
        });
        compH.forEach((horse) => {
          let daysKeys = Object.keys(horse.daysRaw);
          let daysV = daysKeys.map((v) => {
            return horse.daysRaw[v];
          });
          let daysFinal = [[], [], [], [], [], [], []];
          let today = this.startDate.clone();
          for (let i = 0; i < 7; i++) {
            for (let j = 0; j < daysV.length; j++) {
              let currentDate = daysV[j];
              if (currentDate.length > 0) {
                let firstActivity = currentDate[0];
                let startTime = moment.utc(
                  firstActivity.start_time.substring(0, 10),
                  "YYYY-MM-DD"
                );
                if (startTime.isSame(today.clone().add(i, "days"), "date")) {
                  daysFinal[i] = daysFinal[i].concat(currentDate);
                }
              }
            }
          }
          horse.days = daysFinal;
        });

        return compH;
      }
      return [];
    },
  },
  methods: {
    showActivitiesForDay(activities) {
      this.currentActivities = activities;
      this.showModal = true;
    },
    getRiderAvatar(avatar) {
      if (!avatar) {
        return UserAvatar;
      }
      return avatar;
    },
    closeModal() {
      this.showModal = false;
      this.currentActivities = [];
    },
    async getDataForBoard() {
      const barnData = this.$store.getters["barnBoard/companiesBoards"];

      if (!barnData[this.currentCompanyId]) {
        await this.$store
          .dispatch("barnBoard/GET_BARN_BOARD_INFO", {
            companyId: this.currentCompanyId,
            startDate: this.startDate,
          })
          .then((r) => {
            this.boardDataForCurrentWeek = r.data;
          })
          .catch(() => {
            this.boardDataForCurrentWeek = null;
          })
          .finally(() => {});
      } else if (
        !barnData[this.currentCompanyId][this.startDate.format("YYYY-MM-DD")]
      ) {
        await this.$store
          .dispatch("barnBoard/GET_BARN_BOARD_INFO", {
            companyId: this.currentCompanyId,
            startDate: this.startDate,
          })
          .then((r) => {
            this.boardDataForCurrentWeek = r.data;
          })
          .catch(() => {
            this.boardDataForCurrentWeek = null;
          })
          .finally(() => {});
      } else {
        this.boardDataForCurrentWeek =
          barnData[this.currentCompanyId][this.startDate.format("YYYY-MM-DD")];
      }
    },
    async intiailizePage() {
      this.$store.commit("global/START_LOADING");
      this.currentCompanyId = this.$route.params.companyId;
      await this.getDataForBoard();
      this.$store.commit("global/STOP_LOADING");
    },
    goToAddingUserActivity() {
      this.$router.push("/companies/create-user-activity");
    },
  },
  async mounted() {
    await this.intiailizePage();
  },
};
</script>

<style scoped>
.barn-board {
}

#no-activities-error {
  text-align: center;
  margin-top: 15px;
}

.horse-avatar {
  margin: 0px;
}

.rider-avatar {
  margin: 0px;
}

.flex-table {
  align-self: flex-start;
  display: grid;
  grid-template-columns: 60px repeat(7, 250px);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  height: calc(100vh - 230px - env(safe-area-inset-top));
  overflow: scroll;
}

.horse-name {
  margin-top: 5px;
  color: var(--navy);
  font-weight: 600;
  font-size: 1em;
}

.add-button-for-cell {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
}
.activity-name {
  margin: 10px;
  font-size: 1em;
  font-weight: 300;
  color: var(--navy);
  justify-self: flex-start;
  text-align: center;
}
.trainer-name {
  margin: 10px;
  font-weight: 100;
  color: var(--navy);
  font-size: 0.75em;
  justify-self: flex-end;
}

.add-button {
  display: block;
  margin: auto;
  margin-top: 12px;
}
.number-of-activities {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
}

.date-header {
  font-weight: bold;
  background-color: white;
  color: var(--navy);
  text-align: center;
  justify-content: center;
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}

.horse-cell {
  background-color: white;
  color: var(--navy);
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
}
.activity-cell {
  background-color: var(--smoke);
  align-items: flex-start;
  margin: 0px;
}

.activity-time {
  width: 100%;
  color: white;
  padding: 5px;
  background-color: var(--navy);
  margin: 0px;
}

.activity-info {
}

.rider-information {
  margin: 5px;
  width: 100px;
  align-self: stretch;
}

.rider-name {
  font-size: 1em;
  color: var(--navy);
  font-weight: 400;
  margin-top: 5px;
  justify-self: flex-end;
  justify-content: center;
  text-align: center;
}

.activity {
  align-items: stretch;
}

.filler-cell {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 0;
  background-color: white;
}
</style>