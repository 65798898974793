<template>
  <BasePanel class="pannel">
    <Flex direction="column">
      <Flex direction="row" justify="around" class="card-info">
        <Avatar
          class="avatar-image"
          avatar-type="barn"
          :image-source="location.company.avatar"
        />
        <div class="location-details">
          <div class="location-name section-title">{{ location.name }}</div>
          <div class="address-details" v-if="location.address">
            <p> 
              {{ location.address.street_address_line1 }}
            </p>
            <p v-if="location.address.street_address_line2">
              {{ location.address.street_address_line2 }}
            </p>
            <p>{{ location.address.city }},{{ location.address.state }}</p>
          </div>
        </div>
      </Flex>
      <Flex direction="row" justify="around" class="buttons">
        <div class="add-button">
          <BaseButton
            @click="
              addToLocations({
                user_id: currentUser.id,
                location_id: location.id,
              })
            "
            >ADD +</BaseButton
          >
        </div>
        <div class="info-button">
          <BaseButton @click="sendToLocation(location.id)">
            View Profile
          </BaseButton>
        </div>
      </Flex>
    </Flex>
  </BasePanel>
</template>

<script>
import { mapState } from "vuex";
import Cover from "../utils/Cover";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "LocationSearchResult",
  components: { Avatar, Cover, Flex },
  props: {
    location: "",
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  methods: {
    addToLocations(params) {
      this.$store
        .dispatch("location/CREATE_USER_LOCATION", params)
        .then((response) => {
          document.location.reload(true);
        });
    },
    sendToLocation(locationId) {
      this.$router.push({
        name: "barn-profile",
        params: { id: locationId },
      });
    },
  },
};
</script>

<style scoped>
.address-details {
  font-size: 90%;
  font-weight: var(--semibold);
  color: #9b9b9b;
}
.location-name {
  margin-bottom: 3px;
}
.card-info {
  margin-left: 5px;
}
.location-details {
  margin-left: 15px;
}
.pannel {
  width: 100%;
  margin: auto;
}
.buttons {
  margin-top: 20px;
  font-weight: var(--semibold);
}
.info-button {
  height: 45px;
  box-sizing: border-box;
}
.info-button button {
  background-color: #ffffff;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
}
.add-button {
  height: 45px;
  box-sizing: border-box;
}
.avatar-image {
  min-width: 68px;
}
</style>
