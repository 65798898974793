<template>
  <BaseButton class="button-add-remove" theme="round alternate">
    <font-awesome-icon
      :icon="['fas', 'minus']"
      fixed-width
      v-if="type == 'remove'"
    />
    <font-awesome-icon :icon="['fas', 'plus']" fixed-width v-else />
  </BaseButton>
</template>

<script>
export default {
  name: "ButtonAddRemove",
  props: {
    type: { type: String, default: "add" },
  },
};
</script>

<style scoped>
button.button-add-remove {
  color: var(--default-accent-color);
}
</style>
