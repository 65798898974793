<template>
  <article class="activity-panel">
    <h1 class="title space">Activity Request</h1>

    <div>
      <BasePanel theme="active" v-if="userActivity.employee_activity">
        <h2 class="space">Request Details</h2>

        <Flex justify="between" class="space">
          <Flex justify="start">
            <Flex justify="start">
              <Avatar :image-source="userActivity.participant.avatar" />
              <div>
                <p class="name">{{ userActivity.participant.name }}</p>
              </div>
            </Flex>
          </Flex>

          <BaseButton theme="round alternate" @click="acceptRequest()">
            <font-awesome-icon
              :icon="['fas', 'thumbs-up']"
              class="thumbs-up title"
            />
          </BaseButton>
        </Flex>

        <BaseButton
          class="space"
          type="button"
          theme="company"
          @click="editRiderSkillLevel()"
        >
          Edit Rider skill level
        </BaseButton>

        <p class="location space">
          Location:

          <BaseSelect
            v-model="selectedLocationId"
            :label="false"
            @change.native="findHorses(selectedLocationId)"
          >
            <optgroup
              v-for="employer in employerLocations"
              :key="employer.id"
              :label="employer.name"
            >
              <option
                v-for="location in employer.locations"
                :key="location.id"
                :value="location.id"
              >
                {{ location.name }}
              </option>
            </optgroup>
          </BaseSelect>
        </p>

        <div class="space--large" v-if="horses.length > 0">
          <h2 class="section-title">Select Horse</h2>
          <div>
            <TrainerReviewHorsePanel
              :horse="selectedHorse"
              :number-of-activities="
                selectedHorse ? getNumberOfActivities(selectedHorse.id) : null
              "
              :day="userActivity.time_block.start_time"
              @click.native="selectHorse"
            >
              <BaseButton theme="round alternate">
                <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
              </BaseButton>
            </TrainerReviewHorsePanel>
            <activity-horse-select-modal
              id="horse-modal"
              :horses="filteredHorses"
              v-if="showHorseModal"
              :for-trainer-review="true"
              :horses-with-activities="horsesWithActivities"
              @sethorsetonull="setHorseToNull"
              @selecthorsefrommodal="selectHorseFromModal"
            />
          </div>
        </div>

        <p class="lesson-name space">
          {{ userActivity.employee_activity.activity.service }}
          ({{ userActivity.employee_activity.activity.duration_in_minutes }}) -
          ${{ userActivity.employee_activity.price }}
        </p>

        <div class="lesson-time">
          <h2>Scheduled Date and Time:</h2>

          <Flex justify="between" class="" direction="column">
            <div class="time-info">
              <p>
                {{
                  moment.utc(userActivity.time_block.start_time).format(
                    "MMM DD, YYYY"
                  )
                }}
              </p>
              <p>
                {{
                  moment.utc(userActivity.time_block.start_time).format("hh:mm a")
                }}
                -
                {{ moment.utc(userActivity.time_block.end_time).format("hh:mm a") }}
              </p>
            </div>
          </Flex>

          <div v-if="editDateMode">
            <div class="input input--without-label">
              <datetime
                id="activity_date"
                name="activity_date"
                type="date"
                zone="UTC"
                v-model="activityDate"
                @input="getTrainerSchedule()"
                :min-datetime="currentDate"
              >
              </datetime>
            </div>

            <div v-if="timeSlots">
              <BasePanel
                theme="calendar"
                v-for="(timeSlot, index) in timeSlots"
                :key="index"
              >
                <Flex justify="between" class="time">
                  <div class="range">
                    {{
                      moment.utc(
                        timeSlot.start_time.replace(
                          "2000-01-01",
                          moment.utc().format("YYYY-MM-DD")
                        )
                      ).format("hh:mm a")
                    }}
                    -
                    {{
                      moment.utc(
                        timeSlot.end_time.replace(
                          "2000-01-01",
                          moment.utc().format("YYYY-MM-DD")
                        )
                      ).format("hh:mm a")
                    }}
                  </div>

                  <span class="radio">
                    <input
                      :id="index"
                      name="selected_time_slot"
                      type="radio"
                      :value="timeSlot"
                      v-model="selectedTimeSlot"
                    />
                    <label :for="index"></label>
                  </span>
                </Flex>
              </BasePanel>
            </div>
          </div>
        </div>

        <Flex justify="start" direction="column" class="space">
          <BaseButton class="change-data-button" @click="toggleEditDateMode()">
            {{ editDateMode ? "Hide Date Change" : "Change Date" }}
          </BaseButton>
          <div class="hr"></div>
          <BaseButton
            class="space"
            type="button"
            theme="company"
            @click="editRequest()"
          >
            Send to Rider for Approval
          </BaseButton>

          <BaseButton type="button" theme="alternate" @click="denyRequest()">
            Deny Request
          </BaseButton>
        </Flex>
      </BasePanel>
    </div>
    <edit-rider-skill-modal
      v-if="showRiderModal"
      @close="closeModal"
      :rider="userActivity.participant"
    >
    </edit-rider-skill-modal>
  </article>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";
import EditRiderSkillModal from "../trainers/EditRiderSkillModal";
import HorsePanel from "../horses/HorsePanel";
import TrainerReviewHorsePanel from "../horses/TrainerReviewHorsePanel";
import ActivityHorseSelectModal from "../horses/ActivityHorseSelectModal";
import moment from "moment";

export default {
  name: "TrainerActivityRequest",
  components: {
    Flex,
    ActivityHorseSelectModal,
    Avatar,
    HorsePanel,
    EditRiderSkillModal,
    TrainerReviewHorsePanel,
  },

  data() {
    return {
      userActivity: {},
      employerLocations: [],
      selectedLocationId: undefined,
      horses: [],
      selectedHorseId: undefined,
      activityDate: null,
      timeSlots: [],
      selectedTimeSlot: {},
      editDateMode: false,
      showRiderModal: false,
      horsesLoaded: false,
      showHorseModal: false,
      horsesWithActivities: [],
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),

    currentDate() {
      return this.moment.utc().format(this.moment.HTML5_FMT.DATE);
    },
    selectedHorse() {
      if (this.horses && this.selectedHorseId) {
        const filtered = this.horses.filter(
          (r) => r.id == this.selectedHorseId
        );
        if (filtered && filtered.length > 0) {
          return filtered[0];
        }
      }
      return null;
    },

    filteredHorses() {
      if (this.userActivity) {
        const companyId = this.userActivity.com;
        const userRating = this.userActivity.participant.skill_ratings.filter(
          (r) => r.company_id == companyId
        )[0];
        let maxSkill = 5;
        if (userRating) {
          maxSkill = userRating.skill_rating;
        }
        return this.horses.map((e) => {
          let isPreferred = maxSkill >= e.skill_level;
          e.isPreferred = isPreferred;
          return e;
        });
      }
      return [];
    },
  },

  mounted() {
    setTimeout(() => {
      this.correctTrainerIsSignedIn();
      this.findLocations();
    }, 2000);
  },

  methods: {
    getNumberOfActivities(idOfHorse) {
      let horseArray = this.horsesWithActivities.filter(
        (r) => r.id == idOfHorse
      );
      if (horseArray && horseArray.length > 0) {
        return horseArray[0].user_activities_in_time_period.length;
      }
    },
    selectHorseFromModal(e) {
      this.selectedHorseId = e.id;
      this.showHorseModal = false;
    },
    setHorseToNull() {
      this.selectedHorseId = null;
      this.showHorseModal = false;
    },
    selectHorse() {
      this.showHorseModal = true;
    },
    closeModal() {
      this.showRiderModal = false;
    },
    editRiderSkillLevel() {
      this.showRiderModal = true;
    },
    correctTrainerIsSignedIn() {
      if (this.$route.params.trainerId != this.currentUser.id) {
        this.$store.commit(
          "global/GLOBAL_ERROR",
          "You are trying to view someone else's data",
          { root: true }
        );
        this.$router.push("/");
        return;
      } else {
        this.findUserActivity();
      }
    },

    findUserActivity() {
      this.$store
        .dispatch(
          "activity/GET_USER_ACTIVITY",
          this.$route.params.userActivityId
        )
        .then((response) => {
          this.userActivity = response.data;
          this.activityDate = this.moment.utc(
            this.userActivity.time_block.start_time
          ).format("YYYY-MM-DD");
          this.findHorses(this.userActivity.location_id);
          this.selectedLocationId = this.userActivity.location_id;
          this.selectedHorseId = this.userActivity.horse_id;
          this.getTrainerSchedule();
        });
    },

    getTrainerSchedule() {
      const selectedDay = this.moment.utc(this.activityDate).format();
      const data = {
        trainer_id: this.userActivity.employee_activity.employee.id,
        location_id: this.selectedLocationId,
        day: selectedDay,
        duration: this.userActivity.employee_activity.activity.length,
      };

      this.$store
        .dispatch("schedule/GET_TRAINER_AVAILABILITY", data)
        .then((response) => {
          this.timeSlots = response.data;
        });
    },

    findHorses(locationId) {
      this.$store
        .dispatch("horse/GET_HORSES_BY_BARN_ID", locationId)
        .then((response) => {
          this.horses = response.data;
          if (this.selectedHorseId) {
            const searchedHorses = this.horses.filter(
              (r) => r.id == this.selectedLocationId
            );
            if (!searchedHorses || searchedHorses.length == 0) {
              this.$store
                .dispatch("horse/GET_HORSE", this.selectedHorseId)
                .then((response) => {
                  let trailerdHorse = response.data;
                  trailerdHorse.trailedIn = true;
                  this.horses.push(trailerdHorse);
                  this.horsesLoaded = true;
                });
            } else {
              this.horsesLoaded = true;
            }
          } else {
            this.horsesLoaded = true;
          }
          this.retrieveActivitiesForHorses();
        });
    },

    retrieveActivitiesForHorses() {
      let start = moment.utc(this.activityDate).startOf("day");
      let end = moment.utc(this.activityDate).endOf("day");

      this.$store
        .dispatch("horse/GET_HORSE_ACTIVITIES", {
          horse_ids: this.horses.map((r) => r.id),
          start_time: start,
          end_time: end,
        })
        .then((r) => {
          this.horsesWithActivities = r.data;
        });
    },

    findLocations() {
      this.$store
        .dispatch("location/GET_EMPLOYER_LOCATIONS", this.currentUser.id)
        .then((response) => {
          this.employerLocations = response.data;
        });
    },

    editRequest() {
      const userActivity = {
        status: "sent_to_rider",
        location_id: this.locationId,
        horse_id: this.selectedHorseId,
      };
      const activityBlock = {
        schedule_chunk_id: this.selectedTimeSlot.schedule_chunk_id,
        start_time: this.composeDatetime(this.selectedTimeSlot.start_time),
        end_time: this.composeDatetime(this.selectedTimeSlot.end_time),
      };

      const params = {
        id: this.userActivity.id,
        user_activity: userActivity,
        activity_block: activityBlock,
      };

      this.$store
        .dispatch("activity/UPDATE_USER_ACTIVITY", params)
        .then((response) => {
          this.$router.push({
            name: "trainer-activity-requests",
            params: { id: this.userActivity.employee_activity.employee_id },
          });
        });
    },

    acceptRequest() {
      const params = {
        status: "accepted",
        id: this.userActivity.id,
        accepted_by: "trainer",
      };

      this.$store
        .dispatch("activity/ACCEPT_USER_ACTIVITY", params)
        .then((response) => {
          this.$router.push({
            name: "trainer-activity-requests",
            params: { id: this.userActivity.employee_activity.employee_id },
          });
        });
    },

    denyRequest() {
      this.$store
        .dispatch(
          "activity/TRAINER_DECLINE_USER_ACTIVITY",
          this.userActivity.id
        )
        .then((response) => {
          this.$router.push({
            name: "trainer-activity-requests",
            params: { id: this.userActivity.employee_activity.employee_id },
          });
        });
    },

    composeDatetime(time) {
      let parsedTime = this.moment.utc(time);
      let date = this.moment.utc(this.activityDate.substring(0, 10), "YYYY-MM-DD");
      date.hour(parsedTime.hour());
      date.minutes(parsedTime.minutes());

      return date.format();
    },

    toggleEditDateMode() {
      this.editDateMode = !this.editDateMode;
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
}

.time-info {
  width: 100%;
  margin-bottom: 15px;
}

.title {
  font-size: 1.35em;
  font-weight: var(--semibold);
}

.thumbs-up {
  color: var(--blue);
  font-size: 1em;
  padding: 0.25em;
}

.change-data-button {
  /* padding: 15px;
  margin-left:25px; */
  font-size: 16px;
}
.hr {
  height: 1px;
  width: 100%;
  background-color: gray;
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
