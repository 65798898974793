<template>
  <nav :class="{ active: expanded }">
    <ul>
      <li>
        <Avatar :image-source="currentUser.avatar" />
        <span class="username">{{ name }}</span>
      </li>
      <li>
        <router-link to="/" @click.native="closeSidebar()"> Home </router-link>
      </li>
      <li v-if="!currentUser.id">
        <router-link to="/register" @click.native="closeSidebar()">
          Register
        </router-link>
      </li>

      <span v-if="currentUser.id">
        <li>
          <router-link :to="registrationLink" @click.native="closeSidebar()">
            Settings
          </router-link>
        </li>

        <li
          v-if="
            currentUser.account_type == 'company' ||
            currentUser.account_type == 'worker'
          "
        >
          <router-link
            to="/companies/create-user-activity"
            @click.native="closeSidebar()"
          >
            Book Activity
          </router-link>
        </li>
        <span v-if="currentUser.account_type == 'company'">
          <!-- <li>
            <router-link
              to="/companies/invoices"
              @click.native="closeSidebar()"
            >
              Invoices
            </router-link>
          </li> -->
          <li>
            <router-link to="/events" @click.native="closeSidebar()">
              Events
            </router-link>
          </li>
        </span>
        <li>
          <router-link
            v-if="currentUser.account_type == 'personal'"
            to="/activities"
            @click.native="closeSidebar()"
          >
            Book Activity
          </router-link>

          <!-- <router-link
            v-else
            :to="`/trainers/activity-requests/${currentUser.id}`"
            @click.native="closeSidebar()"
          >
            Requests
          </router-link> -->
        </li>

        <li>
          <router-link to="/calendar" @click.native="closeSidebar()">
            My Calendar
          </router-link>
        </li>

        <!--        <li v-if="currentUser.account_type == 'personal'">-->
        <!--          <router-link to="/user/invoices" @click.native="closeSidebar()">-->
        <!--            Invoices-->
        <!--          </router-link>-->
        <!--        </li>-->

        <li>
          <button @click="logout" type="button">Log Out</button>
        </li>
      </span>

      <li v-else>
        <router-link to="/login" @click.native="closeSidebar()">
          Log In
        </router-link>
      </li>

      <!-- <li>
        <router-link to="/about" @click.native="closeSidebar()" aria-label="About Riding Post">
          About
        </router-link>
      </li> -->
      <!-- <li>
        <router-link to="/base-styles" @click.native="closeSidebar()">
          Base Styles
        </router-link>
      </li> -->
    </ul>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import Avatar from "../utils/Avatar.vue";

export default {
  name: "TheSidebar",
  components: { Avatar },
  computed: {
    name() {
      if (this.currentUser.first_name) {
        return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
      } else return "Guest";
    },

    registrationLink() {
      if (this.currentUser) {
        if (this.currentUser.account_type === "personal") {
          return "/register/profile";
        } else {
          return `/register/${this.currentUser.account_type}/profile`;
        }
      }
    },

    ...mapState({
      expanded: (state) => state.theSidebar.expanded,
      currentUser: (state) => state.user.currentUser,
    }),
  },

  mounted() {
    if (this.currentUser.email && this.currentUser.first_name) return;

    this.$store.dispatch("user/GET_USER_FROM_TOKEN").then((response) => {});
  },

  methods: {
    closeSidebar() {
      this.$store.dispatch("theSidebar/closeTheSidebar");
    },

    logout() {
      this.$store.dispatch("user/LOGOUT").then((response) => {
        this.$router.push("/");
        this.closeSidebar();
      });
    },
  },
};
</script>

<style scoped>
nav {
  background-color: var(--current-accent-color);
  padding-top: 60px;
  padding-top: calc(env(safe-area-inset-top) + 60px); /* iPhone X */
  position: fixed;
  text-align: center;
  transition: right 0.25s;
  width: 80%;
  bottom: 0;
  bottom: calc(0px - constant(safe-area-inset-top));
  right: -100%;
  top: 0;
}
nav.active {
  right: 0;
}
nav ul li {
  margin-bottom: 1em;
}
nav ul li a,
nav ul li button {
  font-size: 1.75em;
  font-weight: 400; /*semibold plz*/
  line-height: 1;
}
.username {
  display: block;
  font-size: 1.3125em;
  padding: 0.5em 0;
}
</style>
