<template>
  <span
    class="input"
    :class="{
      'input--dirty': value,
      'input--without-label': !label,
      'time-class': timeStyle,
    }"
    @keydown="notAllowEForNumber($event)"
  >
    <input
      ref="input"
      :id="name"
      :name="name"
      :value="value"
      :step="step"
      :minlength="minlength"
      :maxlength="maxlength"
      :required="required == true"
      :type="type"
      :disabled="disabled"
      @input="emitInput"
      @change="emitChange"
    />
    <label :for="name" v-visible="label" @click="labelClicked">
      {{ labelText }}
    </label>
  </span>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    labelText: String,
    maxlength: String,
    minlength: String,
    step: String,
    name: String,
    placeholder: String,
    value: [String, Number],
    label: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timeStyle: false,
    };
  },

  mounted() {
    if (this.type == "time" || this.type == "date") {
      this.timeStyle = true;
    }
  },

  methods: {
    emitInput(event) {
      this.$emit("input", event.target.value);
    },
    labelClicked() {
      this.$refs.input.focus();
    },
    emitChange(event) {
      this.$emit("change", event.target.value);
    },
    notAllowEForNumber(event) {
      if (this.type == "number") {
        if (event.keyCode === 69) {
          event.preventDefault();
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.time-class {
  min-height: 42px;
}
</style>
