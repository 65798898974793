<template>
  <div class="card">
    <div class="avatar-image-box">
      <img class="avatar-image" :src="imageSrc" alt="avatar" />
    </div>
    <div class="name-box">
      {{ carddispalyname }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["imagepath", "carddispalyname"],
  computed: {
    imageSrc() {
      if (this.imagepath) {
        return this.imagepath;
      }
      return "https://www.w3schools.com/howto/img_avatar.png";
    },
  },
};
</script>

<style scoped>
.card {
  height: 180px;
  width: 110px;
  margin: 5px 5px;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  -moz-box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  display: inline-flex;
  flex-direction: column;
}
.avatar-image-box {
}
.avatar-image {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  position: relative;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.name-box {
  text-align: center;
  margin-top: 20px;
  color: #4a4a4a;
  font-weight: var(--semibold);
  font-size: 1.2em;
  width: 100%;
  white-space: normal;
}
</style>