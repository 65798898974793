<template>
  <div class="home">
    <div class="content" id="slider">
      <section>
        <h1 class="title">Rider Account</h1>
        <p>Find your local barns. Schedule and pay for lessons.</p>
        <p>All from your phone.</p>
        <p>
          Equine professionals swipe
          <font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width /> for a
          Professional Account.
        </p>
        <BaseButton theme="alternate" @click.native="continueToRegistration()">
          Signup
        </BaseButton>
      </section>
      <section>
        <h1 class="title">Professional Account</h1>
        <p>
          List your availability and Services. Schedule lessons and get paid.
        </p>
        <p>All from your phone.</p>
        <p>
          Students swipe
          <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width /> for a
          Rider Account.
        </p>
        <BaseButton theme="alternate" @click.native="continueToRegistration()">
          Signup
        </BaseButton>
      </section>
    </div>
    <BaseLink class="login" link="/login"
      >Already have an account? Login</BaseLink
    >
  </div>
</template>


<script>
import Flickity from "flickity";
import { mapState } from "vuex";
import Flex from "../style/Flex";
import UserContinue from "../registration/PageRegistrationUserContinue";

export default {
  name: "PageHome",
  title: null,
  components: { Flex, UserContinue },
  computed: {
    ...mapState({
      theme: (state) => state.global.theme,
      currentUser: (state) => state.user.currentUser,
    }),

    accountTypeName() {
      return this.accountType === "personal" ? "personal" : "worker";
    },
  },
  data() {
    return {
      accountTypes: [
        {
          type: "personal",
          theme: "default",
        },
        {
          type: "worker",
          theme: "worker",
        },
      ],
      accountType: "personal",
    };
  },

  mounted() {
    this.initializeSlider();
  },

  methods: {
    continueToRegistration() {
      this.$router.push({
        name: "register",
        params: { accountType: this.accountTypeName },
      });
    },

    changeAccountType(index) {
      const accountType = this.accountTypes[index].type;
      const theme = this.accountTypes[index].theme;

      this.accountType = accountType;
      this.changeTheme(theme);
    },

    changeTheme(theme) {
      this.$store.dispatch("global/CHANGE_THEME", theme);
    },

    initializeSlider() {
      const slider = document.getElementById("slider");
      if (!slider) return;

      // Defining `that = this` outside of the arrow function will fail. This
      //   is required in order to get the Vue instance that is lost in the
      //   Flickity logic
      // Set the slider to match the current theme so things don't get set up
      //   improperly when returning to the page if you backtrack
      const setSliderAccountType = (flkty, that = this) => {
        const index = that.accountTypes
          .map(function (e) {
            return e.theme;
          })
          .indexOf(that.theme);
        flkty.selectCell(index, false, true);
      };

      const initAccountTypeChange = (index, that = this) => {
        that.changeAccountType(index);
      };

      const flkty = new Flickity(slider, {
        on: {
          ready: function () {
            setSliderAccountType(this);
          },
          change: function (index) {
            // Vue scope is lost here to Flickity and doesn't accept additional
            //   arguments. Scope is lost until redefined below with a workaround
            initAccountTypeChange(index);
          },
        },
        cellAlign: "left",
        prevNextButtons: false,
        resize: true,
        setGallerySize: false,
      });
    },
  },
};
</script>

<style scoped>
.home {
  background-color: var(--current-accent-color);
  color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-top: 5em;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.2s;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}
.title {
  font-size: 1.75em;
  text-transform: uppercase;
}
p {
  font-size: 1.125em;
  line-height: 1.5em;
  padding: 2em 0;
}
#slider {
  display: inline-flex;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
  padding: 1em 3em;
  width: 100%;
}
#slider:focus {
  outline: none;
}
#slider >>> .flickity-viewport,
#slider >>> .flickity-slider {
  height: 100%;
}
.flickity-slider > section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}
#slider >>> .flickity-page-dots .dot {
  background-color: var(--white);
  border-radius: 100%;
  display: inline-block;
  height: 0.75em;
  margin: 0.5em;
  width: 0.75em;
}
#slider >>> .flickity-page-dots .dot.is-selected {
  background-color: var(--gold);
}
.login {
  background: var(--white);
  padding: 2em;
}
</style>
