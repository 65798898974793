<template>
  <article class="trainer-panel">
    <BasePanel type="active">
      <Flex justify="between">
        <Flex justify="start">
          <Avatar :image-source="trainer.avatar" />
          <div>
            <h1>{{ trainer.name }}</h1>
            <p>Trainer {{ trainer.home_location }}</p>
          </div>
        </Flex>
        <slot />
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";

export default {
  name: "TrainerPanel",
  props: { trainer: Object },
  components: { Flex, Avatar },
  computed: {
    addRemove() {
      if (this.following) return "remove";
      return "add";
    },
  },

  methods: {
    emitEdit() {
      this.$emit("edit-trainer", this.trainer);
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--black);
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  color: var(--black);
  font-size: 0.875em;
  font-weight: var(--semibold);
}
.trainer-panel {
  width: 100%;
}
</style>
