<template>
  <BaseModal ref="modal" @click="clickedOutside">
    <div class="container" @click.stop="">
      <div class="header">
        <Flex justify="space-betweek">
          <div class="title" v-if="formattedDay">
            {{ `${formattedDay}'s Availability` }}
          </div>
          <Flex justify="end" class="close-button">
            <BaseButton
              theme="round alternate"
              aria-label="close"
              @click="$refs.modal.closeModal()"
            >
              <font-awesome-icon :icon="['fas', 'times']" fixed-width />
            </BaseButton>
          </Flex>
        </Flex>
      </div>
      <div class="content">
        <form>
          <Flex
            v-if="displayedScheduleChunks"
            justify="between"
            v-for="(chunk, index) in displayedScheduleChunks"
            :key="chunk[index]"
          >
            <BaseInput
              type="time"
              label-text="Time In"
              :name="`time_in_${index}`"
              v-model="displayedScheduleChunks[index].parsed_start_time"
            />
            <BaseInput
              type="time"
              label-text="Time Out"
              :name="`time_out_${index}`"
              v-model="displayedScheduleChunks[index].parsed_end_time"
            />

            <BaseButton
              type="button"
              theme="alternate round"
              @click="removeScheduleChunk(index)"
            >
              <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
            </BaseButton>
          </Flex>

          <Flex
            v-if="additionalChunks"
            justify="between"
            v-for="(chunk, index) in additionalChunks"
            :key="chunk[index]"
          >
            <BaseInput
              type="time"
              label-text="Time In"
              :name="`time_in_${index}`"
              v-model="additionalChunks[index].parsed_start_time"
            />
            <BaseInput
              type="time"
              label-text="Time Out"
              :name="`time_out_${index}`"
              v-model="additionalChunks[index].parsed_end_time"
            />
          </Flex>

          <Flex justify="between">
            <BaseButton type="button" @click="addAnother()">
              + Add another time slot?
            </BaseButton>

            <BaseButton
              type="button"
              @click="saveScheduleChunks()"
              class="save"
            >
              Save
            </BaseButton>
          </Flex>
        </form>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import Flex from "../../../style/Flex";

export default {
  name: "WorkerAvailabilityModal",
  components: { Flex },
  props: { day: String, schedule: Object },
  data() {
    return {
      additionalChunks: [],
      displayedScheduleChunks: [],
    };
  },

  computed: {
    formattedDay() {
      if (this.day)
        return this.day.charAt(0).toUpperCase() + this.day.substr(1);
    },

    scheduleChunks() {
      if (
        this.schedule.schedule_chunks &&
        this.schedule.schedule_chunks.length > 0
      ) {
        const scheduleChunks = this.schedule.schedule_chunks.filter(
          (d) => d.day == this.day
        );
        return scheduleChunks;
      }
    },
  },

  mounted() {
    this.initializeData();
  },

  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    clickedOutside() {
      this.$refs.modal.closeModal();
    },

    placeholderChunk() {
      return { parsed_start_time: "N/A", parsed_end_time: "N/A" };
    },

    initializeData() {
      // wait for props to pass from parent component
      setTimeout(() => {
        if (this.scheduleChunks && this.scheduleChunks.length > 0) {
          this.displayedScheduleChunks = this.scheduleChunks.map((s) => {
            s.parsed_start_time = this.parseDateTime(s.start_time);
            s.parsed_end_time = this.parseDateTime(s.end_time);
            return s;
          });
        } else {
          this.addAnother();
        }
      }, 2000);
    },

    addAnother() {
      this.additionalChunks.push(this.placeholderChunk());
    },

    removeScheduleChunk(index) {
      this.displayedScheduleChunks.splice(index, 1);
    },

    parseScheduleChunks() {
      const otherDays = this.schedule.schedule_chunks.filter(
        (d) => d.day != this.day
      );
      const bothKinds = this.displayedScheduleChunks.concat(
        this.additionalChunks
      );
      const parsed = bothKinds.map((sc) => {
        sc["day"] = this.day;
        sc["start_time"] = this.composeDatetime(sc["parsed_start_time"]);
        sc["end_time"] = this.composeDatetime(sc["parsed_end_time"]);
        return sc;
      });

      return otherDays.concat(parsed);
    },

    parseDateTime(datetime) {
      if (datetime instanceof this.moment) return datetime;
      //MARK
      //since the time is stored in the database without a date, rails gives back 2000-01-01 as the date.
      //this is a problem because it messes up the daylight savings calculations.
      datetime = datetime.replace(
        "2000-01-01",
        this.moment.utc().format("YYYY-MM-DD")
      );
      let date = this.moment.utc(datetime);
      return date.format("HH:mm");
    },

    composeDatetime(time) {
      let date = this.moment.utc();
      date.hour(time.split(":")[0]);
      date.minute(time.split(":")[1].slice(0, 2));
      date.seconds(0);

      return date.format();
    },

    saveScheduleChunks() {
      const data = {
        schedule_id: this.schedule.id,
        day: this.day,
        schedule_chunks: this.parseScheduleChunks(),
      };

      this.createScheduleChunks(data);
    },

    createScheduleChunks(params) {
      this.$store
        .dispatch("schedule/CREATE_SCHEDULE_CHUNKS", params)
        .then((response) => {
          this.$refs.modal.closeModal();
          window.location.reload(true);
        });
    },
  },
};
</script>

<style scoped>
.container {
  background: var(--white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  width: 100%;
}
.header {
  border-bottom: 1px solid var(--border-color);
}
.header,
.content {
  padding: 1em;
}
.title {
  font-size: 1.5em;
}
.save {
  background: var(--company-accent-color);
}
</style>
