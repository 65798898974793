<template>
  <div id="activity-box" :class="{ selected: activitySelected }">
    <span class="activity-name">{{ activityname }}</span>
    <div class="btn-cta" @click="toggleSelected">
      <span class="sign" v-if="activitySelected"> - </span>
      <span class="sign" v-else> + </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activitySelected: false,
    };
  },
  props: ["selected", "activityname", "activityid"],
  computed: {
    selectedState: function () {
      return this.activitySelected;
    },
  },
  mounted() {
    this.activitySelected = this.selected;
  },
  methods: {
    toggleSelected() {
      this.activitySelected = !this.activitySelected;
      this.$emit("activitytoggle", {
        activitySelected: this.activitySelected,
        activityId: this.activityid,
      });
    },
  },
};
</script>

<style scoped>
#activity-box {
  width: 100%;
  border-radius: 2px;
  color: #9b9b9b;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
  margin: 20px 0;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  height: 65px;
}
#activity-box.selected {
  border: 1px solid #9b9b9b;
  background-color: #f1f1f1 !important;
}

.activity-name {
  max-width: 80%;
  display: inline-block;
}

.sign {
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: relative;
}

.btn-cta {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  color: #64c8c1;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  float: right;
}
</style>