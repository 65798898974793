export const CREATE_ACTIVITY_PACKAGE = "CREATE_ACTIVITY_PACKAGE";
export const ADD_PACKAGE_MEMBERSHIP_TO_PACKAGE =
  "ADD_PACKAGE_MEMBERSHIP_TO_PACKAGE";
export const GET_ACTIVITY_PACKAGE = "GET_ACTIVITY_PACKAGE";
export const DELETE_PACKAGE_MEMBERSHIP = "DELETE_PACKAGE_MEMBERSHIP";
export const UPDATA_PACKAGE_MEMBERSHIP_QUANTITY =
  "UPDATA_PACKAGE_MEMBERSHIP_QUANTITY";
export const UPDATE_ACTIVITY_PACKAGE = "UPDATE_ACTIVITY_PACKAGE";
export const DELETE_ACTIVITY_PACKAGE = "DELETE_ACTIVITY_PACKAGE";
export const RETRIEVE_COMPANY_PACKAGES = "RETRIEVE_COMPANY_PACKAGES";
export const BUY_ACTIVITY_PACKAGE = "BUY_ACTIVITY_PACKAGE";
