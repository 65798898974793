<template>
  <article class="location-panel">
    <BasePanel>
      <Flex justify="between">
        <Flex justify="start">
          <Avatar />
          <div>
            <h1>{{ company.name }}</h1>
            <p>4 Trainers | 12 Services Offered</p>
          </div>
        </Flex>
        <slot />
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import BarnDefaultImage from "../../assets/images/covers/company.jpg";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "CompanyPanel",
  props: {
    company: Object,
    owner: {
      type: Boolean,
      default: false,
    },
  },
  components: { Flex, Avatar },
};
</script>

<style scoped>
h1 {
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
p {
  font-size: 0.875em;
}
</style>
