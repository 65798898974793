import axios from "axios";
import {
  ADD_EMPLOYER,
  GET_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANIES,
  GET_EMPLOYERS,
  UPDATE_EMPLOYER,
  DELETE_EMPLOYER,
} from "../actions/company";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  GET_COMPANIES({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/companies")
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_COMPANY({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/companies/${companyId}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  UPDATE_COMPANY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/companies/${params.id}`, params.companyData)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_EMPLOYERS({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/employers", { params: { user_id: userId } })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  ADD_EMPLOYER({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/employers", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  UPDATE_EMPLOYER({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/employers/${params.id}`, params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  DELETE_EMPLOYER({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/employers/${id}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  GET_COMPANY_EMPLOYEES({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company_employees/${id}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  REMOVE_EMPLOYEE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/company_employees/${params.id}`, {
          params: { employee_id: params.employee_id },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  ADD_EMPLOYEE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/company_employees", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
