<template>
  <BasePanel :class="this.isHidden()">
    <Flex justify="between" direction="row">
      <Avatar size="small" :image-source="event.avatar" avatar-type="event" />
      <Flex direction="column" align="start">
        <p class="name">{{ event.title }}</p>
        <Flex direction="row">
          <p class="location" v-if="event.location">
            {{ event.location.name }}
          </p>
        </Flex>
        <Flex direction="row">
          <p class="location">
            {{ moment.utc(event.start_time).format("MMM D") }} -
            {{ moment.utc(event.end_time).format("MMM D") }}
          </p>
        </Flex>
      </Flex>
      <Flex justify="end" direction="row">
        <ButtonFaButton
          ref="eventInterested"
          class="fa-thumbs-up"
          :icon="['fas', 'thumbs-up']"
          v-on:click="eventInterested(event.id)"
        />
        <ButtonFaButton
          ref="eventNotInterested"
          class="fa-thumbs-down"
          :icon="['fas', 'thumbs-down']"
          v-on:click="eventNotInterested(event.id)"
        />
      </Flex>
    </Flex>
    <Flex justify="start" direction="row">
      <p class="interested-riders">
        Who's interested:
        {{
          event.interested_users
            .flatMap((item) => Object.values(item))
            .join(", ")
        }}
      </p></Flex
    >
  </BasePanel>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";
import ButtonFaButton from "../buttons/ButtonFaButton";
import company from "../../store/modules/company";
import event from "../../store/modules/event";

export default {
  name: "EventInterest",
  components: { Flex, Avatar, ButtonFaButton },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      hidden: false,
    };
  },
  props: {
    event: {},
  },

  mounted() {},

  methods: {
    isHidden() {
      return this.hidden ? "hidden" : "";
    },
    removeFromParent() {
      this.hidden = true;
      setTimeout(() => {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
    eventInterested(eventId) {
      this.$refs["eventInterested"].toggleSpinner();
      //TODO ENUM/DTO
      this.createEvent({
        interest_level: 1,
        user_id: this.currentUser.id,
        event_id: eventId,
      })
        .then((response) => {
          this.removeFromParent();
        })
        .catch((error) => {
          this.toggleSpinner();
        });
    },

    eventNotInterested(eventId) {
      this.$refs["eventNotInterested"].toggleSpinner();
      //TODO ENUM/DTO

      this.createEvent({
        interest_level: -1,
        user_id: this.currentUser.id,
        event_id: eventId,
      })
        .then((response) => {
          this.removeFromParent();
        })
        .catch((error) => {
          this.toggleSpinner();
        });
    },

    createEvent(eventRegistrationData) {
      return this.$store.dispatch(
        "eventRegistration/CREATE_EVENT_REGISTRATION",
        eventRegistrationData
      );
    },

    toggleSpinner(eventRegistrationData) {
      console.log(eventRegistrationData);
    },
  },
};
</script>

<style scoped>
p.interested-riders {
  margin-top: 1.1em;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 0.5s linear;
}
</style>