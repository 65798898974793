<template>
  <div>
    <div v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <Cover :image-source="imageSource" :cover-type="coverType" />
    <Flex justify="center" class="upload">
      <label class="button company auto" for="cover"> Upload Photo </label>
      <input
        class="hide"
        id="cover"
        type="file"
        accept="image/*"
        :name="name"
        @change="processImage"
      />
    </Flex>

    <BaseButton
      v-if="imageSource"
      type="button"
      theme="alternate round"
      @click="emitDeletePhoto()"
    >
      <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
    </BaseButton>
  </div>
</template>

<script>
import Flex from "../style/Flex";
import Cover from "./Cover.vue";

export default {
  name: "CoverUpload",
  components: { Flex, Cover },
  props: {
    name: String,
    coverType: String,
    coverPhoto: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      newCover: "",
      extensionsWhitelist: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "image/bmp",
      ],
      errorMessage: "",
    };
  },

  computed: {
    imageSource() {
      return this.newCover ? this.newCover : this.coverPhoto;
    },
  },

  methods: {
    isValidImageType(image) {
      if (this.extensionsWhitelist.includes(image.type)) {
        this.errorMessage = "";
        return true;
      } else {
        this.errorMessage =
          "The file you added is not of the correct type. Please try another.";
        return false;
      }
    },

    processImage(event) {
      const image = event.target.files[0];
      if (!this.isValidImageType(image)) return;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      const result = (reader.onload = () => (this.newCover = reader.result));
    },

    emitDeletePhoto() {
      this.$emit("delete-cover-photo-request");
    },
  },
};
</script>

<style scoped>
.cover {
  position: relative;
  z-index: 1;
}
.upload {
  position: relative;
  z-index: 2;
  transform: translateY(-50%);
}
.button {
  background-color: var(--current-accent-color);
  border-radius: 100px;
  color: white;
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  padding: 0.875em 1.875em;
  text-align: center;
  width: 100%;
}
.button.company {
  background-color: var(--company-accent-color);
}
.button.auto {
  display: inline-block;
  width: auto;
}
.hide {
  position: absolute;
  visibility: hidden;
}
</style>
