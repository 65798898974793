<template>
  <div>
    <p class="title">Add a New Credit</p>
    <p class="credit-desc">
      Credits are credits that your customers can purchase and use that are
      valid for a collection of activities your company offers. For example, you
      might have an activity credit called
      <span class="italic"> Any 30 minute lesson </span> that could be used for
      30 minute private or group lessons. They can also be put into packages
      your clients can buy.
    </p>
    <input
      class="credit-name"
      placeholder="Credit Name"
      v-model="creditActivity.name"
    />
    <p class="title">Services this Credit is Valid For</p>
    <div v-if="showMappings">
      <company-activity-card
        v-for="activity in computedCompanyActivities"
        :key="activity.id"
        :activityname="activity.full_name"
        :activityid="activity.id"
        :selected="activity.selected"
        @activitytoggle="toogleActivity"
      />
    </div>
    <button class="btn-distructive" @click="goBack">back</button>
    <button
      v-if="$route.params.activityCreditId"
      class="btn-distructive"
      @click="deleteCredit"
    >
      remove lesson credit
    </button>
    <button
      v-else-if="selectedActivitiesForNewCredit.length > 0"
      class="btn-save"
      @click="saveCreditActivity"
    >
      save & continue
    </button>
  </div>
</template>

<script>
import CompanyActivityCard from "./CompanyActivityCard";
import { mapState } from "vuex";

export default {
  name: "ActivityCreditForm",
  components: { CompanyActivityCard },
  data: function () {
    return {
      companyActivities: [],
      creditActivity: {
        name: "",
        creditMappingAttributes: [],
      },
      selectedActivitiesForNewCredit: [],
      initialCreditMappingAttributes: [],
      showMappings: false,
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    computedCompanyActivities() {
      let newArray = this.companyActivities.map((activity) => {
        return {
          ...activity,
          selected: this.initialCreditMappingAttributes.some(
            (r) => r == activity.id
          ),
        };
      });
      return newArray;
    },
    companyId() {
      return this.currentUser.company_id;
    },
  },
  mounted() {
    this.initializeComponent();
  },
  methods: {
    getCompanyActivities(companyId) {
      this.$store
        .dispatch("activity/GET_COMPANY_ACTIVITIES", { companyId: companyId })
        .then((r) => {
          this.companyActivities = null;
          this.companyActivities = r.data;
          this.showMappings = true;
        });
    },
    initializeComponent() {
      this.showMappings = false;
      if (this.$route.params.activityCreditId) {
        this.$store
          .dispatch(
            "activityCredit/GET_ACTIVITY_CREDIT",
            this.$route.params.activityCreditId
          )
          .then((r) => {
            this.creditActivity = null;
            this.creditActivity = r.data;
            this.initialCreditMappingAttributes = null;
            this.initialCreditMappingAttributes =
              this.creditActivity.credit_mappings.map(
                (r) => r.company_activity.id
              );
          })
          .catch((error) => {})
          .finally(() => {
            this.getCompanyActivities(this.companyId);
          });
      } else {
        this.getCompanyActivities(this.companyId);
      }
    },
    toogleActivity(toogleActivityInfo) {
      const id = toogleActivityInfo.activityId;
      const selected = toogleActivityInfo.activitySelected;

      if (this.$route.params.activityCreditId) {
        if (selected) {
          //add
          this.initialCreditMappingAttributes.push(id);
          this.$store
            .dispatch("activityCredit/ADD_ACTIVITY_TO_CREDIT", {
              activity_credit_id: this.$route.params.activityCreditId,
              company_activity_id: id,
            })
            .then((response) => {
              let data = response.data;
              let indexOfMapping =
                this.creditActivity.credit_mappings.findIndex(
                  (r) => r.company_activity.id == data.company_activity.id
                );
              this.creditActivity.credit_mappings.splice(indexOfMapping, 1);
              this.creditActivity.credit_mappings.push(data);
            })
            .catch((error) => {
              this.initializeComponent();
            })
            .finally(() => {});
        } else {
          //delete
          let index = this.selectedActivitiesForNewCredit.indexOf(id);
          if (index > -1) {
            this.selectedActivitiesForNewCredit.splice(index, 1);
          }
          index = this.initialCreditMappingAttributes.indexOf(id);
          if (index > -1) {
            this.initialCreditMappingAttributes.splice(index, 1);
          }
          let creditMappingId = this.creditActivity.credit_mappings.filter(
            (r) => r.company_activity.id == id
          )[0].id;
          this.$store
            .dispatch(
              "activityCredit/DELETE_ACTIVITY_FROM_CREDIT",
              creditMappingId
            )
            .then((response) => {})
            .catch((error) => {
              this.initializeComponent();
              this.creditActivity.credit_mappings =
                this.creditActivity.credit_mappings.filter(
                  (r) => r.company_activity.id == id
                )[0].id;
            });
        }
      } else {
        let foundActivityArray = this.selectedActivitiesForNewCredit.filter(
          (r) => r == id
        );
        if (foundActivityArray && foundActivityArray.length > 0) {
          if (!selected) {
            let index = this.selectedActivitiesForNewCredit.indexOf(id);
            if (index > -1) {
              this.selectedActivitiesForNewCredit.splice(index, 1);
            }
          }
        } else {
          if (selected) {
            this.selectedActivitiesForNewCredit.push(id);
          }
        }
      }
    },
    deleteCredit() {
      let id = this.$route.params.activityCreditId;
      this.$store
        .dispatch("activityCredit/DELETE_ACTIVITY_CREDIT", id)
        .then((r) => {
          window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push("/");
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    saveCreditActivity() {
      if (this.$route.params.activityCreditId) {
      } else {
        const creatActivityCreditParams = {
          name: this.creditActivity.name,
          company_id: this.companyId,
        };
        this.$store
          .dispatch(
            "activityCredit/SAVE_ACTIVITY_CREDIT",
            creatActivityCreditParams
          )
          .then((r) => {
            let responseData = r.data;
            if (responseData && responseData.id) {
              let creditId = responseData.id;
              for (
                let i = 0;
                i < this.selectedActivitiesForNewCredit.length;
                i++
              ) {
                this.$store
                  .dispatch("activityCredit/ADD_ACTIVITY_TO_CREDIT", {
                    activity_credit_id: creditId,
                    company_activity_id: this.selectedActivitiesForNewCredit[i],
                  })
                  .then((response) => {
                    if (i == this.selectedActivitiesForNewCredit.length - 1) {
                      window.history.length > 1
                        ? this.$router.go(-1)
                        : this.$router.push("/");
                    }
                  });
              }
            }
          })
          .catch((error) => {});
      }
    },
  },
};
</script>

<style scoped>
.title {
  color: var(--page-title-color);
  font-weight: 600;
  font-size: 18px;
  margin: 20px 0 10px 0;
}

.credit-desc {
  color: #656565;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.credit-name {
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  border: 2px solid #d6d6d6;
  margin-top: 20px;
  outline: none;
}

.credit-name::placeholder {
  color: #989898;
}

button {
  width: 100%;
  padding: 10px 0;
  border-radius: 50px;
  border: 2px solid #ebebeb;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0 0 0;
  outline: none;
}

.btn-distructive {
  color: #adadad;
  background-color: #ffffff;
}

.btn-save {
  background-color: #9b9b9b;
  color: #fcfcfc;
  border: 2px solid #9b9b9b;
}
</style>