export const UPDATE_USER_PAYMENT_PROFILE = "UPDATE_USER_PAYMENT_PROFILE";
export const FETCH_USER_PAYMENT_PROFILE = "FETCH_USER_PAYMENT_PROFILE";
export const DELETE_USER_PAYMENT_PROFILE = "DELETE_USER_PAYMENT_PROFILE";
export const CREATE_STRIPE_CONNECTED_ACCOUNT =
  "CREATE_STRIPE_CONNECTED_ACCOUNT";
export const GET_USER_STRIPE_CHARGES = "GET_USER_STRIPE_CHARGES";
export const GET_USER_STRIPE_CHARGE = "GET_USER_STRIPE_CHARGE";
export const GET_BILLING_COMPANY = "GET_BILLING_COMPANY";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const GET_USER_INVOICE = "GET_USER_INVOICE";
export const GET_COMPANY_INVOICE = "GET_COMPANY_INVOICE";
export const GET_COMPANY_INVOICES = "GET_COMPANY_INVOICES";
export const GET_USER_INVOICES = "GET_USER_INVOICES";
export const GET_STRIPE_DASHBOARD = "GET_STRIPE_DASHBOARD";
