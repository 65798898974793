<template>
  <div>
    <h1>My Calendar</h1>

    <BasePanel type="clickable" @click.native="goToActivites()">
      <Flex justify="between" direction="row">
        <BaseLink class="add-an-activity">Add Activity</BaseLink>
        <ButtonAddRemove type="add" />
      </Flex>
    </BasePanel>

    <div v-for="event in calendar" :key="event.id">
      <!-- if event is activity -->
      <CalendarDatePanel
        v-if="event.status"
        :trainer="
          currentUser.account_type == 'personal'
            ? event.trainer
            : event.participant.name
        "
        :start-time="event.time_block.start_time"
        :horse="event.horse"
        :end-time="event.time_block.end_time"
        :activity-type="event.employee_activity.activity.service"
        :status="event.status"
        :avatar="
          currentUser.account_type == 'personal'
            ? event.employee_activity.employee.avatar
            : event.participant.avatar
        "
      />

      <InvoicePanel v-else :invoice="event" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import CalendarDatePanel from "../calendars/CalendarDatePanel";
import InvoicePanel from "../invoices/InvoicePanel";

export default {
  name: "PageCalendar",
  title: null,
  components: { CalendarDatePanel, InvoicePanel, Flex, ButtonAddRemove },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    bookActivitiyRoute: function () {
      if (this.currentUser.account_type === "personal") {
        return "/activities";
      }
      return "/companies/create-user-activity";
    },
  },

  data() {
    return {
      invoices: [],
      activities: [],
      calendar: [],
    };
  },

  mounted() {
    setTimeout(() => this.getData(), 500);
  },

  methods: {
    getActivities() {
      if (this.currentUser.account_type == "personal") {
        return this.$store
          .dispatch("activity/GET_USER_ACTIVITIES", {
            userId: this.currentUser.id,
          })
          .then((response) => {
            return response.data;
          });
      } else if (this.currentUser.account_type == "worker") {
        return this.$store
          .dispatch("activity/GET_COMPANY_USER_ACTIVITIES", {
            companyId: this.currentUser.company_id,
          })
          .then((response) => {
            return response.data;
          });
      } else {
        return this.$store
          .dispatch("activity/GET_COMPANY_USER_ACTIVITIES", {
            companyId: this.currentUser.company_id,
          })
          .then((response) => {
            return response.data;
          });
      }
    },

    getInvoices() {
      if (this.currentUser.account_type == "personal") {
        return this.$store
          .dispatch("payment/GET_USER_INVOICES", this.currentUser.id)
          .then((response) => {
            return response.data;
          });
      } else if (this.currentUser.account_type == "company") {
        return this.$store
          .dispatch("payment/GET_COMPANY_INVOICES", this.currentUser.id)
          .then((response) => {
            return response.data;
          });
      } else {
        return [];
      }
    },

    async getData() {
      const activities = await this.getActivities();
      this.activities = activities.map((activity) => {
        activity.sortDate = this.moment.utc(activity.time_block.start_time);
        return activity;
      });

      const invoices = await this.getInvoices();
      this.invoices = invoices.map((invoice) => {
        invoice.sortDate = this.moment.utc(invoice.due_date);
        return invoice;
      });

      this.sortCalendar(this.activities.concat(this.invoices));
    },

    sortCalendar(events) {
      this.calendar = events.sort(
        (a, b) => Date.parse(a.sortDate) > Date.parse(b.sortDate)
      );
    },

    goToActivites() {
      this.$router.push(this.bookActivitiyRoute);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  text-transform: uppercase;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
</style>
