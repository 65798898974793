<template>
  <article>
    <BasePanel type="active">
      <Flex justify="between" align="center">
        <div>
          <h1>{{ name }}</h1>
          <p v-if="following">
            <font-awesome-icon
              :icon="['fas', 'heart']"
              fixed-width
              style="color: var(--red)"
            />
            Following
          </p>
          <p v-else>Thing</p>
        </div>
        <ButtonAddRemove :type="addRemove" />
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import Flex from "../style/Flex";
export default {
  name: "BaseFollowing",
  props: {
    following: {
      type: Boolean,
      default: false,
    },
    name: String,
  },
  components: { ButtonAddRemove, Flex },
  computed: {
    addRemove() {
      return this.following ? "remove" : "add";
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  font-size: 0.875em;
}
</style>
