<template>
  <div class="panel" :class="theme">
    <div class="section">
      <div class="day">{{ day }}</div>
      <div class="ellipsize" v-if="schedule.location">
        <font-awesome-icon :icon="['fa', 'map-marker-alt']" class="marker" />
        {{ schedule.location.name }}
      </div>
    </div>
    <div class="section">
      <div class="times">
        <div v-for="chunk in scheduleTimesOrDefault" :key="chunk.start_time">
          <div>
            Time In:
            <span class="time">{{ formatTime(chunk.start_time) }}</span>
          </div>
          <div>
            Time Out:
            <span class="time">{{ formatTime(chunk.end_time) }}</span>
          </div>
        </div>

        <BaseButton theme="round alternate" @click="openModal()">
          <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
        </BaseButton>
      </div>
    </div>

    <WorkerAvailabilityModal
      ref="availablityModal"
      :day="day"
      :schedule="schedule"
    />
  </div>
</template>

<script>
import WorkerAvailabilityModal from "./WorkerAvailabilityModal";

export default {
  name: "WorkerAvailabilityPanel",
  components: { WorkerAvailabilityModal },
  props: { schedule: Object, day: String },

  data() {
    return {
      placeholderTimes: { start_time: "N/A", end_time: "N/A" },
    };
  },

  computed: {
    theme() {
      if (!this.scheduleChunks.length > 0) return "unavailable";
    },

    scheduleChunks() {
      if (!this.schedule.schedule_chunks) return [];

      const scheduleChunks = this.schedule.schedule_chunks.filter(
        (d) => d.day == this.day
      );
      return scheduleChunks ? scheduleChunks : [];
    },
    scheduleTimesOrDefault() {
      if (this.scheduleChunks && this.scheduleChunks.length > 0) {
        return this.scheduleChunks;
      } else {
        return [this.placeholderTimes];
      }
    },
  },

  mounted() {},

  methods: {
    openModal() {
      this.$refs.availablityModal.openModal();
    },

    formatTime(time) {
      if (time === "N/A" || time instanceof this.moment) return time;
      //MARK
      //since the time is stored in the database without a date, rails gives back 2000-01-01 as the date.
      //this is a problem because it messes up the daylight savings calculations.
      time = time.replace("2000-01-01", this.moment.utc().format("YYYY-MM-DD"));
      return this.moment.utc(time).format("hh:mm a");
    },
  },
};
</script>

<style scoped>
.panel {
  background-color: #ffffff;
  border: 1px solid var(--panel-border-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  font-weight: var(--semibold);
  margin-bottom: 1.25em;
}
.day {
  font-size: 1.3125em;
  margin-bottom: 0.25em;
  text-transform: uppercase;
}
.marker {
  color: var(--default-accent-color);
  font-size: 0.875em;
}
.times {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.time {
  color: var(--dark-grey);
}
.section {
  align-content: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.75em;
  width: 100%;
}
.section:first-child {
  border-right: 1px solid var(--panel-border-color);
  width: 40%;
}
.active {
  background-color: var(--input-accent-color);
  color: var(--company-accent-color);
}
.unavailable {
  background: repeating-linear-gradient(
    135deg,
    #edffff 0em,
    #edffff 2em,
    #d4f4f4 2em,
    #d4f4f4 4em
  );
}
</style>
