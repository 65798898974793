<template>
  <div class="card" @click="$emit('click')">
    <!-- <div class="avatar-box"> -->
    <img :src="companyImage" alt="companyImage" class="avatar-image" />
    <div class="company-details">
      <div class="company-name">
        {{ companyname }}
      </div>
      <div class="company-numbers" v-if="trainerType">
        <span v-if="trainerType">
          <div class="bullet"></div>
          {{ trainerType }}
        </span>
        <span v-else
          >{{ trainersnr }} Trainers | {{ servicesnr }} Services Offered</span
        >
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: [
    "avatarimage",
    "companyname",
    "trainersnr",
    "servicesnr",
    "trainerType",
  ],
  computed: {
    companyImage() {
      if (this.avatarimage) {
        return this.avatarimage;
      }
      return require("../../assets/images/covers/company.jpg");
    },
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 80px;
  margin-bottom: 15px;
  margin-top: 10px;
  /* padding: 15px; */
  -webkit-box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  -moz-box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  box-shadow: 0px 0px 5px 2px rgba(143, 143, 143, 0.5);
  display: flex;
  align-items: center;
}

.bullet {
  display: inline-block;
  height: 9px;
  width: 9px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-left: 8px;
}

.company-details {
  height: 100%;
  margin-left: 20px;
}

.company-name {
  color: gray;
  font-weight: var(--semibold);
  font-size: 1em;
  margin: 18px 0px 10px 10px;
}

.company-numbers {
  margin: 5px 0px 10px 10px;
  font-size: 14px;
  color: gray;
  font-weight: var(--semibold);
}
</style>