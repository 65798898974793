<template>
  <div class="modal-list" @click="clickInBackground">
    <div
      class="card"
      @click.stop="clickedCard"
      v-for="(activity, index) in activities"
      :key="index"
    >
      <div class="cell-container">
        <div class="cell-content-1">
          <img
            :src="activity.rider.avatar"
            alt="rider avatar"
            class="rider-avatar"
          />
          <div class="rider-name">{{ activity.rider.name }}</div>
        </div>
        <div class="cell-content-2">
          <div class="activity-time-slot">
            {{
              moment.utc(activity.start_time).format("hh:mma").substr(0, 6)
            }}-{{ moment.utc(activity.end_time).format("hh:mma").substr(0, 6) }}
          </div>
          <div class="activity-name">{{ activity.company_activity_name }}</div>
          <div class="trainer-name">{{ activity.trainer.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
export default {
  props: ["activities"],
  methods: {
    clickInBackground() {
      this.$emit("click");
    },
    clickedCard() {},
  },
};
</script>

<style scoped>
.modal-list {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 1500;
  align-items: center;
}

.cell-container {
  display: flex;
}

.cell-content-1 {
  width: 45%;
}
.cell-content-2 {
  width: 55%;
}

.rider-avatar {
  height: 60px;
  border-radius: 50%;
  min-width: 60px;
  max-width: 60px;
  margin: auto;
  display: block;
  margin-top: 7px;
}

.rider-name {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}

.card {
  margin: 8px;
  background-color: lightgray;
  width: 65%;
}

.activity-name,
.trainer-name {
  text-align: center;
  margin-top: 16px;
  font-weight: 600;
  font-size: 15px;
}
.trainer-name {
  margin-top: 14px;
}
.activity-time-slot {
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  font-weight: 600;
}
</style>