<template>
  <article class="location-panel">
    <BasePanel theme="active image" :style="fallbackImageSource">
      <Flex justify="between">
        <Flex justify="start">
          <div>
            <h1>{{ location.location.name }}</h1>
            <p>Member</p>
          </div>
        </Flex>
        <BaseButton
          theme="round alternate"
          @click="deleteUserLocation(location.id)"
        >
          <font-awesome-icon :icon="['fas', 'minus']" fixed-width />
        </BaseButton>
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import BarnDefaultImage from "../../assets/images/covers/company.jpg";
import Flex from "../style/Flex";

export default {
  name: "LocationUserPanel",
  components: { Flex },
  props: {
    location: Object,
  },
  computed: {
    fallbackImageSource() {
      var image = this.location.location.avatar
        ? this.location.location.avatar
        : BarnDefaultImage;
      return "background-image: url(" + image + ");";
    },
  },
  methods: {
    confirmDelete(userLocationId) {
      const confirmDelete = window.confirm(`Are you sure you wish to delete?`);
      if (confirmDelete) this.deleteUserLocation(userLocationId);
    },

    deleteUserLocation(userLocationId) {
      this.$store
        .dispatch("location/DELETE_USER_LOCATION", userLocationId)
        .then((response) => {
          document.location.reload(true);
        });
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--white);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  color: var(--white);
  font-size: 0.875em;
  font-weight: var(--semibold);
}
</style>
