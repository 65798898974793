<template>
  <form id="company-activity" @submit.prevent="submit">
    <h1 class="page-title">Create Custom Activity for Your Company</h1>

    <BaseInput
      type="text"
      name="activity[service]"
      label-text="Activity Name"
      v-model="activity.service"
    />
    <BaseInput
      type="number"
      name="activity[duration_in_minutes]"
      label-text="Duration (in minutes)"
      v-model="activity.duration"
    />
    <BaseInput
      v-if="showPayment"
      type="number"
      name="price"
      step="0.01"
      label-text="Price"
      v-model="activity.price"
    />

    <BaseCheckbox
      v-if="!activity.id"
      label-text="I can be personally booked for this service"
      name="subscription"
      v-model="personallyOffer"
    />

    <BaseSelect
      v-show="false"
      label-text="Activity Type"
      name="activity[type]"
      v-model="activity.type"
    >
      <option v-for="type in types" :key="type" :value="type">
        {{ type }}
      </option>
    </BaseSelect>

    <BaseTextArea
      rows="6"
      name="activity[description]"
      :label="false"
      v-model="activity.description"
      placeholder="Add a description for this activity..."
    />

    <BaseButton type="submit" class="space"> Save Custom Activity </BaseButton>

    <BaseButton @click="deleteActivity" class="space" v-if="activity.id">
      Delete Custom Activity
    </BaseButton>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Modal from "../base/BaseModal.vue";
import ButtonAddRemove from "../buttons/ButtonAddRemove";

export default {
  name: "CustomCompanyActivityForm",
  components: { Flex, Modal, ButtonAddRemove },
  props: {
    customActivity: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      activity: {
        id: null,
        amount: 1,
        type: "Lesson",
        service: null,
        duration: null,
        price: null,
        description: null,
      },

      types: ["Lesson", "Task"],
      personallyOffer: true,
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    showPayment() {
      let paymentSetting = this.currentUser.company_settings.filter((e) => {
        return e.name == "no_payment";
      }).length;
      return paymentSetting == 0;
    },
  },

  mounted() {
    if (this.customActivity && this.customActivity.id) {
      this.activity.id = this.customActivity.id;
    }
    if (this.customActivity && this.customActivity.activity) {
      this.activity.service = this.customActivity.activity.service;
    }
    if (this.customActivity && this.customActivity.activity) {
      this.activity.service = this.customActivity.activity.service;
    }
    if (
      this.customActivity &&
      this.customActivity.activity &&
      this.customActivity.activity.duration
    ) {
      this.activity.duration = this.customActivity.activity.duration / 60;
    }
    if (this.customActivity && this.customActivity.price) {
      this.activity.price = this.customActivity.price;
    }
    if (
      this.customActivity &&
      this.customActivity.activity &&
      this.customActivity.activity.type
    ) {
      this.activity.type = this.customActivity.activity.type;
    }
    if (
      this.customActivity &&
      this.customActivity.activity &&
      this.customActivity.activity.description
    ) {
      this.activity.description = this.customActivity.activity.description;
    }
  },

  methods: {
    submit() {
      const data = new FormData(document.getElementById("company-activity"));
      data.append("company_id", this.currentUser.company_id);

      if (!this.activity.id) {
        this.$store
          .dispatch("activity/CREATE_CUSTOM_ACTIVITY", data)
          .then((response) => {
            if (response.data && this.personallyOffer) {
              const data = {
                employee_id: this.currentUser.id,
                company_activity_id: response.data.id,
              };

              this.$store
                .dispatch(`activity/CREATE_TRAINER_ACTIVITY`, data)
                .then((response) => {});
            }
            this.$emit("create-activity-success", response.data);
          });
      } else {
        this.$store
          .dispatch("activity/EDIT_CUSTOM_ACTIVITY", {
            companyActivityId: this.activity.id,
            data: data,
          })
          .then((r) => {
            this.$emit("create-activity-success", r.data);
          });
      }
    },
    deleteActivity() {
      this.$store
        .dispatch("activity/DELETE_CUSTOM_ACTIVITY", {
          companyActivityId: this.activity.id,
        })
        .then((r) => {
          this.$emit("create-activity-success", r.data);
        });
    },
  },
};
</script>

<style scoped>
</style>