<template>
  <div>
    <Flex justify="between">
      <h1 class="title space">Invoices Sent:</h1>

      <BaseLink theme="button auto" link="/companies/invoices/edit">
        New Invioce
      </BaseLink>
    </Flex>

    <div v-for="invoice in invoices" :key="invoice.id">
      <BasePanel
        theme="active"
        type="clickable"
        @click.native="goToInvoicePage(invoice.id)"
      >
        <Flex justify="between" class="space">
          <Flex justify="start">
            <h2>Amount: ${{ invoice.amount }}</h2>

            <h2>User: {{ invoice.user.name }}</h2>
            <Flex justify="start">
              <Avatar size="small" :image-source="invoice.user.avatar" />
            </Flex>
          </Flex>
        </Flex>

        <p class="space">
          Due Date: {{ moment.utc(invoice.due_date).format("MMM DD, YYYY") }}
        </p>
      </BasePanel>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "CompanyInvoices",
  components: { Flex, Avatar },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      invoices: [],
    };
  },

  mounted() {
    setTimeout(() => this.getCompanyInvoices(), 2000);
  },

  methods: {
    getCompanyInvoices() {
      this.$store
        .dispatch("payment/GET_COMPANY_INVOICES", this.currentUser.company_id)
        .then((response) => {
          this.invoices = response.data;
        });
    },

    goToInvoicePage(id) {
      this.$router.push({ name: "edit-invoice", params: { invoiceId: id } });
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 1.35em;
  font-weight: var(--semibold);
}
</style>
