import axios from "axios";

import { GET_BARN_BOARD_INFO } from "../actions/barnBoard";

// Initial State
const state = {
  companiesBoards: {},
};

// Getters
const getters = {
  companiesBoards: (state) => state.companiesBoards,
};

// Actions
const actions = {
  GET_BARN_BOARD_INFO({ commit }, actionParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/barn_boards", {
          params: {
            company_id: actionParams.companyId,
            start_time: actionParams.startDate.utc().startOf("week").format(),
          },
        })
        .then((response) => {
          commit("setBarnData", {
            company_id: actionParams.companyId,
            start_time: actionParams.startDate.format("YYYY-MM-DD"),
            response: response.data,
          });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {
  setBarnData(state, mutationParams) {
    if (!state.companiesBoards[mutationParams.company_id]) {
      state.companiesBoards[mutationParams.company_id] = [];
    }
    if (
      !state.companiesBoards[mutationParams.company_id][
        mutationParams.start_time
      ]
    ) {
      state.companiesBoards[mutationParams.company_id][
        mutationParams.start_time
      ] = mutationParams.response;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
