import axios from "axios";
import { SEARCH_TRAINERS, LOCATION_TRAINERS } from "../actions/trainer";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  SEARCH_TRAINERS({ commit }, searchQuery) {
    return new Promise((resolve, reject) => {
      axios
        .get("/trainers", { params: { query: searchQuery } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },

  LOCATION_TRAINERS({ commit }, locationId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/location_trainers", { params: { id: locationId } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
