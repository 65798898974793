<template>
  <div>
    <h1>Payment Details</h1>

    <div>
      <p class="title">Billed By: {{ billingCompany.name }}</p>

      <Flex justify="start">
        <Cover :image-source="coverImage" cover-type="company" />
      </Flex>

      <h3 class="title">Invoice #{{ payment.id }} Details</h3>
      <Flex justify="start">
        <div>
          <p class="details">{{ payment.description }}</p>
          <p class="details">{{ convertPrice(payment.amount) }}</p>
          <p class="details">Billed: {{ convertTime(payment.created) }}</p>
        </div>
      </Flex>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cover from "../utils/Cover";
import Flex from "../style/Flex";

export default {
  name: "PaymentDetails",
  components: { Cover, Flex },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),

    coverImage() {
      if (this.billingCompany) {
        return this.billingCompany.avatar;
      }
    },
  },

  data() {
    return {
      payment: {},
      billingCompany: {},
    };
  },

  mounted() {
    setTimeout(() => this.getPaymentdetails(), 2000);
  },

  methods: {
    getPaymentdetails() {
      const params = {
        user_id: this.currentUser.id,
        charge_id: this.$route.params.id,
      };
      this.$store
        .dispatch("payment/GET_USER_STRIPE_CHARGE", params)
        .then((response) => {
          this.payment = response.data;
          this.getBillingCompany(this.payment.destination);
        });
    },

    getBillingCompany(stripeAccountId) {
      this.$store
        .dispatch("payment/GET_BILLING_COMPANY", stripeAccountId)
        .then((response) => {
          this.billingCompany = response.data;
        });
    },

    convertPrice(price) {
      return `$${parseInt(price) / 100}`;
    },

    convertTime(timestamp) {
      return this.moment.unix(timestamp).format("MMMM DD, YYYY");
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin: 1em;
}
.title {
  font-size: 1.75em;
  font-weight: var(--semibold);
  color: var(--worker-accent-color);
  margin: 0.5em;
}

.details {
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin: 0.5em;
}
</style>