
<template>
  <article>
    <BasePanel type="active" v-bind:class="{ preferred: isPreferred }">
      <Flex justify="between">
        <Flex justify="start" v-if="horse">
          <Avatar
            :image-source="horse.avatar"
            :avatar-type="'horse'"
            class="horse-avatar"
          />
          <div>
            <h1>
              {{ horse.usef_name }}
              <span v-if="horse.nickname != ''">({{ horse.nickname }})</span>
            </h1>
            <p v-if="horse.location">Location: {{ horse.location.name }}</p>
            <p v-if="horse.trailedIn">Trailed in by rider</p>
            <p v-if="numberOfActivities">
              {{ numberOfActivities }} Other Activities
            </p>
          </div>
        </Flex>
        <div v-else>
          <Flex
            justify="start"
            class="select-message"
            v-if="currentUser.account_type === 'personal'"
          >
            <h1>Let trainer select available horse</h1>
          </Flex>
          <Flex justify="start" class="select-message" v-else>
            <h1>Select a horse</h1>
          </Flex>
        </div>

        <slot />
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "TrainerReviewHorsePanel",
  props: { horse: Object, numberOfActivities: Number },
  components: { Flex, Avatar },
  data() {
    return {
      eventsInday: 0,
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    isPreferred() {
      if (this.horse) {
        return this.horse.isPreferred;
      }
      return false;
    },
  },
};
</script>

<style scoped>
h1 {
  color: var(--black);
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  color: var(--black);
  font-size: 0.875em;
  font-weight: var(--semibold);
}

.select-message {
  color: #3f3f3f;
}

.horse-avatar {
  min-width: 65px;
}

.preferred {
  background-color: var(--fog) !important;
}
</style>
