<template>
  <div>
    <h1>Search</h1>
    <BaseInput
      label-text="Search for Users, Events, Horses, and Barns"
      v-model="searchQuery"
      v-debounce:500ms="search"
    />

    <div v-for="(result, index) in results" :key="result.id + index * 100">
      <BasePanel type="clickable" @click.native="goToResultPage(result)">
        <Flex justify="between">
          <Flex justify="start">
            <!-- if result is horse -->
            <template v-if="result.usef_name">
              <Avatar :image-source="result.avatar" avatar-type="horse" />
              <div>
                <p class="type">Horse</p>
                <h2>{{ result.usef_name }}({{ result.nickname }})</h2>
              </div>
            </template>

            <!-- if result is event -->
            <template v-else-if="result.title">
              <Avatar :image-source="result.avatar" avatar-type="event" />
              <div>
                <p class="type">Event</p>
                <h2>{{ result.title }}</h2>
                {{
                  moment.utc(result.start_time).format("MMM DD, YYYY hh:mm a")
                }}
              </div>
            </template>

            <!-- if result is user-->
            <template v-else-if="result.account_type">
              <Avatar :image-source="result.avatar" avatar-type="user" />
              <div>
                <p class="type">User</p>
                {{ result.name }}
              </div>
            </template>

            <!-- result is barn -->
            <template v-else class="barn-card">
              <Avatar :image-source="result.avatar" avatar-type="company" />
              <div>
                <p class="type">Barn</p>
                {{ result.name }}
              </div>
              <div
                class="add-barn-button"
                v-if="
                  currentUser.account_type == 'personal' &&
                  !riderLocations.some((r) => r.location.id == result.id)
                "
              >
                <BaseButton
                  @click="
                    addToLocations({
                      user_id: currentUser.id,
                      location_id: result.id,
                    })
                  "
                  >ADD +</BaseButton
                >
              </div>
            </template>
          </Flex>
        </Flex>
      </BasePanel>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "PageSearch",
  components: { Flex, Avatar },
  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  data() {
    return {
      searchQuery: "",
      results: [],
      riderLocations: [],
      isAddClicked: false,
    };
  },

  mounted() {
    if (this.currentUser.account_type == "personal") {
      this.getUserBarns(this.currentUser.id);
    }
  },

  methods: {
    search() {
      if (this.searchQuery && this.searchQuery.length > 1) {
        this.$store
          .dispatch("global/GLOBAL_SEARCH", {
            query: this.searchQuery,
            limit: 15,
          })
          .then((response) => {
            this.results = response.data;
          });
      }
    },
    getUserBarns(userId) {
      this.$store
        .dispatch("location/GET_USER_LOCATIONS", userId)
        .then((response) => {
          this.riderLocations = response.data;
        });
    },
    goToResultPage(result) {
      if (result.usef_name) {
        this.navigateAway("horse", result.id);
      } else if (result.title) {
        this.navigateAway("event", result.id);
      } else if (result.account_type) {
        this.navigateAway("user", result.id);
      } else {
        if (!this.isAddClicked) {
          this.navigateAway("barn", result.id);
        }
      }
    },

    addToLocations(param) {
      this.isAddClicked = true;
      this.$store
        .dispatch("location/CREATE_USER_LOCATION", param)
        .then((response) => {
          this.$router.push("/register/locations");
        });
    },

    navigateAway(type, id) {
      this.$router.push({ name: `${type}-profile`, params: { id } });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  text-transform: uppercase;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}

.type {
  color: var(--dark-grey);
  font-size: 0.85em;
  padding-bottom: 0.15em;
}

.barn-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
