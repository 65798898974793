<!-- We'll come back to this later  -->
<!-- https://medium.com/@denny.headrick/pagination-in-vue-js-4bfce47e573b -->
<template>
  <div>
    <template v-for="page in pages">
      <div :key="page"></div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "BasePaginator",
  props: ["type"],
};
</script>

<style scoped>
</style>
