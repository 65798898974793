<template>
  <div>
    <div v-if="employerLocations.length > 0">
      <p class="space">
        Please select all of the services you would like to offer. These should
        be selected based on location.
      </p>

      <form id="profile">
        <h2 class="section-title">Switch Location</h2>
        <BaseSelect
          v-model="selectedLocationId"
          :label="false"
          @change.native="getTrainerScheduleForLocation()"
        >
          <optgroup
            v-for="employer in employerLocations"
            :key="employer.id"
            :label="employer.name"
          >
            <option
              v-for="location in employer.locations"
              :key="location.id"
              :value="location.id"
            >
              {{ location.name }}
            </option>
          </optgroup>
        </BaseSelect>
        <Schedule :schedule="currentSchedule" />

        <BaseButton type="button" @click="goToDashboard()"
          >Finish Profile</BaseButton
        >
      </form>
    </div>
    <div v-else class="no-locations">
      Join a company with locations as an employee to set your availability
    </div>
  </div>
</template>

<script>
import Flex from "../../style/Flex";
import Schedule from "../../trainers/Schedule";

export default {
  name: "Availability",
  title: null,
  components: { Flex, Schedule },
  props: {
    currentIndex: Number,
    steps: Array,
  },

  computed: {
    allLocations() {
      if (this.employerLocations) {
        const nestedLocations = this.employerLocations.map((e) => e.locations);
        // this flattens the nested array
        return nestedLocations.reduce((a, b) => a.concat(b), []);
      }
    },

    selectedLocation() {
      if (this.allLocations && this.selectedLocationId) {
        return this.allLocations.find((l) => l.id == this.selectedLocationId);
      } else {
        return {};
      }
    },
  },

  data() {
    return {
      currentUser: {},
      employerLocations: [],
      selectedLocationId: null,
      currentSchedule: {},
    };
  },

  async mounted() {
    this.$store.commit("global/START_LOADING");
    await this.getEmployerLocations();
    this.$store.commit("global/STOP_LOADING");
  },

  methods: {
    async getEmployerLocations() {
      const user = await this.$store.dispatch("user/GET_USER_FROM_TOKEN");
      this.currentUser = user.data;

      await this.$store
        .dispatch("location/GET_EMPLOYER_LOCATIONS", this.currentUser.id)
        .then(
          await (async (response) => {
            this.employerLocations = response.data;
            if (
              this.employerLocations[0] &&
              this.employerLocations[0].locations.length
            ) {
              this.selectedLocationId =
                this.employerLocations[0].locations[0].id;
            }
            if (this.selectedLocationId)
              await this.getTrainerScheduleForLocation();
          })
        );
    },

    async getTrainerScheduleForLocation() {
      const data = {
        scheduler_id: this.currentUser.id,
        location_id: this.selectedLocationId,
      };
      await this.$store
        .dispatch("schedule/GET_TRAINER_SCHEDULE", data)
        .then((response) => {
          this.currentSchedule = response.data;
        });
    },

    goToDashboard() {
      const data = new FormData();
      data.append("registration_finished", true);
      const userParams = {
        id: this.currentUser.id,
        user: data,
        finished: true,
      };

      this.$store.dispatch("user/UPDATE_USER", userParams).then((response) => {
        this.$router.push("/dashboard");
      });
    },
  },
};
</script>

<style scoped>
.no-locations {
  text-align: center;
}
</style>