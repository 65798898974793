<template>
  <div class="panel" :class="[theme, { inline: notFullWidth }]">
    <div class="content" :class="{ full: !notFullWidth }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BasePanel",
  props: {
    theme: String,
    notFullWidth: Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.panel {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  margin-bottom: 1.25em;
  padding: 0.75em;
}
.active {
  background-color: var(--input-accent-color);
  color: var(--company-accent-color);
}
.worker {
  background-color: var(--worker-accent-color);
  color: var(--white);
}
.company {
  background-color: var(--company-accent-color);
  color: var(--white);
}
.auto {
  display: inline-flex;
}
.clickable {
  cursor: pointer;
}
.date {
  flex: 0 0 0;
  padding: 0;
}
.calendar {
  flex: 1 0 auto;
  padding: 0;
  /* position: relative; */
}
/* Disabled because this will break on calendars with date box prefixes */
/* .calendar:before { */
/* background-color: #F5A623; */
/* content: ""; */
/* display: block; */
/* height: 100%; */
/* left: 0; */
/* position: absolute; */
/* top: 0; */
/* width: 5px; */
/* } */
/* .calendar:nth-child(5n+2):before { */
/* background-color: #D0021B; */
/* } */
/* .calendar:nth-child(5n+3):before { */
/* background-color: #7ED321; */
/* } */
/* .calendar:nth-child(5n+4):before { */
/* background-color: #F8E71C; */
/* } */
/* .calendar:nth-child(5n+5):before { */
/* background-color: #4A90E2; */
/* } */
.image {
  background-color: var(--worker-accent-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: var(--white);
  padding: 1.4em;
  position: relative;
}
.image:before {
  background: linear-gradient(
    to bottom,
    rgba(5, 28, 45, 1) 20%,
    rgba(216, 216, 216, 0) 150%
  );
  content: "";
  opacity: 0.8;
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.content {
  position: relative;
  z-index: 1;
}

.full {
  width: 100%;
}

.inline {
  display: inline-block;
}
</style>
