<template>
  <article class="activity-panel">
    <h1 class="title space">Activity Update Request</h1>

    <div>
      <BasePanel theme="active" v-if="userActivity.employee_activity">
        <h2 class="space">Request Details</h2>

        <Flex justify="start" class="space">
          <h2>Trainer:</h2>
          <Flex justify="start">
            <Avatar
              size="large"
              :image-source="userActivity.employee_activity.employee.avatar"
            />
            <div>
              <p class="name">
                {{ userActivity.employee_activity.employee.name }}
              </p>
            </div>
          </Flex>
        </Flex>

        <p class="location space">
          Location:
          {{ userActivity.location_name }}
        </p>

        <div class="location space" v-if="userActivity.horse_id">
          Horse:
          <p>{{ userActivity.horse }}</p>
        </div>

        <p class="lesson-name space">
          {{ userActivity.employee_activity.activity.service }}
          ({{ userActivity.employee_activity.activity.duration_in_minutes }}) -
          ${{ userActivity.employee_activity.price }}
        </p>

        <div class="lesson-time space">
          <h2>Scheduled Date and Time:</h2>

          <Flex justify="between" class="space">
            <p>
              {{
                moment.utc(userActivity.time_block.start_time).format(
                  "MMM DD, YYYY hh:mm a"
                )
              }}
              -
              {{ moment.utc(userActivity.time_block.end_time).format("hh:mm a") }}
            </p>
          </Flex>
        </div>

        <Flex justify="start" direction="column" class="space">
          <BaseButton
            class="space"
            type="button"
            theme="company"
            @click="acceptRequest()"
          >
            Looks Good! Confirm
            <font-awesome-icon :icon="['fas', 'thumbs-up']" class="thumbs" />
          </BaseButton>

          <BaseButton type="button" theme="alternate" @click="denyRequest()">
            Decline
            <font-awesome-icon :icon="['fas', 'thumbs-down']" class="thumbs" />
          </BaseButton>
        </Flex>
      </BasePanel>
    </div>
  </article>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "UserActivityRequest",
  components: { Flex, Avatar },

  data() {
    return {
      userActivity: {},
    };
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  async mounted() {
    await this.correctUserIsSignedIn()
  },

  methods: {
    async correctUserIsSignedIn() {
      if (this.$route.params.userId != this.currentUser.id) {
        await this.$store.commit(
          "global/GLOBAL_ERROR",
          "You are trying to view someone else's data",
          { root: true }
        );
        this.$router.push("/");
        return;
      } else {
        this.findUserActivity();
      }
    },

    findUserActivity() {
      this.$store
        .dispatch(
          "activity/GET_USER_ACTIVITY",
          this.$route.params.userActivityId
        )
        .then((response) => {
          this.userActivity = response.data;
        });
    },

    acceptRequest() {
      const params = {
        status: "accepted",
        id: this.userActivity.id,
        accepted_by: "rider",
      };

      this.$store
        .dispatch("activity/ACCEPT_USER_ACTIVITY", params)
        .then((response) => {
          this.$router.push({
            name: "user-activity-requests",
            params: { id: this.userActivity.participant_id },
          });
        });
    },

    denyRequest() {
      this.$store
        .dispatch("activity/RIDER_DECLINE_USER_ACTIVITY", this.userActivity.id)
        .then((response) => {
          this.$router.push({
            name: "user-activity-requests",
            params: { id: this.userActivity.participant_id },
          });
        });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
}

.title {
  font-size: 1.35em;
  font-weight: var(--semibold);
}

.thumbs {
  font-size: 0.75em;
  padding: 0.25em;
  margin-left: 1em;
}
</style>
