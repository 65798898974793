export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_COMPANY_ACTIVITIES = "GET_COMPANY_ACTIVITIES";
export const GET_TRAINER_ACTIVITIES = "GET_TRAINER_ACTIVITIES";
export const GET_TRAINER_ACTIVITY = "GET_TRAINER_ACTIVITY";
export const CREATE_TRAINER_ACTIVITY = "CREATE_TRAINER_ACTIVITY";
export const DESTROY_TRAINER_ACTIVITY = "DESTROY_TRAINER_ACTIVITY";
export const CREATE_USER_ACTIVITY = "CREATE_USER_ACTIVITY";
export const ACCEPT_USER_ACTIVITY = "ACCEPT_USER_ACTIVITY";
export const UPDATE_USER_ACTIVITY = "UPDATE_USER_ACTIVITY";
export const GET_USER_ACTIVITIES = "GET_USER_ACTIVITIES";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const TRAINER_DECLINE_USER_ACTIVITY = "TRAINER_DECLINE_USER_ACTIVITY";
export const RIDER_DECLINE_USER_ACTIVITY = "RIDER_DECLINE_USER_ACTIVITY";
export const GET_TRAINER_USER_ACTIVITIES = "GET_TRAINER_USER_ACTIVITIES";
export const CREATE_TRAINER_USER_ACTIVITY = "CREATE_TRAINER_USER_ACTIVITY";
export const CREATE_COMPANY_ACTIVITY = "CREATE_COMPANY_ACTIVITY";
export const UPDATE_COMPANY_ACTIVITY = "UPDATE_COMPANY_ACTIVITY";
export const DELETE_COMPANY_ACTIVITY = "DELETE_COMPANY_ACTIVITY";
export const GET_COMPANY_USER_ACTIVITIES = "GET_COMPANY_USER_ACTIVITIES";
export const CREATE_CUSTOM_ACTIVITY = "CREATE_CUSTOM_ACTIVITY";
export const GET_RECENT_ACTIVITIES = "GET_RECENT_ACTIVITIES";
export const EDIT_CUSTOM_ACTIVITY = "EDIT_CUSTOM_ACTIVITY";
export const DELETE_CUSTOM_ACTIVITY = "DELETE_CUSTOM_ACTIVITY";
export const GET_UNAPPROVED_ACTIVITIES = "GET_UNAPPROVED_ACTIVITIES";
