import axios from "axios";
import {
  CREATE_ACTIVITY_PACKAGE,
  ADD_PACKAGE_MEMBERSHIP_TO_PACKAGE,
  GET_ACTIVITY_PACKAGE,
  DELETE_PACKAGE_MEMBERSHIP,
  UPDATA_PACKAGE_MEMBERSHIP_QUANTITY,
  UPDATE_ACTIVITY_PACKAGE,
  DELETE_ACTIVITY_PACKAGE,
  RETRIEVE_COMPANY_PACKAGES,
  BUY_ACTIVITY_PACKAGE,
} from "../actions/activityPackage";

// Initial State
const state = {};

// Getters
const getters = {};

const actions = {
  CREATE_ACTIVITY_PACKAGE({ commit }, createActivityPackageParams) {
    return new Promise((resolve, reject) => {
      axios
        .post("/activity_packages", createActivityPackageParams)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  ADD_PACKAGE_MEMBERSHIP_TO_PACKAGE({ commit }, addPackageParams) {
    return new Promise((resolve, reject) => {
      axios
        .post("/package_memberships", addPackageParams)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  GET_ACTIVITY_PACKAGE({ commit }, packageId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/activity_packages/" + packageId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  DELETE_PACKAGE_MEMBERSHIP({ commit }, membershipId) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/package_memberships/" + membershipId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  UPDATA_PACKAGE_MEMBERSHIP_QUANTITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put("/package_memberships/" + params.id, { quantity: params.quantity })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  UPDATE_ACTIVITY_PACKAGE({ commit }, params) {
    return new Promise((resolve, reject) => {
      let paramsWithoutId = Object.assign({}, params);
      delete paramsWithoutId.id;
      axios
        .put("/activity_packages/" + params.id, paramsWithoutId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  DELETE_ACTIVITY_PACKAGE({ commit }, packageId) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/activity_packages/" + packageId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  RETRIEVE_COMPANY_PACKAGES({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/activity_packages?company_id=" + companyId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
  BUY_ACTIVITY_PACKAGE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/user_activity_packages", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((error) => {
          commit("global/GLOBAL_ERROR", error.message, { root: true });
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
