<template>
  <Flex>
    <FaButton
      :icon="['fas', 'angle-left']"
      @click="goBack()"
      :class="getStyleClass()"
      :theme="getTheme()"
    >
      Back
    </FaButton>
  </Flex>
</template>

<script>
import { mapState } from "vuex";
import FaButton from "../buttons/ButtonFaButton.vue";
import Flex from "../style/Flex";

export default {
  name: "BackButton",
  components: { FaButton, Flex },
  props: {
    styleClass: { type: String, default: "button-fa-button" },
    theme: { type: String, default: "round alternate" },
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getStyleClass() {
      return this.styleClass;
    },

    getTheme() {
      return this.theme;
    },
  },
};
</script>
<style scoped>
button.top-left {
  color: var(--default-accent-color);
  background-color: transparent;
  position: absolute;
  margin-top: -35px;
  margin-left: -25px;
}
</style>
    