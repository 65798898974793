<template>
  <BaseButton class="button--edit" theme="round alternate">
    <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
  </BaseButton>
</template>

<script>
export default {
  name: "ButtonEdit",
};
</script>
