<template>
  <div>
    <p class="fine-print">
      * This step is optional for now, it is not required to schedule activity
      at this time. You can always add by selecting SCHEDULE then ADD ACTIVITY.
      Click below to skip and complete setup.
    </p>

    <BasePanel type="clickable" @click.native="goToActivites()">
      <Flex justify="between" direction="row">
        <BaseLink class="add-an-activity">
          {{
            activities.length > 0 ? "Book More Actvities" : "Add First Activity"
          }}
        </BaseLink>
        <ButtonAddRemove type="add" />
      </Flex>
    </BasePanel>

    <CalendarDatePanel
      v-for="activity in activities"
      :key="activity.id"
      :start-time="activity.time_block.start_time"
      :end-time="activity.time_block.end_time"
      :trainer="activity.trainer"
      :horse="activity.horse"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonAddRemove from "../../buttons/ButtonAddRemove";
import Flex from "../../style/Flex";
import CalendarDatePanel from "../../calendars/CalendarDatePanel";

export default {
  name: "Activities",
  title: null,
  components: { Flex, ButtonAddRemove, CalendarDatePanel },
  props: { steps: Array },

  data() {
    return {
      activities: [],
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  mounted() {
    setTimeout(() => this.getUserActivities(), 2000);
  },

  methods: {
    goToActivites() {
      const data = new FormData();
      data.append("registration_finished", true);
      const userParams = {
        id: this.currentUser.id,
        user: data,
        finished: true,
      };

      this.$store.dispatch("user/UPDATE_USER", userParams).then((response) => {
        this.$router.push("/activities");
      });
    },

    getUserActivities() {
      this.$store
        .dispatch("activity/GET_USER_ACTIVITIES", {
          userId: this.currentUser.id,
        })
        .then((response) => {
          this.activities = response.data;
        });
    },
  },
};
</script>

<style scoped>
h3 {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
.fine-print {
  color: var(--dark-grey);
  font-size: 0.875em;
  margin-bottom: 1em;
}
.add-an-activity {
  color: var(--border-color);
  font-size: 1.3125em;
  font-weight: var(--semibold);
  margin-bottom: 0;
  text-transform: uppercase;
}
</style>
