<template>
  <form id="company-activity" @submit.prevent="saveCompanyActivity()">
    <BaseSelect
      :label="false"
      label-text="Activity"
      v-model="selectedActivityId"
      placeholder="Select Activity"
    >
      <option
        v-for="activity in activities"
        :key="activity.id"
        :value="activity.id"
      >
        {{ activity.full_title }}
      </option>
    </BaseSelect>

    <BaseInput
      name="price"
      label-text="Price"
      v-model="companyActivity.price"
      v-if="showPayment"
    />

    <BaseCheckbox
      v-if="!companyActivity.id"
      label-text="I can be personally booked for this service"
      name="subscription"
      v-model="personallyOffer"
    />

    <Flex justify="between" class="space">
      <BaseButton type="submit">Save Company Activity</BaseButton>

      <BaseButton
        v-if="companyActivity.id"
        type="button"
        theme="alternate"
        @click="promptDeleteCompanyActivity()"
        >Delete Company Activity</BaseButton
      >
    </Flex>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex.vue";

export default {
  name: "CompanyActivityForm",
  components: { Flex },
  props: {
    companyActivity: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      activities: [],
      selectedActivityId: null,
      personallyOffer: true,
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    showPayment() {
      let paymentSetting = this.currentUser.company_settings.filter((e) => {
        return e.name == "no_payment";
      }).length;
      return paymentSetting == 0;
    },
  },

  created() {
    setTimeout(() => this.getActivities(), 2000);
  },

  methods: {
    getActivities() {
      this.$store.dispatch("activity/GET_ACTIVITIES").then((response) => {
        this.activities = response.data;

        setTimeout(() => {
          if (this.companyActivity.activity) {
            const chosenActivity = this.activities.find(
              (a) => a.id == this.companyActivity.activity.id
            );
            if (chosenActivity) this.selectedActivityId = chosenActivity.id;
          }
        }, 100);
      });
    },

    saveCompanyActivity() {
      const data = {
        activity_id: this.selectedActivityId,
        company_id: this.currentUser.company_id,
        price: this.companyActivity.price || 0,
      };

      this.companyActivity.id
        ? this.updateCompanyActivity(data)
        : this.createCompanyActivity(data);
    },

    createCompanyActivity(data) {
      this.$store
        .dispatch("activity/CREATE_COMPANY_ACTIVITY", data)
        .then((response) => {
          if (response.data && this.personallyOffer) {
            const data = {
              employee_id: this.currentUser.id,
              company_activity_id: response.data.id,
            };

            this.$store
              .dispatch(`activity/CREATE_TRAINER_ACTIVITY`, data)
              .then((response) => {});
          }
          this.$emit("save-activity-success", response.data);
        });
    },

    updateCompanyActivity(data) {
      data.companyActivityId = this.companyActivity.id;
      this.$store
        .dispatch("activity/UPDATE_COMPANY_ACTIVITY", data)
        .then((response) => {
          window.location.reload(true);
        });
    },

    promptDeleteCompanyActivity() {
      const activityDelete = confirm(
        "Do You want to delete this company activity?"
      );
      if (activityDelete) this.deleteCompanyActivity(this.companyActivity.id);
    },

    deleteCompanyActivity(id) {
      this.$store
        .dispatch("activity/DELETE_COMPANY_ACTIVITY", id)
        .then((response) => {
          window.location.reload(true);
        });
    },
  },
};
</script>