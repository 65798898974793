<template>
  <div class="services-container">
    <div class="services-heading" v-bind:style="locationImage">
      <div class="barn-name">
        {{ company.name }}
      </div>
    </div>
    <div v-if="company">
      <service-card
        v-for="service in company.available_services"
        :key="service.id"
        :service="service"
      />
    </div>
  </div>
</template>

<script>
import ServiceCard from "./ServiceCard";
export default {
  components: { ServiceCard },
  props: ["company"],
  data: function () {
    return {
      defaultLocationImage: require("../../assets/images/covers/location.jpg"),
    };
  },
  computed: {
    locationImage() {
      if (this.company && this.company.avatar) {
        return {
          "background-image": "url(" + this.company.avatar + ")",
        };
      }
      return {
        "background-image": "url(" + this.defaultLocationImage + ")",
      };
    },
  },
};
</script>

<style scoped>
.services-container {
  width: 100%;
  margin-top: 25px;
}
.services-heading {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.barn-name {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-top: 10px;
}
</style>