<template>
  <div v-if="this.currentUser.account_type != 'personal'">
    <div class="fixed-content">
      <div class="buttons-container">
        <BaseButton id="prev-button" @click="substractOneWeek"
          >Previous</BaseButton
        >
        <BaseButton id="next-button" @click="addOneWeek">Next</BaseButton>
      </div>
    </div>
    <div class="barn-board-component" v-if="startDate != null">
      <barn-board :start-date="startDate" :date-changes="dateChanges" />
    </div>
  </div>
</template>

<script>
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import BarnBoard from "./BarnBoard";
import BaseButton from "../base/BaseButton";
import { mapState } from "vuex";
var moment = require("moment");

export default {
  name: "BarnBoardPage",
  components: { ButtonAddRemove, BarnBoard, BaseButton },
  data: function () {
    return {
      startDate: moment.utc(new Date()),
      dateChanges: 0,
    };
  },
  mounted() {},
  computed: { ...mapState({ currentUser: (state) => state.user.currentUser }) },
  methods: {
    addOneWeek() {
      this.startDate = this.startDate.add("days", 7);
      this.dateChanges++;
    },
    substractOneWeek() {
      this.startDate = this.startDate.add("days", -7);
      this.dateChanges++;
    },
    goToAddingUserActivity() {
      this.$router.push("/companies/create-user-activity");
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 26px;
}
.add-activity-card {
  padding: 8px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #d8d8d8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-activity-text {
  text-transform: uppercase;
  font-size: 22px;
  color: var(--dark-grey);
  font-weight: 600;
}
.hr {
  height: 1px;
  width: 100%;
  background-color: darkgray;
  margin: 14px 0px;
}
.barn-board-component {
  padding-top: 30px;
}
.fixed-content {
  padding-top: calc(10px + env(safe-area-inset-top));
  position: fixed;
  width: calc(100vw - 30px);
  background-color: white;
  opacity: 1;
  z-index: 48;
  top: 60px;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
}
#prev-button {
  width: 35%;
  text-align: center;
}
#next-button {
  width: 35%;
  text-align: center;
}
</style>