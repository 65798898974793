<template>
  <BaseButton
    :class="getStyleClass()"
    :theme="getTheme()"
    @click="emitClick"
    :disabled="disabledState"
  >
    <font-awesome-icon :icon="getIcon()" fixed-width :class="spinClass()" />
    <slot></slot>
  </BaseButton>
</template>

<script>
export default {
  name: "ButtonFaButton",
  data() {
    return {
      spinner: false,
    };
  },
  props: {
    icon: { type: Array, default: ["fas", "edit"] },
    styleClass: { type: String, default: "button-fa-button" },
    theme: { type: String, default: "round alternate" },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabledState() {
      return this.disabled ? "disabled" : null;
    },
  },
  methods: {
    getStyleClass() {
      return this.styleClass;
    },

    getTheme() {
      return this.theme;
    },

    spinClass() {
      return this.spinner ? "fa-spin" : "";
    },
    setSpinner(value) {
      this.spinner = value;
    },
    toggleSpinner() {
      this.spinner = !this.spinner;
    },
    getIcon() {
      return this.spinner ? "spinner" : this.icon;
    },
    emitClick(event) {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
button.button-fa {
  color: var(--default-accent-color);
}
.innerText {
  font-size: 2em;
}
</style>
