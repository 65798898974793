<template>
  <div id="app" :class="paddingClass">
    <TheHeader />
    <button
      ref="errorButton"
      class="btn btn-outline-danger"
      v-on:click="
        $toast.error(lastError, 'Error', notificationSystem.options.error)
      "
    ></button
    >&nbsp;
    <TheActionMenu v-if="!isHomePage && Object.keys(currentUser).length > 0" />
    <router-view></router-view>
    <loading-modal v-if="global.loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TheHeader from "./global/TheHeader";
import TheActionMenu from "./global/TheActionMenu";
import LoadingModal from "../components/utils/LoadingModal";

export default {
  name: "App",
  components: {
    TheHeader,
    TheActionMenu,
    LoadingModal,
  },

  data: function () {
    return {
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Ok</button>",
                function (instance, toast) {
                  alert("Hello world!");
                },
                true,
              ],
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                    {
                      transitionOut: "fadeOutUp",
                      onClosing: function (instance, toast, closedBy) {
                        console.info("closedBy: " + closedBy);
                      },
                    },
                    toast,
                    "buttonName"
                  );
                },
              ],
            ],
            onOpening: function (instance, toast) {
              console.info("callback abriu!");
            },
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            },
          },
          ballon: {
            balloon: true,
            position: "bottomCenter",
          },
          info: {
            position: "bottomLeft",
          },
          success: {
            position: "bottomRight",
          },
          warning: {
            position: "topLeft",
          },
          error: {
            position: "topRight",
          },
          question: {
            timeout: 20000,
            close: false,
            overlay: true,
            toastOnce: true,
            id: "question",
            zindex: 999,
            position: "center",
            buttons: [
              [
                "<button><b>YES</b></button>",
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "button");
                },
                true,
              ],
              [
                "<button>NO</button>",
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "button");
                },
              ],
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("Closing | closedBy: " + closedBy);
            },
            onClosed: function (instance, toast, closedBy) {
              console.info("Closed | closedBy: " + closedBy);
            },
          },
        },
      },
      lastError: "test",
    };
  },

  methods: {
    showError(message) {},
  },

  computed: {
    ...mapState(["global"]),
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    isHomePage() {
      return this.$route.name === "home";
    },
    paddingClass() {
      if (this.isHomePage) return "home";
    },
  },

  mounted() {},

  created: function () {
    this.$http.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response;
      },
      (error) => {
        this.$store.commit("global/STOP_LOADING");

        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          this.lastError = error.response.data.error;
          this.$refs.errorButton.click();
        } else if (error.response) {
          this.lastError = error.response.statusText;
          this.$refs.errorButton.click();
        }

        if (error.response.status == 401 || error.response.status == 400) {
          // if you ever get an unauthorized, log the user out and set error
          this.$store.dispatch("user/LOGOUT");
          this.$store.commit(
            "global/GLOBAL_ERROR",
            "You do not have access to this section of the app. Perhaps you need to sign back in.",
            { root: true }
          );
          this.$router.push("/login");
        }

        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base-color);
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  min-height: 100%;
  padding: 5em 1em 5em 1em;
  padding-top: calc(5em + env(safe-area-inset-top)); /* iPhone X */
  padding-left: calc(1em + env(safe-area-inset-left)); /* iPhone X */
  padding-right: calc(1em + env(safe-area-inset-right)); /* iPhone X */
  padding-bottom: calc(6em + env(safe-area-inset-bottom)); /* iPhone X */
}
#app.home {
  height: 100%;
  padding: 0;
  padding-top: env(safe-area-inset-top); /* iPhone X */
  padding-left: env(safe-area-inset-left); /* iPhone X */
  padding-right: env(safe-area-inset-right); /* iPhone X */
  padding-bottom: env(safe-area-inset-bottom); /* iPhone X */
}

.iziToast-capsule {
  margin-top: 15%;
  z-index: 5000;
}

.iziToast-capsule:not(:first-child) {
  margin-top: 0px !important;
}
</style>
