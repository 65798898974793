<template>
  <BaseButton class="button--edit" theme="round" @click="emitClick">
    <font-awesome-icon :icon="['fas', 'undo-alt']" fixed-width />
  </BaseButton>
</template>

<script>
export default {
  name: "ButtonRedo",
  props: {
    type: { type: String, default: "add" },
  },
  methods: {
    emitClick(event) {
      this.$emit("click", event.target.value);
    },
  },
};
</script>

<style scoped>
</style>
