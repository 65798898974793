<template>
  <form id="event-form" @submit.prevent="submit">
    <h1 class="page-title">{{ `${invoiceId ? "Edit" : "Create"} Invoice` }}</h1>

    <div class="space--large">
      <h3 class="title">Bill to:</h3>

      <div v-if="selectedUser.id">
        <Flex justify="start">
          <Avatar :image-source="selectedUser.avatar" size="small" />
          <p class="user-name">{{ selectedUser.name }}</p>
        </Flex>
      </div>

      <BaseInput
        name="user_name"
        label-text="Search for user:"
        v-model="searchQuery"
        @input="searchUsers"
      />

      <BasePanel
        theme="calendar"
        v-if="users.length"
        v-for="user in users"
        :key="user.id"
      >
        <Flex justify="between" class="user-box">
          <Flex justify="start">
            <Avatar :image-source="user.avatar" size="small" />
            <p class="user-name">{{ user.name }}</p>
          </Flex>

          <BaseButton theme="auto" @click="selectUser(user)"
            >Select User</BaseButton
          >
        </Flex>
      </BasePanel>
    </div>

    <div v-if="activityTypes.length">
      <h3 class="title">Activity Type:</h3>

      <BaseSelect
        name="activity_type"
        :label="false"
        label-text="Activity Type"
        v-model="selectedActivityId"
      >
        <option
          v-for="activityType in activityTypes"
          :key="activityType.id"
          :value="activityType.id"
        >
          {{ activityType.full_title }}
        </option>
      </BaseSelect>
    </div>

    <div class="space--large">
      <h3 class="title">Payment Due Date:</h3>

      <div class="input input--without-label">
        <datetime
          id="due_date"
          zone="UTC"
          type="date"
          name="due_date"
          v-model="invoice.due_date"
        ></datetime>
      </div>
    </div>

    <div class="space--large">
      <h3 class="title">Special Notes:</h3>

      <BaseTextArea
        name="notes"
        :label="false"
        label-text="Special Notes"
        rows="6"
        v-model="invoice.notes"
      />
    </div>

    <div class="space--large">
      <h3 class="title">Service Cost:</h3>

      <BaseInput
        name="cost"
        type="number"
        step="0.01"
        :label="false"
        label-text="Service Cost:"
        v-model="invoice.amount"
      />
    </div>

    <div class="space--large" v-if="invoice.amount">
      <p class="title">{{ `Total: $${invoice.amount}` }}</p>
    </div>

    <BaseCheckbox
      label-text="Allow recurring payments"
      name="subscription"
      v-model="invoice.subscription_allowed"
    />

    <BaseButton type="submit">
      {{ `${invoiceId ? "Edit" : "Create"} Invoice` }}
    </BaseButton>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "InvoiceForm",
  components: { Flex, Avatar },
  props: {
    invoiceId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      invoice: {
        due_date: this.moment.utc().format(),
      },
      activityTypes: [],
      selectedUser: {},
      selectedActivityId: undefined,
      users: [],
      searchQuery: "",
    };
  },

  mounted() {
    this.getActivityTypes();
    setTimeout(() => this.getInvoice(), 2000);
  },

  methods: {
    submit() {
      const params = {
        company_id: this.currentUser.company_id,
        activity_id: this.selectedActivityId,
        user_id: this.selectedUser.id,
        amount: this.invoice.amount,
        due_date: this.moment
          .utc(this.invoice.due_date)
          .format(this.moment.HTML5_FMT.DATE),
        notes: this.invoice.notes,
        subscription_allowed: this.invoice.subscription_allowed,
      };

      this.invoice.id ? this.updateInvoice(params) : this.createInvoice(params);
    },

    createInvoice(params) {
      this.$store
        .dispatch("payment/CREATE_INVOICE", params)
        .then((response) => {
          this.$router.push("/companies/invoices");
        });
    },

    updateInvoice(params) {
      this.$store
        .dispatch("payment/UPDATE_INVOICE", {
          id: this.invoice.id,
          data: params,
        })
        .then((response) => {
          this.$router.push("/companies/invoices");
        });
    },

    getInvoice() {
      if (this.invoiceId) {
        const params = {
          id: this.invoiceId,
          company_id: this.currentUser.company_id,
        };
        this.$store
          .dispatch("payment/GET_COMPANY_INVOICE", params)
          .then((response) => {
            this.invoice = response.data;
            this.selectUser = this.invoice.user;
            this.selectedActivityId = this.invoice.activity.id;
          });
      }
    },

    getActivityTypes() {
      this.$store.dispatch("activity/GET_ACTIVITIES").then((response) => {
        this.activityTypes = response.data;
      });
    },

    searchUsers() {
      this.$store
        .dispatch("user/SEARCH_USERS", this.searchQuery)
        .then((response) => {
          this.users = response.data;
        });
    },

    selectUser(user) {
      this.selectedUser = user;
      this.users = [];
      this.searchQuery = "";
    },
  },
};
</script>

<style scoped>
.title {
  color: var(--navy);
  font-size: 1.5em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.user-box {
  font-size: 1em;
  margin: 0.5em;
  padding: 1em;
}
.user-name {
  color: var(--navy);
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin-top: 0.25em;
}
</style>