import axios from "axios";
import {
  GET_TRAINER_SCHEDULE,
  GET_TRAINER_AVAILABILITY,
  CREATE_SCHEDULE_CHUNKS,
  GET_USER_INVOICES,
} from "../actions/schedule";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  GET_TRAINER_SCHEDULE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/trainer_schedules/${params.location_id}`, { params: params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_TRAINER_AVAILABILITY({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/schedule_chunks", { params: params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  CREATE_SCHEDULE_CHUNKS({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/schedule_chunks", { schedule: params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_USER_INVOICES({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user_invoices/${userId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
