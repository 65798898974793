<template>
  <div class="unused-package-card">
    <div class="active-credit-card-header">
      <div class="package-name">{{ activityCreditName }}</div>
      <span class="price"> ${{ formatPrice(price) }} </span>
    </div>
    <div class="usability-box">
      <div class="usability-box-title">Includes:</div>
      <span class="usability-item" v-for="item in activities" :key="item.name"
        >{{ item.quantity }} x {{ item.name }}</span
      >
    </div>
    <div class="buttons">
      <div class="btn-cta" @click="editPackage">
        <font-awesome-icon class="edit-icon" :icon="['fas', 'edit']" />
      </div>
      <div class="btn-question" @click="showInfo">
        <font-awesome-icon class="question-icon" :icon="['fas', 'question']" />
      </div>
    </div>
    <base-modal ref="modal">
      <div>
        Used for:
        <ul>
          <li v-for="item in filteredCompanyActivities" :key="item">
            {{ item }}
          </li>
        </ul>
      </div>
    </base-modal>
  </div>
</template>

<script>
import BaseModal from "../base/BaseModal";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  name: "EditActivityPackageCard",
  props: [
    "activitycreditname",
    "usedfor",
    "price",
    "packageId",
    "companyActivities",
  ],
  components: { BaseModal },
  computed: {
    activityCreditName() {
      return this.activitycreditname;
    },
    activities() {
      return this.usedfor;
    },
    filteredCompanyActivities() {
      return this.companyActivities
        .flat(1)
        .filter((r) => r)
        .filter(onlyUnique);
    },
  },
  data: function () {
    return {
      quantity: undefined,
    };
  },
  mounted() {
    this.quantity = this.propquantity;
  },
  methods: {
    editPackage() {
      this.$router.push("/activity-package/" + this.packageId);
      this.$router.go();
    },
    showInfo() {
      this.$refs.modal.openModal();
    },
    formatPrice(price) {
      let splited = price.split(".");
      if (splited[1].length == 1) {
        return price + "0";
      }
      return price;
    },
  },
};
</script>

<style scoped>
.unused-package-card {
  width: 100%;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin: 15px 0px;
  position: relative;
}

.package-name {
  max-width: 80%;
  color: rgb(119, 118, 118);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.price {
  color: #9b9b9b;
}

.usability-box-title {
  display: inline-block;
  color: #9b9b9b;
  font-size: 18px;
  font-weight: 600;
}
.usability-item {
  color: #9b9b9b;
  font-size: 15px;
  padding: 2px;
  font-weight: 400;
  display: block;
}

.sign {
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-55%);
  position: relative;
}

.btn-cta,
.btn-question {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  color: #64c8c1;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  margin-right: 5px;
  position: relative;
}

.active-credit-card-header {
  margin-bottom: 15px;
}
/* .usability-box{
  display: flex;
  flex-direction: column;
} */
.edit-icon,
.question-icon {
  font-size: 18px;
  color: #9b9b9b;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.buttons {
  width: 50px;
  float: right;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}
</style>