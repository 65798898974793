<template>
  <span class="checkbox">
    <input
      :id="name"
      :name="name"
      type="checkbox"
      :checked="value"
      :value="value"
      @input="emitChange"
    />
    <label :for="name" v-visible="label">
      {{ labelText }}
    </label>
  </span>
</template>

<script>
export default {
  name: "BaseCheckbox",
  props: {
    label: {
      type: Boolean,
      default: true,
    },
    labelText: String,
    name: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitChange(event) {
      // This won't work with named values
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style scoped>
</style>
