// Initial State
const state = {
  enabled: true,
};

// Getters
const getters = {};

// Actions
const actions = {
  enableTheActionMenu({ commit }) {
    commit("enableTheActionMenu");
  },
  disableTheActionMenu({ commit }) {
    commit("disableTheActionMenu");
  },
};

// Mutations
const mutations = {
  enableTheActionMenu(state) {
    state.enabled = true;
  },
  disableTheActionMenu(state) {
    state.enabled = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
