<template>
  <div class="single-service-container" v-if="service">
    <div class="info">
      <div class="service-name">
        {{ service.activity.service }}
      </div>
      <div class="service-type">
        <span>
          <div class="bullet"></div>
          {{ service.activity.duration_in_minutes }}
        </span>
      </div>
    </div>
    <div class="cal-button" @click="selectService">
      <button class="btn-cal">
        <font-awesome-icon :icon="['far', 'calendar-alt']" fixed-width />
      </button>
    </div>
    <user-profile-select-location-modal
      :locations="locations"
      v-if="showLocationsModal"
      @setlocationtonull="setLocationToNull"
      @selectlocationfrommodal="selectLocationFromModal"
    />
  </div>
</template>

<script>
import UserProfileSelectLocationModal from "./UserProfileSelectLocationModal";

export default {
  props: ["service"],
  components: { UserProfileSelectLocationModal },
  data: function () {
    return {
      locations: [],
      showLocationsModal: false,
      selectedLocation: null,
    };
  },
  methods: {
    setLocationToNull() {
      this.selectedLocation = null;
      this.showLocationsModal = false;
    },
    goToBooking(locationId) {
      this.$router.push({
        path: "/activities",
        query: {
          locationId: locationId,
          employeeActivityId: this.service.company_activity_id,
          trainerId: this.service.employee_id,
        },
      });
    },
    selectLocationFromModal(e) {
      this.selectedLocationId = e.id;
      this.showLocationsModal = false;
      this.goToBooking(this.selectedLocationId);
    },
    async selectService() {
      this.$store
        .dispatch(
          "company/GET_COMPANY",
          this.service.company_activity_company_id
        )
        .then((r) => {
          let company = r.data;
          if (company && company.locations) {
            if (company.locations.length == 1) {
              this.goToBooking(company.locations[0].id);
            } else if (company.locations.length > 1) {
              this.locations = company.locations;
              this.showLocationsModal = true;
            } else {
              alert("Error no company location");
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.btn-cal {
  background-color: #4ac1ba;
  border-radius: 700px;
  padding: 1em 1em;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  float: right;
  right: 8%;
  color: #ffffff;
  border: 1px solid #bcbcbc;
  font-size: 100%;
  margin-left: 10px;
}
.single-service-container {
  margin-bottom: 10px;
  height: 80px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  position: relative;
}
.bullet {
  display: inline-block;
  height: 9px;
  width: 9px;
  top: -2px;
  background-color: var(--grey);
  border-radius: 50%;
  margin-right: 6px;
  position: relative;
}
.info {
  height: 100%;
  width: 78%;
  display: inline-block;
  position: absolute;
  top: 0;
}
.cal-button {
  width: 18%;
  height: 100%;
  position: absolute;
  left: 80%;
  display: inline-block;
}
.service-name {
  font-size: 19px;
  color: gray;
  font-weight: 600;
  margin-left: 15px;
  margin-top: 15px;
}
.service-type {
  font-size: 16px;
  color: gray;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 15px;
}
</style>