<template>
  <Flex justify="start">
    <div v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <Avatar :image-source="imageSource" :avatar-type="avatarType" />
    <div>
      <label class="button" for="avatar">Upload Photo</label>
      <input
        class="hide"
        id="avatar"
        type="file"
        accept="image/*"
        :name="name"
        @change="processImage"
      />
    </div>

    <BaseButton
      v-if="imageSource"
      type="button"
      theme="alternate round"
      @click="emitDeletePhoto()"
    >
      <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
    </BaseButton>
  </Flex>
</template>

<script>
import Flex from "../style/Flex";
import Avatar from "./Avatar.vue";

export default {
  name: "AvatarUpload",
  components: { Flex, Avatar },
  props: {
    name: String,
    avatarType: String,
    avatar: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      newAvatar: "",
      extensionsWhitelist: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "image/bmp",
      ],
      errorMessage: "",
    };
  },

  computed: {
    imageSource() {
      return this.newAvatar ? this.newAvatar : this.avatar;
    },
  },

  methods: {
    isValidImageType(image) {
      if (this.extensionsWhitelist.includes(image.type)) {
        this.errorMessage = "";
        return true;
      } else {
        this.errorMessage =
          "The file you added is not of the correct type. Please try another.";
        return false;
      }
    },

    processImage(event) {
      const image = event.target.files[0];
      if (!this.isValidImageType(image)) return;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      const result = (reader.onload = () => (this.newAvatar = reader.result));
    },

    emitDeletePhoto() {
      this.$emit("delete-photo-request");
    },
  },
};
</script>

<style scoped>
.avatar {
  margin-bottom: 1.25em;
}
.button {
  background-color: var(--current-accent-color);
  border-radius: 100px;
  color: white;
  cursor: pointer;
  display: block;
  margin-bottom: 0.5em;
  padding: 0.875em 1.875em;
  text-align: center;
  width: 100%;
}
.hide {
  position: absolute;
  visibility: hidden;
}
</style>
