// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from "vue";
import Vuex from "vuex";

import theActionMenu from "./modules/theActionMenu";
import theSidebar from "./modules/theSidebar";
import user from "./modules/user";
import horse from "./modules/horse";
import global from "./modules/global";
import location from "./modules/location";
import trainer from "./modules/trainer";
import activity from "./modules/activity";
import company from "./modules/company";
import schedule from "./modules/schedule";
import payment from "./modules/payment";
import event from "./modules/event";
import eventRegistration from "./modules/eventRegistration";
import activityCredit from "./modules/activityCredit";
import activityPackage from "./modules/activityPackage";
import barnBoard from "./modules/barnBoard";

Vue.use(Vuex);

// Disable strict mode for now, because is diallows the use of v-model on form fields
// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    theActionMenu,
    theSidebar,
    global,
    user,
    horse,
    location,
    trainer,
    activity,
    company,
    schedule,
    payment,
    event,
    eventRegistration,
    activityCredit,
    activityPackage,
    barnBoard,
  },
  strict: false,
});
