<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="loading-modal">
          <div class="loader"></div>
          <div class="text">Please wait</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "loading-modal",
  data() {
    return {};
  },
};
</script>

<style scoped>
.loader {
  border: 16px solid lightgray;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  display: inline-block;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
}

.text {
  margin-top: 15px;
  font-size: 24px;
  text-align: center;
  font-weight: var(--semibold);
}

.loading-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-modal {
  z-index: 2000;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  z-index: 2001;
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
}
</style>