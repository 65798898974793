<template>
  <div>
    <div class="header-extension" v-if="currentUser.id"></div>
    <div class="content space dashboard-cover" v-if="currentUser.id">
      <BasePanel>
        <Flex justify="between" direction="row">
          <Flex justify="center" direction="row">
            <Avatar size="small" :image-source="currentUser.avatar" />
            <p class="name">{{ currentUser.name }}</p>
          </Flex>

          <BaseLink :link="registrationLink">
            <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
          </BaseLink>
        </Flex>
      </BasePanel>
    </div>
    
    <div class="content">
      <UserContinue v-if="!currentUser.registration_finished" />

      <div v-else>
        <BasePanel type="clickable" @click.native="bookActivites()">
          <Flex justify="between" direction="row">
            <BaseLink class="add-an-activity">Add Activity</BaseLink>
            <ButtonAddRemove type="add" />
          </Flex>
        </BasePanel>
      </div>
    </div>

    <hr />

    <div class="content">
     
      <BookingRequestPanel />
      <section class="space--large">
        <header>
          <Flex justify="between">
            <h1 class="page-title">Upcoming Activities</h1>

            <button
              v-if="currentUser.account_type == 'company'"
              @click="goToUserActivityPage()"
            >
              <font-awesome-icon
                :icon="['fas', 'plus-square']"
                fixed-width
                class="page-title"
              />
            </button>
          </Flex>
        </header>

        <CalendarList />

        <BaseLink link="/calendar">&dash; View Calendar</BaseLink>
      </section>
      <section class="space--large" v-if="events.length && currentUser.account_type != 'company'">
          <Flex justify="between">
            <h1 class="page-title">Events</h1>
          </Flex>
        <EventInterest v-for="event in events" :event="event" :key="event.id">
      </section>
      <section
        class="space--large"
        v-if="
          currentUser.account_type === 'personal' && recentActivities.length > 0
        "
      >
        <h1 class="page-title" style="margin-bottom: 5px">Book it again!</h1>
        <p class="latest-booked-description">
          Recently completed activity, view a summary and/or use as a shortcut
          to book these services again
        </p>
        <recent-activity-card
          v-for="recentActivity in recentActivities"
          :key="recentActivity.id"
          :activity="recentActivity"
        />
      </section>
    </div>
  </div>
</template>


<script>
import EventInterest from "../events/EventInterest.vue";
import Flickity from "flickity";
import { mapState } from "vuex";
import Flex from "../style/Flex";
import ActivityPanel from "../activities/ActivityPanel";
import ButtonRedo from "../buttons/ButtonRedo";
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import UserContinue from "../registration/PageRegistrationUserContinue";
import UserPanel from "../users/UserPanel";
import CalendarList from "../calendars/CalendarList";
import Avatar from "../utils/Avatar";
import BookingRequestPanel from "../booking-requests/BookingRequestPanel";
import RecentActivityCard from "../activities/RecentActivityCard";
import BaseInput from "../base/BaseInput";

export default {
  name: "PageDashboard",
  title: null,
  components: {
    Flex,
    ActivityPanel,
    ButtonRedo,
    ButtonAddRemove,
    UserContinue,
    UserPanel,
    CalendarList,
    Avatar,
    BookingRequestPanel,
    RecentActivityCard,
    BaseInput,
    EventInterest,
  },
  computed: {
    ...mapState({
      theme: (state) => state.global.theme,
      currentUser: (state) => state.user.currentUser,
    }),

    bookActivitiyRoute: function () {
      if (this.currentUser.account_type === "personal") {
        return "/activities";
      }
      return "/companies/create-user-activity";
    },

    accountTypeName() {
      return this.accountType === "personal" ? "personal" : "worker";
    },

    registrationLink() {
      if (this.currentUser) {
        if (this.currentUser.account_type === "personal") {
          return "/register/profile";
        } else {
          return `/register/${this.currentUser.account_type}/profile`;
        }
      }
    },
  },

  watch: {
    // "currentUser.id": function (newVal) {
    //   this.initializeSlider();
    //   if (this.currentUser.id) {
    //     this.getEvents();
    //     this.$store
    //       .dispatch("activity/GET_RECENT_ACTIVITIES", this.currentUser.id)
    //       .then((r) => {
    //         this.recentActivities = r.data;
    //       });
    //   }
    // },
  },

  data() {
    return {
      accountTypes: [
        {
          type: "personal",
          theme: "default",
        },
        {
          type: "worker",
          theme: "worker",
        },
      ],
      accountType: "personal",
      recentActivities: [],
      newZip: "",
      withinMiles: 10,
      events: [],
    };
  },

  created() {},
  async mounted() {
    setTimeout(async () => {
      await this.getEvents();
      await this.getRecentActivities();
      // alert(window.matchMedia('(display-mode: standalone)').matches)
    }, 2000);
  },

  methods: {
    async getEvents() {
      await this.$store
        .dispatch("event/GET_EVENTS", { new_for_user_id: this.currentUser.id })
        .then((response) => {
          this.events = response.data;
        });
    },
    async getRecentActivities() {
      await this.$store
        .dispatch("activity/GET_RECENT_ACTIVITIES", this.currentUser.id)
        .then((r) => {
          this.recentActivities = r.data;
        });
    },
    continueToRegistration() {
      this.$router.push({
        name: "register",
        params: { accountType: this.accountTypeName },
      });
    },

    goToUserActivityPage() {
      this.$router.push("/companies/create-user-activity");
    },

    bookActivites() {
      this.$router.push(this.bookActivitiyRoute);
    },

    changeAccountType(index) {
      const accountType = this.accountTypes[index].type;
      const theme = this.accountTypes[index].theme;

      this.accountType = accountType;
      this.changeTheme(theme);
    },

    changeTheme(theme) {
      this.$store.dispatch("global/CHANGE_THEME", theme);
    },
  },
};
</script>

<style scoped>
.within-input {
  display: inline-block;
  width: 40%;
}
.name {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin: 0.5em;
}
.latest-booked-description,
.try-something-new-description {
  color: var(--grey);
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
}

p.description-small-font {
  font-size: 14px;
}

.test-test {
  margin: 20px 0px;
}
.header-extension {
  background-color: var(--current-accent-color);
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -40px;
  padding-top: 50px;
  box-sizing: border-box;
  height: 100px;
  margin-top: -50px;
}

.find-form {
  margin-top: 30px;
}

.find-form-label {
  margin-bottom: 14px;
}

#zipInput {
  width: 30%;
  display: inline-block;
  margin-right: 20px;
}

#browse-button {
  letter-spacing: 1px;
  font-weight: 600;
}
</style>
