<template>
  <div>
    <form @submit.prevent="goToConfirmationPage()">
      <h1>Select a Date for Activity:</h1>
      <activity-panel
        v-if="employeeActivity"
        :activity="employeeActivity.activity"
      />

      <TrainerScheduleTimeSlots
        :employee-activity="employeeActivity"
        :location-id="locationId"
        :trainer-id="schedulerId"
        @set-time-slot="selectTimeSlot"
      />

      <div v-if="error">
        {{ error }}
      </div>

      <BaseButton type="submit">Save & Continue</BaseButton>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import BasePanel from "../base/BasePanel";
import ActivityPanel from "./ActivityPanel";
import TrainerScheduleTimeSlots from "../trainers/TrainerScheduleTimeSlots";

export default {
  name: "UserActivitySelectDate",
  components: { Flex, BasePanel, ActivityPanel, TrainerScheduleTimeSlots },
  props: {
    employeeActivity: Object,
    schedulerId: Number,
    locationId: Number,
    horseId: Number,
  },

  data() {
    return {
      activityDate: null,
      selectedTimeSlot: null,
      trainerSchedule: {},
      horses: [],
      selectedHorseId: null,
      error: "",
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  methods: {
    selectTimeSlot(data) {
      this.selectedTimeSlot = data.timeSlot;
      this.activityDate = data.activityDate;
    },

    goToConfirmationPage() {
      if (!this.selectedTimeSlot) {
        this.errorMessage("You need to select a time slot before continuing.");
      } else {
        const userActivity = {
          employee_activity_id: this.employeeActivity.id,
          participant_id: this.currentUser.id,
          location_id: this.locationId,
          horse_id: this.horseId,
        };
        const activityBlock = {
          schedule_chunk_id: this.selectedTimeSlot.schedule_chunk_id,
          start_time: this.composeDatetime(this.selectedTimeSlot.start_time),
          end_time: this.composeDatetime(this.selectedTimeSlot.end_time),
        };

        this.$router.push({
          name: "activity-payment",
          params: { userActivity, activityBlock },
        });
      }
    },

    errorMessage(message) {
      this.error = message;
    },

    composeDatetime(time) {
      let parsedTime = this.moment.utc(time);
      let date = this.moment.utc(
        this.activityDate.substring(0, 10),
        "YYYY-MM-DD"
      );
      date.hour(parsedTime.hour());
      date.minutes(parsedTime.minutes());

      return date.format();
    },
  },
};
</script>

<style scoped>
</style>
