<template>
  <BasePanel theme="calendar" :class="theme">
    <Flex justify="start" :class="status">
      <div class="time">
        <span class="range">
          {{ formattedStartTime }} -
          <br />
          {{ formattedEndTime }}
        </span>
      </div>
      <div class="trainer">
        <Flex justify="between" v-if="theme == 'unavailable'">
          <div>
            <span class="busy">Marked as busy</span>
            <br />
            <span class="unavailable-text">Unavailable</span>
          </div>
          <ButtonEdit />
        </Flex>
        <Flex v-else-if="theme == 'open'">
          <div class="details">
            Open Availability
            <br />
            <span class="subtext">+ Add Activity</span>
          </div>
          <ButtonAddRemove />
        </Flex>
        <Flex justify="start" align="center" v-else>
          <Avatar size="small" class="avatar" :image-source="avatar" />
          <div class="details">
            {{ trainer }}
            <br />
            {{ activityType }}
            <br />
            <span class="ellipsize" v-if="horse">
              <font-awesome-icon
                :icon="['fa', 'horse-head']"
                class="horse-icon"
              />{{ horse }}
            </span>
          </div>
        </Flex>
        <span v-if="isCancelled()" class="cancelled_text">
          <font-awesome-icon :icon="['fa', 'ban']" /> Cancelled
        </span>
      </div>
    </Flex>
  </BasePanel>
</template>

<script>
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import ButtonEdit from "../buttons/ButtonEdit";

export default {
  name: "CalendarPanel",
  components: { Flex, Avatar, ButtonAddRemove, ButtonEdit },
  props: {
    horse: {
      type: String,
      default: "",
    },
    activityType: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: null,
    },
    trainer: {
      type: String,
      default: "",
    },
    startTime: {
      type: String,
      default: "9:00am",
    },
    endTime: {
      type: String,
      default: "8:00pm",
    },
    theme: {
      type: String,
    },
    status: {
      type: String,
      default: "",
    },
  },

  computed: {
    formattedStartTime() {
      if (this.startTime)
        return this.moment.utc(this.startTime).format("hh:mm a");
    },

    formattedEndTime() {
      if (this.endTime) return this.moment.utc(this.endTime).format("hh:mm a");
    },
  },
  methods: {
    isCancelled() {
      return this.status == "declined";
    },
  },
};
</script>

<style scoped>
.panel.unavailable {
  background: repeating-linear-gradient(
    135deg,
    #edffff 0em,
    #edffff 2em,
    #d4f4f4 2em,
    #d4f4f4 4em
  );
}
.panel.open {
  background: var(--fog);
}
.panel.unavailable:before,
.panel.open:before {
  content: none;
}
.time {
  border-right: 1px solid var(--panel-border-color);
  font-weight: var(--semibold);
  height: 100%;
  line-height: 1.4em;
  /* disabled while color border is disabled */
  /* padding: .75em .5em .75em 1em; */
  padding: 0.75em 0.5em;
}
.range {
  font-size: 1.125em;
}
.trainer {
  padding: 0.5em 0.75em 0.5em 0.25em;
  min-width: 0;
  width: 100%;
}
.avatar {
  flex: 0 0 auto;
}
.details {
  font-weight: var(--semibold);
  line-height: 1.25em;
  margin-left: 0.5em;

  /* Corrects text overflow */
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  min-width: 0;
}
.subtext {
  color: var(--dark-grey);
}
.busy {
  color: var(--dark-grey);
  font-size: 0.625em;
}
.unavailable-text {
  font-size: 1.25em;
  font-weight: var(--semibold);
  text-transform: uppercase;
}
.declined {
  background-color: var(--fog);
  opacity: 0.7;
}
.horse-icon {
  margin-right: 0.5em;
}

.cancelled_text {
  font-size: 1em;
  font-weight: var(--semibold);
}
</style>
