export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOGIN = "LOGIN";
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGOUT = "LOGOUT";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER_FROM_TOKEN = "GET_USER_FROM_TOKEN";
export const SET_USER = "SET_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_USER = "FETCH_USER";
export const SEARCH_USERS = "SEARCH_USERS";
export const GET_USER_RATING = "GET_USER_RATINGS";
export const CREATE_USER_RATING = "CREATE_USER_RATING";
export const UPDATE_USER_RATING = "UPDATE_USER_RATING";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
