import store from "../store";

import PageHome from "../components/pages/PageHome";
import PageDashboard from "../components/pages/PageDashboard";
import PageAbout from "../components/pages/PageAbout";
import PageSearch from "../components/pages/PageSearch";
import PageHorseProfile from "../components/horses/PageHorseProfile";

import PageCalendar from "../components/pages/PageCalendar";
import PageBaseStyles from "../components/pages/PageBaseStyles";

import PageRegistrationLogin from "../components/registration/PageRegistrationLogin";
import PageRegistrationSignup from "../components/registration/PageRegistrationSignup";
import PageRegistrationUserContinue from "../components/registration/PageRegistrationUserContinue";
import PageRegistrationProfessionalTypeSelect from "../components/registration/PageRegistrationProfessionalTypeSelect.vue";

import PageRegistrationUser from "../components/registration/PageRegistrationUser";
import PageRegistrationWorker from "../components/registration/PageRegistrationWorker";
import PageRegistrationCompany from "../components/registration/PageRegistrationCompany";

import PageActivitiesSignup from "../components/activities/PageActivitiesSignup";
import PageActivitiesSelectDate from "../components/activities/SelectDate";
import PageActivitiesPayment from "../components/activities/Payment";
import PageActivitiesRequestConfirmation from "../components/activities/RequestConfirmation";

import TrainerActivityRequestIndex from "../components/trainers/ActivityRequestIndex";
import TrainerActivityRequest from "../components/trainers/ActivityRequest";

import PageUserProfile from "../components/users/PageUserProfile";
import UserActivityRequest from "../components/users/ActivityRequest";
import UserActivityRequestIndex from "../components/users/ActivityRequestIndex";
import PageUserInvoices from "../components/users/Invoices";
import UserInvoiceDetails from "../components/users/InvoiceDetails";

import PageCompanyProfile from "../components/companies/PageCompanyProfile";
import PageLocationProfile from "../components/locations/PageLocationProfile";
import PageCompanyInvoices from "../components/companies/Invoices";
import PageInvoiceForm from "../components/companies/InvoiceForm";
import CompanyCreateUserActivity from "../components/companies/CreateUserActivity";

import PagePaymentHistory from "../components/invoices/UserPaymentHistory";
import PagePaymentDetails from "../components/invoices/PaymentDetails";

import PageEventForm from "../components/events/EventForm";
import PageEventIndex from "../components/events/EventIndex";
import PageEvent from "../components/events/PageEvent";

import ActivityCreditForm from "../components/activity-credits/ActivityCreditForm";
import ActivityPackageForm from "../components/activity-package/ActivityPackageForm";
import BarnBoardPage from "../components/barnBoard/barnBoardPage";

// This is necessary to wait for auth token to be set for the first time after sign up
// watch change in isAuthenticated getter to trigger route change

const checkAuthenticationOnSignUp = (to, from, next) => {
  if (!(from.path === "/register")) return ifAuthenticated(to, from, next);

  store.watch(
    function (_, getters) {
      return getters["user/isAuthenticated"];
    },
    function () {
      ifAuthenticated(to, from, next);
    }
  );
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters["user/isAuthenticated"]) {
    next();
  } else {
    store.commit(
      "global/GLOBAL_ERROR",
      "You need to log in before you can perform this action.",
      { root: true }
    );
    next("/login");
  }
};

const sendUserToDashboard = (to, from, next) => {
  if (store.getters["user/isAuthenticated"]) {
    next("/dashboard");
  } else {
    next();
  }
};

// Helm Readiness/Liveliness

const healthz = { template: "<div>200</div>" };

export default [
  { path: "/healthz", component: healthz },
  {
    path: "/",
    name: "home",
    component: PageHome,
    beforeEnter: sendUserToDashboard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: PageDashboard,
    beforeEnter: ifAuthenticated,
  },
  { path: "/about", component: PageAbout },
  { path: "/calendar", component: PageCalendar },
  { path: "/search", component: PageSearch },
  { path: "/base-styles", component: PageBaseStyles },
  {
    path: "/login",
    component: PageRegistrationLogin,
    beforeEnter: sendUserToDashboard,
  },

  // profile pages
  { path: "/horses/:id", component: PageHorseProfile, name: "horse-profile" },
  { path: "/users/:id", component: PageUserProfile, name: "user-profile" },
  { path: "/events/profile/:id", component: PageEvent, name: "event-profile" },
  {
    path: "/companies/locations/:id",
    component: PageLocationProfile,
    name: "barn-profile",
  },
  {
    path: "/companies/profile/:id",
    component: PageCompanyProfile,
    name: "company-profile",
  },

  {
    path: "/register",
    name: "register",
    component: PageRegistrationSignup,
    props: true,
  },
  {
    path: "/register/continue",
    component: PageRegistrationUserContinue,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/register/professional-type-select",
    component: PageRegistrationProfessionalTypeSelect,
    beforeEnter: checkAuthenticationOnSignUp,
  },

  {
    path: "/register/:step",
    props: true,
    component: PageRegistrationUser,
    beforeEnter: checkAuthenticationOnSignUp,
  },
  {
    path: "/register/worker/:step",
    props: true,
    component: PageRegistrationWorker,
    beforeEnter: checkAuthenticationOnSignUp,
  },
  {
    path: "/register/company/:step",
    props: true,
    component: PageRegistrationCompany,
    beforeEnter: checkAuthenticationOnSignUp,
  },

  // activities
  {
    path: "/activities",
    component: PageActivitiesSignup,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/activities/date",
    name: "activity-date",
    component: PageActivitiesSelectDate,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/activities/payment",
    name: "activity-payment",
    component: PageActivitiesPayment,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/activities/confirmation",
    name: "activity-confirmation",
    component: PageActivitiesRequestConfirmation,
    beforeEnter: ifAuthenticated,
    props: true,
  },

  // trainers
  {
    path: "/trainers/activity-requests/:trainerId",
    name: "trainer-activity-requests",
    component: TrainerActivityRequestIndex,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/trainers/:trainerId/activity-request/:userActivityId",
    name: "trainer-activity-request-review",
    component: TrainerActivityRequest,
    beforeEnter: ifAuthenticated,
  },

  // users
  {
    path: "/users/activity-requests/:userId",
    name: "user-activity-requests",
    component: UserActivityRequestIndex,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/users/:userId/activity-request/:userActivityId",
    name: "user-activity-request-review",
    component: UserActivityRequest,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/invoices",
    component: PageUserInvoices,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/users/invoices/:id",
    name: "user-invoice",
    component: UserInvoiceDetails,
    beforeEnter: ifAuthenticated,
  },

  // companies
  {
    path: "/companies/invoices",
    component: PageCompanyInvoices,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/companies/invoices/edit",
    name: "edit-invoice",
    component: PageInvoiceForm,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/companies/create-user-activity",
    component: CompanyCreateUserActivity,
    ifAuthenticated,
  },

  // payments
  {
    path: "/payments/history",
    component: PagePaymentHistory,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/payments/details/:id",
    name: "payment-details",
    component: PagePaymentDetails,
    beforeEnter: ifAuthenticated,
  },

  // events
  {
    path: "/events/edit",
    name: "edit-event",
    component: PageEventForm,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  { path: "/events", component: PageEventIndex, beforeEnter: ifAuthenticated },

  // activity credit
  {
    path: "/activity-credits/:activityCreditId?",
    name: "activity-credit",
    component: ActivityCreditForm,
    beforeEnter: ifAuthenticated,
    props: true,
  },
  {
    path: "/activity-package/:activityPackageId?",
    name: "activity-package",
    component: ActivityPackageForm,
    beforeEnter: ifAuthenticated,
    props: true,
  },

  {
    path: "/barn-board/:companyId",
    name: "barn-board",
    component: BarnBoardPage,
    beforeEnter: ifAuthenticated,
    props: true,
  },
];
