<template>
  <div>
    <form id="payment">
      <h3>Banking Information</h3>

      <div v-if="company.stripe_account_id">
        <p class="space--large">You have already set up your Stripe Account.</p>
      </div>

      <div v-else>
        <input
          class="radio"
          type="radio"
          id="company"
          value="company"
          v-model="stripeAccountType"
        />
        <label class="radio-label" for="company">Company</label>
        <br />
        <input
          class="radio"
          type="radio"
          id="individual"
          value="individual"
          v-model="stripeAccountType"
        />
        <label class="radio-label" for="individual">Personal</label>
        <br />

        <p class="notice">
          *Please assure the provided information above is accurate and correct,
          upon submitting this form you hereby agree to Stripe’s Terms of
          Service and Connected Account Agreement.
        </p>

        <div v-if="error">
          <p class="error">{{ errorMessage }}</p>
        </div>

        <BaseButton
          type="button"
          @click="createStripeToken()"
          theme="with-space"
        >
          Connect With Stripe
        </BaseButton>
      </div>
      <div class="stripe-dashboard" v-if="company.stripe_account_id">
        <BaseButton theme="alternate" @click="goToStripeDashBoard"
          >Stripe Dashboard</BaseButton
        >
      </div>
      <BaseButton theme="alternate" @click="$parent.nextPage()"
        >Next</BaseButton
      >
    </form>
  </div>
</template>

<script>
const stripe = Stripe(process.env.STRIPE_PUBLISHABLE_API_KEY);

import BaseRadio from "../../base/BaseRadio";

import { mapState } from "vuex";
import Flex from "../../style/Flex";

export default {
  name: "PageRegistrationUserPayment",
  title: null,
  components: { Flex, BaseRadio },
  props: {
    currentIndex: Number,
    steps: Array,
  },

  data() {
    return {
      stripeToken: undefined,
      bankInfo: {},
      stripeAccountType: "company",
      codeFromStripe: undefined,
      company: {},
      error: false,
      errorMessage: "",
    };
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  mounted() {
    setTimeout(() => this.getCompany(), 500);
    setTimeout(() => {
      if (this.$route.query.code) {
        this.codeFromStripe = this.$route.query.code;
        this.createConnectedAccount();
      }
    }, 500);
  },

  methods: {
    goToStripeDashBoard() {
      this.$store
        .dispatch("payment/GET_STRIPE_DASHBOARD", this.currentUser.company_id)
        .then((response) => {
          window.location = response.data.url;
        })
        .catch((e) => {});
    },
    getCompany() {
      this.$store
        .dispatch("company/GET_COMPANY", this.currentUser.company_id)
        .then((response) => {
          this.company = response.data;
        });
    },

    accountNumbersMatch() {
      return this.bankInfo.accountNumber === this.bankInfo.accountNumberVerify
        ? true
        : false;
    },

    createStripeToken() {
      let stripeUrl = "https://connect.stripe.com/express/oauth/authorize?";
      stripeUrl += "client_id=" + process.env.STRIPE_CONNECT_CLIENT_ID;
      stripeUrl += "&state=ridingPostSecretToken";
      stripeUrl += "&stripe_user[business_type]=" + this.stripeAccountType;

      // at the moment stripe shows it as in invalid parameter tho it's from their documentation
      // stripeUrl += '&suggested_capabilities[]=transfers';

      if (this.currentUser.account_type == "company") {
        let companyEmail = this.company.email;
        if (companyEmail != null && companyEmail != "") {
          stripeUrl += "&stripe_user[email]=" + companyEmail;
        } else {
          if (this.currentUser.email) {
            stripeUrl += "&stripe_user[email]=" + this.currentUser.email;
          }
        }
      } else if (this.currentUser.email) {
        stripeUrl += "&stripe_user[email]=" + this.currentUser.email;
      }

      if (this.currentUser.first_name) {
        stripeUrl += "&stripe_user[first_name]=" + this.currentUser.first_name;
      }
      if (this.currentUser.last_name) {
        stripeUrl += "&stripe_user[last_name]=" + this.currentUser.last_name;
      }

      if (this.company.name) {
        stripeUrl += "&stripe_user[business_name]=" + this.company.name;
      }

      stripeUrl += "&stripe_user[country]=US";

      if (this.company.website) {
        stripeUrl += "&stripe_user[url]=" + this.company.website;
      }

      if (this.currentUser.account_type == "company") {
        //          stripeUrl += '&suggested_capabilities[]=transfers';

        let companyPhone = this.company.phone;
        if (companyPhone != null && companyPhone != "") {
          stripeUrl += "&stripe_user[phone_number]=" + companyPhone;
        } else {
          if (this.currentUser.phone) {
            stripeUrl += "&stripe_user[phone_number]=" + this.currentUser.phone;
          }
        }
      } else if (this.currentUser.phone) {
        stripeUrl += "&stripe_user[phone_number]=" + this.currentUser.phone;
      }

      stripeUrl += "&redirect_uri=" + this.getRedirectUrl();

      window.location = stripeUrl;
    },

    getRedirectUrl() {
      switch (process.env.NODE_ENV) {
        case "development":
          return "https://localhost:1234/#/register/company/payment";
        case "staging":
          return "https://app.ridingpost.dev/#/register/company/payment";
        default:
          return "https://app.ridingpost.com/#/register/company/payment";
      }
    },

    createConnectedAccount() {
      const params = {
        company_id: this.currentUser.company_id,
        authorization_code: this.codeFromStripe,
      };
      this.$store
        .dispatch("payment/CREATE_STRIPE_CONNECTED_ACCOUNT", params)
        .then((response) => {
          if (response.data.error) {
            this.error = true;
            this.handleError(`Error : ${response.data.error}`);
            return;
          }

          this.error = false;
          this.$parent.nextPage();
        });
    },

    handleError(message) {
      this.error = true;
      this.errorMessage = message;
    },
  },
};
</script>

<style scoped>
h3 {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 1em;
}
.notice {
  font-size: 0.625em;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 2em;
}
p,
a {
  font-size: 1.125em;
}
.error {
  color: var(--red);
  margin: 0.5em;
  font-size: 1.5em;
}
.radio {
  -webkit-appearance: radio;
  display: inline;
  width: auto;
}
.radio-label {
  display: inline;
}
.stripe-dashboard {
  margin-bottom: 10px;
}
</style>
