<template>
  <form id="company-employees" @submit.prevent="updateCompanyEmployees()">
    <h3 class="space">Company Employees</h3>

    <div v-if="!addingEmployer">
      <BasePanel v-for="employee in employees" :key="employee.id">
        <Flex justify="between" direction="row">
          <Avatar :image-source="employee.avatar" size="small" />
          <p>{{ employee.name }}</p>
          <ButtonAddRemove
            type="remove"
            @click.native="promptRemoveEmployee(employee)"
          />
        </Flex>
      </BasePanel>

      <Flex justify="between">
        <BaseButton
          theme="worker auto"
          type="button"
          @click="toggleAddUser(true)"
        >
          Add New Employee
        </BaseButton>

        <BaseButton @click="$parent.nextPage()" type="button" theme="auto"
          >Save & Continue</BaseButton
        >
      </Flex>
    </div>

    <div v-if="addingEmployer">
      <BaseInput
        name="user_name"
        label-text="Search for user:"
        v-model="searchQuery"
        @input="searchUsers"
      />

      <BasePanel
        theme="calendar"
        v-if="users.length"
        v-for="user in users"
        :key="user.id"
      >
        <Flex justify="between" class="user-box">
          <Flex justify="start">
            <Avatar :image-source="user.avatar" size="small" />
            <p class="user-name">{{ user.name }}</p>
          </Flex>

          <BaseButton theme="auto" @click="promptAddEmployee(user)">
            Add User As Employee
          </BaseButton>
        </Flex>
      </BasePanel>

      <BaseButton theme="alternate auto" @click="toggleAddUser(false)"
        >Cancel</BaseButton
      >
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../../style/Flex";
import ButtonAddRemove from "../../buttons/ButtonAddRemove";
import Avatar from "../../utils/Avatar";

export default {
  name: "Employees",
  title: null,
  components: { Flex, ButtonAddRemove, Avatar },

  data() {
    return {
      employees: [],
      users: [],
      addingEmployer: false,
      searchQuery: "",
    };
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  mounted() {
    this.$store.commit("global/START_LOADING");
    setTimeout(() => this.fetchCompanyEmployees(), 2000);
  },

  methods: {
    fetchCompanyEmployees() {
      this.$store
        .dispatch("company/GET_COMPANY_EMPLOYEES", this.currentUser.company_id)
        .then((response) => {
          this.employees = response.data;
        })
        .finally(() => {
          this.$store.commit("global/STOP_LOADING");
        });
    },

    searchUsers() {
      this.$store
        .dispatch("user/SEARCH_USERS", this.searchQuery)
        .then((response) => {
          const employeeIds = this.employees.map((e) => e.id);
          this.users = response.data.filter((u) => !employeeIds.includes(u.id));
        });
    },

    toggleAddUser(state) {
      this.addingEmployer = state;
    },

    promptRemoveEmployee(employee) {
      const removeEmployee = window.confirm(
        `Do you want to remove ${employee.name} as your employee?`
      );
      if (removeEmployee) this.removeCompanyEmployee(employee.id);
    },

    removeCompanyEmployee(employee_id) {
      const params = { id: this.currentUser.company_id, employee_id };

      this.$store
        .dispatch("company/REMOVE_EMPLOYEE", params)
        .then((response) => {
          this.employees = this.employees.filter((e) => e.id !== employee_id);
        });
    },

    promptAddEmployee(user) {
      const addEmployee = window.confirm(
        `Do you want to add ${user.name} as your employee?`
      );
      if (addEmployee) this.addCompanyEmployee(user.id);
    },

    addCompanyEmployee(user_id) {
      const params = {
        employer_id: this.currentUser.company_id,
        employee_id: user_id,
      };

      this.$store.dispatch("company/ADD_EMPLOYEE", params).then((response) => {
        this.employees.push(response.data);
        this.toggleAddUser(false);
      });
    },
  },
};
</script>

<style scoped>
.user-box {
  font-size: 1em;
  margin: 0.5em;
  padding: 1em;
}
.user-name {
  color: var(--navy);
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin-top: 0.25em;
}
</style>
