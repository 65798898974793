<template>
  <div>
    <BaseProgress :current-step="currentStep" :steps="steps" />
    <component
      v-bind:is="currentStep"
      :current-index="currentIndex"
      :steps="steps"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Profile from "./worker/Profile";
import Employers from "./worker/Employers";
import Services from "./worker/Services";
import Availability from "./worker/Availability";
import Flex from "../style/Flex";
import AvatarUpload from "../utils/AvatarUpload";

export default {
  name: "PageRegistrationWorkerProfile",
  title: null,
  components: {
    Flex,
    AvatarUpload,
    Profile,
    Employers,
    Services,
    Availability,
  },
  data() {
    return {
      steps: [
        { text: "Profile", value: "profile" },
        { text: "Employers", value: "employers" },
        { text: "Services", value: "services" },
        { text: "Availability", value: "availability" },
        /* { text: 'Schedule', value: 'schedule' }*/
      ],
    };
  },

  computed: {
    currentStep() {
      return this.$route.params.step;
    },
    currentIndex() {
      return this.steps.findIndex((step) => step.value === this.currentStep);
    },
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  methods: {
    nextPage() {
      if (this.currentIndex < this.steps.length - 1) {
        const newPath = this.steps[this.currentIndex + 1].value;
        this.$router.push(newPath);
      } else {
        var data = new FormData();
        data.append("registration_finished", true);
        const userParams = {
          id: this.currentUser.id,
          user: data,
          finished: true,
        };

        this.$store
          .dispatch("user/UPDATE_USER", userParams)
          .then((response) => {
            this.$router.push("/dashboard");
          });
      }
    },
  },
};
</script>

<style scoped>
p,
a {
  font-size: 1.125em;
}
</style>
