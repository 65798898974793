<template>
  <div class="unused-package-card">
    <div class="active-credit-card-header">
      <span class="package-name">{{ activityCredit.name }}</span>
      <div class="btn-cta" @click="addPackage">
        <span class="sign"> + </span>
      </div>
      <div class="btn-cta" @click="editPackage">
        <font-awesome-icon class="edit-icon" :icon="['fas', 'edit']" />
      </div>
    </div>
    <div class="usability-box">
      <span class="usability-box-title">Can be used for:</span>
      <div
        class="usability-item"
        v-for="item in activityCredit.usedFor"
        :key="item"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnusedActivityCreditCard",
  props: ["activityCredit"],
  computed: {
    activities() {
      return this.usedfor;
    },
  },
  data: function () {
    return {
      quantity: undefined,
    };
  },
  mounted() {
    this.quantity = this.propquantity;
  },
  methods: {
    addPackage() {
      this.$emit("add-credit", this.activityCredit.id);
    },
    editPackage() {
      this.$router.push("/activity-credits/" + this.activityCredit.id);
    },
  },
};
</script>

<style scoped>
.unused-package-card {
  width: 100%;
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin: 15px 0px;
}

.package-name {
  max-width: 80%;
  display: inline-block;
  color: rgb(119, 118, 118);
  font-size: 20px;
  font-weight: 600;
}

.usability-box-title {
  display: inline-block;
  width: 100%;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
}
.usability-item {
  color: #9b9b9b;
  font-size: 15px;
  padding: 2px;
  font-weight: 400;
}

.sign {
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-55%);
  position: relative;
}

.btn-cta {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  color: #64c8c1;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  float: right;
  margin-left: 7px;
  position: relative;
}
.active-credit-card-header {
  margin-bottom: 30px;
}
.usability-box {
  display: flex;
  flex-direction: column;
}
.edit-icon {
  font-size: 20px;
  color: #9b9b9b;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>