<template>
  <button
    class="button"
    :class="theme"
    :type="type"
    :disabled="disabledState"
    @click="emitClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    theme: String,
    type: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disabledState() {
      return this.disabled ? "disabled" : null;
    },
  },

  methods: {
    emitClick(event) {
      this.$emit("click", event.target.value);
    },
  },
};
</script>

<style scoped>
.button {
  background-color: var(--current-accent-color);
  border-radius: 100px;
  color: white;
  cursor: pointer;
  display: block;
  padding: 0.875em 1.875em;
  text-align: center;
  width: 100%;
}
.disabled {
  background-color: var(--disabled-accent-color);
  color: var(--disabled-subaccent-color);
  pointer-events: none;
}
.primary {
  background-color: var(--default-accent-color);
}
.alternate {
  background-color: var(--alternate-accent-color);
  border: 1px solid var(--border-color);
  color: var(--company-accent-color);
}
.facebook {
  background-color: var(--facebook-accent-color);
}
.worker {
  background-color: var(--worker-accent-color);
}
.company {
  background-color: var(--company-accent-color);
}
.auto {
  display: inline-block;
  width: auto;
}
.round {
  padding: 0.875em 0.75em;
  width: auto;
}
.narrow {
  padding: 0.875em;
}
.with-space {
  margin-bottom: 0.5em;
}
</style>
