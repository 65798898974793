<template>
  <article class="activity-panel">
    <h1 class="title space">Activity Requests</h1>

    <p class="success space" v-if="successMessage">{{ successMessage }}</p>

    <div v-for="userActivity in userActivities" :key="userActivity.id">
      <BasePanel theme="active">
        <Flex justify="between" class="space">
          <Flex justify="start">
            <Flex justify="start">
              <Avatar
                size="small"
                :image-source="userActivity.participant.avatar"
              />
              <div>
                <p class="name">{{ userActivity.participant.name }}</p>
                <p class="location space">
                  Location:
                  {{ userActivity.location_name }}
                </p>
              </div>
            </Flex>
          </Flex>
        </Flex>

        <p class="lesson-name">
          {{ userActivity.employee_activity.activity.service }}
          ({{ userActivity.employee_activity.activity.duration_in_minutes }}) -
          ${{ userActivity.employee_activity.price }}
        </p>

        <p class="lesson-time space">
          {{
            moment.utc(userActivity.time_block.start_time).format(
              "MMM DD, YYYY hh:mm a"
            )
          }}
          -
          {{ moment.utc(userActivity.time_block.end_time).format("hh:mm a") }}
        </p>

        <div
          class="horse-container"
          v-if="userActivity.horse_id && loadingDone"
        >
          <TrainerReviewHorsePanel
            :horse="getHorse(userActivity.horse_id)"
            :number-of-activities="getNumberOfActivities(userActivity.horse_id)"
          >
          </TrainerReviewHorsePanel>
        </div>

        <Flex justify="start" class="space">
          <BaseButton
            class="reviewButton"
            theme="alternate"
            @click="reviewRequest(userActivity.id)"
          >
            Review Request
          </BaseButton>
          <div v-if="userActivity.loading">loading ...</div>
          <BaseButton
            v-else
            class="button-approve font-awesome-ic"
            theme="round"
            @click="acceptRequest(userActivity.id)"
            :disabled="userActivity.loading"
          >
            <font-awesome-icon :icon="['fas', 'thumbs-up']" />
          </BaseButton>
        </Flex>
      </BasePanel>
    </div>
  </article>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";
import TrainerReviewHorsePanel from "../horses/TrainerReviewHorsePanel";
import moment from "moment";

export default {
  name: "TrainerActivityRequestIndex",
  components: { Flex, Avatar, TrainerReviewHorsePanel },

  data() {
    return {
      userActivities: [],
      successMessage: "",
      horses: [],
      horsesWithActivities: [],
      loadingDone: false,
    };
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  async mounted() {
    await this.correctTrainerIsSignedIn();
  },

  methods: {
    async correctTrainerIsSignedIn() {
      await this.findTrainerUserActivities();
    },

    getNumberOfActivities(idOfHorse) {
      let horseArray = this.horsesWithActivities.filter(
        (r) => r.id == idOfHorse
      );
      if (horseArray && horseArray.length > 0) {
        return horseArray[0].user_activities_in_time_period.length;
      }
    },

    async findTrainerUserActivities() {
      await this.$store
        .dispatch("activity/GET_TRAINER_USER_ACTIVITIES", {
          trainerId: this.$route.params.trainerId,
          forApproval: true,
        })
        .then((response) => {
          this.userActivities = response.data;
        });
      if (this.userActivities) {
        for (let i = 0; i < this.userActivities.length; i++) {
          if (this.userActivities[i].horse_id) {
            await this.$store
              .dispatch("horse/GET_HORSE", this.userActivities[i].horse_id)
              .then((r) => {
                this.horses.push(r.data);
              });
            let start = moment.utc(
              this.userActivities[i].time_block.start_time
            ).startOf("day");
            let end = moment.utc(
              this.userActivities[i].time_block.start_time
            ).endOf("day");
            await this.$store
              .dispatch("horse/GET_HORSE_ACTIVITIES", {
                horse_ids: this.userActivities[i].horse_id,
                start_time: start,
                end_time: end,
              })
              .then((r) => {
                this.horsesWithActivities.push(r.data[0]);
              });
          }
        }
        this.loadingDone = true;
      }
    },

    getHorse(horseId) {
      if (this.horses) {
        if (this.horses.length > 0) {
          return this.horses.filter((r) => r.id == horseId)[0];
        }
      }
    },

    acceptRequest(id) {
      const selectedUserActivity = this.userActivities.find(
        (ua) => ua.id == id
      );
      Vue.set(selectedUserActivity, "loading", true);

      const params = { status: "approved", id, accepted_by: "trainer" };

      this.$store
        .dispatch("activity/ACCEPT_USER_ACTIVITY", params)
        .then((response) => {
          this.handleSuccess(`Activity Approved`);
          this.userActivities = this.userActivities.filter((a) => a.id != id);
          Vue.set(selectedUserActivity, "loading", false);
        });
    },

    reviewRequest(id) {
      this.$router.push({
        name: "trainer-activity-request-review",
        params: {
          userActivityId: id,
          trainer_id: this.$route.params.trainerId,
        },
      });
    },

    handleSuccess(message) {
      this.successMessage = message;
      setTimeout(() => (this.successMessage = ""), 3000);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
}

.title {
  font-size: 1.35em;
  font-weight: var(--semibold);
}

.success {
  font-size: 1.25em;
  color: var(--blue);
}

.name {
  margin-top: 14px;
}

.thumbs-up {
  color: var(--fog);
  font-size: 1.75em;
  padding: 0.5em;
}
.button-approve {
  right: 5%;
  position: absolute;
}
.reviewButton {
  width: 70%;
}
.font-awesome-ic {
  background-color: var(--seafoam);
}

.horse-container {
  margin: 15px 0px;
}
</style>
