<template>
  <span
    class="input"
    :class="{ 'input--dirty': value, 'input--without-label': !label }"
  >
    <textarea
      :id="name"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :rows="rows"
      @input="emitInput"
      @change="emitChange"
    />
    <label :for="name" v-visible="label">
      {{ labelText }}
    </label>
  </span>
</template>

<script>
export default {
  name: "BaseTextArea",
  props: {
    placeholder: String,
    rows: String,
    name: String,
    labelText: String,
    value: String,
    label: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    emitInput(event) {
      this.$emit("input", event.target.value);
    },
    emitChange(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>
