<template>
  <div class="progress-wrapper">
    <h1 class="registration-title">
      Step {{ currentStepIndex }}: {{ currentStepObject.text }}
    </h1>
    <div class="progress" :style="progressStyle">
      <template v-for="(step, index) in steps">
        <div class="marker" :key="step.value" :class="stepClass(step)"></div>

        <div
          class="separator"
          :key="index"
          :class="stepClass(step)"
          v-if="index + 1 != steps.length"
        ></div>
      </template>
    </div>

    <BaseSelect id="currentStep" v-model="currentStepData" @input="updateRoute">
      <option v-for="step in steps" :key="step.value" :value="step.value">
        {{ step.text }}
      </option>
    </BaseSelect>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "BaseProgress",
  props: {
    currentStep: String,
    steps: Array,
  },
  data() {
    return {
      currentStepLocal: this.currentStep,
    };
  },
  computed: {
    // Corrects routing strangeness with setting data from props
    currentStepData: {
      get() {
        return this.currentStep;
      },
      set(newValue) {
        this.currentStepLocal = newValue;
      },
    },
    currentStepObject() {
      return this.steps.find((step) => step.value === this.currentStepData);
    },
    currentStepIndex() {
      return (
        this.steps.findIndex((step) => step === this.currentStepObject) + 1
      );
    },
    progressStyle() {
      const concat = "1em auto ";
      const columns = concat.repeat(this.steps.length - 1) + "1em";

      return "grid-template-columns: " + columns;
    },
  },
  methods: {
    updateRoute() {
      this.$router.push(this.currentStepLocal);
    },
    stepClass(step) {
      const index = this.steps.indexOf(step) + 1;
      const currentIndex =
        this.steps.findIndex((i) => i.value === this.currentStep) + 1;

      if (step.value === this.currentStep) return "current";
      if (index < currentIndex) return "past";
    },
  },
  mounted() {
    this.$forceUpdate();
  },
};
</script>

<style scoped>
.progress-wrapper {
  margin-bottom: 2em;
}
.progress {
  display: grid;
  align-items: center;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
}
.marker {
  background: var(--progress-background-color);
  border: 0.25em solid var(--progress-background-color);
  border-radius: 100%;
  box-sizing: border-box;
  display: block;
  height: 1em;
  width: 1em;
  z-index: 1;
}
.marker.current {
  background: white;
}
.marker.past {
  background: var(--current-accent-color);
  border-color: var(--current-accent-color);
}
.separator {
  background: var(--input-accent-color);
  height: 2px;
}
.separator.past {
  background: var(--current-accent-color);
}
</style>
