<template>
  <article class="activity-panel">
    <h1 class="title space">Activity Requests</h1>

    <div v-for="userActivity in userActivities" :key="userActivity.id">
      <BasePanel theme="active">
        <Flex justify="between" class="space">
          <Flex justify="start">
            <h2>Trainer:</h2>
            <Flex justify="start">
              <Avatar
                size="small"
                :image-source="userActivity.employee_activity.employee.avatar"
              />
              <div>
                <p class="name">
                  {{ userActivity.employee_activity.employee.name }}
                </p>

                <p class="location space">
                  Location:
                  {{ userActivity.location_name }}
                </p>
              </div>
            </Flex>
          </Flex>
        </Flex>

        <p class="lesson-name">
          {{ userActivity.employee_activity.activity.service }}
          ({{ userActivity.employee_activity.activity.duration_in_minutes }}) -
          ${{ userActivity.employee_activity.price }}
        </p>

        <p class="lesson-time space">
          {{
            moment.utc(userActivity.time_block.start_time).format(
              "MMM DD, YYYY hh:mm a"
            )
          }}
          -
          {{ moment.utc(userActivity.time_block.end_time).format("hh:mm a") }}
        </p>

        <Flex justify="between" class="space">
          <BaseButton
            @click="reviewRequest(userActivity.id)"
            v-if="userActivity.status === 'sent_to_rider'"
          >
            Review Request
          </BaseButton>
          <BaseButton @click="cancelRequest(userActivity)" v-else>
            Cancel Request
          </BaseButton>
        </Flex>
      </BasePanel>
    </div>
  </article>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";

export default {
  name: "UserActivityRequestIndex",
  components: { Flex, Avatar },

  data() {
    return {
      userActivities: [],
    };
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  async mounted() {
    await this.correctUserIsSignedIn()
  },

  methods: {
    async correctUserIsSignedIn() {
      if (this.$route.params.userId != this.currentUser.id) {
        this.$store.commit(
          "global/GLOBAL_ERROR",
          "You are trying to view someone else's data",
          { root: true }
        );
        this.$router.push("/");
        return;
      } else {
        this.findUserActivities();
      }
    },

    findUserActivities() {
      this.$store
        .dispatch(
          "activity/GET_UNAPPROVED_ACTIVITIES",
          this.$route.params.userId
        )
        .then((response) => {
          this.userActivities = response.data;
        });
    },

    reviewRequest(id) {
      this.$router.push({
        name: "user-activity-request-review",
        params: {
          userActivityId: id,
          trainer_id: this.$route.params.trainerId,
        },
      });
    },
    cancelRequest(userActivity) {
      this.$store
        .dispatch("activity/RIDER_DECLINE_USER_ACTIVITY", userActivity.id)
        .then((response) => {
          this.findUserActivities();
        });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
}

.title {
  font-size: 1.35em;
  font-weight: var(--semibold);
}
</style>
