<template>
  <div>
    <h1>Payment History</h1>

    <PaymentPanel
      v-for="payment in payments"
      :key="payment.id"
      :invoice="payment"
    ></PaymentPanel>
  </div>
</template>

<script>
import PaymentPanel from "./PaymentPanel";
import { mapState } from "vuex";

export default {
  name: "UserPaymentHistory",
  components: { PaymentPanel },
  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  data() {
    return {
      payments: [],
    };
  },

  mounted() {
    setTimeout(() => this.getUserPaymentHistory(), 2000);
  },

  methods: {
    getUserPaymentHistory() {
      this.$store
        .dispatch("payment/GET_USER_STRIPE_CHARGES", this.currentUser.id)
        .then((response) => {
          this.payments = response.data;
        });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin: 1em;
}
</style>
