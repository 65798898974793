// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import moment from "moment";
import VueIziToast from "vue-izitoast";

import titleMixin from "./mixins/titleMixin";
import visibilityDirective from "./directives/visibilityDirective";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarAlt,
  faMoneyBillAlt,
  faTrashAlt,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBook,
  faBan,
  faSpinner,
  faWarehouse,
  faMapMarkerAlt,
  faPhone,
  faMapSigns,
  faQuestion,
  faHorseHead,
  faPlus,
  faRedo,
  faCog,
  faMinus,
  faEnvelope,
  faTimes,
  faHeart,
  faEdit,
  faGlobeAmericas,
  faTrophy,
  faUndoAlt,
  faAngleDown,
  faAngleLeft,
  faGlobe,
  faPlusSquare,
  faThumbsUp,
  faThumbsDown,
  faDollarSign,
  faExclamationTriangle,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faSearch,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Datetime from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import vueDebounce from "vue-debounce";

import store from "./store";
import routes from "./router";

// Required for some browsers
/* eslint-disable no-unused-vars */
import { Promise } from "es6-promise";

import "./assets/stylesheets/reset";
import "./assets/stylesheets/theme";
import "./assets/stylesheets/type";
import "./assets/stylesheets/forms";
import "izitoast/dist/css/iziToast.css";

// Components
import components from "./components/base/*.vue";
import App from "./components/App";

if (location.protocol !== "https:") {
  location.protocol = "https:";
}

Vue.use(VueIziToast);

const url = ((env) => {
  switch (env) {
    case "development":
      return "https://localhost:8443/v1";
    case "staging":
      return "https://api.ridingpost.dev/v1";
    default:
      return "https://api.ridingpost.com/v1";
  }
})(process.env.NODE_ENV);

// const url = 'https://staging-api.ridingpost.com/v1';

axios.defaults.baseURL = url;

if (
  "serviceWorker" in navigator &&
  process.env.NODE_ENV != "staging" &&
  process.env.NODE_ENV != "development"
) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("../sw.js").then(
      function (registration) {
        // Registration was successful
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      function (err) {
        // registration failed :(
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

Vue.prototype.$http = axios;
Vue.prototype.moment = moment;
window.Vue = Vue;

const token = localStorage.getItem("user-token");
if (token) axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.common["Cache-Control"] = "no-cache";

const themeColor = localStorage.getItem("theme-color");
if (themeColor && token) {
  document.documentElement.style.setProperty(
    "--current-accent-color",
    themeColor
  );
}

Vue.use(VueRouter);
Vue.use(vueDebounce);
Vue.mixin(titleMixin);
Vue.use(Datetime);
Vue.directive("visible", visibilityDirective);

// Import Base Compoennts
for (const prop in components) {
  Vue.component(prop, components[prop].default);
}

// Import FontAwesome
library.add(
  faBook,
  faSpinner,
  faMapMarkerAlt,
  faWarehouse,
  faPhone,
  faCalendarAlt,
  faMoneyBillAlt,
  faTrashAlt,
  faHorseHead,
  faBan,
  faEye,
  faMapSigns,
  faQuestion,
  faPlus,
  faRedo,
  faCog,
  faMinus,
  faEnvelope,
  faTimes,
  faHeart,
  faEdit,
  faGlobeAmericas,
  faTrophy,
  faUndoAlt,
  faAngleDown,
  faAngleLeft,
  faPlusSquare,
  faThumbsUp,
  faThumbsDown,
  faDollarSign,
  faExclamationTriangle,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faSearch,
  faHome,
  faGlobe
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// Configuration
Vue.config.productionTip = false;

// Router
const router = new VueRouter({ routes });

// Primary app vue instance
// DO NOT DELETE/UNCOMMENT - Required for eslint
/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});

// TODO add instructions for install the PWA somewhere
window.addEventListener("DOMContentLoaded", () => {
  let displayMode = "browser tab";
  if (navigator.standalone) {
    displayMode = "standalone-ios";
  }
  if (window.matchMedia("(display-mode: standalone)").matches) {
    displayMode = "standalone";
  }
  // Log launch display mode to analytics
});
