<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-select-horse">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <div class="header">
            Select a location for this activity
          </div>
        </header>
        <section
          class="modal-body space--large"
          id="modalDescription"
        >
          <div v-for="location in locations" :key="location.id">
            <select-location-panel  :location="location" class="location-card" @click.native="selectLocation($event, location)">
          </div>       
        </section>
        <base-button id="back-button" @click="setToNull"> Back </base-button>
      </div>
    </div>
  </transition>
</template>

<script>
import SelectLocationPanel from "./SelectLocationPanel";
import BaseButton from "../base/BaseButton";

export default {
  name: "UserProfileSelectLocationModal",
  components: { SelectLocationPanel, BaseButton },
  props: {
    locations: Array,
  },
  data() {
    return {
      skill: 0,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setToNull() {
      this.$emit("setlocationtonull");
    },
    selectLocation(e, location) {
      this.$emit("selectlocationfrommodal", location);
    },
  },
};
</script>

<style>
.modal-backdrop-select-horse {
  width: 100%;
  height: 90%;
  position: fixed;
  top: 55px;
  overflow-y: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.99);
  display: flex;
  justify-content: center;
  z-index: 100;
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.modal-header {
  padding: 15px;
  display: flex;
}

.modal-header {
  color: #013053;
  justify-content: space-between;
  font-size: 21px;
  font-weight: 600;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.location-card {
  width: 100%;
  padding: 20px;
  -webkit-box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}

#back-button {
  width: 60%;
  margin: 0 auto;
}
</style>