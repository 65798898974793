<template>
  <form id="location" @submit.prevent="saveLocation()">
    <CoverUpload
      @delete-cover-photo-request="confirmDeleteBarnPhoto"
      name="avatar"
      :cover-photo="barn.location.avatar"
      avatar-type="barn"
    >
    </CoverUpload>

    <BaseInput
      name="name"
      label-text="Barn Name"
      v-model="barn.location.name"
    />
    <BaseInput
      name="address_attributes[street_address_line1]"
      label-text="Address Line 1"
      v-model="barn.address.street_address_line1"
    />
    <BaseInput
      name="address_attributes[street_address_line2]"
      label-text="Address Line 2"
      v-model="barn.address.street_address_line2"
    />
    <BaseInput
      name="address_attributes[city]"
      label-text="City"
      v-model="barn.address.city"
    />
    <BaseInput
      name="address_attributes[state]"
      label-text="State"
      v-model="barn.address.state"
    />
    <BaseInput
      name="address_attributes[postal_code]"
      label-text="Zip Code"
      v-model="barn.address.postal_code"
    />

    <BaseTextArea
      name="introduction"
      :label="false"
      rows="6"
      v-model="barn.location.introduction"
      placeholder="Barn description"
    />

    <Flex justify="space-between">
      <BaseButton theme="with-space" type="submit">Save Barn</BaseButton>
      <BaseButton
        v-if="barn.location.id"
        type="button"
        theme="worker with-space"
        @click="promptDeleteBarn(barn.location.id)"
      >
        Delete Barn
      </BaseButton>
    </Flex>
    <loading-modal v-if="showLoading"></loading-modal>
  </form>
</template>

<script>
import CoverUpload from "../utils/CoverUpload";
import Flex from "../style/Flex";
import LoadingModal from "../utils/LoadingModal";

export default {
  name: "LocationForm",
  components: { CoverUpload, Flex, LoadingModal },
  props: {
    currentUserId: Number,
    companyId: Number,
    barn: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      showLoading: false,
    };
  },

  mounted() {},

  methods: {
    saveLocation() {
      const data = new FormData(document.getElementById("location"));
      data.append("company_id", this.companyId);
      data.append("user_id", this.currentUserId);

      return this.barn.id
        ? this.updateLocation(data)
        : this.createLocation(data);
    },

    createLocation(formdata) {
      this.$store
        .dispatch("location/CREATE_LOCATION", formdata)
        .then((response) => {
          window.location.reload(true);
        });
    },

    updateLocation(formdata) {
      this.showLoading = true;
      const locationId = this.barn.location.id;

      this.$store
        .dispatch("location/UPDATE_LOCATION", {
          id: locationId,
          location: formdata,
        })
        .then((response) => {
          window.location.reload(true);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },

    promptDeleteBarn(barnId) {
      const barnDelete = confirm("Do You wan to delete this barn?");
      if (barnDelete) this.deleteBarn(barnId);
    },

    deleteBarn(id) {
      this.$store.dispatch("location/DELETE_LOCATION", id).then((response) => {
        window.location.reload(true);
      });
    },

    confirmDeleteBarnPhoto() {
      const deletePhoto = window.confirm(
        "Are you sure you want to delete this barn photo?"
      );
      const data = new FormData();
      data.append("destroy_avatar", true);
      const params = { id: this.barn.location.id, location: data };

      if (deletePhoto) {
        this.$store
          .dispatch("location/UPDATE_LOCATION", params)
          .then((response) => {
            window.location.reload(true);
          });
      }
    },
  },
};
</script>
