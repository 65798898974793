<template>
  <article class="activity-panel">
    <BasePanel>
      <Flex justify="between">
        <Flex justify="start">
          <div>
            <h1>{{ activity.service }}</h1>
            <p>
              {{ activity.duration_in_minutes }}
            </p>
          </div>
        </Flex>
        <slot />
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import Flex from "../style/Flex";

export default {
  name: "ActivityPanel",
  props: { activity: Object },
  components: { Flex },
};
</script>

<style scoped>
h1 {
  color: var(--black);
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  color: var(--black);
  font-size: 0.875em;
  font-weight: var(--semibold);
}

.activity-panel {
  width: 100%;
}
</style>
