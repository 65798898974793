<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-select-horse">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="company-heading"
          v-if="company"
          v-bind:style="companyImage"
        >
          <div class="heading-content">
            <div class="logo-box">
              <img :src="logoImage" alt="logo" class="logo-image" />
            </div>
            <h3 class="text-center title">{{ company.name }}</h3>
          </div>
        </header>
        <base-button id="view-profile-button" @click="goToCompanyProfile"
          >VIEW PROFILE</base-button
        >

        <section class="modal-body space--large" id="modalDescription">
          <div class="content-title">Activity Packages</div>
          <div
            class="content"
            v-if="companyPackages && companyPackages.length > 0"
          >
            <div
              class="package-card"
              v-bind:class="{
                highlighted: checkPackageForActivity(companyPackage),
              }"
              v-for="companyPackage in companyPackages"
              :key="companyPackage.id"
            >
              <div class="package-card-heading">
                <div class="package-card-title">{{ companyPackage.name }}</div>
                <div class="package-card-price">
                  ${{ companyPackage.price }}
                </div>
              </div>
              <div class="package-card-description">
                {{ companyPackage.description }}
              </div>
              <div class="package-card-includes-container">
                <div class="package-card-includes-label">Includes:</div>
                <div
                  class="package-card-includes-content"
                  v-for="membership in companyPackage.package_memberships"
                  :key="membership.id"
                >
                  {{ membership.quantity }} x
                  {{ membership.activity_credit.name }}
                </div>
              </div>
              <base-button
                class="button-buy-activity-package"
                @click="buyPackage(companyPackage.id)"
                >BUY PACKAGE - ${{ companyPackage.price }}</base-button
              >
              <div class="btn-question" @click="showInfo(companyPackage)">
                <font-awesome-icon
                  class="question-icon"
                  :icon="['fas', 'question']"
                />
              </div>
            </div>
          </div>
        </section>
        <base-button id="back-button" @click="goBack">Back</base-button>
      </div>
      <base-modal ref="modal">
        <div>
          Used for:
          <ul>
            <li v-for="item in usedForActivities" :key="item">{{ item }}</li>
          </ul>
        </div>
      </base-modal>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BuyActivityPackagesModal",
  props: {
    companyId: Number,
    activityId: Number,
  },
  data() {
    return {
      companyPackages: [],
      company: null,
      defaultCompanyImage: require("../../assets/images/covers/company.jpg"),
      logoImage: require("../../assets/images/logo.svg"),
      usedForActivities: [],
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    companyImage() {
      if (this.company.avatar) {
        return {
          "background-image": "url(" + this.company.avatar + ")",
        };
      }
      return {
        "background-image": "url(" + this.defaultCompanyImage + ")",
      };
    },
    filteredCompanyActivities() {
      return [];
    },
  },
  async mounted() {
    this.$store.commit("global/START_LOADING");
    await this.$store
      .dispatch("company/GET_COMPANY", this.companyId)
      .then((r) => {
        this.company = r.data;
      });

    await this.$store
      .dispatch("activityPackage/RETRIEVE_COMPANY_PACKAGES", this.companyId)
      .then((r) => {
        this.companyPackages = r.data;
      })
      .catch((e) => {});
    this.$store.commit("global/STOP_LOADING");
  },
  methods: {
    goBack() {
      this.$emit("goback");
    },
    checkPackageForActivity(companyPackage) {
      return companyPackage.activity_credits.some((credit) => {
        return credit.company_activities.some((activity) => {
          return activity.activity_id == this.activityId;
        });
      });
    },
    showInfo(companyPackage) {
      this.usedForActivities = null;
      let acts = [];
      companyPackage.activity_credits.forEach((activity_credit) => {
        activity_credit.company_activities.forEach((company_activity) => {
          acts.push(company_activity.full_name);
        });
      });
      var uniqueItems = Array.from(new Set(acts));
      this.usedForActivities = uniqueItems;
      this.$refs.modal.openModal();
    },
    goToCompanyProfile() {
      if (!this.activityId) {
        this.$emit("goback");
        return;
      }
      if (
        confirm(
          "You will lose you current booking! Are you sure you want to continue?"
        )
      ) {
        this.$router.push("/companies/profile/" + this.company.id);
      }
    },
    async buyPackage(id) {
      if (confirm("Do you confirm the payment?")) {
        this.$store.commit("global/START_LOADING");
        let params = {
          user_id: this.currentUser.id,
          activity_package_id: id,
        };
        await this.$store
          .dispatch("activityPackage/BUY_ACTIVITY_PACKAGE", params)
          .then((r) => {})
          .finally(() => {
            this.$store.commit("global/STOP_LOADING");
          });
        this.$emit("goback");
      }
    },
  },
};
</script>

<style scoped>
.package-card-includes-container {
  margin-left: 6px;
  margin-bottom: 20px;
}
.package-card-includes-content {
  margin-left: 14px;
  font-size: 15px;
  color: var(--dark-grey);
  font-weight: 400;
}
.package-card-includes-label {
  font-size: 18px;
  color: var(--dark-grey);
  font-weight: 600;
}
.package-card-title {
  font-size: 20px;
  font-weight: 600;
}
.package-card-price {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-grey);
}
.modal-backdrop-select-horse {
  width: 100%;
  height: calc(100% - 70px);
  position: fixed;
  top: 55px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.99);
  display: flex;
  justify-content: center;
  z-index: 100;
  overflow-y: auto;
  padding-bottom: 20px;
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.modal-header {
  padding: 15px;
  display: flex;
}
.btn-question {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  color: #64c8c1;
  background-color: #ffffff;
  text-align: center;
  font-size: 40px;
  margin-right: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.modal-header {
  color: #013053;
  justify-content: center;
  margin-top: 10px;
  font-size: 21px;
  font-weight: 600;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.location-card {
  width: 100%;
  padding: 20px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

#back-button,
#view-profile-button {
  width: 60%;
  margin: 0 auto;
  color: var(--dark-grey);
  background-color: white;
  border: 1px solid var(--dark-grey);
  font-weight: 600;
}
#back-button {
  margin-bottom: 70px;
}

.button-buy-activity-package {
  width: 85%;
  margin: 0 auto;
  color: white;
  background-color: var(--dark-grey);
  font-weight: 600;
  letter-spacing: 1.1;
}

.question-icon {
  font-size: 18px;
  color: #9b9b9b;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

#view-profile-button {
  transform: translateY(-50%);
}

#save-button {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.company-heading {
  /* background: url(../../assets/images/covers/company.jpg); */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 35vh;
}
.heading-content {
  position: absolute;
  height: 15%;
  width: 60%;
  left: 50%;
  top: 5%;
  transform: translateX(-50%);
  text-align: center;
}

.logo-image {
  height: 100px;
  width: 75%;
}
.title {
  margin-top: 30px;
  font-size: 1.4em;
  font-weight: var(--semibold);
  line-height: 1.6;
  color: #ffffff;
}

.content-title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
}

.package-card {
  /* border: 3px solid var(--grey); */
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 8px;
  position: relative;
  margin: 10px 0px;
}
.package-card-description {
  margin: 15px 5px;
}

.highlighted {
  background-color: var(--transparent-seafoam);
}
</style>