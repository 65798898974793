<template>
  <transition name="modal-fade">
    <div class="modal-backdrop-select-horse">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <div class="header">Change password</div>
        </header>
        <section class="modal-body space--large" id="modalDescription">
          <BaseInput
            type="password"
            name="current-password"
            label-text="Current Password"
            v-model="currentPassword"
          />
          <BaseInput
            type="password"
            name="new-password"
            label-text="New Password"
            v-model="newPassword"
          />
          <BaseInput
            type="password"
            name="confirm-password"
            label-text="Confirm Password"
            v-model="confirmPassword"
          />
        </section>
        <base-button id="save-button" @click="changePassword">
          Change Password
        </base-button>
        <base-button id="back-button" @click="goBack"> Back </base-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ChangePasswordModal",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  methods: {
    goBack() {
      this.$emit("goback");
    },
    changePassword() {
      if (this.newPassword != this.confirmPassword) {
        alert("Your new password and confirmation password does not match");
        return;
      }
      let params = {
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        oldPassword: this.currentPassword,
        userId: this.currentUser.id,
      };
      this.$store
        .dispatch("user/CHANGE_PASSWORD", params)
        .then((r) => {
          this.goBack();
        })
        .catch((e) => {
          this.goBack();
        });
    },
  },
};
</script>

<style scoped>
.modal-backdrop-select-horse {
  width: 100%;
  height: 90%;
  position: fixed;
  top: 55px;
  overflow-y: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.99);
  display: flex;
  justify-content: center;
  z-index: 100;
}

.modal {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  z-index: 101;
}

.modal-header {
  padding: 15px;
  display: flex;
}

.modal-header {
  color: #013053;
  justify-content: center;
  margin-top: 10px;
  font-size: 21px;
  font-weight: 600;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.location-card {
  width: 100%;
  padding: 20px;
  -webkit-box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}

#back-button {
  width: 60%;
  margin: 0 auto;
}
#save-button {
  width: 60%;
  margin: 0 auto;
  margin-bottom: 10px;
}
</style>