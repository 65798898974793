<template>
  <Flex justify="center" direction="column">
    <h1>Request Sent!</h1>

    <div v-if="location.name">
      <UserActivityInformation
        :employee-activity="userActivity.employee_activity"
        :time-block="userActivity.time_block"
        :location="location"
      >
      </UserActivityInformation>
    </div>
  </Flex>
</template>

<script>
import Flex from "../style/Flex";
import UserActivityInformation from "./UserActivityInformation";

export default {
  name: "UserActivityRequestConfirmation",
  props: { userActivity: Object },
  components: { Flex, UserActivityInformation },
  data() {
    return {
      location: {},
    };
  },

  mounted() {
    setTimeout(() => {
      this.findLocation();
    }, 2000);
  },

  methods: {
    findLocation() {
      this.$store
        .dispatch("location/GET_LOCATION", this.userActivity.location_id)
        .then((response) => {
          this.location = response.data;
        });
    },
  },
};
</script>

<style style scoped>
h1,
h2 {
  font-size: 1.25em;
  font-weight: var(--semibold);
}
</style>
