<template>
  <div>
    <div v-if="creditCard.id && !editing">
      <Flex justify="center" direction="column">
        <Flex justify="center">
          <h1>Card Information</h1>
          <button @click="promptDelete()">
            <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
          </button>
        </Flex>

        <BaseCreditCard
          :name="creditCard.name || currentUser.name"
          :credit-card="creditCard"
        />
      </Flex>
      <Flex class="billing-box" justify="center" direction="column">
        <h2 class="emphasized billing-info">Billing Address</h2>
        <div>
          <p class="billing-info">
            {{ billingAddress.street_address_line1 }}
          </p>
          <p class="billing-info">
            {{ billingAddress.street_address_line2 }}
          </p>
          <p class="billing-info">
            {{ billingAddress.city }}, {{ billingAddress.state }},
            {{ billingAddress.postal_code }}
          </p>
        </div>
      </Flex>

      <Flex
        v-if="!currentUser.require_pin"
        class="billing-box"
        justify="center"
        direction="column"
      >
        <p class="emphasized">Pin # is NOT required for all transactions</p>

        <button class="link-text" @click="toggleEditMode()">
          Would you like to add one? Edit Payment Profile
        </button>
      </Flex>
    </div>

    <form id="payment" v-else>
      <h2 class="section-title">Card Holder Information</h2>
      <BaseInput
        name="name"
        label-text="Full Name on Card"
        v-model="creditCard.name"
      />
      <BaseInput
        name="address_1"
        label-text="Billing Address"
        v-model="billingAddress.street_address_line1"
      />
      <BaseInput
        name="address_2"
        label-text="Billing Address Line 2"
        v-model="billingAddress.street_address_line2"
      />
      <BaseInput name="city" label-text="City" v-model="billingAddress.city" />
      <BaseInput
        name="state"
        label-text="State"
        v-model="billingAddress.state"
      />
      <BaseInput
        name="zip"
        label-text="Zip Code"
        v-model="billingAddress.postal_code"
      />

      <h2 class="section-title">Credit Card Information</h2>
      <BaseInput
        name="cc_number"
        label-text="Card Number"
        maxlength="16"
        v-model="creditCard.number"
      />
      <Flex justify="between">
        <BaseInput
          class="auto"
          name="exp_month"
          label-text="Exp Month"
          v-model="creditCard.exp_month"
        />
        <BaseInput
          class="auto"
          name="exp_year"
          label-text="Exp Year"
          maxlength="4"
          v-model="creditCard.exp_year"
        />
        <BaseInput
          class="auto"
          maxlength="4"
          name="cc_cvc"
          label-text="CVC"
          v-model="creditCard.cvc"
        />
      </Flex>

      <div v-if="!currentUser.of_age">
        As a minor, a four digit pin must be set by your guardian for
        transactions
      </div>
      <div v-else>
        Would you like to require a PIN for all transactions? Parent/gaurdian:
        This PIN will be required for all purchases. Forgotten PINs cannot be
        retreived for security purposes. Forgotten PINs will require a reset to
        this payment profile.
        <BaseCheckbox name="Yes" v-model="currentUser.require_pin" />
      </div>

      <Flex
        justify="between"
        v-if="currentUser.require_pin || !currentUser.of_age"
      >
        <BaseInput
          type="password"
          class="auto"
          maxlength="4"
          name="pin"
          label-text="Transaction Pin"
          v-model="pin"
        />
        <BaseInput
          type="password"
          class="auto"
          maxlength="4"
          name="pin_confirm"
          label-text="Confirm Pin"
          v-model="confirmPin"
        />
      </Flex>
    </form>

    <Flex direction="column">
      <div v-if="errorMessage">
        <p>{{ errorMessage }}</p>
      </div>

      <BaseButton
        v-if="creditCard.id"
        theme="alternate"
        @click="toggleEditMode()"
      >
        {{ editing ? "View Current Payment Profile" : "Edit Payment Profile" }}
      </BaseButton>
      <BaseButton type="button" @click="saveOrContinue()"
        >Save & Continue</BaseButton
      >
    </Flex>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../../style/Flex";

export default {
  name: "Payment",
  title: null,
  components: { Flex },
  props: {
    currentIndex: Number,
    steps: Array,
  },
  data() {
    return {
      pin: "",
      confirmPin: "",
      creditCard: {},
      stripeAccount: {},
      billingAddress: {},
      editing: false,
      error: false,
      errorMessage: "",
    };
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  async mounted() {
    this.$store.commit("global/START_LOADING");
    setTimeout(async () => {
      if (this.currentUser.stripe_customer_id)
        await this.fetchUserPaymentProfile();
      if (this.currentUser.billing_address)
        this.billingAddress = this.currentUser.billing_address;
      this.$store.commit("global/STOP_LOADING");
    }, 2000);
  },

  methods: {
    params() {
      return {
        user_id: this.currentUser.id,
        pin: this.pin,
        require_pin: this.currentUser.require_pin,
        credit_card_attributes: {
          name: this.creditCard.name,
          number: this.creditCard.number,
          cvc: this.creditCard.cvc,
          exp_month: this.creditCard.exp_month,
          exp_year: this.creditCard.exp_year,
        },
        address_attributes: {
          street_address_line1: this.billingAddress.street_address_line1,
          street_address_line2: this.billingAddress.street_address_line2,
          state: this.billingAddress.state,
          city: this.billingAddress.city,
          postal_code: this.billingAddress.postal_code,
        },
      };
    },

    async fetchUserPaymentProfile() {
      if (!this.currentUser || !this.currentUser.stripe_customer_id) {
        return;
      }

      await this.$store
        .dispatch("payment/FETCH_USER_PAYMENT_PROFILE", this.currentUser.id)
        .then((response) => {
          this.stripeAccount = response.data;

          const defaultSource = response.data.sources.data.find(
            (source) => source.id === response.data.default_source
          );
          if (defaultSource) this.creditCard = defaultSource;
        });
    },

    pinInvalid() {
      if (this.currentUser.require_pin && this.pin !== this.confirmPin) {
        this.handleError(
          "Your pin numbers do not match. Please verify and try again."
        );
        return true;
      } else {
        return false;
      }
    },

    saveOrContinue() {
      this.editing || !this.creditCard.id
        ? this.savePaymentProfile()
        : this.$parent.nextPage();
    },

    savePaymentProfile() {
      if (this.pinInvalid()) return;

      this.$store
        .dispatch("payment/UPDATE_USER_PAYMENT_PROFILE", this.params())
        .then((response) => {
          this.handleSuccess();
          this.$parent.nextPage();
        });
    },

    promptDelete() {
      const deletePaymentProfile = confirm(
        "Are you sure you want to delete this payment profile?"
      );
      if (deletePaymentProfile) this.deletePaymentProfile();
    },

    deletePaymentProfile() {
      const params = {
        user_id: this.currentUser.id,
        source_id: this.creditCard.id,
      };
      this.$store
        .dispatch("payment/DELETE_USER_PAYMENT_PROFILE", params)
        .then((response) => {
          this.handleSuccess();
          window.location.reload(true);
        });
    },

    handleError(errorMessage) {
      this.error = true;
      this.errorMessage = errorMessage;
    },

    handleSuccess() {
      this.error = false;
    },

    toggleEditMode() {
      this.editing = !this.editing;
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: 600;
}
p,
a {
  font-size: 1.125em;
}
.emphasized {
  font-size: 1.125em;
  font-weight: 600;
}
.billing-info {
  padding: 0em 1em 0.125em 1em;
}
.billing-box {
  margin: 1em;
}
.link-text {
  color: var(--blue);
}
</style>
