<template>
  <div>
    <div v-if="!editingMode">
      <ActivityPanel
        v-for="companyActivity in companyActivities"
        :key="companyActivity.id"
        :activity="companyActivity.activity"
        @click.native="editCompanyActivity(companyActivity)"
      >
        <Flex justify="start" v-if="showPayment">
          <p class="price">Price: ${{ companyActivity.price }}</p>
        </Flex>
        <ButtonEdit />
      </ActivityPanel>

      <Flex justify="between" class="space">
        <BaseButton @click="openCompanyActivity()"
          >Add New Company Activity</BaseButton
        >
        <!-- <BaseButton theme="worker" @click="openCustomActivity()"
          >Add Custom Company Activity</BaseButton
        > -->
      </Flex>

      <Flex justify="between">
        <BaseButton theme="alternate" @click="goToDashboard()"
          >Save & Continue</BaseButton
        >
      </Flex>
    </div>

    <div v-if="editing">
      <CompanyActivityForm
        :company-activity="currentActivity"
        @save-activity-success="saveSuccess"
      />

      <BaseButton class="space" @click="toggleEditMode('editing', false)"
        >Cancel</BaseButton
      >
    </div>

    <div v-if="addingNew">
      <CustomCompanyActivityForm
        :custom-activity="selectedCustomActivity"
        @create-activity-success="saveCreate"
      />

      <BaseButton class="space" @click="toggleEditMode('addingNew', false)"
        >Cancel</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ActivityPanel from "../../activities/ActivityPanel";
import CompanyActivityForm from "../../activities/CompanyActivityForm";
import CustomCompanyActivityForm from "../../companies/CustomCompanyActivityForm";
import ButtonEdit from "../../buttons/ButtonEdit";
import Flex from "../../style/Flex";

export default {
  name: "Activities",
  title: null,
  components: {
    Flex,
    ActivityPanel,
    CompanyActivityForm,
    ButtonEdit,
    CustomCompanyActivityForm,
  },
  props: {
    currentIndex: Number,
    steps: Array,
  },

  data() {
    return {
      companyActivities: [],
      currentActivity: {},
      editing: false,
      addingNew: false,
      selectedCustomActivity: null,
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),

    editingMode() {
      return this.editing || this.addingNew;
    },

    showPayment() {
      let paymentSetting = this.currentUser.company_settings.filter((e) => {
        return e.name == "no_payment";
      }).length;
      return paymentSetting == 0;
    },
  },
  mounted() {},

  async mounted() {
    this.$store.commit("global/START_LOADING");
    await this.getCompanyActivities();
  },

  methods: {
    async getCompanyActivities() {
      await this.$store
        .dispatch("activity/GET_COMPANY_ACTIVITIES", {
          companyId: this.currentUser.company_id,
        })
        .then((response) => {
          this.companyActivities = response.data;
        })
        .finally(() => {
          this.$store.commit("global/STOP_LOADING");
        });
    },

    toggleEditMode(prop, state) {
      this[prop] = state;
    },

    saveSuccess(companyActivity) {
      if (
        !this.companyActivities.map((a) => a.id).includes(companyActivity.id)
      ) {
        this.companyActivities.push(companyActivity);
      }

      this.toggleEditMode("editing", false);
    },

    saveCreate(companyActivity) {
      this.getCompanyActivities();
      this.toggleEditMode("addingNew", false);
    },

    openCompanyActivity() {
      this.currentActivity = {};
      this.toggleEditMode("editing", true);
    },

    openCustomActivity() {
      this.selectedCustomActivity = null;
      this.toggleEditMode("addingNew", true);
    },

    editCompanyActivity(companyActivity) {
      if (companyActivity.activity.custom_activity_company_id) {
        this.selectedCustomActivity = companyActivity;
        this.toggleEditMode("addingNew", true);
      } else {
        this.currentActivity = companyActivity;
        this.toggleEditMode("editing", true);
      }
    },

    goToDashboard() {
      const newPath = this.steps[this.currentIndex + 1].value;
      this.$router.push(newPath);
    },
  },
};
</script>

<style scoped>
.price {
  color: var(--navy);
  font-size: 1.3125em;
  font-weight: var(--semibold);
}
</style>
