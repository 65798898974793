<template>
  <div>
    <div class="overlay" v-if="open" @click="closeModal()">
      <Flex justify="end" class="space">
        <BaseButton
          class="close-button"
          theme="round alternate"
          aria-label="close modal"
          @click="closeModal()"
        >
          <font-awesome-icon
            :icon="['fas', 'times']"
            fixed-width
            @click.stop="closeModal()"
          />
        </BaseButton>
      </Flex>

      <div class="modal" @click.stop="">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Flex from "../style/Flex";

export default {
  name: "BaseModal",
  components: { Flex },

  data() {
    return {
      open: false,
    };
  },

  methods: {
    bodyOverflowToggle() {
      const element = document.getElementById("app");
      element.className += "hide";
    },

    openModal() {
      this.open = true;
    },

    closeModal() {
      this.open = false;
    },
  },
};
</script>

<style scoped>
.overlay {
  align-content: center;
  background: rgba(255, 255, 255, 0.8);
  color: var(--black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  padding: 1em;
  position: fixed;
  z-index: 150;

  top: -50vh;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal {
  align-content: center;
  display: flex;
  justify-content: center;
  overflow: scroll;
  width: 95%;
  margin-top: 120px;
}
.close-button {
  z-index: 1007;
  position: fixed;
  top: 10vh;
}
.space {
  min-height: 50px;
}
</style>
