<template>
  <article>
    <BasePanel type="active">
      <Flex justify="between" align="center">
        <div>
          <h1>{{ name }}</h1>
          <p>Lesson Type</p>
        </div>

        <slot></slot>
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import Flex from "../style/Flex";

export default {
  name: "BaseLesson",
  props: { name: String },
  components: { Flex },
};
</script>

<style scoped>
h1 {
  font-weight: var(--semibold);
  margin-bottom: 0.35em;
}
p {
  font-size: 0.875em;
}
</style>
