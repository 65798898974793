<template>
  <section class="space--large" v-if="userActivities.length > 0">
    <BasePanel>
      <Flex justify="start">
        <font-awesome-icon
          :icon="['far', 'calendar-alt']"
          class="bookings-calendar"
        />
        <div>
          <header class="bookings-title">
            <h1>{{ userActivities.length }} booking requests</h1>
          </header>
          <!--<p class="bookings-breakdown">Breakdown: 12 lessons | 0 tasks</p>-->
        </div>
      </Flex>
      <hr class="bookings-divider" />
      <Flex justify="between">
        <BaseLink theme="button narrow" v-bind:link="computedRoute"
          >View Requests</BaseLink
        >
        <BaseLink theme="button narrow alternate" link="/activities"
          >Add Activity</BaseLink
        >
      </Flex>
    </BasePanel>
  </section>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";

export default {
  name: "BookingRequestPanel",
  components: { Flex },

  data() {
    return {
      userActivities: [],
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    computedRoute: function () {
      if (this.currentUser.account_type === "personal") {
        return "/users/activity-requests/" + this.currentUser.id;
      }
      return "/trainers/activity-requests/" + this.currentUser.id;
    },
  },

  mounted() {
    setTimeout(() => this.findTrainerUserActivities(), 500);
  },

  methods: {
    findTrainerUserActivities() {
      if (this.currentUser.account_type == "personal") {
        this.$store
          .dispatch("activity/GET_UNAPPROVED_ACTIVITIES", this.currentUser.id)
          .then((response) => {
            this.userActivities = response.data;
          });
      } else {
        this.$store
          .dispatch("activity/GET_TRAINER_USER_ACTIVITIES", {
            trainerId: this.currentUser.id,
            forApproval: true,
          })
          .then((response) => {
            this.userActivities = response.data;
          });
      }
    },
  },
};
</script>

<style scoped>
.bookings-calendar {
  color: var(--navy);
  font-size: 4em;
}
.bookings-title {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
  margin-bottom: 0.2em;
}
.bookings-breakdown {
  color: var(--dark-grey);
  font-size: 0.875em;
}
.bookings-divider {
  border-color: var(--panel-border-color);
}
</style>
