<template>
  <ul v-if="activities.length > 0">
    <li v-for="activity in activities" :key="activity.id">
      <span class="time">
        {{ getStartDate(activity) }}
      </span>

      <span :class="classesForActivity(activity)">
        {{ displayUserOrTrainerName(activity) }}-
        {{ activity.employee_activity.activity.service }}
      </span>
      <span class="horse">
        <font-awesome-icon :icon="['fas', 'horse-head']" class="marker" />
        {{ activity.horse }}
      </span>
      <span class="location">
        <font-awesome-icon :icon="['fas', 'map-marker-alt']" class="marker" />
        {{ activity.location_name }}
        <font-awesome-icon
          v-if="activity.status != 'declined'"
          @click="promptCancelActivity(activity)"
          :icon="['far', 'trash-alt']"
          fixed-width
        />
      </span>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import Flex from "../style/Flex";
import Avatar from "../utils/Avatar";
import ButtonAddRemove from "../buttons/ButtonAddRemove";
import ButtonEdit from "../buttons/ButtonEdit";

export default {
  name: "CalendarList",
  components: { Flex, Avatar, ButtonAddRemove, ButtonEdit },
  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      activities: [],
    };
  },

  created() {
    setTimeout(() => this.getActivities(), 2000);
  },

  methods: {
    classesForActivity(activity) {
      if (activity.status == "declined") {
        return "activity strikethrough";
      } else {
        return "activity";
      }
    },
    getActivities() {
      if (this.currentUser.account_type == "personal") {
        this.$store
          .dispatch("activity/GET_USER_ACTIVITIES", {
            userId: this.currentUser.id,
          })
          .then((response) => {
            this.activities = response.data;
          });
      } else if (this.currentUser.account_type == "worker") {
        this.$store
          .dispatch("activity/GET_COMPANY_USER_ACTIVITIES", {
            companyId: this.currentUser.company_id,
          })
          .then((response) => {
            this.activities = response.data;
          });
      } else {
        this.$store
          .dispatch("activity/GET_COMPANY_USER_ACTIVITIES", {
            companyId: this.currentUser.company_id,
          })
          .then((response) => {
            this.activities = response.data;
          });
      }
    },

    promptCancelActivity(userActivity) {
      const cancelActivity = confirm(
        "Are you sure you want to cancel this activity?"
      );
      if (cancelActivity) this.cancelRequest(userActivity);
    },

    cancelRequest(userActivity) {
      this.$store
        .dispatch("activity/RIDER_DECLINE_USER_ACTIVITY", userActivity.id)
        .then((response) => {
          window.location.reload(true);
        });
    },

    getStartDate(activity) {
      const formattedTime = this.moment
        .utc(activity.time_block.start_time)
        .format("ddd MMMM DD, YYYY - hh:mm a");
      return formattedTime;
    },

    displayUserOrTrainerName(activity) {
      return this.currentUser.account_type == "personal"
        ? activity.trainer
        : activity.participant.name;
    },
  },
};
</script>

<style scoped>
ul {
  margin-left: 0.25em;
}

li {
  border-left: 1px solid var(--border-color);
  font-weight: var(--semibold);
  padding: 0 0.5em 2.25em 1em;
  position: relative;
}

li:last-child {
  border-left-color: transparent;
}

li:before {
  background-color: #f5a623;
  border: 0.2em solid var(--white);
  border-radius: 100%;
  content: "";
  display: block;
  height: 0.5em;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0%);
  width: 0.5em;
}

/* li:nth-child(5n + 2):before {
  background-color: #d0021b;
}
li:nth-child(5n + 3):before {
  background-color: #7ed321;
}
li:nth-child(5n + 4):before {
  background-color: #f8e71c;
}
li:nth-child(5n + 5):before {
  background-color: #4a90e2;
} */
.time {
  color: var(--dark-grey);
  display: block;
  font-size: 0.875em;
  margin-bottom: 1em;
}

.horse {
  margin-right: 0.5em;
}

.activity {
  display: block;
  margin-bottom: 0.75em;
}

.location {
  font-size: 0.875em;
}

.marker {
  margin-right: 0.25em;
}

.strikethrough {
  text-decoration: line-through;
}
</style>
