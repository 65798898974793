<template>
  <form @submit.prevent="payInvoice">
    <h1>Payment Details</h1>

    <BasePanel theme="active" v-if="invoice.id">
      <div v-if="invoice.paid">
        <h3>Invoice is Paid.</h3>
      </div>

      <Flex justify="between" class="space">
        <Flex justify="start">
          <h2>Amount: ${{ invoice.amount }}</h2>

          <h2>Company: {{ invoice.company.name }}</h2>
          <Flex justify="start">
            <Avatar size="small" :image-source="invoice.company.avatar" />
          </Flex>
        </Flex>
      </Flex>

      <Flex class="space" justify="start" v-if="invoice.activity">
        <h2>Activity: {{ invoice.activity.full_title }}</h2>
      </Flex>

      <p class="space">
        Due Date: {{ moment.utc(invoice.due_date).format("MMM DD, YYYY") }}
      </p>

      <div class="space">
        <h3>Notes:</h3>
        <p>{{ invoice.notes }}</p>
      </div>

      <div class="space" v-if="invoice.subscription_allowed">
        <BaseCheckbox
          label-text="Would you like to make this a recurring payment?"
          name="subscription"
          v-model="invoice.subscription"
        />
      </div>

      <BaseButton v-if="!invoice.paid" type="submit">Pay Invoice</BaseButton>
    </BasePanel>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";

export default {
  name: "PaymentDetails",
  components: { Avatar, Flex },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },

  data() {
    return {
      invoice: {},
    };
  },

  mounted() {
    setTimeout(() => this.getInvoice(), 2000);
  },

  methods: {
    getInvoice() {
      const params = {
        user_id: this.currentUser.id,
        id: this.$route.params.id,
      };

      this.$store
        .dispatch("payment/GET_USER_INVOICE", params)
        .then((response) => {
          this.invoice = response.data;
        });
    },

    payInvoice() {
      const params = {
        id: this.invoice.id,
        subscription: this.invoice.subscription,
      };
      this.$store.dispatch("payment/PAY_INVOICE", params).then((response) => {
        this.$router.push("/user/invoices");
      });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin: 1em;
}
.title {
  font-size: 1.75em;
  font-weight: var(--semibold);
  color: var(--worker-accent-color);
  margin: 0.5em;
}

.details {
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin: 0.5em;
}
</style>