<template>
  <div class="base-styles">
    <h2>Buttons</h2>
    <BaseButton>Button</BaseButton>
    <BaseLink>Link Thing</BaseLink>
    <BaseInput type="text" label-text="Text Input" v-model="textInputValue" />
    <BaseInput
      type="email"
      label-text="Email Input"
      v-model="emailInputValue"
    />
    <BaseCheckbox label-text="Checkbox" v-model="booleanValue" />
    <BaseRadio label-text="Radio" v-model="steps" />
    <BaseSelect label-text="Select" :options="steps" v-model="currentStep" />
    <BaseTextArea label-text="Text Area" />
    <h2>Avatars</h2>
    <Avatar />
    <Avatar size="large" />
    <h2>Panels</h2>
    <BasePanel>
      <Flex justify="start" align="center">
        <Avatar />
        <div>
          Farm Name
          <br />
          Role
        </div>
      </Flex>
    </BasePanel>
    <BaseProgress :current-step="currentStep" :steps="steps"
      >Button</BaseProgress
    >
    <CalendarList />
    <CalendarPanel />
    <CalendarDatePanel />
    <PaymentPanel />
  </div>
</template>

<script>
import Avatar from "../utils/Avatar";
import CalendarList from "../calendars/CalendarList";
import CalendarPanel from "../calendars/CalendarPanel";
import CalendarDatePanel from "../calendars/CalendarDatePanel";
import Flex from "../style/Flex";
import PaymentPanel from "../invoices/PaymentPanel";

export default {
  name: "PageBaseStyles",
  title: "Base Styles",
  components: {
    Flex,
    Avatar,
    CalendarList,
    CalendarPanel,
    CalendarDatePanel,
    PaymentPanel,
  },

  data() {
    return {
      textInputValue: "",
      emailInputValue: "",
      booleanValue: true,
      currentStep: "other",
      steps: [
        { text: "thing", value: "thing" },
        { text: "other", value: "other" },
        { text: "another", value: "another" },
      ],
      activity: {
        id: 5,
        type: "Lesson",
        service: "Semi-Private Riding Lesson",
        amount: 1,
        duration: 3600,
        duration_in_minutes: "60 Minuntes",
        description:
          "Get the added benefit and fun of riding with friends while still getting a lot of personal attention in a 1 hour semi-private riding lesson. Semi-private lessons have a minimum of 2 riders and a maximum of 3.",
      },
    };
  },
};
</script>

<style scoped>
</style>
