<template>
  <header class="header">
    <div
      class="overlay"
      :class="{ 'overlay--active': theSidebarExpanded }"
      @click="closeTheSidebar"
    ></div>
    <nav>
      <ul>
        <li>
          <router-link to="/">
            <img src="../../assets/images/logo-small.svg" alt="Riding Post" />
          </router-link>
        </li>
        <li v-if="$route.path == '/'">
          <router-link to="/">
            <img :src="logoSrc" class="logo" alt="Riding Post" />
          </router-link>
        </li>
        <li v-else>
          <h1>{{ title }}</h1>
        </li>
        <li>
          <!-- <div id="magnifier" @click.stop="goToSearch" v-if="currentUser.id">
            <font-awesome-icon
              id="magnifier-icon"
              :icon="['fas', 'search']"
              style="color: white"
            />
          </div> -->
          <TheMenuButton />
        </li>
      </ul>
    </nav>
    <TheSidebar />
  </header>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TheMenuButton from "./TheMenuButton";
import TheSidebar from "./TheSidebar";

export default {
  name: "TheHeader",
  components: {
    TheMenuButton,
    TheSidebar,
  },

  data() {
    return {
      title: "Riding Post",
      logoSrc: require("../../assets/images/logo.svg"),
    };
  },

  computed: mapState({
    theSidebarExpanded: (state) => state.theSidebar.expanded,
    currentUser: (state) => state.user.currentUser,
  }),

  methods: {
    ...mapActions("theSidebar", ["closeTheSidebar"]),
    goToSearch() {
      this.$router.push("/search");
    },
  },
};
</script>

<style scoped>
header {
  background-color: var(--current-accent-color);
  box-sizing: content-box;
  color: #ffffff;
  height: 60px;
  padding-top: env(safe-area-inset-top); /* iPhone X */
  position: fixed;
  width: 100%;
  z-index: 1004;
  left: 0;
  right: 0;
  top: 0;
  transition: background-color 0.2s;
}
.overlay {
  background: rgba(255, 255, 255, 0);
}
.overlay--active {
  background: rgba(255, 255, 255, 1);
  content: "";
  position: fixed;
  transition: background 0.25s;

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
#magnifier {
  margin-right: 40px;
}
#magnifier-icon {
  height: 100%;
  width: 28px;
}
ul {
  display: grid;
  grid-template-columns: auto auto auto;
  list-style-type: none;
  padding: 0;
  width: 100%;
}
li {
  align-items: flex-start;
  display: inline-flex;
  justify-content: center;
  padding: 0.8125em 1em;
  text-align: center;
}
li:first-child {
  justify-content: flex-start;
}
li:last-child {
  justify-content: flex-end;
}
a {
  text-decoration: none;
}
h1 {
  font-size: 1.5625em;
}
.logo {
  height: auto;
  width: 140px;
  margin-top: 40px;
}
</style>
