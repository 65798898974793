<template>
  <div>
    <BaseProgress :current-step="currentStep" :steps="steps"></BaseProgress>
    <component
      v-bind:is="currentStep"
      :current-index="currentIndex"
      :steps="steps"
      v-bind="currentLocationId"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Profile from "./company/Profile";
import Payment from "./company/Payment";
import Locations from "./company/Locations";
import CompanyHorses from "./company/CompanyHorses";
import Activities from "./company/Activities";
import Employees from "./company/Employees";
import Employers from "./worker/Employers";
import Services from "./worker/Services";
import Availability from "./worker/Availability";
import Flex from "../style/Flex";
import AvatarUpload from "../utils/AvatarUpload.vue";
import ActivityPackages from "./company/ActivityPackages";

export default {
  name: "PageRegistrationCompanyProfile",
  title: null,
  components: {
    Flex,
    Employers,
    Services,
    Availability,
    AvatarUpload,
    Profile,
    Payment,
    Locations,
    Activities,
    Employees,
    CompanyHorses,
    ActivityPackages,
  },
  params: ["goToLocationId"],

  data() {
    return {
      steps: [
        { text: "Profiles", value: "profile" },
        // { text: "Company Payment", value: "payment" },
        { text: "Locations", value: "locations" },
        { text: "Horses", value: "CompanyHorses" },
        { text: "Company Services", value: "activities" },
        { text: "My Services", value: "services" },
        { text: "My Availability", value: "availability" },
        // { text: "Service Packages", value: "activityPackages" },
        { text: "Company Employees", value: "employees" },
        { text: "My Employers", value: "employers" },
      ],
      workerSteps: [
        /* { text: 'Schedule', value: 'schedule' }*/
      ],
    };
  },

  computed: {
    currentStep() {
      return this.$route.params.step;
    },
    currentIndex() {
      return this.steps.findIndex((step) => step.value === this.currentStep);
    },
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    currentLocationId() {
      if (this.$route.query.goToLocationId) {
        return {
          currentLocationId: parseInt(this.$route.query.goToLocationId),
        };
      }
      return {};
    },
  },

  methods: {
    nextPage() {
      if (this.currentIndex < this.steps.length - 1) {
        const newPath = this.steps[this.currentIndex + 1].value;
        this.$router.push(newPath);
      } else {
        var data = new FormData();
        data.append("registration_finished", true);
        const userParams = {
          id: this.currentUser.id,
          user: data,
          finished: true,
        };

        this.$store
          .dispatch("user/UPDATE_USER", userParams)
          .then((response) => {
            this.$router.push("/dashboard");
          });
      }
    },
  },
};
</script>

<style scoped>
p,
a {
  font-size: 1.125em;
}
</style>
