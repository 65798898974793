<template>
  <div>
    <div v-if="currentUser.email && currentUser.first_name">
      <UserContinue></UserContinue>
    </div>

    <form v-else @submit.prevent="signup">
      <BaseInput
        type="text"
        name="first_name"
        label-text="First Name"
        v-model="user.first_name"
      />
      <BaseInput
        type="text"
        name="last_name"
        label-text="Last Name"
        v-model="user.last_name"
      />
      <BaseInput
        type="email"
        name="email"
        label-text="Email Address"
        v-model="user.email"
      />
      <BaseInput
        type="password"
        name="password"
        label-text="Password"
        v-model="user.password"
      />
      <BaseInput
        type="password"
        name="password_confirm"
        label-text="Confirm Password"
        v-model="user.password_confirmation"
      />
      <BaseCheckbox
        label-text="I am at least 18 years of age"
        name="of_age"
        v-model="user.of_age"
      />
      <BaseSelect
        class="location-select"
        label-text="Account type"
        :label="true"
        v-model="selectedAccountType"
      >
        <option value="personal">Personal</option>
        <option value="worker">Professional</option>
      </BaseSelect>
      <!-- <div
        id="recaptcha_element"
        class="g-recaptcha"
        data-sitekey="6Lfl4MYUAAAAAErphscFNS9BfgzRoXtcAdvaKYKE"
      ></div> -->
      <BaseButton type="submit">Signup</BaseButton>
      <BaseLink link="/login" text="Already have an account? Login"></BaseLink>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex"
import UserContinue from "./PageRegistrationUserContinue"

export default {
  name: "PageRegistrationSignup",
  title: null,
  components: { UserContinue },
  props: {
    accountType: {
      type: String,
      default: "personal",
    },
  },

  computed: mapState({ currentUser: (state) => state.user.currentUser }),

  data() {
    return {
      user: {},
      selectedAccountType: "personal",
    }
  },

  mounted() {
    // if (window.grecaptcha) {
    //   grecaptcha.render("recaptcha_element", {
    //     sitekey: process.env.RECAPTCHA_SITE_KEY, //'6Lfl4MYUAAAAAErphscFNS9BfgzRoXtcAdvaKYKE'
    //   });
    // }
    this.selectedAccountType = this.accountType
  },

  methods: {
    userParams(user) {
      user.account_type = this.accountType
      return user
    },

    signup() {
      // var response = grecaptcha.getResponse();
      // if (response.length == 0) {
      //   //reCaptcha not verified
      //   //TODO captcha verification
      //   //  alert("Please verify that you are not a robot");
      //   //  return false;
      // }
      //captcha verified
      //do the rest of your validations here

      this.$store
        .dispatch("user/CREATE_USER", this.userParams(this.user))
        .then((response) => {
          const path =
            this.selectedAccountType == "worker"
              ? "/register/professional-type-select"
              : "/register/profile"
          this.$router.push(path)
        })
    },
  },
}
</script>

<style scoped>
div {
  text-align: center;
}
p,
a {
  font-size: 1.125em;
}
.g-recaptcha {
  margin: 5px auto;
  display: inline-block;
}
.g-recaptcha {
  margin-bottom: 15px;
}
</style>
