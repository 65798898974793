<template>
  <div>
    <p class="title" v-if="isNew">Add a New Package</p>
    <p class="title" v-else>Edit this Package</p>

    <BaseInput
      type="text"
      name="packageName"
      label-text="Package name"
      v-model="packageName"
      @change="nameChanged"
    />
    <BaseInput
      type="number"
      name="packagePrice"
      label-text="Price"
      v-model="packagePrice"
      @change="priceChanged"
    />
    <BaseSelect
      class="expiration-select"
      label-text="Expires in "
      :label="true"
      v-model="expirationType"
    >
      <option value="never">Never</option>
      <option value="days">Day(s)</option>
    </BaseSelect>
    <BaseInput
      type="number"
      name="packageDaysExpiry"
      label-text="Day(s) till expiry"
      v-model="expiryDays"
      @change="saveExpiry"
      @input="expiryChange"
      v-if="expirationType == 'days'"
    />
    <BaseTextArea
      name="packageDescription"
      label-text="Package description"
      rows="6"
      v-model="packageDescription"
      @change="descriptionChanged"
    />
    <button
      v-if="!this.activityPackageId"
      class="btn-save"
      @click="saveCreditActivity"
    >
      save & continue
    </button>

    <div v-else>
      <p class="title">Credits In Package</p>
      <div class="added-activity-credits">
        <active-credit-card
          v-for="activeCredit in activeCredits"
          :key="activeCredit.id"
          :activity-credit="activeCredit"
          @remove-credit="removeCreditFromPackage"
          @save-quantity="saveQuantityForCredit"
        />
      </div>
      <div class="horizontal-line"></div>
      <p class="title">Unused Credits</p>
      <add-activity-credit-card @createactivitycredit="createActivityCredit" />
      <div class="horizontal-line"></div>
      <div class="unused-activity-credits">
        <unused-activity-credit-card
          v-for="unusedActivityCredit in unusedCredits"
          :key="unusedActivityCredit.id"
          :activity-credit="unusedActivityCredit"
          @add-credit="addActivityCreditToPackage"
        />
      </div>

      <button class="btn-distructive" @click="goBack">back</button>
      <button
        v-if="this.activityPackageId"
        class="btn-distructive"
        @click="removePackage"
      >
        remove package
      </button>
    </div>
  </div>
</template>

<script>
import ActiveCreditCard from "./ActiveCreditCard";
import AddActivityCreditCard from "./AddActivityCreditCard";
import UnusedActivityCreditCard from "./UnusedActivityCreditCard";

import { mapState } from "vuex";

export default {
  name: "ActivityPackageForm",
  components: {
    ActiveCreditCard,
    AddActivityCreditCard,
    UnusedActivityCreditCard,
  },
  data: function () {
    return {
      activityPackageId: undefined,
      packageName: undefined,
      packagePrice: undefined,
      packageDescription: undefined,
      dbActivityPackage: undefined,
      allCreditActivities: [],
      activityCreditsForNewPackage: [],
      activeCredits: [],
      expirationType: "never",
      expiryDays: null,
      unusedCredits: [
        //   {
        //   id: 1,
        //   name: 'Lesson or Trainer Ride',
        //   quantity: undefined,
        //   usedFor: ['30 Minute Lesson', '30 Minute Trainer Ride']
        // },{
        //   id: 2,
        //   name: 'Grooming Credit',
        //   usedFor: ['Pre-Lesson Tack-up', 'Post-Lesson Tack Cleaning']
        // }, {
        //   id: 3,
        //   name: 'Any Lesson Credit',
        //   usedFor: ['30 Minutes Private Lesson', '30 Minutes Group Lesson']
        // }
      ],
    };
  },
  computed: {
    isNew() {
      return this.activityPackageId === undefined ? true : false;
    },
    ...mapState({ currentUser: (state) => state.user.currentUser }),
  },
  watch: {
    packagePrice: function (newValue) {
      if (newValue < 0) {
        this.packagePrice = 1;
      }
    },
    expirationType: function (newValue) {
      if (newValue == "never") {
        this.expiryDays = null;
      } else {
        if (!this.expiryDays) {
          this.expiryDays = 1;
        }
      }
      this.saveExpiry();
    },
    "currentUser.company_id": function (newValue) {
      if (newValue) {
        if (
          this.$route.params.activityPackageId &&
          this.currentUser.company_id
        ) {
          this.activityPackageId = this.$route.params.activityPackageId;
          this.retrieveActivityPackage();
        }
      }
    },
  },
  methods: {
    nameChanged() {
      if (this.activityPackageId) {
        this.$store.dispatch("activityPackage/UPDATE_ACTIVITY_PACKAGE", {
          id: this.activityPackageId,
          name: this.packageName,
        });
      }
    },
    priceChanged() {
      if (this.packagePrice > 0 && this.activityPackageId) {
        this.$store.dispatch("activityPackage/UPDATE_ACTIVITY_PACKAGE", {
          id: this.activityPackageId,
          price: this.packagePrice,
        });
      }
    },
    saveExpiry() {
      if (this.activityPackageId) {
        this.$store.dispatch("activityPackage/UPDATE_ACTIVITY_PACKAGE", {
          id: this.activityPackageId,
          days_valid_after_purchase: parseInt(this.expiryDays),
        });
      }
    },
    expiryChange() {
      if (this.expiryDays <= 0) {
        this.expiryDays = 1;
      }
      if (this.expiryDays % 1 > 0) {
        this.expiryDays = Math.trunc(this.expiryDays);
      }
    },
    descriptionChanged() {
      if (this.activityPackageId) {
        this.$store.dispatch("activityPackage/UPDATE_ACTIVITY_PACKAGE", {
          id: this.activityPackageId,
          description: this.packageDescription,
        });
      }
    },
    createActivityCredit() {
      this.$router.push("/activity-credits");
    },
    saveCreditActivity() {
      if (this.$route.params.activityPackageIdrou) {
      } else {
        this.$store
          .dispatch("activityPackage/CREATE_ACTIVITY_PACKAGE", {
            name: this.packageName,
            price: this.packagePrice,
            description: this.packageDescription,
            days_valid_after_purchase:
              this.expirationType == "never" ? null : parseInt(this.expiryDays),
            company_id: this.currentUser.company_id,
          })
          .then((r) => {
            const activityId = r.data.id;
            this.$router.push("/activity-package/" + activityId);
            window.location.reload();
          });
      }
    },
    addActivityCreditToPackage(e) {
      if (!this.activityCreditsForNewPackage.some((r) => r.id == e)) {
        this.activityCreditsForNewPackage.push({ id: e, quantity: undefined });
        let index = this.unusedCredits.findIndex((r) => r.id == e);
        if (index >= 0) {
          let addedActivity = {
            ...this.unusedCredits[index],
            quantity: 1,
          };
          this.activeCredits.push(addedActivity);
          this.$store
            .dispatch("activityPackage/ADD_PACKAGE_MEMBERSHIP_TO_PACKAGE", {
              quantity: addedActivity.quantity,
              activity_credit_id: addedActivity.id,
              activity_package_id: this.activityPackageId,
            })
            .then((r) => {
              addedActivity.membership_id = r.data.id;
            });
          this.unusedCredits.splice(index, 1);
        }
      }
    },
    removeCreditFromPackage(e) {
      const found = this.activeCredits.filter((r) => r.id == e)[0];
      this.$store
        .dispatch(
          "activityPackage/DELETE_PACKAGE_MEMBERSHIP",
          found.membership_id
        )
        .then((r) => {
          this.activeCredits.splice(this.activeCredits.indexOf(found), 1);
          this.unusedCredits.push({
            id: found.id,
            name: found.name,
            usedFor: found.usedFor,
            quantity: 1,
          });
        });

      // if (this.activityCreditsForNewPackage.some(r => r.id == e)) {
      //   let currentIndex = this.activityCreditsForNewPackage.findIndex(
      //     r => r.id == e
      //   );
      //   if (currentIndex >= 0) {
      //     this.activityCreditsForNewPackage.splice(currentIndex, 1);
      //   }
      //   let index = this.activeCredits.findIndex(r => r.id == e);
      //   if (index >= 0) {
      //     this.unusedCredits.push(this.activeCredits[index]);
      //     this.activeCredits.splice(index, 1);
      //   }
      // }
    },
    getCompanyActivityCredits() {
      this.$store
        .dispatch(
          "activityCredit/GET_COMPANY_ACTIVITY_CREDITS",
          this.currentUser.company_id
        )
        .then((r) => {
          this.allCreditActivities = r.data.map((act) => {
            return {
              id: act.id,
              name: act.name,
              usedFor: act.company_activities.map((companyActivity) => {
                return companyActivity.full_name;
              }),
            };
          });
          (this.unusedCredits = []),
            (this.unusedCredits = this.allCreditActivities.filter((creditAct) =>
              this.dbActivityPackage.package_memberships.every(
                (mem) => mem.activity_credit.id != creditAct.id
              )
            ));
          (this.activeCredits = []),
            (this.activeCredits =
              this.dbActivityPackage.package_memberships.map((mem) => {
                let activity = this.allCreditActivities.filter(
                  (act) => act.id == mem.activity_credit.id
                )[0];
                return {
                  id: activity.id,
                  membership_id: mem.id,
                  name: activity.name,
                  usedFor: activity.usedFor,
                  quantity: mem.quantity,
                };
              }));
          //this.unusedCredits = this.allCreditActivities;
        });
    },
    saveQuantityForCredit(e) {
      let id = e.id;
      let quantity = e.quantity;
      let memId = e.membership_id;
      this.$store.dispatch(
        "activityPackage/UPDATA_PACKAGE_MEMBERSHIP_QUANTITY",
        {
          id: memId,
          quantity: quantity,
        }
      );
    },
    retrieveActivityPackage() {
      if (this.activityPackageId != null && this.activityPackageId != "") {
        this.$store
          .dispatch(
            "activityPackage/GET_ACTIVITY_PACKAGE",
            this.activityPackageId
          )
          .then((r) => {
            this.dbActivityPackage = r.data;
            this.packageName = this.dbActivityPackage.name;
            this.packagePrice = this.dbActivityPackage.price;
            this.packageDescription = this.dbActivityPackage.description;
            this.expiryDays = this.dbActivityPackage.days_valid_after_purchase;
            if (this.expiryDays != null) {
              this.expirationType = "days";
            } else {
              this.expirationType = "never";
            }
            this.getCompanyActivityCredits();
          });
      }
    },
    removePackage() {
      this.$store
        .dispatch(
          "activityPackage/DELETE_ACTIVITY_PACKAGE",
          this.activityPackageId
        )
        .then((r) => {
          window.history.length > 1
            ? this.$router.go(-1)
            : this.$router.push("/");
        });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
  mounted() {
    if (this.$route.params.activityPackageId && this.currentUser.company_id) {
      this.activityPackageId = this.$route.params.activityPackageId;
      this.retrieveActivityPackage();
    }
  },
};
</script>

<style scoped>
.title {
  color: var(--page-title-color);
  font-weight: 800;
  font-size: 20px;
  margin: 0px 0 20px 0;
}
.added-activity-credits,
.unused-activity-credits {
  display: flex;
  flex-direction: column;
}
.horizontal-line {
  width: 100%;
  height: 30px;
  border-bottom: 2px solid rgba(155, 155, 155, 0.5);
  margin-bottom: 20px;
}
.btn-distructive {
  color: #adadad;
  background-color: #ffffff;
}

.btn-save {
  background-color: #9b9b9b;
  color: #fcfcfc;
  border: 2px solid #9b9b9b;
}

button {
  width: 100%;
  padding: 10px 0;
  border-radius: 50px;
  border: 2px solid #ebebeb;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  margin: 10px 0 0 0;
  outline: none;
}
</style>