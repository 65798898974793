<template>
  <Flex justify="between" align="stretch">
    <BasePanel theme="date">
      <Flex justify="center" class="weekday">
        {{ weekday }}
      </Flex>
      <Flex justify="center" class="day">
        {{ dayOfMonth }}
      </Flex>
    </BasePanel>
    <CalendarPanel
      class="calendar-panel"
      :theme="theme"
      :start-time="startTime"
      :end-time="endTime"
      :avatar="avatar"
      :trainer="trainer"
      :horse="horse"
      :status="status"
      :activity-type="activityType"
    />
  </Flex>
</template>

<script>
import Flex from "../style/Flex";
import CalendarPanel from "./CalendarPanel";

export default {
  name: "CalendarDatePanel",
  components: { Flex, CalendarPanel },
  props: {
    theme: String,
    horse: {
      type: String,
      default: "",
    },
    avatar: {
      type: String,
      default: null,
    },
    activityType: {
      type: String,
      default: "",
    },
    trainer: {
      type: String,
      default: "",
    },
    startTime: {
      type: String,
      default: "9:00am",
    },
    endTime: {
      type: String,
      default: "8:00pm",
    },
    status: {
      type: String,
      default: "",
    },
  },

  computed: {
    weekday() {
      if (this.startTime) return this.moment.utc(this.startTime).format("ddd");
    },

    dayOfMonth() {
      if (this.startTime) return this.moment.utc(this.startTime).format("DD");
    },
  },
};
</script>

<style scoped>
.weekday,
.day {
  color: var(--navy);
  font-weight: var(--semibold);
  text-align: center;
  padding: 0 0.5rem;
}
.weekday {
  border-bottom: 1px solid var(--panel-border-color);
  font-size: 1.3125em;
  padding: 0.25rem;
  text-transform: uppercase;
}
.day {
  font-size: 2.25em;
}
.calendar.calendar-panel {
  flex: 1 0 0;
  min-width: 0;
}
</style>
