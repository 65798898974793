<template>
  <article class="activity-panel">
    <BasePanel theme="active">
      <p class="lesson-name">
        {{ employeeActivity.activity.service }}
        ({{ employeeActivity.activity.duration_in_minutes }}) - ${{
          employeeActivity.price
        }}
      </p>

      <p class="lesson-time space" v-if="timeBlock">
        {{ moment.utc(timeBlock.start_time).format("MMMM DD, YYYY hh:mm a") }} -
        {{ moment.utc(timeBlock.end_time).format("hh:mm a") }}
      </p>

      <p class="location">
        Location:
        {{ location.name }}
      </p>

      <Flex justify="start" class="space">
        <h2>Trainer:</h2>
        <Flex justify="start">
          <Avatar
            size="small"
            :image-source="employeeActivity.employee.avatar"
          />
          <div>
            <p>{{ employeeActivity.employee.name }}</p>
          </div>
        </Flex>
      </Flex>

      <Flex justify="start" class="space">
        <h2>Description:</h2>
        <br />
        {{ employeeActivity.activity.description }}
      </Flex>
    </BasePanel>
  </article>
</template>

<script>
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";

export default {
  name: "UserActivityInformation",
  props: { employeeActivity: Object, timeBlock: Object, location: Object },
  components: { Flex, Avatar },
};
</script>

<style>
h2 {
  font-size: 1.25em;
  font-weight: var(--semibold);
}
.name {
  color: var(--charcoal);
  font-size: 1.3125em;
  font-weight: var(--semibold);
}
.location {
  color: var(--navy);
  font-size: 1.125em;
  font-weight: var(--semibold);
}
.lesson-name {
  color: var(--charcoal);
  font-size: 1.25em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.lesson-time {
  font-size: 1.25em;
  font-style: italic;
  font-weight: var(--semibold);
}
</style>
