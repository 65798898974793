<template>
  <div v-if="user.id">
    <BackButton theme="round" style-class="top-left" />
    <div class="user-heading">
      <div class="user-content">
        <div class="logo-box">
          <img :src="logoImage" alt="logo" class="logo-image" />
        </div>
        <div class="user-avatar">
          <Avatar :image-source="userImage" size="large" />
        </div>
        <h3 class="user-header-details text-center title">{{ user.name }}</h3>
        <!-- <h4 class="user-header-details text-center subtitle">
          Status
        </h4>-->
      </div>
      <!-- <button id="btn-follow"> FOLLOW 
         <span class="hearth">♡  ♥</span>
      </button>-->

      <button id="btn-mail" v-if="user.email" @click="sendToMail">
        <font-awesome-icon :icon="['fas', 'envelope']" fixed-width />
      </button>

      <!-- THIS BUTTON WILL BE SHOWN WHEN WE DO SOME VALIDATION ON WHO CAN SEE IT -->
      <!-- <button id="btn-settings" v-if="currentUser.account_type !== 'personal'" @click="goToSettings">
        <font-awesome-icon :icon="['fas','cog']" fixed-width />
      </button>-->
    </div>

    <div v-if="user.account_type != 'personal'">
      <span class="contact">Companies</span>

      <div class="companies-wrapper">
        <router-link
          :to="'/companies/profile/' + company.id"
          v-for="company in companies"
          :key="company.id"
        >
          <company-card
            :avatarImage="company.avatar"
            :companyName="company.name"
            :trainer-type="company.owner_id == user.id ? 'Owner' : 'Member'"
          />
        </router-link>
      </div>
    </div>

    <span class="contact">Barns</span>

    <div class="barns-wrapper" v-if="user.account_type != 'personal'">
      <router-link
        :to="'/companies/locations/' + location.id"
        v-for="location in locations"
        :key="location.id"
      >
        <barn-card
          :avatarimage="location.avatarImage"
          :barnname="location.barnName"
          :trainer-type="location.type"
        />
      </router-link>
    </div>

    <div v-else>
      <router-link
        :to="'/companies/locations/' + location.location.id"
        v-for="location in barns"
        :key="location.location.id"
      >
        <div v-if="location.location">
          <barn-card
            :avatarimage="location.location.avatar"
            :barnname="location.location.name"
          />
        </div>
      </router-link>
    </div>

    <div v-if="user && horses.length">
      <span class="contact">Horses</span>

      <router-link
        v-for="horse in this.horses"
        :to="'/horses/' + horse.id"
        :key="horse.id"
      >
        <HorsePanel :horse="horse"> </HorsePanel>
      </router-link>
    </div>

    <div v-if="user.bio">
      <p class="user-description">{{ aboutParagraph }}</p>

      <span
        class="expand-reading"
        v-if="showContinueReading && !continueReadingPressed"
        @click="continueReadingPressed = true"
        >Continue Reading...</span
      >
    </div>

    <div v-if="user.account_type != 'personal'">
      <span class="contact">Services Offered</span>

      <user-services-offered-card
        v-for="company in companies"
        :key="company.id"
        :company="company"
      />
    </div>
  </div>
</template>

<script>
import Avatar from "../utils/Avatar";
import Flex from "../style/Flex";
import CompanyCard from "../common/CompanyCard";
import BarnCard from "../common/BarnCard";
import UserServicesOfferedCard from "./UserServicesOfferedCard";
import { mapState } from "vuex";
import HorsePanel from "../horses/HorsePanel";
import BackButton from "../common/BackButton.vue";

export default {
  name: "PageUserProfile",
  components: {
    Flex,
    Avatar,
    CompanyCard,
    BarnCard,
    UserServicesOfferedCard,
    HorsePanel,
    BackButton,
  },

  data() {
    return {
      user: {},
      logoImage: require("../../assets/images/logo.svg"),
      defaultCompanyImage: require("../../assets/images/covers/company.jpg"),
      continueReadingPressed: false,
      defaultLocationImage: require("../../assets/images/covers/location.jpg"),
      companies: [],
      userActivities: [],
      barns: [],
      horses: [],
    };
  },

  computed: {
    ...mapState({ currentUser: (state) => state.user.currentUser }),
    userImage() {
      if (this.user.avatar) {
        return this.user.avatar;
      }
      return this.defaultUserImage;
    },
    locations() {
      if (this.companies && this.companies.length > 0) {
        let locations = [];
        for (let i = 0; i < this.companies.length; i++) {
          for (let j = 0; j < this.companies[i].locations.length; j++) {
            if (this.user.id == this.companies[i].owner_id) {
              this.companies[i].locations[j].user_type = "Owner";
            } else {
              this.companies[i].locations[j].user_type = "Member";
            }
          }
          locations = locations.concat(this.companies[i].locations);
        }
        return locations.map((r) => {
          return {
            id: r.id,
            avatarImage: r.avatar,
            barnName: r.name,
            type: r.user_type,
          };
        });
      }

      return [];
    },
    aboutParagraph() {
      if (this.user && this.user.bio) {
        var allWords = this.user.bio.split(" ");
        var result = "";
        for (var i = 0; i < allWords.length; i++) {
          if (i < 30) {
            result += allWords[i] + " ";
          } else if (i >= 30 && this.continueReadingPressed) {
            result += allWords[i] + " ";
          }
        }
        if (allWords.length >= 30 && !this.continueReadingPressed) {
          result += "...";
        }
        return result;
      }
      return "";
    },
    showContinueReading() {
      if (!this.user || !this.user.bio) {
        return false;
      }
      var allWords = this.user.bio.split(" ");
      if (allWords.length > 29) {
        return true;
      }
      return false;
    },
  },

  async mounted() {
    await this.getUser();
    await this.getUserCompany();
    await this.getUserHorses();
    await this.getUserServices();
    if (this.user.account_type == "personal") {
      this.getPersonalUserBarns(this.user.id);
    }
  },

  methods: {
    async getUserHorses() {
      await this.$store
        .dispatch("horse/GET_HORSES_FOR_ACTIVITY", {
          owner_id: this.user.id,
          type: "owned",
        })
        .then((response) => {
          this.horses = response.data;
        });
    },

    async getUserServices() {
      this.$store
        .dispatch("activity/GET_TRAINER_ACTIVITIES", {
          userId: this.user.id,
        })
        .then((r) => {
          this.userActivities = r.data;
          this.userActivities.forEach((activity) => {
            let company_id = activity.company_activity_company_id;
            let company = this.companies.filter(
              (comp) => comp.id == company_id
            )[0];
            company.available_services.push(activity);
          });
        });
    },

    getPersonalUserBarns(userId) {
      this.$store
        .dispatch("location/GET_USER_LOCATIONS", userId)
        .then((response) => {
          this.barns = response.data;
        });
    },

    async getUser() {
      await this.$store
        .dispatch("user/FETCH_USER", this.$route.params.id)
        .then((response) => {
          this.user = response.data;
        });
    },
    sendToMail() {
      window.location.href = "mailto:" + this.user.email;
    },
    async getUserCompany() {
      await this.$store
        .dispatch("company/GET_EMPLOYERS", this.user.id)
        .then((r) => {
          if (r.data) {
            this.companies = r.data
              .filter((relationship) => {
                return relationship.approved || true; // The features for the approved relationship between worker and company are not yet implemented
              })
              .map((relationship) => {
                relationship.employer.available_services = [];
                return relationship.employer;
              });
          }
        });

      // this.$store.dispatch('company/GET_COMPANY',  this.user.company_id)
      //   .then(response => {
      //     this.company = response.data;
      //   })
    },
  },
};
</script>

<style scoped>
.hearth {
  color: red;
}
.text-center {
  text-align: center;
}
#btn-follow {
  background-color: #ffffff;
  padding: 1em 2em;
  border-radius: 50px;
  top: 100%;
  position: relative;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  color: #bcbcbc;
  border: 1px solid #bcbcbc;
  display: none;
}
#btn-follow > span {
  color: #ff9cb0;
  margin-left: 5px;
}

.stats-title,
.about-title {
  color: #2c526e;
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.user-name {
  color: var(--navy);
  font-size: 1.75em;
  font-weight: var(--semibold);
  margin-bottom: 0.25em;
}
.title {
  font-size: 1.4em;
  font-weight: var(--semibold);
  line-height: 1.6;
  color: #ffffff;
}
.subtitle {
  font-size: 1.1em;
  color: #ffffff;
}
.user-heading {
  background: url(../../assets/images/covers/company.jpg);
  background-position: center;
  background-size: cover;
  height: 45vh;
  margin-bottom: 30px;
}
.user-content {
  position: absolute;
  height: 15%;
  width: 40%;
  left: 50%;
  top: 12%;
  transform: translateX(-50%);
  text-align: center;
}
.user-avatar {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
}
.user-avatar-image {
  border-radius: 50%;
  margin-bottom: 30%;
  height: 90px;
  width: 90px;
}
.logo-image {
  height: 100px;
  width: 100%;
}
.heading {
  color: var(--navy);
  font-size: 1.15em;
  font-weight: var(--semibold);
}
.user-profile-input {
  width: 98%;
}
.user-profile-input-inline {
  display: inline-block;
}
.about-content {
  padding: 0px 8px;
  /* font-weight: var(--semibold); */
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
}
.continue-reading {
  color: #4cc1ba;
  margin-top: 10px;
  font-size: 18px;
}

.user-header-details {
  text-shadow: 2px 2px 4px #000000;
}

#btn-mail {
  background-color: #4ac1ba;
  border-radius: 700px;
  padding: 1em 1em;
  top: 100%;
  position: relative;
  transform: translateY(-50%);
  float: right;
  right: 8%;
  color: #ffffff;
  border: 1px solid #bcbcbc;
  font-size: 100%;
  margin-left: 10px;
}

.contact {
  color: #4a4a4a;
  line-height: 30px;
  font-size: 1.25em;
  font-weight: var(--semibold);
}

.user-description {
  font-size: 18px;
  line-height: 1.3;
  margin: 20px 0;
  font-family: "Graphik";
}

.expand-reading {
  color: #4ac1ba;
  font-size: 20px;
  margin: 10px 0 30px 0;
  display: block;
  font-style: italic;
}
</style>
