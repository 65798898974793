import axios from "axios";
import {
  FETCH_USER_PAYMENT_PROFILE,
  UPDATE_USER_PAYMENT_PROFILE,
  GET_USER_STRIPE_CHARGES,
  GET_USER_STRIPE_CHARGE,
  DELETE_USER_PAYMENT_PROFILE,
  CREATE_STRIPE_CONNECTED_ACCOUNT,
  GET_USER_INVOICE,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  GET_COMPANY_INVOICES,
  GET_USER_INVOICES,
  GET_COMPANY_INVOICE,
  GET_STRIPE_DASHBOARD,
} from "../actions/payment";

// Initial State
const state = {};

// Getters
const getters = {};

// Actions
const actions = {
  DELETE_USER_PAYMENT_PROFILE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/user_payment_profiles/${params.user_id}`, {
          params: { source_id: params.source_id },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_USER_PAYMENT_PROFILE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/user_payment_profiles/${params.user_id}`, params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  FETCH_USER_PAYMENT_PROFILE({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user_payment_profiles/${userId}`)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  CREATE_STRIPE_CONNECTED_ACCOUNT({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/stripe_accounts", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_USER_STRIPE_CHARGES({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/user_payments", { params: { user_id: userId } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_USER_STRIPE_CHARGE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/user_payments/${params.user_id}`, {
          params: { charge_id: params.charge_id, user_id: params.user_id },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_BILLING_COMPANY({ commit }, stripeAccountId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/billing_companies/${stripeAccountId}`, {
          params: { stripe_account_id: stripeAccountId },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  CREATE_INVOICE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post("/company_invoices", params)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  UPDATE_INVOICE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/company_invoices/${params.id}`, params.data)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_COMPANY_INVOICE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company_invoices/${params.id}`, {
          params: { company_id: params.company_id },
        })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_USER_INVOICE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invoices/${params.id}`, { params: { user_id: params.user_id } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_USER_INVOICES({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/invoices", { params: { user_id: userId } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  GET_COMPANY_INVOICES({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/company_invoices", { params: { company_id: companyId } })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },

  PAY_INVOICE({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/invoices/${params.id}`, { subscription: params.subscription })
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
  GET_STRIPE_DASHBOARD({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios
        .post("/trainer_stripe_connect_dashboard?company_id=" + companyId)
        .then((response) => {
          commit("global/GLOBAL_SUCCESS", "", { root: true });
          resolve(response);
        })
        .catch((err) => {
          commit("global/GLOBAL_ERROR", err.message, { root: true });
          reject(err);
        });
    });
  },
};

// Mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
